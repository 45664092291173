<main class="profile-page" style="margin-top: 10%">
 
    <section class="relative py-16  ">
      <div class="container mx-auto px-4">
        <div
          class="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-xl rounded-lg -mt-64"
          style="min-height: 300px;">
          <div class="px-6">
            <div class="flex flex-wrap justify-center">
              <div class="w-full lg:w-3/12 px-4 lg:order-2 flex justify-center">
                <div class="relative">
              
                </div>
              </div>
              <div
                class="w-full lg:w-4/12 px-4 lg:order-3 lg:text-right lg:self-center"
              >
          
              </div>
            
            </div>
            <div class="text-center mt-12">
              <h5
                class="text-2xl font-semibold leading-normal mb-2 text-blueGray-700 mb-2"
              >
              {{ _langService.GetLanguage()=='FR' ? ' Solution nutritive préparé le: ' : 'Nutrient solution prepared on: ' }}
              {{date}} 
              </h5>
              <div
                class="text-2xl leading-normal mt-0 mb-2 text-blueGray-400 font-bold uppercase"
              >
              {{ _langService.GetLanguage()=='FR' ? 'Opérateur: ' : 'Operator: ' }}

                {{operateur}}
              </div>
             
         
     
            </div>

            <div
            class="text-sm leading-normal mt-0 mb-2 text-blueGray-400 font-bold  "
          >
      
            
          <div class="card" *ngIf="cuve1.length" >

            <div class="container">
                <h4><b>
                  {{ _langService.GetLanguage()=='FR' ? 'CUVE N°1: ' : 'TANK N°1: ' }}
                  </b></h4> 
                <p>
                    <li >
                      {{ _langService.GetLanguage()=='FR' ? "Volume d'eau: " : 'Water volume: ' }}
                         {{eau1.quantity}}
                      </li>

                      <li *ngFor="let SC of cuve1">
                        {{SC.quantity}}    {{SC.mesure}} 
                        {{ _langService.GetLanguage()=='FR' ? ' de ' : ' of ' }}

                          {{SC.label}}
                      </li>

                </p> 
              </div>
            </div>      
           
            <div class="card"  *ngIf="cuve2.length" >
  
              <div class="container">
                  <h4><b>
                    {{ _langService.GetLanguage()=='FR' ? 'CUVE N°2: ' : 'TANK N°2: ' }}
                    </b></h4> 
                  <p>
                    <li >
                      {{ _langService.GetLanguage()=='FR' ? "Volume d'eau: " : 'Water volume: ' }}
                      {{eau2.quantity}}
                      </li>

                      <li *ngFor="let SC of cuve2">
                        {{SC.quantity}}    {{SC.mesure}} 
                        {{ _langService.GetLanguage()=='FR' ? ' de ' : ' of ' }}

                          {{SC.label}}
                        </li>
  
                  </p> 
                </div>
              </div>      
           
              <div class="card"   *ngIf="cuve3.length">
    
                <div class="container">
                    <h4><b>
                      {{ _langService.GetLanguage()=='FR' ? 'CUVE N°3: ' : 'TANK N°3: ' }}
                    </b></h4> 
                    <p>
                        <li >
                          {{ _langService.GetLanguage()=='FR' ? "Volume d'eau: " : 'Water volume: ' }}
                          {{eau3.quantity}}
                          </li>
    
                        <li *ngFor="let SC of cuve3 ">
                          {{SC.quantity}}    {{SC.mesure}} 
                          {{ _langService.GetLanguage()=='FR' ? ' de ' : ' of ' }}
  
                            {{SC.label}}
                          </li>
    
                    </p> 
                  </div>
                </div>      
           
                <div class="card"   *ngIf="cuve4.length">
      
                  <div class="container">
                      <h4><b>
                        {{ _langService.GetLanguage()=='FR' ? 'CUVE N°4: ' : 'TANK N°4: ' }}

                      </b></h4> 
                      <p>
                        <li >
                          {{ _langService.GetLanguage()=='FR' ? "Volume d'eau: " : 'Water volume: ' }}
                          {{eau4.quantity}}
                          </li>
    
                          <li *ngFor="let SC of cuve4">
                            {{SC.quantity}}    {{SC.mesure}} 
                            {{ _langService.GetLanguage()=='FR' ? ' de ' : ' of ' }}
    
                              {{SC.label}}
                            </li>
      
                      </p> 
                    </div>
                  </div>      
           
                  <div class="card"   *ngIf="cuve5.length">
        
                    <div class="container">
                        <h4><b>
                          {{ _langService.GetLanguage()=='FR' ? 'CUVE N°5: ' : 'TANK N°5: ' }}
                           </b></h4> 
                        <p>
                            <li >
                              {{ _langService.GetLanguage()=='FR' ? "Volume d'eau: " : 'Water volume: ' }}
                              {{eau5.quantity}}
                              </li>
        
                            <li *ngFor="let SC of cuve5">
                              {{SC.quantity}}    {{SC.mesure}} 
                              {{ _langService.GetLanguage()=='FR' ? ' de ' : ' of ' }}
      
                                {{SC.label}}
                              </li>
        
                        </p> 
                      </div>
                    </div>
                       
           
                  <div class="card"   *ngIf="cuve6.length">
        
                    <div class="container">
                    
                        <h4><b>
                          {{ _langService.GetLanguage()=='FR' ? 'CUVE IBC P2: ' : 'TANK IBC P2: ' }}
                          </b></h4> 
                        <p>
                            <li >
                              {{ _langService.GetLanguage()=='FR' ? "Volume d'eau: " : 'Water volume: ' }}
                              {{eau6.quantity}}
                              </li>
        
                            <li *ngFor="let SC of cuve6">
                              {{SC.quantity}}    {{SC.mesure}} 
                              {{ _langService.GetLanguage()=='FR' ? ' de ' : ' of ' }}
      
                                {{SC.label}}
                              </li>
        
                        </p> 
                      </div>
                    </div>
          </div>
      
          </div>
        </div>
      </div>
    </section>
  </main>