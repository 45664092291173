
<div
class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded"
[ngClass]="color === 'light' ? 'bg-white' : 'bg-red-900 text-white'"
>
<div class="rounded-t mb-0 px-4 py-3 border-0">
  <div class="flex flex-wrap items-center">
    <div class="relative w-full px-4 max-w-full flex-grow flex-1">
      <h3
        class="font-semibold text-lg"
        [ngClass]="color === 'light' ? 'text-blueGray-700' : 'text-white'"
      >
      {{ _langService.GetLanguage()=='FR' ? 'Liste des commandes' : 'List of commands' }}

        
      </h3>
    </div>
  </div>
</div>
<div class="block w-full overflow-x-auto">
  <!-- Projects table -->
  <table class="items-center w-full bg-transparent border-collapse">
    <thead>
      <tr>
        <th
          class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
          [ngClass]="
            color === 'light'
              ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
              : 'bg-red-800 text-red-300 border-red-700'
          " 
          (click)="sort('variete')"
        >
        {{ _langService.GetLanguage()=='FR' ? 'Variété' : 'Variety' }}

        
        </th>
        <th
          class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
          [ngClass]="
            color === 'light'
              ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
              : 'bg-red-800 text-red-300 border-red-700'
          "
          (click)="sort('dateCommande')"
        >
        {{ _langService.GetLanguage()=='FR' ? 'Date commande' : 'Order date' }}

          
        </th>
        <th
          class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
          [ngClass]="
            color === 'light'
              ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
              : 'bg-red-800 text-red-300 border-red-700'
          "
          (click)="sort('nbr')"
        >
        {{ _langService.GetLanguage()=='FR' ? 'Nombre de' : 'Number of' }}
        <br> 
        {{ _langService.GetLanguage()=='FR' ? 'graines commandées' : 'seeds ordered' }}

           
          
        </th>
        <th
        class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
        [ngClass]="
          color === 'light'
            ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
            : 'bg-red-800 text-red-300 border-red-700'
        "
        (click)="sort('nbr_recue')"
      >
      {{ _langService.GetLanguage()=='FR' ? 'Nombre de' : 'Number of' }}
      <br> 
      {{ _langService.GetLanguage()=='FR' ? 'graines reçues' : 'seeds received' }}
        
        
      </th>
        <th
          class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
          [ngClass]="
            color === 'light'
              ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
              : 'bg-red-800 text-red-300 border-red-700'
          "
          (click)="sort('fournisseur')"
        >

        {{ _langService.GetLanguage()=='FR' ? 'Fournisseur' : 'Supplier' }}
        
          
        </th>
        <th
          class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
          [ngClass]="
            color === 'light'
              ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
              : 'bg-red-800 text-red-300 border-red-700'
          "
          (click)="sort('type')"
        >
        Type
        </th>
        <th
        class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
        [ngClass]="
          color === 'light'
            ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
            : 'bg-red-800 text-red-300 border-red-700'
        "
        (click)="sort('etat')"
      >
      {{ _langService.GetLanguage()=='FR' ? 'État' : 'Condition' }}

      
      </th>
      <th
      class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-center"
      [ngClass]="
        color === 'light'
          ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
          : 'bg-red-800 text-red-300 border-red-700' "
    >
  Actions
  </th>
      </tr>
    </thead>
    <tbody>
      <tr  *ngFor="let commande of listcommande" >
        <th
          class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left flex items-left"
        >
      
          <span
            class="ml-3 font-bold"
            [ngClass]="color === 'light' ? 'text-blueGray-600' : 'text-white'"
          >
            {{commande.variete}}
          </span>
        </th>
        <td
          class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
        >
        {{commande.dateCommande}}
        </td>
        <td
          class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
        >
        {{commande.nbr}}

        </td>
        <td
          class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
        >
        {{commande.nbr_recue}}

        </td>

        <td
          class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
        >
       {{commande.fournisseur}}
        </td>
        <td
          class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
        >
        {{commande.type}}
        </td>
        <td
        class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
      >
      {{commande.etat}}
      </td>
      
            
         <td
         class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-center"
       style="cursor:grab ;"
         >
        <i class="fas fas fa-edit "  style="margin-right:15px ; color: rgb(79, 170, 255);"  [routerLink]="['/admin/editCommande/',commande.id_commande]"></i>
       <i class="fas fa-trash "  style="margin-right:15px ; color: rgb(255 89 89);"  [routerLink]="['/admin/deleteCommande/',commande.id_commande]"></i>
      </td>
      </tr>
 
    </tbody>
  </table>
</div>
</div>
 