<div
  class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0"
>
  <div class="rounded-t bg-white mb-0 px-6 py-6">
    <div class="text-center flex justify-between">
      <h6 class="text-blueGray-700 text-xl font-bold">
        <span> {{ _langService.GetLanguage()=='FR' ? 'Préparer une solution nutritive' : 'Prepare a nutrient solution' }}</span>
        

        <button  [routerLink]="['/admin/Historique_Solution/' ]"  style=" position: absolute;right: 50px;  background-color: rgb(70, 175, 0); color: white;padding: 5px;border-radius: 6px;">
          <span> {{ _langService.GetLanguage()=='FR' ? 'Historique des solutions nutritives' : 'History of nutrient solutions' }}</span>

          
        </button>

      </h6>
   
    </div>
  </div>
  <div class="flex-auto px-4 lg:px-10 py-10 pt-0">
    <form [formGroup]="_solutionService.formSolution" autocomplete="off"  >
      <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
        <span> {{ _langService.GetLanguage()=='FR' ? 'Informations générales' : 'General informations' }}</span>
        
      </h6>
      <div class="flex flex-wrap">
 
 
        <div class="w-full lg:w-6/12 px-4">
            <div class="relative w-full mb-3">
            
              <label
              class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
           
            >
           {{ _langService.GetLanguage()=='FR' ? "Date d'opération" : 'Operation date' }} 
            
            </label>
       
              <input class="datepicker-here form-control border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"  name="dateDebutprojet"  formControlName="dateDebutprojet"  
                type="date" id="start" name="trip-start"
                 formControlName="date"
                 [value]="date"
                 [(ngModel)]="date"  > 
 
            </div>
          </div>
  
        <div class="w-full lg:w-6/12 px-6">
          <div class="relative w-full mb-3">
            <label
              class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
           
            >
           {{ _langService.GetLanguage()=='FR' ? 'Opérateur' : 'Operator' }} 
            
            </label>
            <input
            type="numbre"
               class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
              [placeholder]="_langService.GetLanguage()=='FR' ? 'Opérateur' : 'Operator' "
              formControlName="operateur"
              [class.is-invalid]="_solutionService.formSolution.controls['operateur'].errors?.required"

             />
             
      
          </div>
        </div>
    </div>


   
    </form>
    <!------ cuve 1 ------->

    <form  [formGroup]="_solutionService.formSolution_Fertisant" autocomplete="off"   > 
 <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
  {{ _langService.GetLanguage()=='FR' ? 'Cuve N°1' : 'Tank No. 1' }} 
       
      </h6>
      <div class="w-full lg:w-4/12 px-4">
        <div class="relative w-full mb-3">
            <label
              class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
             >
             {{ _langService.GetLanguage()=='FR' ? "Volume d'eau" : 'Water volume' }}
            
            </label>
            <input
            type="number"
             min="1"
              class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
              [placeholder]="_langService.GetLanguage()=='FR' ? 'Volume d eau' : 'Water volume'"
              formControlName="eau1"
              [class.is-invalid]="_solutionService.formSolution_Fertisant.controls['eau1'].errors?.required"
              style="width:200px ;"
              />
             
      
          </div>        
          <div class="relative w-full mb-3">
            <label
              class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
             >
             {{ _langService.GetLanguage()=='FR' ? 'Nombre de fertilisants' : 'Number of fertilizers' }}
            
            </label>
            <input
            type="number"
             min="1"
             (change)="nbr_fertisants1()"
              class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
              [placeholder]="_langService.GetLanguage()=='FR' ? 'Nombre de fertilisants' : 'Number of fertilizers'"
              formControlName="nbr_cuve1"
              [class.is-invalid]="_solutionService.formSolution_Fertisant.controls['nbr_cuve1'].errors?.required"
              style="width:200px ;"
              />
             
      
          </div>
      </div>
  
</form>
<div class="flex flex-wrap">
     
    <table class="flex flex-wrap"> 

        <tbody>
        <tr  *ngFor="let dynamic of dynamicArray; let i = index;" style="min-width: 500px !important; ;" >
          <td > 
   
          <span   style=" display: inline-block; width: 250px;">
            <select     
            class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
            name="{{dynamic.fertilisant_object}}" [(ngModel)]="dynamic.fertilisant_object"  type="text" 
    
          >
          <option   *ngFor="let nbr of NBR"
          [value] ="nbr.id_fertilisant" >   {{nbr.label}}
         </option>
               </select>
              </span>
        
              <span  style=" display: inline-block; width: 150px; ">
                <input     
                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                name="{{dynamic.quantity}}" [(ngModel)]="dynamic.quantity"  type="number" 
                    placeholder="Quantité"
                />
                  </span>
                  <span  style=" display: inline-block; width: 50px; ">
                  {{mesure(dynamic.fertilisant_object) }} 
                  </span>
                  </td>
                </tr>
              </tbody>
  
   </table>


</div> 
<!------ cuve 2 ------->
<form  [formGroup]="_solutionService.formSolution_Fertisant" autocomplete="off"   > 
    <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
      {{ _langService.GetLanguage()=='FR' ? 'Cuve N°2' : 'Tank No. 2' }} 
         </h6>
         <div class="w-full lg:w-4/12 px-4">
            <div class="relative w-full mb-3">
                <label
                  class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                 >
                 {{ _langService.GetLanguage()=='FR' ? "Volume d'eau" : 'Water volume' }}
                </label>
                <input
                type="number"
                 min="1"
                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  [placeholder]="_langService.GetLanguage()=='FR' ? 'Volume d eau' : 'Water volume'"
                  formControlName="eau2"
                  [class.is-invalid]="_solutionService.formSolution_Fertisant.controls['eau2'].errors?.required"
                  style="width:200px ;"
                  />
                 
          
              </div>        
           <div class="relative w-full mb-3">
             <label
               class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
              >
              {{ _langService.GetLanguage()=='FR' ? 'Nombre de fertilisants' : 'Number of fertilizers' }}
             </label>
             <input
             type="number"
              min="1"
              (change)="nbr_fertisants2()"
               class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
               [placeholder]="_langService.GetLanguage()=='FR' ? 'Nombre de fertilisants' : 'Number of fertilizers'"
               formControlName="nbr_cuve2"
               [class.is-invalid]="_solutionService.formSolution_Fertisant.controls['nbr_cuve2'].errors?.required"
               style="width:200px ;"
               />
              
       
           </div>
         </div>
     
   </form>
   <div class="flex flex-wrap">
        
       <table class="flex flex-wrap"> 
   
           <tbody>
           <tr  *ngFor="let dynamic of dynamicArray2; let i = index;" style="min-width: 500px !important; ;" >
             <td > 
      
             <span   style=" display: inline-block; width:250px;">
               <select     
               class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
               name="{{dynamic.fertilisant_object}}" [(ngModel)]="dynamic.fertilisant_object"  type="text" 
       
             >
             <option   *ngFor="let nbr of NBR"
             [value] ="nbr.id_fertilisant" >   {{nbr.label}}
            </option>
                  </select>
                 </span>
           
                 <span  style=" display: inline-block; width: 150px; ">
                   <input     
                   class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                   name="{{dynamic.quantity}}" [(ngModel)]="dynamic.quantity"  type="number" 
                       placeholder="Quantité"
                   />
                     </span>
                     <span  style=" display: inline-block; width: 50px; ">
                     {{mesure(dynamic.fertilisant_object) }} 
                     </span>
                     </td>
                   </tr>
                 </tbody>
     
      </table>
   
   
   </div> 

   <!------ cuve 2 ------->
<form  [formGroup]="_solutionService.formSolution_Fertisant" autocomplete="off"   > 
    <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
      {{ _langService.GetLanguage()=='FR' ? 'Cuve N°3' : 'Tank No. 3' }} 
         </h6>
         <div class="w-full lg:w-4/12 px-4">
            <div class="relative w-full mb-3">
                <label
                  class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                 >
                 {{ _langService.GetLanguage()=='FR' ? "Volume d'eau" : 'Water volume' }}
                </label>
                <input
                type="number"
                 min="1"
                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  [placeholder]="_langService.GetLanguage()=='FR' ? 'Volume d eau' : 'Water volume'"
                  formControlName="eau3"
                  [class.is-invalid]="_solutionService.formSolution_Fertisant.controls['eau3'].errors?.required"
                  style="width:200px ;"
                  />
                 
          
              </div>        
           
           <div class="relative w-full mb-3">
             <label
               class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
              >
              {{ _langService.GetLanguage()=='FR' ? 'Nombre de fertilisants' : 'Number of fertilizers' }}
             </label>
             <input
             type="number"
              min="1"
              (change)="nbr_fertisants3()"
               class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
               [placeholder]="_langService.GetLanguage()=='FR' ? 'Nombre de fertilisants' : 'Number of fertilizers'"
               formControlName="nbr_cuve3"
               [class.is-invalid]="_solutionService.formSolution_Fertisant.controls['nbr_cuve3'].errors?.required"
               style="width:200px ;"
               />
              
       
           </div>
         </div>
     
   </form>
   <div class="flex flex-wrap">
        
       <table class="flex flex-wrap"> 
   
           <tbody>
           <tr  *ngFor="let dynamic of dynamicArray3; let i = index;" style="min-width: 500px !important; ;" >
             <td > 
      
             <span   style=" display: inline-block; width: 250px;">
               <select     
               class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
               name="{{dynamic.fertilisant_object}}" [(ngModel)]="dynamic.fertilisant_object"  type="text" 
       
             >
             <option   *ngFor="let nbr of NBR"
             [value] ="nbr.id_fertilisant" >   {{nbr.label}}
            </option>
                  </select>
                 </span>
           
                 <span  style=" display: inline-block; width: 150px; ">
                   <input     
                   class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                   name="{{dynamic.quantity}}" [(ngModel)]="dynamic.quantity"  type="number" 
                       placeholder="Quantité"
                   />
                     </span>
                     <span  style=" display: inline-block; width: 50px; ">
                     {{mesure(dynamic.fertilisant_object) }} 
                     </span>
                     </td>
                   </tr>
                 </tbody>
     
      </table>
   
   
   </div> 
   <!------ cuve 4 ------->
<form  [formGroup]="_solutionService.formSolution_Fertisant" autocomplete="off"   > 
    <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
      {{ _langService.GetLanguage()=='FR' ? 'Cuve N°4' : 'Tank No. 4' }} 
         </h6>
         <div class="w-full lg:w-4/12 px-4">
            <div class="relative w-full mb-3">
                <label
                  class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                 >
                 {{ _langService.GetLanguage()=='FR' ? "Volume d'eau" : 'Water volume' }}
                </label>
                <input
                type="number"
                 min="1"
                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  [placeholder]="_langService.GetLanguage()=='FR' ? 'Volume d eau' : 'Water volume'"
                  formControlName="eau4"
                  [class.is-invalid]="_solutionService.formSolution_Fertisant.controls['eau4'].errors?.required"
                  style="width:200px ;"
                  />
                 
          
              </div>        
           
           <div class="relative w-full mb-3">
             <label
               class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
              >
              {{ _langService.GetLanguage()=='FR' ? 'Nombre de fertilisants' : 'Number of fertilizers' }}
             </label>
             <input
             type="number"
              min="1"
              (change)="nbr_fertisants4()"
               class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
               [placeholder]="_langService.GetLanguage()=='FR' ? 'Nombre de fertilisants' : 'Number of fertilizers'"
               formControlName="nbr_cuve4"
               [class.is-invalid]="_solutionService.formSolution_Fertisant.controls['nbr_cuve4'].errors?.required"
               style="width:200px ;"
               />
              
       
           </div>
         </div>
     
   </form>
   <div class="flex flex-wrap">
        
       <table class="flex flex-wrap"> 
   
           <tbody>
           <tr  *ngFor="let dynamic of dynamicArray4; let i = index;" style="min-width: 500px !important; ;" >
             <td > 
      
             <span   style=" display: inline-block; width:250px;">
               <select     
               class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
               name="{{dynamic.fertilisant_object}}" [(ngModel)]="dynamic.fertilisant_object"  type="text" 
       
             >
             <option   *ngFor="let nbr of NBR"
             [value] ="nbr.id_fertilisant" >   {{nbr.label}}
            </option>
                  </select>
                 </span>
           
                 <span  style=" display: inline-block; width: 150px; ">
                   <input     
                   class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                   name="{{dynamic.quantity}}" [(ngModel)]="dynamic.quantity"  type="number" 
                       placeholder="Quantité"
                   />
                     </span>
                     <span  style=" display: inline-block; width: 50px; ">
                     {{mesure(dynamic.fertilisant_object) }} 
                     </span>
                     </td>
                   </tr>
                 </tbody>
     
      </table>
   
   
   </div> 
   <!------ cuve 5 ------->
<form  [formGroup]="_solutionService.formSolution_Fertisant" autocomplete="off"   > 
    <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
      {{ _langService.GetLanguage()=='FR' ? 'Cuve N°5' : 'Tank No. 5' }} 
         </h6>
         <div class="w-full lg:w-4/12 px-4">
            <div class="relative w-full mb-3">
                <label
                  class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                 >
                 {{ _langService.GetLanguage()=='FR' ? "Volume d'eau" : 'Water volume' }}
                </label>
                <input
                type="number"
                 min="1"
                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  [placeholder]="_langService.GetLanguage()=='FR' ? 'Volume d eau' : 'Water volume'"
                  formControlName="eau5"
                  [class.is-invalid]="_solutionService.formSolution_Fertisant.controls['eau5'].errors?.required"
                  style="width:200px ;"
                  />
                 
          
              </div>        
           
           <div class="relative w-full mb-3">
             <label
               class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
              >
              {{ _langService.GetLanguage()=='FR' ? 'Nombre de fertilisants' : 'Number of fertilizers' }}
             </label>
             <input
             type="number"
              min="1"
              (change)="nbr_fertisants5()"
               class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
               [placeholder]="_langService.GetLanguage()=='FR' ? 'Nombre de fertilisants' : 'Number of fertilizers'"
               formControlName="nbr_cuve5"
               [class.is-invalid]="_solutionService.formSolution_Fertisant.controls['nbr_cuve5'].errors?.required"
               style="width:200px ;"
               />
              
       
           </div>
         </div>
     
   </form>
   <div class="flex flex-wrap">
        
       <table class="flex flex-wrap"> 
   
           <tbody>
           <tr  *ngFor="let dynamic of dynamicArray5; let i = index;" style="min-width: 500px !important; ;" >
             <td > 
      
             <span   style=" display: inline-block; width: 250px;">
               <select     
               class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
               name="{{dynamic.fertilisant_object}}" [(ngModel)]="dynamic.fertilisant_object"  type="text" 
       
             >
             <option   *ngFor="let nbr of NBR"
             [value] ="nbr.id_fertilisant" >   {{nbr.label}}
            </option>
                  </select>
                 </span>
           
                 <span  style=" display: inline-block; width: 150px; ">
                   <input     
                   class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                   name="{{dynamic.quantity}}" [(ngModel)]="dynamic.quantity"  type="number" 
                       placeholder="Quantité"
                   />
                     </span>
                     <span  style=" display: inline-block; width: 50px; ">
                     {{mesure(dynamic.fertilisant_object) }} 
                     </span>
                     </td>
                   </tr>
                 </tbody>
     
      </table>
   
   
   </div> 

    <!------ cuve 6 ------->
<form  [formGroup]="_solutionService.formSolution_Fertisant" autocomplete="off"   > 
    <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
      {{ _langService.GetLanguage()=='FR' ? 'Cuve IBC P2' : 'Tank IBC P2' }} 
          
         </h6>
         <div class="w-full lg:w-4/12 px-4">
            <div class="relative w-full mb-3">
                <label
                  class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                 >
                 {{ _langService.GetLanguage()=='FR' ? "Volume d'eau" : 'Water volume' }}
                </label>
                <input
                type="number"
                 min="1"
                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  [placeholder]="_langService.GetLanguage()=='FR' ? 'Volume d eau' : 'Water volume'"
                  formControlName="eau6"
                  [class.is-invalid]="_solutionService.formSolution_Fertisant.controls['eau6'].errors?.required"
                  style="width:200px ;"
                  />
                 
          
              </div>        
           
           <div class="relative w-full mb-3">
             <label
               class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
              >
              {{ _langService.GetLanguage()=='FR' ? 'Nombre de fertilisants' : 'Number of fertilizers' }}
             </label>
             <input
             type="number"
              min="1"
              (change)="nbr_fertisants6()"
               class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
               [placeholder]="_langService.GetLanguage()=='FR' ? 'Nombre de fertilisants' : 'Number of fertilizers'"
               formControlName="nbr_cuve6"
               [class.is-invalid]="_solutionService.formSolution_Fertisant.controls['nbr_cuve6'].errors?.required"
               style="width:200px ;"
               />
              
       
           </div>
         </div>
     
   </form>
   <div class="flex flex-wrap">
        
       <table class="flex flex-wrap"> 
   
           <tbody>
           <tr  *ngFor="let dynamic of dynamicArray6; let i = index;" style="min-width: 500px !important; ;" >
             <td > 
      
             <span   style=" display: inline-block; width: 250px;">
               <select     
               class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
               name="{{dynamic.fertilisant_object}}" [(ngModel)]="dynamic.fertilisant_object"  type="text" 
       
             >
             <option   *ngFor="let nbr of NBR"
             [value] ="nbr.id_fertilisant" >   {{nbr.label}}
            </option>
                  </select>
                 </span>
           
                 <span  style=" display: inline-block; width: 150px; ">
                   <input     
                   class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                   name="{{dynamic.quantity}}" [(ngModel)]="dynamic.quantity"  type="number" 
                       placeholder="Quantité"
                   />
                     </span>
                     <span  style=" display: inline-block; width: 50px; ">
                     {{mesure(dynamic.fertilisant_object) }} 
                     </span>
                     </td>
                   </tr>
                 </tbody>
     
      </table>
   
   
   </div> 
      <div class="align-self-center mx-auto text-center" style="padding-top: 15px;">
      <button  *ngIf="!submit" 
      class="bg-red-600 text-white active:bg-red-700 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
      type="button"  
      (click)="onSubmit()"
      >
      {{ _langService.GetLanguage()=='FR' ? 'Ajouter' : 'Add' }}
      
    </button></div>
    <div *ngIf="submit" class="alert alert-success" style="background-color: rgb(98, 223, 98);height: 50px;text-align: center;">
      {{ _langService.GetLanguage()=='FR' ? 'Ajouter avec succès' : 'Add successfully' }}

      </div>

  </div>
</div>
