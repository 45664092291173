import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LangService } from 'src/app/services/lang.service';
import { SolutionService } from 'src/app/services/solution.service';

@Component({
  selector: 'app-detail-solution',
  templateUrl: './detail-solution.component.html',
  styleUrls: ['./detail-solution.component.css']
})
export class DetailSolutionComponent implements OnInit {
  solution
  date=''
  operateur=''
  id: any;
  cuve1=[];
  cuve2=[];
  cuve3=[];
  cuve4=[];
  cuve5=[];
  cuve6=[];
  eau1 ='0 '
  eau2  ='0 '
  eau3 ='0 '
  eau4  ='0 '
  eau5 ='0 '
  eau6 ='0 '
  constructor( public _solutionService:SolutionService,private route: ActivatedRoute, public _langService:LangService) {}

  ngOnInit(): void {
  this.route.params.subscribe(params => {
       this.id=params['id'] //log the value of id
    });
    this.get_Solution();
   }
 
   get_Solution(){
     this._solutionService.get_Solution(this.id).subscribe(res => {
       this.solution = res as any 
       this.date=res['date']
       this.operateur=res['operateur']
       })

       this._solutionService.get_Solution_Fertilisant(1,this.id).subscribe(res => {
        this.cuve1 = res  as [] 
        })
        this._solutionService.get_Solution_Fertilisant(2,this.id).subscribe(res => {
          this.cuve2 = res   as []
          })
          this._solutionService.get_Solution_Fertilisant(3,this.id).subscribe(res => {
            this.cuve3 = res   as []
            })
            this._solutionService.get_Solution_Fertilisant(4,this.id).subscribe(res => {
              this.cuve4 = res   as []
              })
              this._solutionService.get_Solution_Fertilisant(5,this.id).subscribe(res => {
                this.cuve5 = res   as []
                })
                this._solutionService.get_Solution_Fertilisant(6,this.id).subscribe(res => {
                  this.cuve6 = res   as []
                  })

                  
       this._solutionService.get_Solution_Eau(1,this.id).subscribe(res => {
        this.eau1 = res  as any        
        })
        this._solutionService.get_Solution_Eau(2,this.id).subscribe(res => {
          this.eau2 = res   as any
          })
          this._solutionService.get_Solution_Eau(3,this.id).subscribe(res => {
            this.eau3 = res   as any
            })
            this._solutionService.get_Solution_Eau(4,this.id).subscribe(res => {
              this.eau4 = res  as any
              })
              this._solutionService.get_Solution_Eau(5,this.id).subscribe(res => {
                this.eau5 = res  as any
                })              
                this._solutionService.get_Solution_Eau(6,this.id).subscribe(res => {
                  this.eau6 = res  as any
                  })
                       
   }
}
