import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { CheckListeService } from 'src/app/services/checkListe.service';
import { LangService } from 'src/app/services/lang.service';


@Component({
  selector: 'app-checklist-user',
  templateUrl: './checklist-user.component.html',
  styleUrls: ['./checklist-user.component.css']
})
export class ChecklistUserComponent implements OnInit {
  P28H1:any;
  P28H2:any;
  P28H3:any;  
  P211H1:any;
  P211H2:any;
  P211H3:any; 
  P215H1:any;
  P215H2:any;
  P215H3:any;
  P18H1:any;
  P18H2:any;
  P18H3:any;
  P111H1:any;
  P111H2:any;
  P111H3:any;  
  P115H1:any;
  P115H2:any;
  P115H3:any;
  Service1:any;
  Service2:any;
  Service3:any;
  Service4:any;
  Service5:any;
  Service6:any;
  P1WeekEnd1:any;
  P1WeekEnd2:any;
  P1WeekEnd3:any;
  P2WeekEnd1:any;
  P2WeekEnd2:any;
  P2WeekEnd3:any;

  ///
  Global_Service
  Global_P1
  Global_P2
  ///
  submit: boolean;
  heureMode='8h'
  Mode='P1'
  constructor(private datePipe: DatePipe, public _checkService:CheckListeService, public _langService:LangService) { }
 
  ngOnInit(): void {
    this._checkService.initialiserVide()

    this.P28H1 = [
      {
        id: 1,
        title: 'Mousses séches',
        checked: false,
      },
      {
        id: 2,
        title: "Plantules fanées",
        checked: false,
      },
      {
        id: 3,
        title: "Présences d'insectes sur mousse",
        checked: false,
      },
      {
        id: 4,
        title: "Plantules mortes",
        checked: false,
      },
      {
        id: 5,
        title: "Nombre d'insectes sur plaques jaunes et bleues",
        checked: false,
      },
      {
        id: 6,
        title: "Niveau solution dans cuve IBC",
        checked: false,
      },
      {
        id: 7,
        title: 'Relevés PH/EC/OD dans cuve IBC',
        checked: false,
      },
      {
        id: 8,
        title: 'Vérification douchette irrigation',
        checked: false,
      },
      {
        id: 9,
        title: 'Chariots propres et désinfectés',
        checked: false,
      },
    ];
    this.P28H2 = [
      {
        id: 1,
        title: "Les pots sont en contact avec l'eau",
        checked: false,
      },
      {
        id: 2,
        title: "Niveau d'eau des tables",
        checked: false,
      },
      {
        id: 3,
        title: "Fuites au niveau des tables",
        checked: false,
      } ,
      {
        id: 4,
        title: "Fuites au niveau des raccordements",
        checked: false,
      } ,
      {
        id: 5,
        title: "Plantes avec traces de maladie ou virus",
        checked: false,
      } ,
      {
        id: 6,
        title: "Plantes mortes",
        checked: false,
      } ,
      {
        id: 7,
        title: "Plantes retournées",
        checked: false,
      } ,
      {
        id: 8,
        title: "Présence d'algues (échantillon)",
        checked: false,
      } ,
      {
        id: 9,
        title: "Blower en marche sans bruit anormal",
        checked: false,
      } ,
      {
        id: 10,
        title: "Plateaux désinfectés et lavés",
        checked: false,
      } ,
      {
        id: 11,
        title: "Plateaux posés correctement sur le plastique",
        checked: false,
      } 
    ];
    this.P28H3 = [
      {
        id: 1,
        title: 'Obstacles ouverture/fermeture des fênetres latérales',
        checked: false,
      },
      {
        id: 2,
        title: 'Test manuel ouverture fênetres latérales et toit',
        checked: false,
      },
      {
        id: 3,
        title: 'Etat ouvertures manuelles nord-sud',
        checked: false,
      } ,
      {
        id: 4,
        title: 'Etat chauffage conforme aux consignes',
        checked: false,
      } ,
      {
        id: 5,
        title: 'Etat ventilateurs (toit et latéraux) conforme aux consignes',
        checked: false,
      } ,
      {
        id: 6,
        title: 'Fog conforme aux consignes',
        checked: false,
      } ,
      {
        id: 7,
        title: "Infiltration d'eau (toit et latérales)",
        checked: false,
      } ,
      {
        id: 8,
        title: 'Etat plastique et moustiquaire',
        checked: false,
      } ,
      {
        id: 9,
        title: 'Frigo en marche',
        checked: false,
      } ,
      {
        id: 10,
        title: 'Automatisme en marche',
        checked: false,
      } ,
      {
        id: 11,
        title: 'Musique + Internet',
        checked: false,
      } ,
      {
        id: 12,
        title: 'Allumage/Extinction asperseurs suivant consignes',
        checked: false,
      } ,
      {
        id: 13,
        title: 'Allumage/Extinction LED',
        checked: false,
      } ,
      {
        id: 14,
        title: 'Stock équipements et consommables hygiène',
        checked: false,
      } ,
      {
        id: 15,
        title: 'Portes fermées',
        checked: false,
      } 
    ];
    this.P211H1 = [
      {
        id: 1,
        title: 'Mousses séches',
        checked: false,
      },
      {
        id: 2,
        title: "Plantules fanées",
        checked: false,
      },
      {
        id: 3,
        title: "Présences d'insectes sur mousse",
        checked: false,
      },
      {
        id: 4,
        title: "Plantules mortes",
        checked: false,
      },
      {
        id: 5,
        title: "Nombre d'insectes sur plaques jaunes et bleues",
        checked: false,
      },
      {
        id: 6,
        title: "Niveau solution dans cuve IBC",
        checked: false,
      },
      {
        id: 7,
        title: 'Relevés PH/EC/OD dans cuve IBC',
        checked: false,
      },
      {
        id: 8,
        title: 'Vérification douchette irrigation',
        checked: false,
      } 
    ];
    this.P211H2 = [
      {
        id: 1,
        title: "Les pots sont en contact avec l'eau",
        checked: false,
      },
      {
        id: 2,
        title: "Niveau d'eau des tables",
        checked: false,
      },
      {
        id: 3,
        title: "Fuites au niveau des tables",
        checked: false,
      } ,
      {
        id: 4,
        title: "Fuites au niveau des raccordements",
        checked: false,
      } ,
      {
        id: 5,
        title: "Plantes avec traces de maladie ou virus",
        checked: false,
      } ,
      {
        id: 6,
        title: "Plantes mortes",
        checked: false,
      } ,
      {
        id: 7,
        title: "Plantes retournées",
        checked: false,
      } ,
      {
        id: 8,
        title: "Présence d'algues (échantillon)",
        checked: false,
      } ,
      {
        id: 9,
        title: "Relevés PH/EC/OD",
        checked: false,
      } ,
      {
        id: 10,
        title: "Blower en marche sans bruit anormal",
        checked: false,
      } ,
      {
        id: 11,
        title: "Plateaux désinfectés et lavés",
        checked: false,
      } ,
      {
        id: 12,
        title: "Plateaux posés correctement sur le plastique",
        checked: false,
      } 
    ];
    this.P211H3 = [
      {
        id: 1,
        title: 'Obstacles ouverture/fermeture des fênetres latérales',
        checked: false,
      },
      {
        id: 2,
        title: 'Etat des ouvertures conforme aux consignes',
        checked: false,
      },
      {
        id: 4,
        title: 'Etat chauffage conforme aux consignes',
        checked: false,
      } ,
      {
        id: 5,
        title: 'Etat ventilateurs (toit et latéraux) conforme aux consignes',
        checked: false,
      } ,
      {
        id: 6,
        title: 'Fog conforme aux consignes',
        checked: false,
      } ,
      {
        id: 7,
        title: "Automatisme en marche",
        checked: false,
      }  ,
      {
        id: 12,
        title: 'Allumage/Extinction asperseurs suivant consignes',
        checked: false,
      } ,
      {
        id: 13,
        title: 'Allumage/Extinction LED',
        checked: false,
      } ,
      {
        id: 14,
        title: 'Relevé température (exterieur+serre) + humidité',
        checked: false,
      } ,
      {
        id: 15,
        title: 'Portes fermées',
        checked: false,
      } 
    ];
    this.P215H1 = [
      {
        id: 1,
        title: 'Mousses séches',
        checked: false,
      },
      {
        id: 2,
        title: "Plantules fanées",
        checked: false,
      },
      {
        id: 3,
        title: "Présences d'insectes sur mousse",
        checked: false,
      },
      {
        id: 4,
        title: "Plantules mortes",
        checked: false,
      },
      {
        id: 5,
        title: "Nombre d'insectes sur plaques jaunes et bleues",
        checked: false,
      },
      {
        id: 6,
        title: "Niveau solution dans cuve IBC",
        checked: false,
      },
      {
        id: 7,
        title: 'Relevés PH/EC/OD dans cuve IBC',
        checked: false,
      },
      {
        id: 8,
        title: 'Vérification douchette irrigation',
        checked: false,
      } ,
      {
        id: 9,
        title: 'Chariots propres et désinfectés',
        checked: false,
      } 
    ];
    this.P215H2 = [
      {
        id: 1,
        title: "Les pots sont en contact avec l'eau",
        checked: false,
      },
      {
        id: 2,
        title: "Niveau d'eau des tables",
        checked: false,
      },
      {
        id: 3,
        title: "Fuites au niveau des tables",
        checked: false,
      } ,
      {
        id: 4,
        title: "Fuites au niveau des raccordements",
        checked: false,
      } ,
      {
        id: 5,
        title: "Plantes avec traces de maladie ou virus",
        checked: false,
      } ,
      {
        id: 6,
        title: "Plantes mortes",
        checked: false,
      } ,
      {
        id: 7,
        title: "Plantes retournées",
        checked: false,
      } ,
      {
        id: 8,
        title: "Présence d'algues (échantillon)",
        checked: false,
      } ,
      {
        id: 9,
        title: "Relevés PH/EC/OD",
        checked: false,
      } ,
      {
        id: 10,
        title: "Blower en marche sans bruit anormal",
        checked: false,
      } ,
      {
        id: 11,
        title: "Plateaux désinfectés et lavés",
        checked: false,
      } ,
      {
        id: 12,
        title: "Plateaux posés correctement sur le plastique",
        checked: false,
      } 
    ];
    this.P215H3 = [
      {
        id: 1,
        title: 'Obstacles ouverture/fermeture des fênetres latérales',
        checked: false,
      },
      {
        id: 2,
        title: 'Test manuel ouverture fênetres latérales et toit',
        checked: false,
      },
      {
        id: 3,
        title: 'Etat ouvertures manuelles nord-sud',
        checked: false,
      } ,
      {
        id: 4,
        title: 'Etat des ouvertures conforme aux consignes',
        checked: false,
      } ,
      {
        id: 4,
        title: 'Etat chauffage conforme aux consignes',
        checked: false,
      } ,
      {
        id: 5,
        title: 'Etat ventilateurs (toit et latéraux) conforme aux consignes',
        checked: false,
      } ,
      {
        id: 6,
        title: 'Fog conforme aux consignes',
        checked: false,
      } ,
      {
        id: 7,
        title: "Infiltration d'eau (toit et latérales)",
        checked: false,
      } ,
      {
        id: 8,
        title: 'Etat plastique et moustiquaire',
        checked: false,
      } ,
      {
        id: 9,
        title: 'Frigo en marche',
        checked: false,
      } ,
      {
        id: 10,
        title: 'Automatisme en marche',
        checked: false,
      } ,
      {
        id: 11,
        title: 'Musique + Internet',
        checked: false,
      } ,
      {
        id: 12,
        title: 'Allumage/Extinction asperseurs suivant consignes',
        checked: false,
      } ,
      {
        id: 13,
        title: 'Allumage/Extinction LED',
        checked: false,
      } ,
      {
        id: 14,
        title: 'Stock équipements et consommables hygiène',
        checked: false,
      } ,
      {
        id: 15,
        title: 'Relevé température (exterieur+serre) + humidité',
        checked: false,
      } ,
      {
        id: 16,
        title: 'Armoires électriques fermées et vérouillées',
        checked: false,
      } ,
      {
        id: 17,
        title: 'Couloirs dégagés et propres',
        checked: false,
      } ,
      {
        id: 18,
        title: 'Balayage serre',
        checked: false,
      } ,
      {
        id: 19,
        title: 'Poubelles vidées',
        checked: false,
      } ,
      {
        id: 20,
        title: 'Rangement et nettoyage outils et petits chariots',
        checked: false,
      }  ,
      {
        id: 21,
        title: 'Portes fermées',
        checked: false,
      } 
    ];
    
    this.P18H1 = [
      {
        id: 1,
        title: 'Structure en déséquilibre/risque',
        checked: false,
      },
      {
        id: 2,
        title: "Problème de niveau des tubes",
        checked: false,
      },
      {
        id: 3,
        title: "Fuite d'eau/débordement",
        checked: false,
      },
      {
        id: 4,
        title: "Niveau d'eau homogène dans les tubes",
        checked: false,
      },
      {
        id: 5,
        title: "Ajustement niveau d'eau par rapport au stade de croissance",
        checked: false,
      },
      {
        id: 6,
        title: "Vérification état des vannes",
        checked: false,
      } 
    ];
    this.P18H2 = [
      {
        id: 1,
        title: "Les plantes sont en contact avec l'eau",
        checked: false,
      },
      {
        id: 2,
        title: "Plantes flétries",
        checked: false,
      },
      {
        id: 3,
        title: "Plantes fanées",
        checked: false,
      } ,
      {
        id: 4,
        title: "Plantes mortes",
        checked: false,
      } ,
      {
        id: 5,
        title: "Présence d'insectes",
        checked: false,
      } ,
      {
        id: 6,
        title: "Présence massive d'algues",
        checked: false,
      } ,
      {
        id: 7,
        title: "Signes de montaison",
        checked: false,
      } ,
      {
        id: 8,
        title: "Disparités haut/bas",
        checked: false,
      } ,
      {
        id: 9,
        title: "Disparités intérieur/extérieur",
        checked: false,
      } ,
      {
        id: 10,
        title: "Racines claires bien développées",
        checked: false,
      }  
    ];
    this.P18H3 = [
      {
        id: 1,
        title: 'Test manuel ouverture fênetres latérales et toit',
        checked: false,
      },
      {
        id: 2,
        title: 'Etat des ouvertures conforme aux consignes',
        checked: false,
      },
      {
        id: 3,
        title: 'Etat chauffage conforme aux consignes',
        checked: false,
      } ,
      {
        id: 4,
        title: 'Etat ventilateurs (toit et latéraux) conforme aux consignes',
        checked: false,
      } ,
      {
        id: 5,
        title: 'Fog conforme aux consignes',
        checked: false,
      } ,
      {
        id: 6,
        title: "Infiltration d'eau (toit et latérales)",
        checked: false,
      } ,
      {
        id: 7,
        title: "Etat plastique et moustiquaire",
        checked: false,
      } ,
      {
        id: 8,
        title: 'Automatisme en marche',
        checked: false,
      } ,
      {
        id: 9,
        title: 'Musique + Internet',
        checked: false,
      } ,
      {
        id: 12,
        title: 'Allumage/Extinction LED',
        checked: false,
      } ,
      {
        id: 13,
        title: 'Etat ecran thermique latéral manuel conforme aux consignes',
        checked: false,
      } ,
      {
        id: 14,
        title: 'Etat ecran thermique toit conforme aux consignes',
        checked: false,
      } ,
      {
        id: 15,
        title: 'Stock équipements et consommables hygiène',
        checked: false,
      } ,
      {
        id: 16,
        title: 'Portes fermées',
        checked: false,
      } 
    ];
    this.P111H1 = [
      {
        id: 1,
        title: 'Structure en déséquilibre/risque',
        checked: false,
      },
      {
        id: 2,
        title: "Problème de niveau des tubes",
        checked: false,
      },
      {
        id: 3,
        title: "Fuite d'eau/débordement",
        checked: false,
      },
      {
        id: 4,
        title: "Niveau d'eau homogène dans les tubes",
        checked: false,
      },
      {
        id: 5,
        title: "Vannes correctement ouvertes",
        checked: false,
      } 
    ];
    this.P111H2 = [
      {
        id: 1,
        title: "Les plantes sont en contact avec l'eau",
        checked: false,
      },
      {
        id: 2,
        title: "Plantes flétries",
        checked: false,
      },
      {
        id: 3,
        title: "Plantes fanées",
        checked: false,
      } ,
      {
        id: 4,
        title: "Plantes mortes",
        checked: false,
      } ,
      {
        id: 5,
        title: "Présence d'insectes",
        checked: false,
      } ,
      {
        id: 6,
        title: "Présence massive d'algues",
        checked: false,
      } ,
      {
        id: 7,
        title: "Relevés PH/EC/OD",
        checked: false,
      }
    ];
    this.P111H3 = [
       
      {
        id: 2,
        title: 'Etat des ouvertures conforme aux consignes',
        checked: false,
      },
      {
        id: 3,
        title: 'Etat chauffage conforme aux consignes',
        checked: false,
      } ,
      {
        id: 4,
        title: 'Etat ventilateurs  conforme aux consignes',
        checked: false,
      } ,
      {
        id: 5,
        title: 'Fog conforme aux consignes',
        checked: false,
      } ,
      {
        id: 6,
        title: "Infiltration d'eau (toit et latérales)",
        checked: false,
      } ,
    
      {
        id: 8,
        title: 'Automatisme en marche',
        checked: false,
      } ,
      {
        id: 9,
        title: 'Musique + Internet',
        checked: false,
      }  ,
      {
        id: 12,
        title: 'Allumage/Extinction LED',
        checked: false,
      } ,
      {
        id: 13,
        title: 'Relevé température (exterieur+serre) + humidité',
        checked: false,
      } ,
      {
        id: 16,
        title: 'Portes fermées',
        checked: false,
      } 
    ];
        
    this.P115H1 = [
      {
        id: 1,
        title: 'Structure en déséquilibre/risque',
        checked: false,
      },
      {
        id: 2,
        title: "Problème de niveau des tubes",
        checked: false,
      },
      {
        id: 3,
        title: "Fuite d'eau/débordement",
        checked: false,
      },
      {
        id: 4,
        title: "Niveau d'eau homogène dans les tubes",
        checked: false,
      },
      {
        id: 6,
        title: "Vannes correctement ouvertes",
        checked: false,
      } 
    ];
    this.P115H2 = [
      {
        id: 1,
        title: "Les plantes sont en contact avec l'eau",
        checked: false,
      },
      {
        id: 2,
        title: "Plantes flétries",
        checked: false,
      },
      {
        id: 3,
        title: "Plantes fanées",
        checked: false,
      } ,
      {
        id: 4,
        title: "Plantes mortes",
        checked: false,
      } ,
      {
        id: 5,
        title: "Présence d'insectes",
        checked: false,
      } ,
      {
        id: 6,
        title: "Présence massive d'algues",
        checked: false,
      } ,
      {
        id: 7,
        title: "Relevés PH/EC/OD",
        checked: false,
      } 
    ];
    this.P115H3 = [
    
      {
        id: 2,
        title: 'Etat des ouvertures conforme aux consignes',
        checked: false,
      },
      {
        id: 3,
        title: 'Etat chauffage conforme aux consignes',
        checked: false,
      } ,
      {
        id: 4,
        title: 'Etat ventilateurs (toit et latéraux) conforme aux consignes',
        checked: false,
      } ,
      {
        id: 5,
        title: 'Fog conforme aux consignes',
        checked: false,
      } ,
      {
        id: 6,
        title: "Infiltration d'eau (toit et latérales)",
        checked: false,
      } ,
      {
        id: 8,
        title: 'Automatisme en marche',
        checked: false,
      } ,
      {
        id: 9,
        title: 'Musique + Internet',
        checked: false,
      } ,
      {
        id: 12,
        title: 'Allumage/Extinction LED',
        checked: false,
      } ,
      {
        id: 12,
        title: 'Relevé température (exterieur+serre) + humidité',
        checked: false,
      } ,
       
      {
        id: 14,
        title: 'Etat ecran thermique toit conforme aux consignes',
        checked: false,
      } ,
      {
        id: 14,
        title: 'Armoires électriques fermées et vérouillées',
        checked: false,
      } ,
      
      {
        id: 15,
        title: 'Stock équipements et consommables hygiène',
        checked: false,
      } ,
      {
        id: 16,
        title: 'Rangement et nettoyage outils et chariots',
        checked: false,
      }  ,
      {
        id: 18,
        title: 'Couloirs dégagés et propres',
        checked: false,
      }  ,
      {
        id: 19,
        title: 'Poubelles vidées',
        checked: false,
      }  ,
      {
        id: 20,
        title: 'Portes fermées',
        checked: false,
      } 
    ];

    this.Service1 = [
      {
        id: 2,
        title: 'Vérification cuve drainage P1',
        checked: false,
      },
      {
        id: 3,
        title: 'Vérification cuve drainage P2',
        checked: false,
      } ,
      {
        id: 4,
        title: 'Vérification cuve eaux de lavage',
        checked: false,
      } ,
      {
        id: 5,
        title: 'Vérification état bassin',
        checked: false,
      } ,
       
      {
        id: 8,
        title: 'Nettoyage des filtres',
        checked: false,
      }  
    ];
    this.Service2 = [
      {
        id: 2,
        title: "Verification tableau général (disjoncteurs enclenchés, présence d'eau, odeur)",
        checked: false,
      },
      {
        id: 3,
        title: 'Vérification tableaux électriques irrigation',
        checked: false,
      } ,
      {
        id: 4,
        title: 'Niveau eau osmosée',
        checked: false,
      } ,
      {
        id: 5,
        title: 'Etat unité osmose/Maintenance',
        checked: false,
      } ,
       
      {
        id: 8,
        title: 'Fuites cuves inox et raccordements',
        checked: false,
      },
       
      {
        id: 9,
        title: 'Vérifications filtres et pressions',
        checked: false,
      },
       
      {
        id: 10,
        title: 'Vérification niveau fertilisants',
        checked: false,
      },
       
      {
        id: 11,
        title: 'Nettoyage cuves fertilisants (filet)',
        checked: false,
      },
       
      {
        id: 12,
        title: 'Présence insectes dans cuves fertilisants',
        checked: false,
      },
       
      {
        id: 13,
        title: 'Vérification des vannes',
        checked: false,
      },
       
      {
        id: 14,
        title: 'Verification petit compresseur (aération)',
        checked: false,
      },
       
      {
        id: 15,
        title: 'Blower en marche sans bruit anormal',
        checked: false,
      },
       
      {
        id: 16,
        title: 'Etat tubes PVC dans tranchées',
        checked: false,
      },
       
      {
        id: 17,
        title: 'Vérification des injecteurs/doeurs',
        checked: false,
      }  ,
       
      {
        id: 18,
        title: 'Balayage & nettoyage',
        checked: false,
      }  
    ];
    
    this.Service3 = [
      {
        id: 2,
        title: 'Niveau cuves solution nutritive',
        checked: false,
      },
      {
        id: 3,
        title: 'Niveau cuves drainage',
        checked: false,
      } ,
      {
        id: 4,
        title: 'Etat filtres UV/Maintenance',
        checked: false,
      } ,
      {
        id: 5,
        title: 'Fuites',
        checked: false,
      } ,
       
      {
        id: 8,
        title: 'Etat des vannes',
        checked: false,
      }  ,
       
      {
        id: 8,
        title: 'Pompe vidange tranchées',
        checked: false,
      }  
    ];    
    this.Service4 = [
      {
        id: 2,
        title: 'Etat compresseur',
        checked: false,
      },
      {
        id: 3,
        title: "Niveau d'huile",
        checked: false,
      } ,
      {
        id: 4,
        title: 'Maintenance',
        checked: false,
      } ,
      {
        id: 5,
        title: 'Température',
        checked: false,
      } 
    ];
    this.Service5 = [
      {
        id: 2,
        title: 'Pression au niveau des secteurs',
        checked: false,
      },
      {
        id: 3,
        title: "Pression au niveau Xframes",
        checked: false,
      } ,
      {
        id: 4,
        title: 'Fuites et débordements',
        checked: false,
      } ,
      {
        id: 5,
        title: 'Structure en déséquilibre/risque',
        checked: false,
      }  ,
      {
        id: 5,
        title: 'Infiltration eaux pluviales',
        checked: false,
      }  ,
      {
        id: 5,
        title: 'Leds défaillantes',
        checked: false,
      }  ,
      {
        id: 5,
        title: 'Problèmes buses Fog',
        checked: false,
      }  ,
      {
        id: 5,
        title: "Vérification niveau d'eau par rapport au stade de croissance",
        checked: false,
      } 
    ];
    this.Service6 = [
      {
        id: 2,
        title: 'Fuites et débordements',
        checked: false,
      },
      {
        id: 3,
        title: "Infiltration eaux pluviales",
        checked: false,
      } ,
      {
        id: 4,
        title: 'Tables instables',
        checked: false,
      } ,
      {
        id: 5,
        title: 'Leds défaillantes',
        checked: false,
      }  ,
      {
        id: 5,
        title: 'Problèmes buses Fog',
        checked: false,
      } 
    ];
    this.P1WeekEnd1 = [
      {
        id: 1,
        title: 'Structure en déséquilibre/risque',
        checked: false,
      },
      {
        id: 2,
        title: "Problème de niveau des tubes",
        checked: false,
      },
      {
        id: 3,
        title: "Fuite d'eau/débordement",
        checked: false,
      },
      {
        id: 4,
        title: "Niveau d'eau homogène dans les tubes",
        checked: false,
      },
       
      {
        id: 6,
        title: "Vannes correctement ouvertes",
        checked: false,
      } 
    ];
    this.P1WeekEnd2 = [
      {
        id: 1,
        title: "Les plantes sont en contact avec l'eau",
        checked: false,
      },
 
      {
        id: 4,
        title: "Plantes mortes",
        checked: false,
      } ,
      {
        id: 5,
        title: "Présence d'insectes",
        checked: false,
      } ,
     
      {
        id: 7,
        title: "Signes de montaison",
        checked: false,
      } ,
      {
        id: 8,
        title: "Disparités haut/bas",
        checked: false,
      } ,
      {
        id: 9,
        title: "Disparités intérieur/extérieur",
        checked: false,
      } ,
      {
        id: 10,
        title: "Racines claires bien développées",
        checked: false,
      }  
    ];
    this.P1WeekEnd3 = [
 
      {
        id: 2,
        title: 'Etat des ouvertures conforme aux consignes',
        checked: false,
      },
      {
        id: 3,
        title: 'Etat chauffage conforme aux consignes',
        checked: false,
      } ,
      {
        id: 4,
        title: 'Etat ventilateurs (toit et latéraux) conforme aux consignes',
        checked: false,
      } ,
      {
        id: 5,
        title: 'Fog conforme aux consignes',
        checked: false,
      } ,
      
      {
        id: 7,
        title: "Etat plastique et moustiquaire",
        checked: false,
      } ,
      {
        id: 8,
        title: 'Automatisme en marche',
        checked: false,
      } ,
   
      {
        id: 12,
        title: 'Allumage/Extinction LED',
        checked: false,
      } ,
      {
        id: 13,
        title: 'Etat ecran thermique latéral manuel conforme aux consignes',
        checked: false,
      } ,
      {
        id: 14,
        title: 'Etat ecran thermique toit conforme aux consignes',
        checked: false,
      } ,
 
      {
        id: 16,
        title: 'Portes fermées',
        checked: false,
      } 
    ];
    this.P2WeekEnd1 = [
      {
        id: 1,
        title: 'Mousses séches',
        checked: false,
      },
    
      {
        id: 3,
        title: "Présences d'insectes sur mousse",
        checked: false,
      },
      {
        id: 4,
        title: "Plantules mortes",
        checked: false,
      },
     
      {
        id: 7,
        title: 'Relevés PH/EC/OD dans cuve IBC',
        checked: false,
      },
    
    ];
    this.P2WeekEnd2 = [
      {
        id: 1,
        title: "Les pots sont en contact avec l'eau",
        checked: false,
      },
      {
        id: 2,
        title: "Niveau d'eau des tables",
        checked: false,
      },
     
      {
        id: 6,
        title: "Plantes mortes",
        checked: false,
      } ,
      {
        id: 7,
        title: "Plantes retournées",
        checked: false,
      } ,
      
      {
        id: 9,
        title: "Blower en marche sans bruit anormal",
        checked: false,
      }  
    ];
    this.P2WeekEnd3 = [
    
      {
        id: 3,
        title: 'Etat ouvertures manuelles nord-sud',
        checked: false,
      } ,
      {
        id: 4,
        title: 'Etat chauffage conforme aux consignes',
        checked: false,
      } ,
      {
        id: 5,
        title: 'Etat ventilateurs (toit et latéraux) conforme aux consignes',
        checked: false,
      } ,
      {
        id: 6,
        title: 'Fog conforme aux consignes',
        checked: false,
      } ,
      
      {
        id: 8,
        title: 'Etat plastique et moustiquaire',
        checked: false,
      } ,
      {
        id: 9,
        title: 'Frigo en marche',
        checked: false,
      } ,
      {
        id: 10,
        title: 'Automatisme en marche',
        checked: false,
      } ,
   
      {
        id: 12,
        title: 'Allumage/Extinction asperseurs suivant consignes',
        checked: false,
      } ,
      {
        id: 13,
        title: 'Allumage/Extinction LED',
        checked: false,
      } ,
   
      {
        id: 15,
        title: 'Portes fermées',
        checked: false,
      } 
    ];

    this.Global_Service = [
      {
        id: 3,
        title: "Relevés PH/EC/OD au niveau départ irrigation et comparaison avec valeurs Nutritec",
        checked: false,
      }  
    ];
    this.Global_P1  = [
    
      {
        id: 3,
        title: "Relevés PH/EC/OD à l'entrée et à la sortie d'un Xframe (sur les 4 zones de la serre)",
        checked: false,
      }  ,
      {
        id: 3,
        title: "Relevé température (exterieure et intérieure) + humidité",
        checked: false,
      }  ,
    
    ];
    this.Global_P2  = [
    
      {
        id: 3,
        title: "Relevés PH/EC/OD",
        checked: false,
      }  ,
      {
        id: 3,
        title: "Relevé température (exterieure et intérieure) + humidité",
        checked: false,
      }  ,
    
    ];

  }

 

insertCheck(){
  let operateur=localStorage.getItem('log');
  this._checkService.insertCheck(this.datePipe.transform(new Date(), 'dd-MM-yyyy'),this.datePipe.transform( new Date(), 'H:mm'),this.Mode,operateur).subscribe(res => {
    
    if(this.Mode=='P1' ){
  
      for(let i=0;i<this.Global_P1.length;i++){
        this._checkService.insertCheck_Item(this.Global_P1[i].title,this.Global_P1[i].checked,res,'Global').subscribe(res => {
        })
      }
    }
    else   if(this.Mode=='P2' ){
  
      for(let i=0;i<this.Global_P2.length;i++){
        this._checkService.insertCheck_Item(this.Global_P2[i].title,this.Global_P2[i].checked,res,'Global').subscribe(res => {
        })
      }
    }
    else {
      for(let i=0;i<this.Global_Service.length;i++){
        this._checkService.insertCheck_Item(this.Global_Service[i].title,this.Global_Service[i].checked,res,'Global').subscribe(res => {
        })
      }
    }
   
    if(this.Mode=='P1' && this.heureMode=='8h'){
      for(let i=0;i<this.P18H1.length;i++){
      this._checkService.insertCheck_Item(this.P18H1[i].title,this.P18H1[i].checked,res,'Etat des Xframes').subscribe(res => {
      })
    }
      for(let i=0;i<this.P18H2.length;i++){
      this._checkService.insertCheck_Item(this.P18H2[i].title,this.P18H2[i].checked,res,'Etat des plantes').subscribe(res => {
      })
    }
      
    for(let i=0;i<this.P18H3.length;i++){
      this._checkService.insertCheck_Item(this.P18H3[i].title,this.P18H3[i].checked,res,'Etat de la Serre').subscribe(res => {
      })
    }
    }
    else if (this.Mode=='P1' && this.heureMode=='11h'){
      for(let i=0;i<this.P111H1.length;i++){
        this._checkService.insertCheck_Item(this.P111H1[i].title,this.P111H1[i].checked,res,'Etat des Xframes').subscribe(res => {
        })
      }
        for(let i=0;i<this.P111H2.length;i++){
        this._checkService.insertCheck_Item(this.P111H2[i].title,this.P111H2[i].checked,res,'Etat des plantes').subscribe(res => {
        })
      }
        
      for(let i=0;i<this.P111H3.length;i++){
        this._checkService.insertCheck_Item(this.P111H3[i].title,this.P111H3[i].checked,res,'Etat de la Serre').subscribe(res => {
        })
      }
    }
    else if (this.Mode=='P1' && this.heureMode=='15h'){
      for(let i=0;i<this.P115H1.length;i++){
        this._checkService.insertCheck_Item(this.P115H1[i].title,this.P115H1[i].checked,res,'Etat des Xframes').subscribe(res => {
        })
      }
        for(let i=0;i<this.P115H2.length;i++){
        this._checkService.insertCheck_Item(this.P115H2[i].title,this.P115H2[i].checked,res,'Etat des plantes').subscribe(res => {
        })
      }
        
      for(let i=0;i<this.P115H3.length;i++){
        this._checkService.insertCheck_Item(this.P115H3[i].title,this.P115H3[i].checked,res,'Etat de la Serre').subscribe(res => {
        })
      }
    }
    else  if(this.Mode=='P2' && this.heureMode=='8h'){
      for(let i=0;i<this.P28H1.length;i++){
      this._checkService.insertCheck_Item(this.P28H1[i].title,this.P28H1[i].checked,res,'Etat des mousses/semis').subscribe(res => {
      })
    }
      for(let i=0;i<this.P28H2.length;i++){
      this._checkService.insertCheck_Item(this.P28H2[i].title,this.P28H2[i].checked,res,'Etat des tables floating').subscribe(res => {
      })
    }
      
    for(let i=0;i<this.P28H3.length;i++){
      this._checkService.insertCheck_Item(this.P28H3[i].title,this.P28H3[i].checked,res,'Etat de la Serre').subscribe(res => {
      })
    }
    }
    else if (this.Mode=='P2' && this.heureMode=='11h'){
      for(let i=0;i<this.P211H1.length;i++){
        this._checkService.insertCheck_Item(this.P211H1[i].title,this.P211H1[i].checked,res,'Etat des mousses/semis').subscribe(res => {
        })
      }
        for(let i=0;i<this.P211H2.length;i++){
        this._checkService.insertCheck_Item(this.P211H2[i].title,this.P211H2[i].checked,res,'Etat des tables floating').subscribe(res => {
        })
      }
        
      for(let i=0;i<this.P211H3.length;i++){
        this._checkService.insertCheck_Item(this.P211H3[i].title,this.P211H3[i].checked,res,'Etat de la Serre').subscribe(res => {
        })
      }
    }
    else if (this.Mode=='P2' && this.heureMode=='15h'){
      for(let i=0;i<this.P215H1.length;i++){
        this._checkService.insertCheck_Item(this.P215H1[i].title,this.P215H1[i].checked,res,'Etat des mousses/semis').subscribe(res => {
        })
      }
        for(let i=0;i<this.P215H2.length;i++){
        this._checkService.insertCheck_Item(this.P215H2[i].title,this.P215H2[i].checked,res,'Etat des tables floating').subscribe(res => {
        })
      }
        
      for(let i=0;i<this.P215H3.length;i++){
        this._checkService.insertCheck_Item(this.P215H3[i].title,this.P215H3[i].checked,res,'Etat de la Serre').subscribe(res => {
        })
      }
    }
    else if (this.Mode=='P1WeekEnd' ){
      for(let i=0;i<this.P1WeekEnd1.length;i++){
        this._checkService.insertCheck_Item(this.P1WeekEnd1[i].title,this.P1WeekEnd1[i].checked,res,'Etat des Xframes').subscribe(res => {
        })
      }
        for(let i=0;i<this.P1WeekEnd2.length;i++){
        this._checkService.insertCheck_Item(this.P1WeekEnd2[i].title,this.P1WeekEnd2[i].checked,res,'Etat des plantes').subscribe(res => {
        })
      }
        
      for(let i=0;i<this.P1WeekEnd3.length;i++){
        this._checkService.insertCheck_Item(this.P1WeekEnd3[i].title,this.P1WeekEnd3[i].checked,res,'Etat de la Serre').subscribe(res => {
        })
      }
    }
    else if (this.Mode=='P2WeekEnd' ){
      for(let i=0;i<this.P2WeekEnd1.length;i++){
        this._checkService.insertCheck_Item(this.P2WeekEnd1[i].title,this.P2WeekEnd1[i].checked,res,'Etat des Xframes').subscribe(res => {
        })
      }
        for(let i=0;i<this.P2WeekEnd2.length;i++){
        this._checkService.insertCheck_Item(this.P2WeekEnd2[i].title,this.P2WeekEnd2[i].checked,res,'Etat des plantes').subscribe(res => {
        })
      }
        
      for(let i=0;i<this.P2WeekEnd3.length;i++){
        this._checkService.insertCheck_Item(this.P2WeekEnd3[i].title,this.P2WeekEnd3[i].checked,res,'Etat de la Serre').subscribe(res => {
        })
      }
    }
    else if (this.Mode=='Service' ){
      for(let i=0;i<this.Service1.length;i++){
        this._checkService.insertCheck_Item(this.Service1[i].title,this.Service1[i].checked,res,'Cuves drainage').subscribe(res => {
        })
      }
        for(let i=0;i<this.Service2.length;i++){
        this._checkService.insertCheck_Item(this.Service2[i].title,this.Service2[i].checked,res,'Local Technique A').subscribe(res => {
        })
      }
        
      for(let i=0;i<this.Service3.length;i++){
        this._checkService.insertCheck_Item(this.Service3[i].title,this.Service3[i].checked,res,'Local Technique B').subscribe(res => {
        })
      }
      for(let i=0;i<this.Service4.length;i++){
        this._checkService.insertCheck_Item(this.Service4[i].title,this.Service4[i].checked,res,'Local compresseur').subscribe(res => {
        })
      }
        for(let i=0;i<this.Service5.length;i++){
        this._checkService.insertCheck_Item(this.Service5[i].title,this.Service5[i].checked,res,'Serres P1').subscribe(res => {
        })
      }
        
      for(let i=0;i<this.Service6.length;i++){
        this._checkService.insertCheck_Item(this.Service6[i].title,this.Service6[i].checked,res,'Serres P2').subscribe(res => {
        })
      }
    }
   this.submit=true
  })
}

onSubmit(){
  this.insertCheck();
}
///////////////
}
