import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Projet } from '../models/Projet/Projet.model';
import { environment } from 'src/environments/environment';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { DatePipe } from '@angular/common';

@Injectable({
  providedIn: 'root' 
})
export class ProjetService {
   formProjet=this.fb.group({
    id_commande : ["" , Validators.required],
	  graine :  ["" , Validators.required],
	  nbr :    ""  ,
    nbr_recue : "",
	  dateCommande : ["" , Validators.required],
    dateLivraison :  ""  ,
	  commentaire : ["" , Validators.required],
	  etat : ["" , Validators.required],
   });
   listProjet:Projet[]
 
  constructor(private datePipe: DatePipe,private http: HttpClient, private fb: FormBuilder) {

   }
     
   initialiserVide(){
    this.formProjet.setValue({
      id_commande :  "" ,
      graine :  "" ,
      nbr :    ""  ,
      nbr_recue : "",
      dateCommande : "" ,
      dateLivraison :  ""  ,
      commentaire : "" ,
      etat : "" ,

    })
     }

  insertProjet( dateCommande , dateLivraison) {
      return this.http
    .post(
      "https://seeds.bg-agri.com/babylone_API/Commandes" + "/save.php?dateCommande="+
    this.datePipe.transform( dateCommande, 'dd/MM/yyyy')
      
      +"&dateLivraison="+  this.datePipe.transform( dateLivraison, 'dd/MM/yyyy') 
      +"&graine="+this.formProjet.value['graine']
      +"&nbr="+this.formProjet.value['nbr']
      +"&nbr_recue="+this.formProjet.value['nbr_recue']
      +"&commentaire="+this.formProjet.value['commentaire']
      +"&etat=En cours"
 
      ,JSON.stringify(''))
 
  }

  All_Projet() {
 

     return this.http.get("https://seeds.bg-agri.com/babylone_API/Commandes" + "/all.php" );
  }

 

  get_Projet(id:any) {
    return this.http.get("https://seeds.bg-agri.com/babylone_API/Commandes" + "/get.php?id_commande="+id);
  }
   

  get_Details_commande(id:any) {
    return this.http.get("https://seeds.bg-agri.com/babylone_API/Commandes" + "/details.php?id_commande="+id);
  }


  //get_Details_commande
  initialiser(ev){
    //2022/06/01
    //09/06/2022
    let dateCommande=ev.dateCommande.substring(6,4) +'/'+  ev.dateCommande.substring(2,2) + '/'+ ev.dateCommande.substring(0,2)
    let dateLivraison=ev.dateLivraison.substring(6,4) +'/'+  ev.dateLivraison.substring(2,2) + '/'+ ev.dateLivraison.substring(0,2)
    ev.dateCommande=this.datePipe.transform( dateCommande, 'yyyy-MM-dd') 
    ev.dateLivraison=this.datePipe.transform( dateLivraison, 'yyyy-MM-dd') 
    this.formProjet.setValue(ev)
  
  }

  modifierProjet( dateCommande , dateLivraison) {
     
    return this.http
  .post(
    "https://seeds.bg-agri.com/babylone_API/Commandes" + "/update.php?dateCommande="+
    this.datePipe.transform( dateCommande, 'dd/MM/yyyy')
    +"&dateLivraison="+ this.datePipe.transform( dateLivraison, 'dd/MM/yyyy') 
    +"&graine="+this.formProjet.value['graine']
    +"&nbr="+this.formProjet.value['nbr']
    +"&nbr_recue="+this.formProjet.value['nbr_recue']
    +"&commentaire="+this.formProjet.value['commentaire']
    +"&id_commande="+this.formProjet.value['id_commande']
    +"&etat="+this.formProjet.value['etat']

    ,JSON.stringify(''))
  }

  delete_commande(id:any) {
    return this.http.get("https://seeds.bg-agri.com/babylone_API/Commandes" + "/delete.php?id_commande="+id);
  }

 
          modifierEtat(id){
             return this.http
            .post(
              "https://seeds.bg-agri.com/babylone_API/Commandes" + "/updateEtat.php?id_commande="+id
              ,JSON.stringify(''))
          }
  
          All_Commande_Chart(){
            return this.http.get("https://seeds.bg-agri.com/babylone_API/Commandes" + "/All_Commande_Chart.php");

          }
 
 
}
