<div
  class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0"
>

  <div class="rounded-t bg-white mb-0 px-6 py-6">
    <div class="text-center flex justify-between">
      <h6 class="text-blueGray-700 text-xl font-bold">
        {{ _langService.GetLanguage()=='FR' ? 'Sélectionner une check-list' : 'Select a checklist' }}
             
        <select
        class="border-0 px-5 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring ease-linear transition-all duration-150"
        [(ngModel)]="Mode" 
      >
      <option  value="P1" > P1</option>
      <option  value="P1WeekEnd" > P1 Week-end</option>
      <option  value="P2" > P2</option>
      <option  value="P2WeekEnd" > P2 Week-end</option>
      <option  value="Service" > 
        {{ _langService.GetLanguage()=='FR' ? 'Service Technique' : 'Technical service' }}
        </option>
     
   </select>
        <select
        class="border-0 px-6 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring  ease-linear transition-all duration-150"
        [(ngModel)]="heureMode" 
        *ngIf="Mode=='P1' || Mode=='P2'"
        >
      <option  value="8h" > 
        {{ _langService.GetLanguage()=='FR' ? 'Au démarrage (8h)' : 'At startup (8h)' }}
        </option>
      <option  value="11h" > 
        {{ _langService.GetLanguage()=='FR' ? 'Au Courant de la journée (11h30)' : 'During the day (11:30 a.m.)' }} 
        </option>
      <option  value="15h" > 
        {{ _langService.GetLanguage()=='FR' ? 'Fin de journée (15h/16h/17h)' : 'End of day (3 p.m./4 p.m./5 p.m.)' }}
        </option>
      </select>
      <!--
      <button  [routerLink]="['/user/Check_lists/' ]"  style=" position: absolute;right: 50px;  background-color: rgb(70, 175, 0); color: white;padding: 5px;border-radius: 6px;">Historique Check-Liste</button>
      -->
      </h6>
    </div>
  </div>  
   <!-----------   P1       ------------>
  <form style="padding-top:20px" [formGroup]="_checkService.formCheck" autocomplete="off" (ngSubmit)="onSubmit()" *ngIf="Mode=='P1' && heureMode=='8h'">
    
    <div class="flex-auto px-4 lg:px-10 py-10 pt-0"  >
      <div class="flex flex-wrap">
 
          <div class="w-full lg:w-12/12 px-12">
            <div *ngFor="let item of Global_P1">
                <input type="checkbox" [(ngModel)]="item.checked"  formControlName="etat"> {{item.title}}
              </div>
            </div>   
        </div>
    </div>

    <div class="flex-auto px-4 lg:px-10 py-10 pt-0" style="border:1px solid black;">
        <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
          {{ _langService.GetLanguage()=='FR' ? 'État des Xframes' : 'Status of Xframes' }}
          
        </h6>
        <div class="flex flex-wrap">
   
            <div class="w-full lg:w-12/12 px-12">
              <div *ngFor="let item of P18H1">
                  <input type="checkbox" [(ngModel)]="item.checked"  formControlName="etat"> {{item.title}}
                </div>
              </div>   
          </div>
      </div>
      <div class="flex-auto px-4 lg:px-10 py-10 pt-0" style="border:1px solid black;margin-top: 20px;margin-bottom: 20px;">
        <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
          {{ _langService.GetLanguage()=='FR' ? 'État des Xframes' : 'Status of Xframes' }}
          
        </h6>
        <div class="flex flex-wrap">
   
            <div class="w-full lg:w-12/12 px-12">
              <div *ngFor="let item of P18H2">
                  <input type="checkbox" [(ngModel)]="item.checked"  formControlName="etat"> {{item.title}}
                </div>
              
              </div>   
          </div>
      </div>
      <div class="flex-auto px-4 lg:px-10 py-10 pt-0" style="border:1px solid black;margin-top: 20px;margin-bottom: 20px;">
        <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
          {{ _langService.GetLanguage()=='FR' ? 'État de la Serre' : 'State of the Greenhouse' }}
          
        </h6>
        <div class="flex flex-wrap">
   
            <div class="w-full lg:w-12/12 px-12">
              <div *ngFor="let item of P28H3">
                  <input type="checkbox" [(ngModel)]="item.checked"  formControlName="etat"> {{item.title}}
                </div>
              
              </div>   
          </div>
      </div>
         
  
      <div class="flex-auto px-4 lg:px-10 py-10 pt-0">
          <div class="w-full lg:w-10/12 px-2">
              <div class="relative w-full mb-3">
                <label
                  class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                  htmlFor="grid-password"
                >
                {{ _langService.GetLanguage()=='FR' ? 'Rapport' : 'Report' }}
                
                </label>
                <textarea 
                  type="text"
                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  placeholder="....."
                  formControlName="commentaire"
                  [class.is-invalid]="_checkService.formCheck['controls'].commentaire.errors?.required"
                ></textarea>
              </div>
            </div>
    
        <div class="align-self-center mx-auto text-center" style="padding-top: 15px;">
        <button   
        class="bg-red-600 text-white active:bg-red-700 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
        type="submit"  
        >
        {{ _langService.GetLanguage()=='FR' ? 'Valider' : 'Confirm' }}
        
      </button></div>
      <div *ngIf="submit" class="alert alert-success" style="background-color: rgb(98, 223, 98);height: 50px;text-align: center;">La check-liste  a été ajouté avec succès</div>
   
    
       </div>
   </form>
   <form style="padding-top:20px" [formGroup]="_checkService.formCheck" autocomplete="off" (ngSubmit)="onSubmit()" *ngIf="Mode=='P1' && heureMode=='11h'">
   
    <div class="flex-auto px-4 lg:px-10 py-10 pt-0"  >
      <div class="flex flex-wrap">
 
          <div class="w-full lg:w-12/12 px-12">
            <div *ngFor="let item of Global_P1">
                <input type="checkbox" [(ngModel)]="item.checked"  formControlName="etat"> {{item.title}}
              </div>
            </div>   
        </div>
    </div>

    <div class="flex-auto px-4 lg:px-10 py-10 pt-0" style="border:1px solid black;">
        <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
          {{ _langService.GetLanguage()=='FR' ? 'État des Xframes' : 'Status of Xframes' }}
        </h6>
        <div class="flex flex-wrap">
   
            <div class="w-full lg:w-12/12 px-12">
              <div *ngFor="let item of P111H1">
                  <input type="checkbox" [(ngModel)]="item.checked"  formControlName="etat"> {{item.title}}
                </div>
              </div>   
          </div>
      </div>
      <div class="flex-auto px-4 lg:px-10 py-10 pt-0" style="border:1px solid black;margin-top: 20px;margin-bottom: 20px;">
        <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
          {{ _langService.GetLanguage()=='FR' ? 'État des plantes' : 'Plant condition' }}
          
        </h6>
        <div class="flex flex-wrap">
   
            <div class="w-full lg:w-12/12 px-12">
              <div *ngFor="let item of P111H2">
                  <input type="checkbox" [(ngModel)]="item.checked"  formControlName="etat"> {{item.title}}
                </div>
              
              </div>   
          </div>
      </div>
      <div class="flex-auto px-4 lg:px-10 py-10 pt-0" style="border:1px solid black;margin-top: 20px;margin-bottom: 20px;">
        <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
          {{ _langService.GetLanguage()=='FR' ? 'État de la Serre' : 'Plant condition' }}
          
        </h6>
        <div class="flex flex-wrap">
   
            <div class="w-full lg:w-12/12 px-12">
              <div *ngFor="let item of P111H3">
                  <input type="checkbox" [(ngModel)]="item.checked"  formControlName="etat"> {{item.title}}
                </div>
              
              </div>   
          </div>
      </div>
         
  
      <div class="flex-auto px-4 lg:px-10 py-10 pt-0">
          <div class="w-full lg:w-10/12 px-2">
              <div class="relative w-full mb-3">
                <label
                  class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                  htmlFor="grid-password"
                >
                {{ _langService.GetLanguage()=='FR' ? 'Rapport' : 'Report' }}
                
                </label>
                <textarea 
                  type="text"
                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  placeholder="....."
                  formControlName="commentaire"
                  [class.is-invalid]="_checkService.formCheck['controls'].commentaire.errors?.required"
                ></textarea>
              </div>
            </div>
    
        <div class="align-self-center mx-auto text-center" style="padding-top: 15px;">
        <button   
        class="bg-red-600 text-white active:bg-red-700 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
        type="submit"  
        >
        {{ _langService.GetLanguage()=='FR' ? 'Valider' : 'Confirm' }}
      </button></div>
      <div *ngIf="submit" class="alert alert-success" style="background-color: rgb(98, 223, 98);height: 50px;text-align: center;">La check-liste  a été ajouté avec succès</div>
   
    
       </div>
   </form>
   <form style="padding-top:20px" [formGroup]="_checkService.formCheck" autocomplete="off" (ngSubmit)="onSubmit()" *ngIf="Mode=='P1' && heureMode=='15h'">
    <div class="flex-auto px-4 lg:px-10 py-10 pt-0" >
      <div class="flex flex-wrap">
 
          <div class="w-full lg:w-12/12 px-12">
            <div *ngFor="let item of Global_P1">
                <input type="checkbox" [(ngModel)]="item.checked"  formControlName="etat"> {{item.title}}
              </div>
            </div>   
        </div>
    </div>
    <div class="flex-auto px-4 lg:px-10 py-10 pt-0" style="border:1px solid black;">
        <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
          {{ _langService.GetLanguage()=='FR' ? 'État des Xframes' : 'Status of Xframes' }}
        </h6>
        <div class="flex flex-wrap">
   
            <div class="w-full lg:w-12/12 px-12">
              <div *ngFor="let item of P115H1">
                  <input type="checkbox" [(ngModel)]="item.checked"  formControlName="etat"> {{item.title}}
                </div>
              </div>   
          </div>
      </div>
      <div class="flex-auto px-4 lg:px-10 py-10 pt-0" style="border:1px solid black;margin-top: 20px;margin-bottom: 20px;">
        <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
          {{ _langService.GetLanguage()=='FR' ? 'État des plantes' : 'Plant condition' }}
        </h6>
        <div class="flex flex-wrap">
   
            <div class="w-full lg:w-12/12 px-12">
              <div *ngFor="let item of P115H2">
                  <input type="checkbox" [(ngModel)]="item.checked"  formControlName="etat"> {{item.title}}
                </div>
              
              </div>   
          </div>
      </div>
      <div class="flex-auto px-4 lg:px-10 py-10 pt-0" style="border:1px solid black;margin-top: 20px;margin-bottom: 20px;">
        <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
          {{ _langService.GetLanguage()=='FR' ? 'État de la Serre' : 'State of the Greenhouse' }}
        </h6>
        <div class="flex flex-wrap">
   
            <div class="w-full lg:w-12/12 px-12">
              <div *ngFor="let item of P115H3">
                  <input type="checkbox" [(ngModel)]="item.checked"  formControlName="etat"> {{item.title}}
                </div>
              
              </div>   
          </div>
      </div>
         
  
      <div class="flex-auto px-4 lg:px-10 py-10 pt-0">
          <div class="w-full lg:w-10/12 px-2">
              <div class="relative w-full mb-3">
                <label
                  class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                  htmlFor="grid-password"
                >
                {{ _langService.GetLanguage()=='FR' ? 'Rapport' : 'Report' }}
                </label>
                <textarea 
                  type="text"
                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  placeholder="....."
                  formControlName="commentaire"
                  [class.is-invalid]="_checkService.formCheck['controls'].commentaire.errors?.required"
                ></textarea>
              </div>
            </div>
    
        <div class="align-self-center mx-auto text-center" style="padding-top: 15px;">
        <button   
        class="bg-red-600 text-white active:bg-red-700 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
        type="submit"  
        >
        {{ _langService.GetLanguage()=='FR' ? 'Valider' : 'Confirm' }}
      </button></div>
      <div *ngIf="submit" class="alert alert-success" style="background-color: rgb(98, 223, 98);height: 50px;text-align: center;">La check-liste  a été ajouté avec succès</div>
   
    
       </div>
   </form>

      <!-----------   P1    WeekEnd   ------------>
  <form style="padding-top:20px" [formGroup]="_checkService.formCheck" autocomplete="off" (ngSubmit)="onSubmit()" *ngIf="Mode=='P1WeekEnd'  ">
    <div class="flex-auto px-4 lg:px-10 py-10 pt-0"  >
      <div class="flex flex-wrap">
 
          <div class="w-full lg:w-12/12 px-12">
            <div *ngFor="let item of Global_P1">
                <input type="checkbox" [(ngModel)]="item.checked"  formControlName="etat"> {{item.title}}
              </div>
            </div>   
        </div>
    </div>
    <div class="flex-auto px-4 lg:px-10 py-10 pt-0" style="border:1px solid black;">
        <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
          {{ _langService.GetLanguage()=='FR' ? 'État des Xframes' : 'Status of Xframes' }}
        </h6>
        <div class="flex flex-wrap">
   
            <div class="w-full lg:w-12/12 px-12">
              <div *ngFor="let item of P1WeekEnd1">
                  <input type="checkbox" [(ngModel)]="item.checked"  formControlName="etat"> {{item.title}}
                </div>
              </div>   
          </div>
      </div>
      <div class="flex-auto px-4 lg:px-10 py-10 pt-0" style="border:1px solid black;margin-top: 20px;margin-bottom: 20px;">
        <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
          {{ _langService.GetLanguage()=='FR' ? 'État des plantes' : 'Plant condition' }}
        </h6>
        <div class="flex flex-wrap">
   
            <div class="w-full lg:w-12/12 px-12">
              <div *ngFor="let item of P1WeekEnd2">
                  <input type="checkbox" [(ngModel)]="item.checked"  formControlName="etat"> {{item.title}}
                </div>
              
              </div>   
          </div>
      </div>
      <div class="flex-auto px-4 lg:px-10 py-10 pt-0" style="border:1px solid black;margin-top: 20px;margin-bottom: 20px;">
        <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
          {{ _langService.GetLanguage()=='FR' ? 'État de la Serre' : 'State of the Greenhouse' }}
        </h6>
        <div class="flex flex-wrap">
   
            <div class="w-full lg:w-12/12 px-12">
              <div *ngFor="let item of P1WeekEnd3">
                  <input type="checkbox" [(ngModel)]="item.checked"  formControlName="etat"> {{item.title}}
                </div>
              
              </div>   
          </div>
      </div>
         
  
      <div class="flex-auto px-4 lg:px-10 py-10 pt-0">
          <div class="w-full lg:w-10/12 px-2">
              <div class="relative w-full mb-3">
                <label
                  class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                  htmlFor="grid-password"
                >
                {{ _langService.GetLanguage()=='FR' ? 'Rapport' : 'Report' }}
                </label>
                <textarea 
                  type="text"
                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  placeholder="....."
                  formControlName="commentaire"
                  [class.is-invalid]="_checkService.formCheck['controls'].commentaire.errors?.required"
                ></textarea>
              </div>
            </div>
    
        <div class="align-self-center mx-auto text-center" style="padding-top: 15px;">
        <button   
        class="bg-red-600 text-white active:bg-red-700 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
        type="submit"  
        >
        {{ _langService.GetLanguage()=='FR' ? 'Valider' : 'Confirm' }}
      </button></div>
      <div *ngIf="submit" class="alert alert-success" style="background-color: rgb(98, 223, 98);height: 50px;text-align: center;">La check-liste  a été ajouté avec succès</div>
   
    
       </div>
   </form>

   <!-----------   P2       ------------>
   <form style="padding-top:20px" [formGroup]="_checkService.formCheck" autocomplete="off" (ngSubmit)="onSubmit()" *ngIf="Mode=='P2' && heureMode=='8h'">
    <div class="flex-auto px-4 lg:px-10 py-10 pt-0"  >
      <div class="flex flex-wrap">
 
          <div class="w-full lg:w-12/12 px-12">
            <div *ngFor="let item of Global_P2">
                <input type="checkbox" [(ngModel)]="item.checked"  formControlName="etat"> {{item.title}}
              </div>
            </div>   
        </div>
    </div>
    <div class="flex-auto px-4 lg:px-10 py-10 pt-0" style="border:1px solid black;">
        <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
          {{ _langService.GetLanguage()=='FR' ? 'État des mousses/semis' : 'Condition of moss/seedlings' }}
          
        </h6>
        <div class="flex flex-wrap">
   
            <div class="w-full lg:w-12/12 px-12">
              <div *ngFor="let item of P28H1">
                  <input type="checkbox" [(ngModel)]="item.checked"  formControlName="etat"> {{item.title}}
                </div>
              </div>   
          </div>
      </div>
      <div class="flex-auto px-4 lg:px-10 py-10 pt-0" style="border:1px solid black;margin-top: 20px;margin-bottom: 20px;">
        <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
          {{ _langService.GetLanguage()=='FR' ? 'État des tables Transplantation' : 'Status of Transplantation tables' }}
          
        </h6>
        <div class="flex flex-wrap">
   
            <div class="w-full lg:w-12/12 px-12">
              <div *ngFor="let item of P28H2">
                  <input type="checkbox" [(ngModel)]="item.checked"  formControlName="etat"> {{item.title}}
                </div>
              
              </div>   
          </div>
      </div>
      <div class="flex-auto px-4 lg:px-10 py-10 pt-0" style="border:1px solid black;margin-top: 20px;margin-bottom: 20px;">
        <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
          {{ _langService.GetLanguage()=='FR' ? 'État de la Serre' : 'State of the Greenhouse' }}
        </h6>
        <div class="flex flex-wrap">
   
            <div class="w-full lg:w-12/12 px-12">
              <div *ngFor="let item of P28H3">
                  <input type="checkbox" [(ngModel)]="item.checked"  formControlName="etat"> {{item.title}}
                </div>
              
              </div>   
          </div>
      </div>
         
  
      <div class="flex-auto px-4 lg:px-10 py-10 pt-0">
          <div class="w-full lg:w-10/12 px-2">
              <div class="relative w-full mb-3">
                <label
                  class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                  htmlFor="grid-password"
                >
                {{ _langService.GetLanguage()=='FR' ? 'Rapport' : 'Report' }}
                </label>
                <textarea 
                  type="text"
                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  placeholder="....."
                  formControlName="commentaire"
                  [class.is-invalid]="_checkService.formCheck['controls'].commentaire.errors?.required"
                ></textarea>
              </div>
            </div>
    
        <div class="align-self-center mx-auto text-center" style="padding-top: 15px;">
        <button   
        class="bg-red-600 text-white active:bg-red-700 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
        type="submit"  
        >
        {{ _langService.GetLanguage()=='FR' ? 'Valider' : 'Confirm' }}
      </button></div>
      <div *ngIf="submit" class="alert alert-success" style="background-color: rgb(98, 223, 98);height: 50px;text-align: center;">La check-liste  a été ajouté avec succès</div>
   
    
       </div>
   </form> 
   <form style="padding-top:20px" [formGroup]="_checkService.formCheck" autocomplete="off" (ngSubmit)="onSubmit()" *ngIf="Mode=='P2' && heureMode=='11h'">
    <div class="flex-auto px-4 lg:px-10 py-10 pt-0"  >
      <div class="flex flex-wrap">
 
          <div class="w-full lg:w-12/12 px-12">
            <div *ngFor="let item of Global_P2">
                <input type="checkbox" [(ngModel)]="item.checked"  formControlName="etat"> {{item.title}}
              </div>
            </div>   
        </div>
    </div>
    <div class="flex-auto px-4 lg:px-10 py-10 pt-0" style="border:1px solid black;">
        <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
          {{ _langService.GetLanguage()=='FR' ? 'État des mousses/semis' : 'Condition of moss/seedlings' }}
        </h6>
        <div class="flex flex-wrap">
   
            <div class="w-full lg:w-12/12 px-12">
              <div *ngFor="let item of P211H1">
                  <input type="checkbox" [(ngModel)]="item.checked"  formControlName="etat"> {{item.title}}
                </div>
              </div>   
          </div>
      </div>
      <div class="flex-auto px-4 lg:px-10 py-10 pt-0" style="border:1px solid black;margin-top: 20px;margin-bottom: 20px;">
        <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
           {{ _langService.GetLanguage()=='FR' ? 'État des tables Transplantation' : 'Status of Transplantation tables' }}
        </h6>
        <div class="flex flex-wrap">
   
            <div class="w-full lg:w-12/12 px-12">
              <div *ngFor="let item of P211H2">
                  <input type="checkbox" [(ngModel)]="item.checked"  formControlName="etat"> {{item.title}}
                </div>
              
              </div>   
          </div>
      </div>
      <div class="flex-auto px-4 lg:px-10 py-10 pt-0" style="border:1px solid black;margin-top: 20px;margin-bottom: 20px;">
        <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
          {{ _langService.GetLanguage()=='FR' ? 'État de la Serre' : 'State of the Greenhouse' }}
        </h6>
        <div class="flex flex-wrap">
   
            <div class="w-full lg:w-12/12 px-12">
              <div *ngFor="let item of P211H3">
                  <input type="checkbox" [(ngModel)]="item.checked"  formControlName="etat"> {{item.title}}
                </div>
              
              </div>   
          </div>
      </div>
         
  
      <div class="flex-auto px-4 lg:px-10 py-10 pt-0">
          <div class="w-full lg:w-10/12 px-2">
              <div class="relative w-full mb-3">
                <label
                  class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                  htmlFor="grid-password"
                >
                {{ _langService.GetLanguage()=='FR' ? 'Rapport' : 'Report' }}
                </label>
                <textarea 
                  type="text"
                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  placeholder="....."
                  formControlName="commentaire"
                  [class.is-invalid]="_checkService.formCheck['controls'].commentaire.errors?.required"
                ></textarea>
              </div>
            </div>
    
        <div class="align-self-center mx-auto text-center" style="padding-top: 15px;">
        <button   
        class="bg-red-600 text-white active:bg-red-700 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
        type="submit"  
        >
        {{ _langService.GetLanguage()=='FR' ? 'Valider' : 'Confirm' }}
      </button></div>
      <div *ngIf="submit" class="alert alert-success" style="background-color: rgb(98, 223, 98);height: 50px;text-align: center;">La check-liste  a été ajouté avec succès</div>
   
    
       </div>
   </form>
   <form style="padding-top:20px" [formGroup]="_checkService.formCheck" autocomplete="off" (ngSubmit)="onSubmit()" *ngIf="Mode=='P2' && heureMode=='15h'">
    <div class="flex-auto px-4 lg:px-10 py-10 pt-0"  >
      <div class="flex flex-wrap">
 
          <div class="w-full lg:w-12/12 px-12">
            <div *ngFor="let item of Global_P2">
                <input type="checkbox" [(ngModel)]="item.checked"  formControlName="etat"> {{item.title}}
              </div>
            </div>   
        </div>
    </div>
    <div class="flex-auto px-4 lg:px-10 py-10 pt-0" style="border:1px solid black;">
        <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
          {{ _langService.GetLanguage()=='FR' ? 'État des mousses/semis' : 'Condition of moss/seedlings' }}
        </h6>
        <div class="flex flex-wrap">
   
            <div class="w-full lg:w-12/12 px-12">
              <div *ngFor="let item of P215H1">
                  <input type="checkbox" [(ngModel)]="item.checked"  formControlName="etat"> {{item.title}}
                </div>
              </div>   
          </div>
      </div>
      <div class="flex-auto px-4 lg:px-10 py-10 pt-0" style="border:1px solid black;margin-top: 20px;margin-bottom: 20px;">
        <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
           {{ _langService.GetLanguage()=='FR' ? 'État des tables Transplantation' : 'Status of Transplantation tables' }}
        </h6>
        <div class="flex flex-wrap">
   
            <div class="w-full lg:w-12/12 px-12">
              <div *ngFor="let item of P215H2">
                  <input type="checkbox" [(ngModel)]="item.checked"  formControlName="etat"> {{item.title}}
                </div>
              
              </div>   
          </div>
      </div>
      <div class="flex-auto px-4 lg:px-10 py-10 pt-0" style="border:1px solid black;margin-top: 20px;margin-bottom: 20px;">
        <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
          {{ _langService.GetLanguage()=='FR' ? 'État de la Serre' : 'State of the Greenhouse' }}
        </h6>
        <div class="flex flex-wrap">
   
            <div class="w-full lg:w-12/12 px-12">
              <div *ngFor="let item of P215H3">
                  <input type="checkbox" [(ngModel)]="item.checked"  formControlName="etat"> {{item.title}}
                </div>
              
              </div>   
          </div>
      </div>
         
  
      <div class="flex-auto px-4 lg:px-10 py-10 pt-0">
          <div class="w-full lg:w-10/12 px-2">
              <div class="relative w-full mb-3">
                <label
                  class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                  htmlFor="grid-password"
                >
                {{ _langService.GetLanguage()=='FR' ? 'Rapport' : 'Report' }}
                </label>
                <textarea 
                  type="text"
                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  placeholder="....."
                  formControlName="commentaire"
                  [class.is-invalid]="_checkService.formCheck['controls'].commentaire.errors?.required"
                ></textarea>
              </div>
            </div>
    
        <div class="align-self-center mx-auto text-center" style="padding-top: 15px;">
        <button   
        class="bg-red-600 text-white active:bg-red-700 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
        type="submit"  
        >
        {{ _langService.GetLanguage()=='FR' ? 'Valider' : 'Confirm' }}
      </button></div>
      <div *ngIf="submit" class="alert alert-success" style="background-color: rgb(98, 223, 98);height: 50px;text-align: center;">La check-liste  a été ajouté avec succès</div>
   
    
       </div>
   </form>

      <!-----------   P2   WeekEnd    ------------>
  <form style="padding-top:20px" [formGroup]="_checkService.formCheck" autocomplete="off" (ngSubmit)="onSubmit()" *ngIf="Mode=='P2WeekEnd' ">
   
    <div class="flex-auto px-4 lg:px-10 py-10 pt-0"  >
      <div class="flex flex-wrap">
 
          <div class="w-full lg:w-12/12 px-12">
            <div *ngFor="let item of Global_P2">
                <input type="checkbox" [(ngModel)]="item.checked"  formControlName="etat"> {{item.title}}
              </div>
            </div>   
        </div>
    </div>
    <div class="flex-auto px-4 lg:px-10 py-10 pt-0" style="border:1px solid black;">
        <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
          {{ _langService.GetLanguage()=='FR' ? 'État des mousses/semis' : 'Condition of moss/seedlings' }}
        </h6>
        <div class="flex flex-wrap">
    
            <div class="w-full lg:w-12/12 px-12">
              <div *ngFor="let item of P2WeekEnd1">
                  <input type="checkbox" [(ngModel)]="item.checked"  formControlName="etat"> {{item.title}}
                </div>
              </div>   
          </div>
      </div>
      <div class="flex-auto px-4 lg:px-10 py-10 pt-0" style="border:1px solid black;margin-top: 20px;margin-bottom: 20px;">
        <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
           {{ _langService.GetLanguage()=='FR' ? 'État des tables Transplantation' : 'Status of Transplantation tables' }}
        </h6>
        <div class="flex flex-wrap">
    
            <div class="w-full lg:w-12/12 px-12">
              <div *ngFor="let item of P2WeekEnd2">
                  <input type="checkbox" [(ngModel)]="item.checked"  formControlName="etat"> {{item.title}}
                </div>
              
              </div>   
          </div>
      </div>
      <div class="flex-auto px-4 lg:px-10 py-10 pt-0" style="border:1px solid black;margin-top: 20px;margin-bottom: 20px;">
        <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
          {{ _langService.GetLanguage()=='FR' ? 'État de la Serre' : 'State of the Greenhouse' }}
        </h6>
        <div class="flex flex-wrap">
    
            <div class="w-full lg:w-12/12 px-12">
              <div *ngFor="let item of P2WeekEnd3">
                  <input type="checkbox" [(ngModel)]="item.checked"  formControlName="etat"> {{item.title}}
                </div>
              
              </div>   
          </div>
      </div>
          
  
      <div class="flex-auto px-4 lg:px-10 py-10 pt-0">
          <div class="w-full lg:w-10/12 px-2">
              <div class="relative w-full mb-3">
                <label
                  class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                  htmlFor="grid-password"
                >
                {{ _langService.GetLanguage()=='FR' ? 'Rapport' : 'Report' }}
                </label>
                <textarea 
                  type="text"
                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  placeholder="....."
                  formControlName="commentaire"
                  [class.is-invalid]="_checkService.formCheck['controls'].commentaire.errors?.required"
                ></textarea>
              </div>
            </div>
    
        <div class="align-self-center mx-auto text-center" style="padding-top: 15px;">
        <button   
        class="bg-red-600 text-white active:bg-red-700 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
        type="submit"  
        >
        {{ _langService.GetLanguage()=='FR' ? 'Valider' : 'Confirm' }}
      </button></div>
      <div *ngIf="submit" class="alert alert-success" style="background-color: rgb(98, 223, 98);height: 50px;text-align: center;">La check-liste  a été ajouté avec succès</div>
    
    
        </div>
    </form> 

      <!-----------   Service       ------------>
  <form style="padding-top:20px" [formGroup]="_checkService.formCheck" autocomplete="off" (ngSubmit)="onSubmit()" *ngIf="Mode=='Service' ">
    <div class="flex-auto px-4 lg:px-10 py-10 pt-0"  >
      <div class="flex flex-wrap">
 
          <div class="w-full lg:w-12/12 px-12">
            <div *ngFor="let item of Global_Service">
                <input type="checkbox" [(ngModel)]="item.checked"  formControlName="etat"> {{item.title}}
              </div>
            </div>   
        </div>
    </div>
    <div class="flex-auto px-4 lg:px-10 py-10 pt-0" style="border:1px solid black;">
        <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
          {{ _langService.GetLanguage()=='FR' ? 'Cuves drainage' : 'Drainage tanks' }}
          
        </h6>
        <div class="flex flex-wrap">
    
            <div class="w-full lg:w-12/12 px-12">
              <div *ngFor="let item of Service1">
                  <input type="checkbox" [(ngModel)]="item.checked"  formControlName="etat"> {{item.title}}
                </div>
              </div>   
          </div>
      </div>
      <div class="flex-auto px-4 lg:px-10 py-10 pt-0" style="border:1px solid black;margin-top: 20px;margin-bottom: 20px;">
        <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
          {{ _langService.GetLanguage()=='FR' ? 'Local Technique A' : 'Technical Room A' }}
          
        </h6>
        <div class="flex flex-wrap">
    
            <div class="w-full lg:w-12/12 px-12">
              <div *ngFor="let item of Service2">
                  <input type="checkbox" [(ngModel)]="item.checked"  formControlName="etat"> {{item.title}}
                </div>
              
              </div>   
          </div>
      </div>
      <div class="flex-auto px-4 lg:px-10 py-10 pt-0" style="border:1px solid black;margin-top: 20px;margin-bottom: 20px;">
        <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
          {{ _langService.GetLanguage()=='FR' ? 'Local Technique B' : 'Technical Room B' }}
          
        </h6>
        <div class="flex flex-wrap">
    
            <div class="w-full lg:w-12/12 px-12">
              <div *ngFor="let item of Service3">
                  <input type="checkbox" [(ngModel)]="item.checked"  formControlName="etat"> {{item.title}}
                </div>
              
              </div>   
          </div>
      </div>
          

      <div class="flex-auto px-4 lg:px-10 py-10 pt-0" style="border:1px solid black;margin-top: 20px;margin-bottom: 20px;">
        <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
          {{ _langService.GetLanguage()=='FR' ? 'Local compresseur' : 'Compressor room' }}
          
        </h6>
        <div class="flex flex-wrap">
    
            <div class="w-full lg:w-12/12 px-12">
              <div *ngFor="let item of Service4">
                  <input type="checkbox" [(ngModel)]="item.checked"  formControlName="etat"> {{item.title}}
                </div>
              
              </div>   
          </div>
      </div>
          
      <div class="flex-auto px-4 lg:px-10 py-10 pt-0" style="border:1px solid black;margin-top: 20px;margin-bottom: 20px;">
        <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
          {{ _langService.GetLanguage()=='FR' ? 'Serres P1' : 'P1 Greenhouses' }}
          
        </h6>
        <div class="flex flex-wrap">
    
            <div class="w-full lg:w-12/12 px-12">
              <div *ngFor="let item of Service5">
                  <input type="checkbox" [(ngModel)]="item.checked"  formControlName="etat"> {{item.title}}
                </div>
              
              </div>   
          </div>
      </div>
      <div class="flex-auto px-4 lg:px-10 py-10 pt-0" style="border:1px solid black;margin-top: 20px;margin-bottom: 20px;">
        <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
          Serres P2
        </h6>
        <div class="flex flex-wrap">
    
            <div class="w-full lg:w-12/12 px-12">
              <div *ngFor="let item of Service6">
                  <input type="checkbox" [(ngModel)]="item.checked"  formControlName="etat"> {{item.title}}
                </div>
              
              </div>   
          </div>
      </div>
          

          


      <div class="flex-auto px-4 lg:px-10 py-10 pt-0">
          <div class="w-full lg:w-10/12 px-2">
              <div class="relative w-full mb-3">
                <label
                  class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                  htmlFor="grid-password"
                >
                {{ _langService.GetLanguage()=='FR' ? 'Rapport' : 'Report' }}
                </label>
                <textarea 
                  type="text"
                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  placeholder="....."
                  formControlName="commentaire"
                  [class.is-invalid]="_checkService.formCheck['controls'].commentaire.errors?.required"
                ></textarea>
              </div>
            </div>
    
        <div class="align-self-center mx-auto text-center" style="padding-top: 15px;">
        <button   
        class="bg-red-600 text-white active:bg-red-700 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
        type="submit"  
        >
        {{ _langService.GetLanguage()=='FR' ? 'Valider' : 'Confirm' }}
      </button></div>
      <div *ngIf="submit" class="alert alert-success" style="background-color: rgb(98, 223, 98);height: 50px;text-align: center;">Check-list ajoutée avec succès</div>        
        </div>
    </form> 

</div>
