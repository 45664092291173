 
<div
class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0"
>
<div class="rounded-t bg-white mb-0 px-6 py-6">
  <div class="text-center flex justify-between">
    <h6 class="text-blueGray-700 text-xl font-bold">
      {{ _langService.GetLanguage()=='FR' ? 'Détails commande' : 'Order details' }}
      </h6>
 
  </div>
</div>
<div class="flex-auto px-4 lg:px-10 py-10 pt-0">


 <form  [formGroup]="_projetService.formProjet" autocomplete="off" (ngSubmit)="onSubmit()" >
  <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
    {{ _langService.GetLanguage()=='FR' ? 'Informations générales' : 'General informations' }}

  </h6>
  <div class="flex flex-wrap">

    <div class="w-full lg:w-6/12 px-4">
      <div class="relative w-full mb-3">
        <label
          class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
          htmlFor="grid-password"
        >
        {{ _langService.GetLanguage()=='FR' ? 'Graine' : 'Seed' }}
        
        </label>
        <select 
          type="text"
          class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
           formControlName="graine"
          [class.is-invalid]="_projetService.formProjet.controls['graine'].errors?.required"
          disabled
        >
        <option *ngFor="let graine of _graineService.listCompetence" [value]="graine.id_graine" > {{graine.variete}} - {{graine.type}} </option>

        </select>
      </div>
    </div>
    
    <div class="w-full lg:w-6/12 px-4">
      <div class="relative w-full mb-3">
        <label
          class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
          htmlFor="grid-password"
        >
        {{ _langService.GetLanguage()=='FR' ? 'Nombre de graines commandées' : 'Number of seeds ordered' }}
        
        </label>
        <input
          type="number"
          class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
           formControlName="nbr"
          [class.is-invalid]="_projetService.formProjet.controls['nbr'].errors?.required"
          
        />
      </div>
    </div>



    <div class="w-full lg:w-6/12 px-4">
        <div class="relative w-full mb-3">
          <label
            class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
            htmlFor="grid-password"
          >
          {{ _langService.GetLanguage()=='FR' ? 'Date commande' : 'Order date' }}

          
          </label>
   
          <input class="datepicker-here form-control border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"  name="dateDebutprojet"  formControlName="dateDebutprojet"  
            type="date" id="start" name="trip-start"
           [value]="dateCommande"
            [(ngModel)]="dateCommande" 
            formControlName="dateCommande"

            > 
        </div>
      </div>
    <div class="w-full lg:w-6/12 px-4">
        <div class="relative w-full mb-3">
          <label
            class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
            htmlFor="grid-password"
          >
          {{ _langService.GetLanguage()=='FR' ? 'Date de livraison estimée' : 'estimated date of delivery' }}
          
          </label>
          <input
            type="date"
            class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
             formControlName="dateLivraison"
            [value]="dateLivraison"
            [(ngModel)]="dateLivraison" 
           />
        </div>
      </div>
      <div class="w-full lg:w-6/12 px-4">
        <div class="relative w-full mb-3">
          <label
            class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
            htmlFor="grid-password"
          >
          {{ _langService.GetLanguage()=='FR' ? 'Graines reçues' : 'Seeds received' }}

           
          </label>
          <input
            type="number"
            class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
            formControlName="nbr_recue"
            [class.is-invalid]="_projetService.formProjet.controls['nbr_recue'].errors?.required"
            disabled
          />
        </div>
      </div>
 
        <div class="w-full lg:w-6/12 px-4">
            <div class="relative w-full mb-3">
              <label
                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
              {{ _langService.GetLanguage()=='FR' ? 'Commentaire' : 'Comment' }}

              
              </label>
              <textarea 
                type="text"
                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                formControlName="commentaire"
                [class.is-invalid]="_projetService.formProjet['controls'].commentaire.errors?.required"
              ></textarea>
            </div>
          </div>
    
    
   </div>



  <div class="align-self-center mx-auto text-center" style="padding-top: 15px;">
    <button  [routerLink]= "['/admin/reception',id]"
    type="button"
    class="bg-green-600 text-white active:bg-green-700 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
     style="background-color:green ;"
     *ngIf="_projetService.formProjet.value['etat']=='En cours'"
    >
    <i class="fas fa-cart-arrow-down"></i>
    {{ _langService.GetLanguage()=='FR' ? 'Réception' : 'Reception' }}
    
  </button>
  <button  
  class="bg-red-600 text-white active:bg-red-700 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
  type="submit"  
  >
  <i class="fas fa-check"></i>
  {{ _langService.GetLanguage()=='FR' ? 'Modifier' : 'Save' }}

  
</button></div>
<div *ngIf="submit" class="alert alert-success" style="background-color: rgb(98, 223, 98);height: 50px;text-align: center;">
  {{ _langService.GetLanguage()=='FR' ? 'Commande modifiée avec succès' : 'Order modified successfully' }}

  </div>
</form>
</div>
</div>
