import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from 'src/environments/environment';
import { FormBuilder, Validators } from '@angular/forms';
import { Stock } from '../models/stock/stock.model';
import { DatePipe } from '@angular/common';
 
@Injectable({
  providedIn: 'root' 
})
export class BonLivraisonService {
 
  formBonLivraison=this.fb.group({
    id_bonLivraison : ["" , Validators.required],
    code_bonLivraison : ["" , Validators.required],
	  date : ["" , Validators.required],
	  client : [ "" , Validators.required],
    adresse : ["" , Validators.required],
	  zip : ["", Validators.required],
	  ville : ["" , Validators.required],
    intra : ["" , Validators.required],
    codeTVA : [ 6  , Validators.required],
    taux : [10  , Validators.required],
    num_facture : ["" , Validators.required],
    echeance  : ["" , Validators.required],
    commentaire : ["" , Validators.required],

  });

  listBonLivraison:any[]
  constructor(private datePipe: DatePipe,private http: HttpClient, private fb: FormBuilder) {
   }

   AddBonLivraison(date,echeance) { 
    let annee=  new Date().getFullYear()
    let month=  new Date().getMonth()
    
      return this.http
    .post(
      "https://seeds.bg-agri.com/babylone_API/Livraison" + "/save.php?date="+
      this.datePipe.transform( date , 'dd/MM/yyyy')
      +"&adresse="+this.formBonLivraison.value['adresse']
      +"&commentaire="+this.formBonLivraison.value['commentaire']
      +"&client="+this.formBonLivraison.value['client']
      +"&zip="+this.formBonLivraison.value['zip']
      +"&ville="+this.formBonLivraison.value['ville']
      +"&intra="+this.formBonLivraison.value['intra']
      +"&codeTVA="+this.formBonLivraison.value['codeTVA']
      +"&taux="+this.formBonLivraison.value['taux']
      +"&echeance="+this.datePipe.transform( echeance , 'dd/MM/yyyy')
      +"&annee="+annee
      +"&month="+month
 
      ,JSON.stringify(''))
  }

  initialiser(ev){
    let date;
    if(ev.date.indexOf("-")>-1)
    if(ev.date.indexOf("-")==2){
      date=  ev.date.substring(6) + '/'+ ev.date.substring(3,5)+'/'+ ev.date.substring(0,2)
     
    }
    else{
      date=ev.date.replaceAll("-","/")
    }
    else{
      date=  ev.date.substring(6) + '/'+ ev.date.substring(3,5)+'/'+ ev.date.substring(0,2)
    }
    ev.date=this.datePipe.transform( date, 'yyyy-MM-dd')  
     console.log(ev.date)
    this.formBonLivraison.setValue(ev)
     }

     initialiserClientInfo(client){
       
      this.formBonLivraison.value['intra']=client['Tva_Intra']
      this.formBonLivraison.value['adresse']=client['adresse']
      this.formBonLivraison.value['zip']=client['zip']
      this.formBonLivraison.value['ville']=client['ville']
      this.formBonLivraison.setValue(this.formBonLivraison.value)
     }
     
  All_BonLivraison(){
     return this.http.get("https://seeds.bg-agri.com/babylone_API/Livraison" + "/all.php" );
  }
 

  get_BonLivraison(id){
    return this.http.get("https://seeds.bg-agri.com/babylone_API/Livraison" + "/get.php?id="+id);
   }

  modifierBonLivraison(date) {
    return this.http
  .post(
    "https://seeds.bg-agri.com/babylone_API/Livraison" + "/update?date="+
    this.datePipe.transform( date , 'dd/MM/yyyy')
    +"&adresse="+this.formBonLivraison.value['adresse']
    +"&commentaire="+this.formBonLivraison.value['commentaire']
    +"&client="+this.formBonLivraison.value['client']
    +"&zip="+this.formBonLivraison.value['zip']
    +"&ville="+this.formBonLivraison.value['ville']
    +"&intra="+this.formBonLivraison.value['intra']
    +"&codeTVA="+this.formBonLivraison.value['codeTVA']
    +"&taux="+this.formBonLivraison.value['taux']
    +"&num_facture="+this.formBonLivraison.value['num_facture']
    +"&echeance="+this.formBonLivraison.value['echeance']
    +"&id_bonLivraison="+this.formBonLivraison.value['id_bonLivraison']
    
    ,JSON.stringify(''))
  }

  delete_BonLivraison(id:any) {
    return this.http.get("https://seeds.bg-agri.com/babylone_API/Livraison" + "/delete.php?id="+id);
  }
 
///ajout chaque bon de livraison

AddArticle(article,id_bonLivraison) {  
  console.log( "https://seeds.bg-agri.com/babylone_API/Livraison" + "/saveArticle.php?Code="+
  article['Code']
  +"&Quantity="+article['Quantity']
  +"&Poids="+article['Poids']
  +"&PU="+article['PU']
  +"&bonLivraison="+id_bonLivraison);
  
  this.formBonLivraison.value['id_bonLivraison']=id_bonLivraison
     
  return this.http
.post(
  "https://seeds.bg-agri.com/babylone_API/Livraison" + "/saveArticle.php?Code="+
  article['Code']
  +"&Quantity="+article['Quantity']
  +"&Poids="+article['Poids']
  +"&PU="+article['PU']
  +"&bonLivraison="+id_bonLivraison

  ,JSON.stringify(''))
}



Add_PDF_BonLivraison() {     
  return this.http
.post(
  "https://seeds.bg-agri.com/babylone_API/PDF_Generate" + "/export_Bon_Livraison_PDF.php?id_bonLivraison="+
   +this.formBonLivraison.value['id_bonLivraison']
  ,JSON.stringify(''))
}
Add_PDF_Facture() {     
  return this.http
.post(
  "https://seeds.bg-agri.com/babylone_API/PDF_Generate" + "/export_Facture_PDF.php?id_bonLivraison="+
   +this.formBonLivraison.value['id_bonLivraison']
  ,JSON.stringify(''))
}
Add_PDF_Etiquette() {     
  return this.http
.post(
  "https://seeds.bg-agri.com/babylone_API/PDF_Generate" + "/export_Etiquette.php?id_bonLivraison="+
   +this.formBonLivraison.value['id_bonLivraison']
  ,JSON.stringify(''))
}
}
