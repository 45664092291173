<div class="relative flex w-full flex-wrap items-stretch" style="margin-bottom: 20px;">
    <span class="z-10 h-full leading-snug font-normal absolute text-center text-blueGray-300 absolute bg-transparent rounded text-base items-center justify-center w-8 pl-3 py-3">
        <i class="fas fa-search"></i>
    </span>
    <input type="text" placeholder="Search here..." [(ngModel)]="search" class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 relative bg-white bg-white rounded text-sm shadow outline-none focus:outline-none focus:ring w-full pl-10">
</div>

<div
  class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded"
  [ngClass]="color === 'light' ? 'bg-white' : 'bg-red-900 text-white'"
>
  <div class="rounded-t mb-0 px-4 py-3 border-0">
    <div class="flex flex-wrap items-center">
      <div class="relative w-full px-4 max-w-full flex-grow flex-1">
        <h3
          class="font-semibold text-lg"
          [ngClass]="color === 'light' ? 'text-blueGray-700' : 'text-white'"
        >
        {{ _langService.GetLanguage()=='FR' ? 'Gérer les graines' : 'Managing seeds' }}

          
        </h3>
      </div>
    </div>
  </div>
  <div class="block w-full overflow-x-auto">
    <!-- Projects table -->
    <table class="items-center w-full bg-transparent border-collapse">
      <thead>
        <tr>
          <th
            class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
            [ngClass]="
              color === 'light'
                ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                : 'bg-red-800 text-red-300 border-red-700'
            "
            (click)="sort('variete')"
          >
          {{ _langService.GetLanguage()=='FR' ? 'Variété' : 'Variety' }}

            
          </th>
          <th
            class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
            [ngClass]="
              color === 'light'
                ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                : 'bg-red-800 text-red-300 border-red-700'
            "
          (click)="sort('fournisseur')"
          >
          {{ _langService.GetLanguage()=='FR' ? 'Fournisseur' : 'Supplier' }}

          
          </th>
      
          <th
            class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
            [ngClass]="
              color === 'light'
                ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                : 'bg-red-800 text-red-300 border-red-700'
            "
          (click)="sort('type')"
          >
          Type
          </th>
      
          <th
            class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
            [ngClass]="
              color === 'light'
                ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                : 'bg-red-800 text-red-300 border-red-700'
            "
          (click)="sort('saison')"
          >
          {{ _langService.GetLanguage()=='FR' ? 'Saison' : 'Season' }}

          
          </th>
      
          <th
            class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
            [ngClass]="
              color === 'light'
                ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                : 'bg-red-800 text-red-300 border-red-700'
            "
          (click)="sort('historique')"
          >
          {{ _langService.GetLanguage()=='FR' ? 'Historique' : 'History' }}
          
          </th>
      
          <th
            class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-center"
            [ngClass]="
              color === 'light'
                ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                : 'bg-red-800 text-red-300 border-red-700'
            "
          >
        Actions
        </th>
        </tr>
      </thead>
      <tbody>
        <tr  *ngFor="let competence of listCompetence | filter:search">
          <th
            class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left flex items-left"
          >
          
            <span
              class="ml-3 font-bold"
              [ngClass]="color === 'light' ? 'text-blueGray-600' : 'text-white'"
            >
              {{competence.variete}}  
            </span>
          </th>
          <td
            class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
          >
            {{competence.fournisseur}}
          </td>
 
          <td
            class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
          >
            {{competence.type}}
          </td>
 
          <td
            class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
          >
            {{competence.saison}}
          </td>
 
          <td
            class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
          >
            {{competence.historique}}
          </td>
 
          <td
            class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-center"
          style="cursor:grab ;"
            >
            <i class="fas fa-eye" style="margin-right:15px ;color: rgb(0, 109, 24);" [routerLink]="['/admin/detailGraine/',competence.id_graine]"></i>
          <i class="fas fas fa-edit "  style="margin-right:15px ; color: rgb(79, 170, 255);"  [routerLink]="['/admin/updateGraine/',competence.id_graine]"></i>
         </td>
        </tr>
      
  
      
       
      </tbody>
    </table>
  </div>
</div>
