import { Component, Input, OnInit } from '@angular/core';
import { LangService } from 'src/app/services/lang.service';
import { TypeService } from 'src/app/services/type.service';

@Component({
  selector: 'app-gestion-type',
  templateUrl: './gestion-type.component.html',
  styleUrls: ['./gestion-type.component.css']
})
export class GestionTypeComponent implements OnInit {

  @Input()
  get color(): string {
    return this._color;
  }
  set color(color: string) {
    this._color = color !== "light" && color !== "dark" ? "light" : color;
  }
  private _color = "light";

  listtype : any[]

  constructor(public _typeService:TypeService, public _langService:LangService) {}

  ngOnInit(): void {
    this._typeService.All_Type().subscribe(res => {
      this.listtype = res as any[]
      console.log(res);
      
       
      }) 
  }
}