<div
  class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0"
>
  <div class="rounded-t bg-white mb-0 px-6 py-6">
    <div class="text-center flex justify-between">
      <h6 class="text-blueGray-700 text-xl font-bold">
        {{ _langService.GetLanguage()=='FR' ? 'Ajouter une opération semis' : 'Add a seeding operation' }}

        
        <button  [routerLink]="['/admin/Liste_Semis/' ]"  style=" position: absolute;right: 50px;  background-color: rgb(70, 175, 0); color: white;padding: 5px;border-radius: 6px;">
          {{ _langService.GetLanguage()=='FR' ? 'Historique semis' : 'Seeding history' }}

          
        </button>

      </h6>
   
    </div>
  </div>
  <div class="flex-auto px-4 lg:px-10 py-10 pt-0">
    <form [formGroup]="_semisService.formSemis" autocomplete="off" (ngSubmit)="onSubmit()">
      <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
        {{ _langService.GetLanguage()=='FR' ? 'Informations générales' : 'General informations' }}
      </h6>
      <div class="flex flex-wrap">
 

        <div class="w-full lg:w-6/12 px-2">
          <div class="relative w-full mb-3">
            <label
              class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
              htmlFor="grid-password"
            >
            {{ _langService.GetLanguage()=='FR' ? 'Graines semées' : 'Seeds sown' }}

            
            </label>
            <select
               class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
               formControlName="unique_id"
              [class.is-invalid]="_semisService.formSemis.controls['unique_id'].errors?.required"

             >
             <option   *ngFor="let graine of stock"
             [value] ="graine.unique_id" > {{graine.nbr}} {{graine.variete}} ({{graine.dateReception}})
            </option>
                  </select>
          </div>
        </div>
        <div class="w-full lg:w-6/12 px-4">
            <div class="relative w-full mb-3">
              <label
                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
              {{ _langService.GetLanguage()=='FR' ? ' Date de semis' : 'Sowing date' }}

             
              </label>
       
              <input class="datepicker-here form-control border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"  name="dateDebutprojet"  formControlName="dateDebutprojet"  
                type="date" id="start" name="trip-start"
                [value]="datesemis"
                [(ngModel)]="datesemis" 
                 formControlName="date"
                 > 
                
       
            </div>
          </div>
 
        <div class="w-full lg:w-4/12 px-4">
          <div class="relative w-full mb-3">
            <label
              class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
              htmlFor="grid-password"
            >
            {{ _langService.GetLanguage()=='FR' ? 'Nombre de graines' : 'Number of seeds' }}
            
            </label>
            <input
            type="number"
            min="1"
                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
              [placeholder]="_langService.GetLanguage()=='FR' ? 'Nombre de graines' : 'Number of seeds'"
              formControlName="nbr_graines"
              [class.is-invalid]="_semisService.formSemis.controls['nbr_graines'].errors?.required"

             />
             
      
          </div>
        </div>
        <div class="w-full lg:w-4/12 px-4">
            <div class="relative w-full mb-3">
              <label
                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
              {{ _langService.GetLanguage()=='FR' ? 'Nombre de plaques ' : 'Number of Foam plates ' }}

               ( {{mousses}} 
               {{ _langService.GetLanguage()=='FR' ? ' mousses disponibles ' : ' foams available ' }}

                )
              </label>
              <input
              type="number"
              min="1"
              max="{{mousses}}"

                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                [placeholder]="_langService.GetLanguage()=='FR' ? 'Nombre de plaques ' : 'Number of plates '"
                formControlName="nbr_plaques"

                [class.is-invalid]="_semisService.formSemis.controls['nbr_plaques'].errors?.required"
   
               />
               
        
            </div>
          </div>
          <div class="w-full lg:w-4/12 px-4">
            <div class="relative w-full mb-3">
              <label
                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
              {{ _langService.GetLanguage()=='FR' ? 'Opérateur' : 'Operator' }}

              
              </label>
              <input
              type="numbre"
                 class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                [placeholder]=" _langService.GetLanguage()=='FR' ? 'Opérateur' : 'Operator' "
                formControlName="operateur"
                [class.is-invalid]="_semisService.formSemis.controls['operateur'].errors?.required"
  
               />
               
        
            </div>
          </div>
 
        <div class="w-full lg:w-10/12 px-2">
            <div class="relative w-full mb-3">
              <label
                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
              {{ _langService.GetLanguage()=='FR' ? 'Commentaire' : 'Comment' }}

              
              </label>
              <textarea 
                type="text"
                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                [placeholder]="_langService.GetLanguage()=='FR' ? 'Commentaire' : 'Comment' "
                formControlName="commentaire"
                [class.is-invalid]="_semisService.formSemis['controls'].commentaire.errors?.required"
              ></textarea>
            </div>
          </div>
  
  
     </div>

      <div class="align-self-center mx-auto text-center" style="padding-top: 15px;">
      <button  *ngIf="!submit" 
      class="bg-red-600 text-white active:bg-red-700 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
      type="submit"  
      >
      {{ _langService.GetLanguage()=='FR' ? 'Ajouter' : 'Add' }}

      
    </button></div>
    <div *ngIf="submit" class="alert alert-success" style="background-color: rgb(98, 223, 98);height: 50px;text-align: center;">
      {{ _langService.GetLanguage()=='FR' ? 'Semis ajouté avec succès' : 'Seedling added successfully' }}

      </div>

    <div *ngIf="!submit && erreur1" class="alert alert-success" style="background-color: rgb(205, 78, 78);height: 50px;text-align: center;color:white">
      {{ _langService.GetLanguage()=='FR' ? 'Erreur: il y a une semis de méme graine dans la méme date' : 'Error: there is a sowing of the same seed on the same date' }}

      
    </div>
</form>
  </div>
</div>
