// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: true,
    GlobaleApiUrl : 'http://localhost:8080',
    UtilisateurApiUrl :'https://seeds.bg-agri.com/babylone_API/Utilisateurs',
    CompetenceApiUrl : 'https://seeds.bg-agri.com/babylone_API/Graines',
    FournisseurApiUrl : '"https://seeds.bg-agri.com/babylone_API/Fournisseurs"',
    ClientApiUrl : 'https://seeds.bg-agri.com/babylone_API/Clients',
    TypeApiUrl : 'https://seeds.bg-agri.com/babylone_API/Types',
    UserCompetenceApiUrl : 'http://localhost:8080/UserCompetence',
    ModuleApiUrl : 'http://localhost:8080/Modules',
    SemisApiUrl : 'https://seeds.bg-agri.com/babylone_API/Semis',
    FloatingApiUrl : 'https://seeds.bg-agri.com/babylone_API/Floating',
    PlantationApiUrl  : 'https://seeds.bg-agri.com/babylone_API/Plantation',
    RecolteApiUrl  : 'https://seeds.bg-agri.com/babylone_API/Recolte',
    BonLivraisonApiUrl  : 'https://seeds.bg-agri.com/babylone_API/Livraison',
    NettoyageApiUrl : 'https://seeds.bg-agri.com/babylone_API/Nettoyage',
    CommandeApiUrl : 'https://seeds.bg-agri.com/babylone_API/Commandes',
    FactureApiUrl : 'https://seeds.bg-agri.com/babylone_API/Factures',
    StockApiUrl : 'https://seeds.bg-agri.com/babylone_API/Stocks',
    PDF_Generate :  'https://seeds.bg-agri.com/babylone_API/PDF_Generate',
    FertilisantApiUrl :  'https://seeds.bg-agri.com/babylone_API/Fertilisant',
    SolutionApiUrl :  'https://seeds.bg-agri.com/babylone_API/Solution',
    MesureApiUrl :  'https://seeds.bg-agri.com/babylone_API/Mesure',
    CheckApiUrl :  'https://seeds.bg-agri.com/babylone_API/CheckList',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
