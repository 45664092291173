<div
  class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0"
>
  <div class="rounded-t bg-white mb-0 px-6 py-6">
    <div class="text-center flex justify-between">
      <h6 class="text-blueGray-700 text-xl font-bold">
        {{ _langService.GetLanguage()=='FR' ? 'Ajouter une opération de récolte' : 'Add a harvest operation' }}

        

 
      </h6>
   
    </div>
  </div>
  <div class="flex-auto px-4 lg:px-10 py-10 pt-0">
    <form [formGroup]="_recolteService.formRecolte" autocomplete="off" (ngSubmit)="onSubmit()">
      <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
        {{ _langService.GetLanguage()=='FR' ? 'Informations générales' : 'General informations' }}

      </h6>
      <div class="flex flex-wrap">
 

        <div class="w-full lg:w-4/12 px-4">
          <div class="relative w-full mb-3">
            <label
              class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
              htmlFor="grid-password"
            >
            {{ _langService.GetLanguage()=='FR' ? 'Variété' : 'Variety' }}

            
            </label>
            <select
               class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
               formControlName="id_plantation"
              [class.is-invalid]="_recolteService.formRecolte.controls['id_plantation'].errors?.required"
               (change)="GraineChange()"
             >
             <option   *ngFor="let graine of plantations" 
             [value] ="graine.id_plantation" > {{graine.unique_id}} -- {{graine.variete}} ({{graine.nbr}})  -- {{graine.date}} 
            </option>
                  </select>
          </div>
        </div>
        <div class="w-full lg:w-6/12 px-4">
            <div class="relative w-full mb-3">
              <label
                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
              {{ _langService.GetLanguage()=='FR' ? "Date d'opération" : 'Operation date' }}

             
              </label>
       
              <input class="datepicker-here form-control border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"  name="dateDebutprojet"  formControlName="dateDebutprojet"  
                type="date" id="start" name="trip-start"
                 formControlName="date"
                 [value]="dateRecolte"
                 [(ngModel)]="dateRecolte" 
                                 > 
                
       
            </div>
          </div>
          <div class="w-full lg:w-4/12 px-4">
            <div class="relative w-full mb-3">
              <label
                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
              {{ _langService.GetLanguage()=='FR' ? 'Opérateur' : 'Operator' }}

              
              </label>
              <input
              type="numbre"
                 class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                [placeholder]=" _langService.GetLanguage()=='FR' ? 'Opérateur' : 'Operator' "
                formControlName="operateur"
                [class.is-invalid]="_recolteService.formRecolte.controls['operateur'].errors?.required"
  
               />
               
        
            </div>
          </div>
        <div class="w-full lg:w-4/12 px-4">
          <div class="relative w-full mb-3">
            <label
              class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
              htmlFor="grid-password"
            >
            {{ _langService.GetLanguage()=='FR' ? 'Nombre de plantes' : 'Number of plants' }}

           
            </label>
            <input
            type="number"
            min="1"
                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
              [placeholder]="_langService.GetLanguage()=='FR' ? 'Nombre de plantes' : 'Number of plants'"
              formControlName="nbr_graines"
              [class.is-invalid]="_recolteService.formRecolte.controls['nbr_graines'].errors?.required"

             />
             
      
          </div>
        </div>

        </div>
        <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
          Xframes
        </h6>
        <div class="flex flex-wrap">
        <div class="w-full lg:w-3/12 px-3">
            <div class="relative w-full mb-3">
              <label
                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
              {{ _langService.GetLanguage()=='FR' ? 'Nombre de Xframes' : 'Number of Xframes' }}
 
              </label>
              <input
              type="number"
              min="1"

                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                [placeholder]="_langService.GetLanguage()=='FR' ? 'Nombre de Xframes' : 'Number of Xframes'"
                formControlName="nbr_Xframe"
                [class.is-invalid]="_recolteService.formRecolte.controls['nbr_Xframe'].errors?.required"
               />
               
        
            </div>
          </div>
          <div class="w-full lg:w-8/12 px-8" *ngIf="Xframes">
            <label
            class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
            htmlFor="grid-password"
          >
           
          </label>
          

             <div  *ngFor="let Xframe of Xframes" style=" display: inline-block; width: 200px;" >
              <input (change)="fieldsChange($event)" [value]="Xframe" type="checkbox" />
              <label  style="margin-left: 20px;"  >Xframe {{Xframe}}</label>
  
            </div>
          </div>

        </div>
        <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
          {{ _langService.GetLanguage()=='FR' ? 'STOCKAGE' : 'STORAGE' }}

          
          
        </h6>
        <div class="flex flex-wrap">
    
          <div class="w-full lg:w-3/12 px-3">
            <div class="relative w-full mb-3">
              <label
                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
              {{ _langService.GetLanguage()=='FR' ? 'STOCKAGE' : 'STORAGE' }}

              
              </label>
              <select
              type="text"
                 class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                 formControlName="stockage"
                [class.is-invalid]="_recolteService.formRecolte.controls['stockage'].errors?.required"
  
              >
              <option value="Oui" selected>
                {{ _langService.GetLanguage()=='FR' ? 'Oui' : 'Yes' }}

                </option>
              <option value="Non">
                {{ _langService.GetLanguage()=='FR' ? 'Non' : 'No' }}

                </option>
               </select>
        
            </div>
          </div>
          <div class="w-full lg:w-4/12 px-4">
            <div class="relative w-full mb-3">
              <label
                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
              {{ _langService.GetLanguage()=='FR' ? 'Lieu de stockage' : 'Storage area' }}

              
              </label>
              <input
              type="text"
                 class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                [placeholder]="_langService.GetLanguage()=='FR' ? 'Lieu de stockage' : 'Storage area' "
                formControlName="lieu"
                [class.is-invalid]="_recolteService.formRecolte.controls['lieu'].errors?.required"
  
               />
               
        
            </div>
          </div>
          <div class="w-full lg:w-4/12 px-4">
            <div class="relative w-full mb-3">
              <label
                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
              {{ _langService.GetLanguage()=='FR' ? 'Température de stockage' : 'Storage temperature' }}

              
              </label>
              <input
              type="numbre"
                 class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                [placeholder]="_langService.GetLanguage()=='FR' ? 'Température de stockage' : 'Storage temperature'"
                formControlName="tomperature"
                [class.is-invalid]="_recolteService.formRecolte.controls['tomperature'].errors?.required"
  
               />
               
        
            </div>
          </div>
 
        <div class="w-full lg:w-6/12 px-6">
            <div class="relative w-full mb-3">
              <label
                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
              {{ _langService.GetLanguage()=='FR' ? 'Commentaire' : 'Comment' }}

              
              </label>
              <textarea 
                type="text"
                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                [placeholder]="_langService.GetLanguage()=='FR' ? 'Commentaire' : 'Comment' "
                formControlName="commentaire"
                [class.is-invalid]="_recolteService.formRecolte['controls'].commentaire.errors?.required"
              ></textarea>
            </div>
          </div>
  
  
     </div>

      <div class="align-self-center mx-auto text-center" style="padding-top: 15px;">
      <button  *ngIf="!submit" 
      class="bg-red-600 text-white active:bg-red-700 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
      type="submit"  
      >
      {{ _langService.GetLanguage()=='FR' ? 'Ajouter' : 'Add' }}

      
    </button></div>
    <div *ngIf="submit" class="alert alert-success" style="background-color: rgb(98, 223, 98);height: 50px;text-align: center;">
      {{ _langService.GetLanguage()=='FR' ? 'Opération de récolté ajoutée avec succès' : 'Harvest operation added successfully' }}

      </div>
</form>
  </div>
</div>
