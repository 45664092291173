 
<div
class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded"
[ngClass]="color === 'light' ? 'bg-white' : 'bg-red-900 text-white'"
>
<div class="rounded-t mb-0 px-4 py-3 border-0">
  <div class="flex flex-wrap items-center">
    <div class="relative w-full px-4 max-w-full flex-grow flex-1">
      <h3
        class="font-semibold text-lg"
        [ngClass]="color === 'light' ? 'text-blueGray-700' : 'text-white'"
      >
      {{ _langService.GetLanguage()=='FR' ? 'Historique des récoltes' : 'Harvest history' }}
      
      <!-- <button  [routerLink]="['/admin/Ajouter_recolte/' ]"  style=" position: absolute;right: 50px;  background-color: rgb(70, 175, 0); color: white;padding: 5px;border-radius: 6px;">Ajouter un recolte</button>
--> 
      </h3>
    </div>
  </div>
</div>
<br><br>
<div class="block w-full overflow-x-auto">
  <!-- Projects table -->
  <table class="items-center   bg-transparent border-collapse" style="width: 100%;">
    <thead>
      <tr>
        <th
          class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
          [ngClass]="
            color === 'light'
              ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
              : 'bg-red-800 text-red-300 border-red-700'
          "
          (click)="sort('unique_id')"
        >
        {{ _langService.GetLanguage()=='FR' ? 'Identifiant' : 'Identifier' }}
        
        </th>
        <th
        class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
        [ngClass]="
          color === 'light'
            ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
            : 'bg-red-800 text-red-300 border-red-700'
        "
          (click)="sort('variete')"
      >
      {{ _langService.GetLanguage()=='FR' ? 'Variété' : 'Variety' }}
      
      </th>
      <th
      class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
      [ngClass]="
        color === 'light'
          ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
          : 'bg-red-800 text-red-300 border-red-700'
      "
        (click)="sort('date')"
    >
    {{ _langService.GetLanguage()=='FR' ? 'Date' : 'harvest' }}
    <br> 
    {{ _langService.GetLanguage()=='FR' ? 'récolte' : 'date' }}
     
    </th>
    <th
    class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
    [ngClass]="
      color === 'light'
        ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
        : 'bg-red-800 text-red-300 border-red-700'
    "
      (click)="sort('date_plantation')"
  >
  {{ _langService.GetLanguage()=='FR' ? 'Date' : 'planting' }}
  <br> 
  {{ _langService.GetLanguage()=='FR' ? 'plantation' : 'date' }}
 
  </th>
         
      <th
      class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
      [ngClass]="
        color === 'light'
          ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
          : 'bg-red-800 text-red-300 border-red-700'
      "
        (click)="sort('nbr_graines')"
    >
    {{ _langService.GetLanguage()=='FR' ? 'Plantes' : 'harvested' }}
    <br> 
    {{ _langService.GetLanguage()=='FR' ? 'récoltées' : 'plants' }}

     
    </th>
                   
      <th
      class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
      [ngClass]="
        color === 'light'
          ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
          : 'bg-red-800 text-red-300 border-red-700'
      "
          (click)="sort('nbr_Xframe')"
    >
     nbr Xframes 
    </th>
             
    <th
    class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
    [ngClass]="
      color === 'light'
        ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
        : 'bg-red-800 text-red-300 border-red-700'
    "
          (click)="sort('Xframe')"
  >
  Xframes
  </th>  
  <th
class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
[ngClass]="
  color === 'light'
    ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
    : 'bg-red-800 text-red-300 border-red-700'
"
          (click)="sort('nbr_livraison')"
>
{{ _langService.GetLanguage()=='FR' ? 'Plantes' : 'plants' }}
<br> 
{{ _langService.GetLanguage()=='FR' ? 'livrées' : 'delivered' }}
 
</th>
                       
        <th
        class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
        [ngClass]="
          color === 'light'
            ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
            : 'bg-red-800 text-red-300 border-red-700'
        "
          (click)="sort('nbr_disponible')"
      >
      {{ _langService.GetLanguage()=='FR' ? 'Plantes' : 'plants' }}
    <br> 
    {{ _langService.GetLanguage()=='FR' ? 'disponibles' : 'available' }}
 
      </th>

        
<th
class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
[ngClass]="
  color === 'light'
    ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
    : 'bg-red-800 text-red-300 border-red-700'
"
          (click)="sort('stockage')"
>
{{ _langService.GetLanguage()=='FR' ? 'Stockage' : 'Storage' }}
 


</th>
        
<th
class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
[ngClass]="
  color === 'light'
    ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
    : 'bg-red-800 text-red-300 border-red-700'
"
          (click)="sort('lieu')"
>
    {{ _langService.GetLanguage()=='FR' ? 'Lieu' : 'storage' }}
    <br> 
    {{ _langService.GetLanguage()=='FR' ? ' de stockage' : 'area' }}
 
</th>
        
<th
class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
[ngClass]="
  color === 'light'
    ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
    : 'bg-red-800 text-red-300 border-red-700'
"
          (click)="sort('tomperature')"
>
{{ _langService.GetLanguage()=='FR' ? 'Température' : 'Temperature' }}
 

</th>
        
<th
class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
[ngClass]="
  color === 'light'
    ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
    : 'bg-red-800 text-red-300 border-red-700'
"
        (click)="sort('operateur')"
>
{{ _langService.GetLanguage()=='FR' ? 'Opérateur' : 'Operator' }}

</th>            
 
<th
class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
[ngClass]="
  color === 'light'
    ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
    : 'bg-red-800 text-red-300 border-red-700'
"
          (click)="sort('commentaire')"
>
{{ _langService.GetLanguage()=='FR' ? 'Commentaire' : 'Comment' }}


</th>   
<th
class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
[ngClass]="
  color === 'light'
    ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
    : 'bg-red-800 text-red-300 border-red-700'
"
>
Action

</th>

      </tr>
    </thead>
    <tbody>
      <tr  *ngFor="let recolte of listrecolte" >
        <th
          class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left flex items-left"
        >
      
          <span
            class="ml-3 font-bold"
            [ngClass]="color === 'light' ? 'text-blueGray-600' : 'text-white'"
          >
            {{recolte.unique_id}}
          </span>
        </th>
        <td
        class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
      >
        {{recolte.variete}}
      </td>
  
      <td
      class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
    >
      {{recolte.date}}
    </td>
  
    <td
    class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
  >
    {{recolte.date_plantation}}
  </td>

      <td
      class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
    >
      {{recolte.nbr_graines}}
    </td>
    
    <td
    class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
  >
    {{recolte.nbr_Xframe}}
  </td>  

  <td
  class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
>
  {{recolte.Xframe}}
</td>
        
<td
class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
>
{{recolte.nbr_livraison}}
</td>
    <td
    class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
  >
    {{recolte.nbr_disponible}}
  </td>
  

          <td
          class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
        >
          {{recolte.stockage}}
        </td>
        <td
        class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
      >
        {{recolte.lieu}}
      </td>
      <td
      class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
    >
      {{recolte.tomperature}} °C
    </td>

 
    <td
    class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
  >
    {{recolte.operateur}}
  </td>
    <td
    class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
    style="white-space: pre-line"
  >
    {{recolte.com}} 
  </td>

  
  <td
  class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-center"
style="cursor:grab ;"
  >
 <i class="fas fa-edit "  style="margin-right:15px ; color: rgb(79, 170, 255);"  [routerLink]="['/admin/EditRecolte/',recolte.id_recolte]"></i>

</td>


      </tr>
 
    </tbody>
  </table>
</div>
</div>
