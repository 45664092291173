<nav
  class="md:left-0 md:block md:fixed md:top-0 md:bottom-0 md:overflow-y-auto md:flex-row md:flex-nowrap md:overflow-hidden shadow-xl bg-white flex flex-wrap items-center justify-between relative md:w-64 z-10 py-4 px-6"
>
  <div
    class="md:flex-col md:items-stretch md:min-h-full md:flex-nowrap px-0 flex flex-wrap items-center justify-between w-full mx-auto"
 
    >
    <!-- Toggler -->
    <button
      class="cursor-pointer text-black opacity-50 md:hidden px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent"
      type="button"
      (click)="toggleCollapseShow('bg-white m-2 py-3 px-6')"
    >
      <i class="fas fa-bars"></i>
    </button>
    <!-- Brand -->
    <a
      [routerLink]="['/']"
      class="md:block text-left md:pb-2 text-blueGray-600 mr-0 inline-block whitespace-nowrap text-sm uppercase font-bold p-4 px-0"
    >
      <span class="block sm:hidden"> <img src="assets/img/logo.png" style="width:170px ;">  </span>
      <span class="hidden sm:block"> <img src="assets/img/logo.png" style="width:170px ;"> </span>
    </a>
    <!-- User -->
    <ul class="md:hidden items-center flex flex-wrap list-none">
      <li class="inline-block relative">
        <app-notification-dropdown class="block"></app-notification-dropdown>
      </li>
      <li class="inline-block relative">
        <app-user-dropdown class="block"></app-user-dropdown>
      </li>
    </ul>
    <!-- Collapse -->
    <div
      class="md:flex md:flex-col md:items-stretch md:opacity-100 md:relative md:mt-4 md:shadow-none shadow absolute top-0 left-0 right-0 z-40 overflow-y-auto overflow-x-hidden h-auto items-center flex-1 rounded"
      [ngClass]="collapseShow"
    >
      <!-- Collapse header -->
      <div
        class="md:min-w-full md:hidden block pb-4 mb-4 border-b border-solid border-blueGray-200"
      >
        <div class="flex flex-wrap">
          <div class="w-6/12">
            <a
              [routerLink]="['/']"
              class="md:block text-left md:pb-2 text-blueGray-600 mr-0 inline-block whitespace-nowrap text-sm uppercase font-bold p-4 px-0"
            >
            Babylone Growers
            </a>
          </div>
          <div class="w-6/12 flex justify-end">
            <button
              type="button"
              class="cursor-pointer text-black opacity-50 md:hidden px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent"
              (click)="toggleCollapseShow('hidden')"
            >
              <i class="fas fa-times"></i>
            </button>
          </div>
        </div>
      </div>
      <!-- Form -->
      <!--
      <form class="mt-6 mb-4 md:hidden">
        <div class="mb-3 pt-0">
          <input
            type="text"
            placeholder="Search"
            class="border-0 px-3 py-2 h-12 border border-solid border-blueGray-500 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-base leading-snug shadow-none outline-none focus:outline-none w-full font-normal"
          />
        </div>
      </form>
-->
      <!-- Divider -->
      <hr class="my-4 md:min-w-full" />
      <!-- Heading -->
 
      <!-- Navigation -->

      <ul class="md:flex-col md:min-w-full flex flex-col list-none">
        <li class="items-center">
          <a
            [routerLink]="['/admin/dashboard']"
            class="text-xs uppercase py-3 font-bold block"
            routerLinkActive
            #adminDashboard="routerLinkActive"
            [ngClass]="
              adminDashboard.isActive
                ? 'text-red-600 hover:text-red-700'
                : 'text-blueGray-700 hover:text-blueGray-500'
            "
            (click)="choisirmenu('Dashboard')"

          >
            <i
              class="fas fa-tv mr-2 text-sm"
              [ngClass]="
                adminDashboard.isActive ? 'opacity-75' : 'text-blueGray-300'
              "

              style="cursor: grap;"
            ></i>
            Dashboard
          </a>
        </li>
 
      </ul>
      <hr class="my-4 md:min-w-full" />
      <!-- Heading -->
      <h6
        class="md:min-w-full text-blueGray-500 text-xs uppercase font-bold block pt-1 pb-4 no-underline"
       (click)="choisirmenu('user')"

       style="cursor: grap;"
        >
        {{ _langService.GetLanguage()=='FR' ? 'Gestion des utilisateurs' : 'User Management' }}

        
      </h6>
      <!-- Navigation -->

      <ul class="md:flex-col md:min-w-full flex flex-col list-none" *ngIf="menu=='user'">
        <li class="items-center">
          <a
            [routerLink]="['/admin/adduser']"
            class="text-xs uppercase py-3 font-bold block"
            routerLinkActive
            #adminAdduser="routerLinkActive"
            [ngClass]="
            adminAdduser.isActive
                ? 'text-red-600 hover:text-red-700'
                : 'text-blueGray-700 hover:text-blueGray-500'
            "
          >
            <i
              class="fas fa-user-plus	 mr-2 text-sm "
              [ngClass]="
              adminAdduser.isActive ? 'opacity-75' : 'text-blueGray-300'
              "
            ></i>
            {{ _langService.GetLanguage()=='FR' ? 'Ajouter un utilisateur' : 'Add a user' }}

            
          </a>
        </li>

        <li class="items-center">
          <a
            [routerLink]="['/admin/gestionuser']"
            class="text-xs uppercase py-3 font-bold block"
            routerLinkActive
            #GestionUtilisateurs="routerLinkActive"
            [ngClass]="
              GestionUtilisateurs.isActive
                ? 'text-red-600 hover:text-red-700'
                : 'text-blueGray-700 hover:text-blueGray-500'
            "
          >
            <i
              class="fas fa-user-friends mr-2 text-sm"
              [ngClass]="
              GestionUtilisateurs.isActive ? 'opacity-75' : 'text-blueGray-300'
              "
            ></i>
            {{ _langService.GetLanguage()=='FR' ? 'Liste des utilisateurs' : 'Users list' }}

            
          </a>
        </li> 
      </ul>
      
   
      <hr class="my-4 md:min-w-full" />
       <h6
        class="md:min-w-full text-blueGray-500 text-xs uppercase font-bold block pt-1 pb-4 no-underline"
        (click)="choisirmenu('graine')"

        style="cursor: grap;"
      >
      {{ _langService.GetLanguage()=='FR' ? 'Gestion des graines' : 'Seed management' }}

        
      </h6>
 
      <ul class="md:flex-col md:min-w-full flex flex-col list-none" *ngIf="menu=='graine'">
        
        <li class="items-center">
          <a
            [routerLink]="['/admin/types']"
            class="text-xs uppercase py-3 font-bold block"
            routerLinkActive
            #types="routerLinkActive"
            [ngClass]="
            types.isActive
                ? 'text-red-600 hover:text-red-700'
                : 'text-blueGray-700 hover:text-blueGray-500'
            ">
            <i
              class="fab fa-buromobelexperte mr-2 text-sm"
              [ngClass]="
              types.isActive ? 'opacity-75' : 'text-blueGray-300'
              "
            ></i>
            {{ _langService.GetLanguage()=='FR' ? 'Types de graine' : 'Seed Types' }}

            
          </a>
        </li>
        <li class="items-center">
          <a
            [routerLink]="['/admin/fournisseurs']"
            class="text-xs uppercase py-3 font-bold block"
            routerLinkActive
            #fournisseurs="routerLinkActive"
            [ngClass]="
            fournisseurs.isActive
                ? 'text-red-600 hover:text-red-700'
                : 'text-blueGray-700 hover:text-blueGray-500'
            "
          >
            <i
              class="fab fa-buromobelexperte mr-2 text-sm"
              [ngClass]="
              fournisseurs.isActive ? 'opacity-75' : 'text-blueGray-300'
              "
            ></i>
            {{ _langService.GetLanguage()=='FR' ? 'Fournisseurs' : 'Suppliers' }}

            
          </a>
        </li>
    
        <li class="items-center">
          <a
            [routerLink]="['/admin/addGraine']"
            class="text-xs uppercase py-3 font-bold block"
            routerLinkActive
            #adminAddGraine="routerLinkActive"
            [ngClass]="
            adminAddGraine.isActive
                ? 'text-red-600 hover:text-red-700'
                : 'text-blueGray-700 hover:text-blueGray-500'
            "
          >
            <i
              class="fas fa-bullseye mr-2 text-sm"
              [ngClass]="
              adminAddGraine.isActive ? 'opacity-75' : 'text-blueGray-300'
              "
            ></i>
            {{ _langService.GetLanguage()=='FR' ? 'Ajouter une graine' : 'Add a seed' }}

            
          </a>
        </li>

        <li class="items-center">
          <a
            [routerLink]="['/admin/gestionGraine']"
            class="text-xs uppercase py-3 font-bold block"
            routerLinkActive
            #GestionGraine="routerLinkActive"
            [ngClass]="
            GestionGraine.isActive
                ? 'text-red-600 hover:text-red-700'
                : 'text-blueGray-700 hover:text-blueGray-500'
            "
          >
            <i
              class="fab fa-buromobelexperte mr-2 text-sm"
              [ngClass]="
              GestionGraine.isActive ? 'opacity-75' : 'text-blueGray-300'
              "
            ></i>
            {{ _langService.GetLanguage()=='FR' ? 'Liste des graines' : 'List of seeds' }}

            
          </a>
        </li>
        <li class="items-center">
          <a
            [routerLink]="['/admin/AddCommande']"
            class="text-xs uppercase py-3 font-bold block"
            routerLinkActive
            #adminAddCommande="routerLinkActive"
            [ngClass]="
            adminAddCommande.isActive
                ? 'text-red-600 hover:text-red-700'
                : 'text-blueGray-700 hover:text-blueGray-500'
            "
          >
            <i
              class="fas fa-calendar-plus mr-2 text-sm"
              [ngClass]="
              adminAddCommande.isActive ? 'opacity-75' : 'text-blueGray-300'
              "
            ></i>
            {{ _langService.GetLanguage()=='FR' ? 'Passer une commande' : 'To order' }}

            
          </a>
        </li>

        <li class="items-center">
          <a
            [routerLink]="['/admin/commandes']"
            class="text-xs uppercase py-3 font-bold block"
            routerLinkActive
            #adminListecommandes="routerLinkActive"
            [ngClass]="
            adminListecommandes.isActive
                ? 'text-red-600 hover:text-red-700'
                : 'text-blueGray-700 hover:text-blueGray-500'
            "
          >
            <i
              class="fas fa-calendar mr-2 text-sm"
              [ngClass]="
              adminListecommandes.isActive ? 'opacity-75' : 'text-blueGray-300'
              "
            ></i>
            {{ _langService.GetLanguage()=='FR' ? 'Liste des commandes' : 'List of commands' }}

            
          </a>
        </li>
        <li class="items-center">
          <a
            [routerLink]="['/admin/Stock']"
            class="text-xs uppercase py-3 font-bold block"
            routerLinkActive
            #adminStock="routerLinkActive"
            [ngClass]="
            adminStock.isActive
                ? 'text-red-600 hover:text-red-700'
                : 'text-blueGray-700 hover:text-blueGray-500'
            "
          >
            <i
              class="fas fa-chart-bar mr-2 text-sm"
              [ngClass]="
              adminStock.isActive ? 'opacity-75' : 'text-blueGray-300'
              "
            ></i>
            {{ _langService.GetLanguage()=='FR' ? 'Stock de graines' : 'Seed stock' }}

            
          </a>
        </li>
        <li class="items-center">
          <a
            [routerLink]="['/admin/AddFacture']"
            class="text-xs uppercase py-3 font-bold block"
            routerLinkActive
            #adminAddfacture="routerLinkActive"
            [ngClass]="
            adminAddfacture.isActive
                ? 'text-red-600 hover:text-red-700'
                : 'text-blueGray-700 hover:text-blueGray-500'
            "
          >
            <i
              class="fas fa-calendar-plus mr-2 text-sm"
              [ngClass]="
              adminAddfacture.isActive ? 'opacity-75' : 'text-blueGray-300'
              "
            ></i>
            {{ _langService.GetLanguage()=='FR' ? 'Ajouter une facture' : 'Add an invoice' }}

            
          </a>
        </li>
        <li class="items-center">
          <a
            [routerLink]="['/admin/factures']"
            class="text-xs uppercase py-3 font-bold block"
            routerLinkActive
            #adminfactures="routerLinkActive"
            [ngClass]="
            adminfactures.isActive
                ? 'text-red-600 hover:text-red-700'
                : 'text-blueGray-700 hover:text-blueGray-500'
            "
          >
            <i
              class="fas fa-calendar-plus mr-2 text-sm"
              [ngClass]="
              adminfactures.isActive ? 'opacity-75' : 'text-blueGray-300'
              "
            ></i>
            {{ _langService.GetLanguage()=='FR' ? 'Gestion des factures' : 'Invoice management' }}

            
          </a>
        </li>

        <li class="items-center">
          <a
            [routerLink]="['/admin/mousses']"
            class="text-xs uppercase py-3 font-bold block"
            routerLinkActive
            #mousses="routerLinkActive"
            [ngClass]="
            mousses.isActive
                ? 'text-red-600 hover:text-red-700'
                : 'text-blueGray-700 hover:text-blueGray-500'
            ">
            <i
              class="fab fa-buromobelexperte mr-2 text-sm"
              [ngClass]="
              mousses.isActive ? 'opacity-75' : 'text-blueGray-300'
              "
            ></i>
            {{ _langService.GetLanguage()=='FR' ? 'Mousses' : 'Foams' }}

            
          </a>
        </li>
      </ul>
      <hr class="my-4 md:min-w-full" />

      <h6
      class="md:min-w-full text-blueGray-500 text-xs uppercase font-bold block pt-1 pb-4 no-underline"
      (click)="choisirmenu('fertilisant')"

      style="cursor: grap;"
    >
    {{ _langService.GetLanguage()=='FR' ? 'Gestion des fertilisants' : 'Fertilizer management' }}

      
    </h6>

    <ul class="md:flex-col md:min-w-full flex flex-col list-none"  *ngIf="menu=='fertilisant'">
      <li class="items-center">
        <a
          [routerLink]="['/admin/Ajouter_fertilisant']"
          class="text-xs uppercase py-3 font-bold block"
          routerLinkActive
          #adminAddFertilisant="routerLinkActive"
          [ngClass]="
          adminAddFertilisant.isActive
              ? 'text-red-600 hover:text-red-700'
              : 'text-blueGray-700 hover:text-blueGray-500'
          "
        >
          <i
            class="fas fa-calendar-plus mr-2 text-sm"
            [ngClass]="
            adminAddFertilisant.isActive ? 'opacity-75' : 'text-blueGray-300'
            "
          ></i>
          {{ _langService.GetLanguage()=='FR' ? 'Créer  un fertilisant' : 'Create fertilizer' }}

          
        </a>
      </li>
      <li class="items-center">
        <a
          [routerLink]="['/admin/Stock_Fertilisants']"
          class="text-xs uppercase py-3 font-bold block"
          routerLinkActive
          #adminStockFertilisant="routerLinkActive"
          [ngClass]="
          adminStockFertilisant.isActive
              ? 'text-red-600 hover:text-red-700'
              : 'text-blueGray-700 hover:text-blueGray-500'
          "
        >
          <i
            class="fas fa-chart-bar mr-2 text-sm"
            [ngClass]="
            adminStockFertilisant.isActive ? 'opacity-75' : 'text-blueGray-300'
            "
          ></i>
          {{ _langService.GetLanguage()=='FR' ? 'Ajouter un fertilisant' : 'Add fertilizer' }}

          
        </a>
      </li>
      <li class="items-center">
        <a
          [routerLink]="['/admin/Fertilisants']"
          class="text-xs uppercase py-3 font-bold block"
          routerLinkActive
          #adminListefertilisant="routerLinkActive"
          [ngClass]="
          adminListefertilisant.isActive
              ? 'text-red-600 hover:text-red-700'
              : 'text-blueGray-700 hover:text-blueGray-500'
          "
        >
          <i
            class="fas fa-calendar mr-2 text-sm"
            [ngClass]="
            adminListefertilisant.isActive ? 'opacity-75' : 'text-blueGray-300'
            "
          ></i>
          {{ _langService.GetLanguage()=='FR' ? 'Stock fertilisants' : 'Fertilizer stock' }}

          
        </a>
      </li>


 
      <li class="items-center">
        <a
          [routerLink]="['/admin/Preparer_Solution']"
          class="text-xs uppercase py-3 font-bold block"
          routerLinkActive
          #adminAddsolution="routerLinkActive"
          [ngClass]="
          adminAddsolution.isActive
              ? 'text-red-600 hover:text-red-700'
              : 'text-blueGray-700 hover:text-blueGray-500'
          "
        >
          <i
            class="fas fa-chart-bar mr-2 text-sm"
            [ngClass]="
            adminAddsolution.isActive ? 'opacity-75' : 'text-blueGray-300'
            "
          ></i>
          {{ _langService.GetLanguage()=='FR' ? 'Préparer une solution nutritive' : 'Prepare a nutrient solution' }}

          
        </a>
      </li>

 
      <li class="items-center">
        <a
          [routerLink]="['/admin/Historique_Solution']"
          class="text-xs uppercase py-3 font-bold block"
          routerLinkActive
          #Historique_Solution="routerLinkActive"
          [ngClass]="
          Historique_Solution.isActive
              ? 'text-red-600 hover:text-red-700'
              : 'text-blueGray-700 hover:text-blueGray-500'
          "
        >
          <i
            class="fas fa-chart-bar mr-2 text-sm"
            [ngClass]="
            Historique_Solution.isActive ? 'opacity-75' : 'text-blueGray-300'
            "
          ></i>
          {{ _langService.GetLanguage()=='FR' ? 'Historique des solutions nutritives' : 'History of nutrient solutions' }}

          
        </a>
      </li>

      
      
    </ul> 

  
    <hr class="my-4 md:min-w-full" />
    <h6
     class="md:min-w-full text-blueGray-500 text-xs uppercase font-bold block pt-1 pb-4 no-underline"
     (click)="choisirmenu('mesure')"

     style="cursor: grap;"
   >
   {{ _langService.GetLanguage()=='FR' ? 'Gestion des mesures' : 'Measurement management' }}

   
   </h6>
   <ul class="md:flex-col md:min-w-full flex flex-col list-none"  *ngIf="menu=='mesure'">
   <!-- <li class="items-center">
      <a
        [routerLink]="['/admin/Ajouter_Mesure']"
        class="text-xs uppercase py-3 font-bold block"
        routerLinkActive
        #adminmesure="routerLinkActive"
        [ngClass]="
        adminmesure.isActive
            ? 'text-red-600 hover:text-red-700'
            : 'text-blueGray-700 hover:text-blueGray-500'
        "
      >
        <i
          class="fas fa-calendar-plus mr-2 text-sm"
          [ngClass]="
          adminmesure.isActive ? 'opacity-75' : 'text-blueGray-300'
          "
        ></i>
        Ajouter mesure
      </a>
    </li>
    --->
    <li class="items-center">
      <a
        [routerLink]="['/admin/Historique_Mesure']"
        class="text-xs uppercase py-3 font-bold block"
        routerLinkActive
        #adminHistoriquemesure="routerLinkActive"
        [ngClass]="
        adminHistoriquemesure.isActive
            ? 'text-red-600 hover:text-red-700'
            : 'text-blueGray-700 hover:text-blueGray-500'
        "
      >
        <i
          class="fas fa-calendar-plus mr-2 text-sm"
          [ngClass]="
          adminHistoriquemesure.isActive ? 'opacity-75' : 'text-blueGray-300'
          "
        ></i>
        {{ _langService.GetLanguage()=='FR' ? 'Historique des mesures' : 'Measurement history' }}

        
      </a>
    </li>
</ul>

        <hr class="my-4 md:min-w-full" />
        <h6
         class="md:min-w-full text-blueGray-500 text-xs uppercase font-bold block pt-1 pb-4 no-underline"
         (click)="choisirmenu('P2')"

         style="cursor: grap;"
       >
       {{ _langService.GetLanguage()=='FR' ? 'Opérations P2' : 'P2 Operations' }}

       
       </h6>
       <ul class="md:flex-col md:min-w-full flex flex-col list-none" *ngIf="menu=='P2'">
         <li class="items-center">
           <a
             [routerLink]="['/admin/semis']"
             class="text-xs uppercase py-3 font-bold block"
             routerLinkActive
             #adminSemmis="routerLinkActive"
             [ngClass]="
             adminSemmis.isActive
                 ? 'text-red-600 hover:text-red-700'
                 : 'text-blueGray-700 hover:text-blueGray-500'
             "
           >
             <i
               class="fas fa-calendar-plus mr-2 text-sm"
               [ngClass]="
               adminSemmis.isActive ? 'opacity-75' : 'text-blueGray-300'
               "
             ></i>
             {{ _langService.GetLanguage()=='FR' ? 'Ajouter semis' : 'Add seedlings' }}

             
           </a>
         </li>

         <li class="items-center">
          <a
            [routerLink]="['/admin/Liste_Semis']"
            class="text-xs uppercase py-3 font-bold block"
            routerLinkActive
            #adminHistoriqueSemmis="routerLinkActive"
            [ngClass]="
            adminHistoriqueSemmis.isActive
                ? 'text-red-600 hover:text-red-700'
                : 'text-blueGray-700 hover:text-blueGray-500'
            "
          >
            <i
              class="fas fa-history mr-2 text-sm"
              [ngClass]="
              adminHistoriqueSemmis.isActive ? 'opacity-75' : 'text-blueGray-300'
              "
            ></i>
            {{ _langService.GetLanguage()=='FR' ? 'Historique semis' : 'Sowing history' }}

           </a>
        </li>

        <hr class="my-2 md:min-w-full" />

         <li class="items-center">
           <a
             [routerLink]="['/admin/floating']"
             class="text-xs uppercase py-3 font-bold block"
             routerLinkActive
             #adminFloating="routerLinkActive"
             [ngClass]="
             adminFloating.isActive
                 ? 'text-red-600 hover:text-red-700'
                 : 'text-blueGray-700 hover:text-blueGray-500'
             "
           >
             <i
               class="fas fa-calendar-plus mr-2 text-sm"
               [ngClass]="
               adminFloating.isActive ? 'opacity-75' : 'text-blueGray-300'
               "
             ></i>
             {{ _langService.GetLanguage()=='FR' ? 'Ajouter Transplantation' : 'Add Transplantation' }}

             
           </a>
         </li>

         <li class="items-center">
          <a
            [routerLink]="['/admin/Liste_Floating']"
            class="text-xs uppercase py-3 font-bold block"
            routerLinkActive
            #adminHistoriqueFloating="routerLinkActive"
            [ngClass]="
            adminHistoriqueFloating.isActive
                ? 'text-red-600 hover:text-red-700'
                : 'text-blueGray-700 hover:text-blueGray-500'
            "
          >
            <i
              class="fas fa-history mr-2 text-sm"
              [ngClass]="
              adminHistoriqueFloating.isActive ? 'opacity-75' : 'text-blueGray-300'
              "
            ></i>
            {{ _langService.GetLanguage()=='FR' ? 'Historique Transplantation' : 'History Transplantation' }}

            
          </a>
        </li>
</ul>

<hr class="my-4 md:min-w-full" />
        <h6
         class="md:min-w-full text-blueGray-500 text-xs uppercase font-bold block pt-1 pb-4 no-underline"
         (click)="choisirmenu('P1')"

         style="cursor: grap;"
       >
       {{ _langService.GetLanguage()=='FR' ? 'Opérations P1' : 'P1 Operations' }}

       
       </h6>
       <ul class="md:flex-col md:min-w-full flex flex-col list-none" *ngIf="menu=='P1'">
        <li class="items-center">
          <a
            [routerLink]="['/admin/plantation']"
            class="text-xs uppercase py-3 font-bold block"
            routerLinkActive
            #adminPlantation="routerLinkActive"
            [ngClass]="
            adminPlantation.isActive
                ? 'text-red-600 hover:text-red-700'
                : 'text-blueGray-700 hover:text-blueGray-500'
            "
          >
            <i
              class="fas fa-calendar-plus mr-2 text-sm"
              [ngClass]="
              adminPlantation.isActive ? 'opacity-75' : 'text-blueGray-300'
              "
            ></i>
            {{ _langService.GetLanguage()=='FR' ? 'Ajouter Plantation' : 'Add Planting' }}

            
          </a>
        </li>
        
        <li class="items-center">
          <a
            [routerLink]="['/admin/Liste_Plantation']"
            class="text-xs uppercase py-3 font-bold block"
            routerLinkActive
            #adminHistoriquePlantation="routerLinkActive"
            [ngClass]="
            adminHistoriquePlantation.isActive
                ? 'text-red-600 hover:text-red-700'
                : 'text-blueGray-700 hover:text-blueGray-500'
            "
          >
            <i
              class="fas fa-history mr-2 text-sm"
              [ngClass]="
              adminHistoriquePlantation.isActive ? 'opacity-75' : 'text-blueGray-300'
              "
            ></i>
            {{ _langService.GetLanguage()=='FR' ? 'Historique Plantations' : 'History Plantations' }}

            
          </a>
        </li>
        
        <hr class="my-4 md:min-w-full" />


        <li class="items-center">
          <a
            [routerLink]="['/admin/recolte']"
            class="text-xs uppercase py-3 font-bold block"
            routerLinkActive
            #adminRecolte="routerLinkActive"
            [ngClass]="
            adminRecolte.isActive
                ? 'text-red-600 hover:text-red-700'
                : 'text-blueGray-700 hover:text-blueGray-500'
            "
          >
            <i
              class="fas fa-calendar-plus mr-2 text-sm"
              [ngClass]="
              adminRecolte.isActive ? 'opacity-75' : 'text-blueGray-300'
              "
            ></i>
            {{ _langService.GetLanguage()=='FR' ? 'Ajouter Récolte' : 'Add Harvest' }}

            
          </a>
        </li>        
        <li class="items-center">
          <a
            [routerLink]="['/admin/Liste_Recolte']"
            class="text-xs uppercase py-3 font-bold block"
            routerLinkActive
            #adminHistoriqueRecolte="routerLinkActive"
            [ngClass]="
            adminHistoriqueRecolte.isActive
                ? 'text-red-600 hover:text-red-700'
                : 'text-blueGray-700 hover:text-blueGray-500'
            "
          >
            <i
              class="fas fa-history mr-2 text-sm"
              [ngClass]="
              adminHistoriqueRecolte.isActive ? 'opacity-75' : 'text-blueGray-300'
              "
            ></i>
            {{ _langService.GetLanguage()=='FR' ? 'Historique Récoltes' : 'Harvest History' }}

            
          </a>
        </li>
     <!--   
      
      <li class="items-center">
          <a
            [routerLink]="['/admin/Stock_Recolte']"
            class="text-xs uppercase py-3 font-bold block"
            routerLinkActive
            #adminStockRecolte="routerLinkActive"
            [ngClass]="
            adminStockRecolte.isActive
                ? 'text-red-600 hover:text-red-700'
                : 'text-blueGray-700 hover:text-blueGray-500'
            "
          >
            <i
              class="fas fa-history mr-2 text-sm"
              [ngClass]="
              adminStockRecolte.isActive ? 'opacity-75' : 'text-blueGray-300'
              "
            ></i>
            Stock recolte
          </a>
        </li>

        --->
 
        
        <hr class="my-4 md:min-w-full" />

        
        <li class="items-center">
          <a
            [routerLink]="['/admin/nettoyage']"
            class="text-xs uppercase py-3 font-bold block"
            routerLinkActive
            #adminNetoyage="routerLinkActive"
            [ngClass]="
            adminNetoyage.isActive
                ? 'text-red-600 hover:text-red-700'
                : 'text-blueGray-700 hover:text-blueGray-500'
            "
          >
            <i
              class="fas fa-calendar-plus mr-2 text-sm"
              [ngClass]="
              adminNetoyage.isActive ? 'opacity-75' : 'text-blueGray-300'
              "
            ></i>
            {{ _langService.GetLanguage()=='FR' ? 'Nettoyage Xframe' : 'Xframe Cleanup' }}

            
          </a>
        </li>
        
        <li class="items-center">
          <a
            [routerLink]="['/admin/Liste_Nettoyage']"
            class="text-xs uppercase py-3 font-bold block"
            routerLinkActive
            #adminHistoriqueNetoyage="routerLinkActive"
            [ngClass]="
            adminHistoriqueNetoyage.isActive
                ? 'text-red-600 hover:text-red-700'
                : 'text-blueGray-700 hover:text-blueGray-500'
            "
          >
            <i
              class="fas fa-history mr-2 text-sm"
              [ngClass]="
              adminHistoriqueNetoyage.isActive ? 'opacity-75' : 'text-blueGray-300'
              "
            ></i>
            {{ _langService.GetLanguage()=='FR' ? 'Historique Nettoyage' : 'Cleaning History' }}

            
          </a>
        </li>
</ul>
<hr class="my-4 md:min-w-full" />
        <h6
         class="md:min-w-full text-blueGray-500 text-xs uppercase font-bold block pt-1 pb-4 no-underline"
         (click)="choisirmenu('Livraisons')"

         style="cursor: grap;"
       >
       {{ _langService.GetLanguage()=='FR' ? 'Livraisons' : 'Deliveries' }}

       
       </h6>
       <ul class="md:flex-col md:min-w-full flex flex-col list-none" *ngIf="menu=='Livraisons'">
<li class="items-center">
  <a
    [routerLink]="['/admin/clients']"
    class="text-xs uppercase py-3 font-bold block"
    routerLinkActive
    #client="routerLinkActive"
    [ngClass]="
    client.isActive
        ? 'text-red-600 hover:text-red-700'
        : 'text-blueGray-700 hover:text-blueGray-500'
    "
  >
    <i
      class="fab fa-buromobelexperte mr-2 text-sm"
      [ngClass]="
      client.isActive ? 'opacity-75' : 'text-blueGray-300'
      "
    ></i>
    {{ _langService.GetLanguage()=='FR' ? 'Clients' : 'Customers' }}

    
  </a>
</li>
<li class="items-center">
  <a
    [routerLink]="['/admin/livraison']"
    class="text-xs uppercase py-3 font-bold block"
    routerLinkActive
    #adminlivraison="routerLinkActive"
    [ngClass]="
    adminlivraison.isActive
        ? 'text-red-600 hover:text-red-700'
        : 'text-blueGray-700 hover:text-blueGray-500'
    "
  >
    <i
      class="fas fa-calendar-plus mr-2 text-sm"
      [ngClass]="
      adminlivraison.isActive ? 'opacity-75' : 'text-blueGray-300'
      "
    ></i>
    {{ _langService.GetLanguage()=='FR' ? 'Ajouter Livraison' : 'Add Delivery' }}

    
  </a>
</li>

<li class="items-center">
  <a
    [routerLink]="['/admin/Liste_Livraison']"
    class="text-xs uppercase py-3 font-bold block"
    routerLinkActive
    #adminHistoriquelivraison="routerLinkActive"
    [ngClass]="
    adminHistoriquelivraison.isActive
        ? 'text-red-600 hover:text-red-700'
        : 'text-blueGray-700 hover:text-blueGray-500'
    "
  >
    <i
      class="fas fa-history mr-2 text-sm"
      [ngClass]="
      adminHistoriquelivraison.isActive ? 'opacity-75' : 'text-blueGray-300'
      "
    ></i>
    {{ _langService.GetLanguage()=='FR' ? 'Historique Livraisons' : 'Delivery History' }}

    
  </a>
</li>
</ul>
<hr class="my-4 md:min-w-full" />
        <h6
         class="md:min-w-full text-blueGray-500 text-xs uppercase font-bold block pt-1 pb-4 no-underline"
         (click)="choisirmenu('Check')"

         style="cursor: grap;"
       >
       {{ _langService.GetLanguage()=='FR' ? 'Check-lists' : 'Checklists' }}

       
       </h6>
       <ul class="md:flex-col md:min-w-full flex flex-col list-none" *ngIf="menu=='Check'">
        
        <li class="items-center">
          <a
            [routerLink]="['/admin/Check_lists']"
            class="text-xs uppercase py-3 font-bold block"
            routerLinkActive
            #adminHistoriqueCheck="routerLinkActive"
            [ngClass]="
            adminHistoriqueCheck.isActive
                ? 'text-red-600 hover:text-red-700'
                : 'text-blueGray-700 hover:text-blueGray-500'
            "
          >
            <i
              class="fas fa-history mr-2 text-sm"
              [ngClass]="
              adminHistoriqueCheck.isActive ? 'opacity-75' : 'text-blueGray-300'
              "
            ></i>
            {{ _langService.GetLanguage()=='FR' ? 'Historique Check-List' : 'History Check-List' }}

            
          </a>
        </li>
        
         </ul>
 
         
         <hr class="my-4 md:min-w-full" />
         <h6
          class="md:min-w-full text-blueGray-500 text-xs uppercase font-bold block pt-1 pb-4 no-underline"
          (click)="choisirmenu('Mesure')"
 
          style="cursor: grap;"
        >
        {{ _langService.GetLanguage()=='FR' ? 'Mesure' : 'MEASUREMENT' }}
 
        
        </h6>
        <ul class="md:flex-col md:min-w-full flex flex-col list-none" *ngIf="menu=='Mesure'">
         
          <li class="items-center">
            <a
              [routerLink]="['/admin/Ajouter_Mesure']"
              class="text-xs uppercase py-3 font-bold block"
              routerLinkActive
              #adminAddMesure="routerLinkActive"
              [ngClass]="
              adminAddMesure.isActive
                  ? 'text-red-600 hover:text-red-700'
                  : 'text-blueGray-700 hover:text-blueGray-500'
              "
            >
              <i
                class="fas fa-history mr-2 text-sm"
                [ngClass]="adminAddMesure.isActive ? 'opacity-75' : 'text-blueGray-300'"
              ></i>
              {{ _langService.GetLanguage()=='FR' ? 'Ajouter une Mesure' : 'Add a Measure' }}
  
              
            </a>
          </li>         
          <li class="items-center">
            <a
              [routerLink]="['/admin/Historique_Mesure']"
              class="text-xs uppercase py-3 font-bold block"
              routerLinkActive
              #adminHistoriqueMesure="routerLinkActive"
              [ngClass]="
              adminHistoriqueMesure.isActive
                  ? 'text-red-600 hover:text-red-700'
                  : 'text-blueGray-700 hover:text-blueGray-500'
              "
            >
              <i
                class="fas fa-history mr-2 text-sm"
                [ngClass]="
                adminHistoriqueMesure.isActive ? 'opacity-75' : 'text-blueGray-300'
                "
              ></i>
              {{ _langService.GetLanguage()=='FR' ? 'Historique Mesures' : 'History of Measures' }}
  
              
            </a>
          </li>
         
          </ul>

    </div>
  </div>
</nav>
