import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Utilisateur } from 'src/app/models/utilisateur/utilisateur.model';
import { LangService } from 'src/app/services/lang.service';
import { UtilisateurService } from 'src/app/services/utilisateur.service';


@Component({
  selector: 'app-edituser',
  templateUrl: './edituser.component.html',
  styleUrls: ['./edituser.component.css']
})
export class EdituserComponent implements OnInit {
  id: any;
  submit: boolean;
  permissions: Object;

   constructor(public _utilisateurService:UtilisateurService, public _langService:LangService,private route: ActivatedRoute) {}
 
  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.id=params['id'] //log the value of id
   });
    this._utilisateurService.get_utilisateur(this.id).subscribe(res => {
      this._utilisateurService.initialiser(res)
       
      })
      this._utilisateurService.get_permissions(this.id).subscribe(res => {
        this.permissions=res 
        console.log(res);
        
         
        })
  }


  EditUtilisateur(){
    this._utilisateurService.modifierUtilisateur( ).subscribe(res => {

      let test =res as Utilisateur;
     if (test != null) {
        this._utilisateurService.All_utilisateur().subscribe(res => {
        this._utilisateurService.listUtilisateur = res as Utilisateur[]
        this.submit=true

        })
     }
   },
    err => {
       console.log(err)
           }
   )
  }

  onSubmit(){
    this.EditUtilisateur();
  }


  fieldsChange(values:any):void {

    this._utilisateurService.modifier_permission(values.target.defaultValue,this.id,values.currentTarget.checked).subscribe((response: any) => {
       console.log(response)
 
      });
  }

}
