import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FournisseurService } from 'src/app/services/fournisseur.service';
import { LangService } from 'src/app/services/lang.service';

@Component({
  selector: 'app-edit-fournisseur',
  templateUrl: './edit-fournisseur.component.html',
  styleUrls: ['./edit-fournisseur.component.css']
})
export class EditFournisseurComponent implements OnInit {
  submit: boolean;
  id: any;

  constructor(public _fournisseurService:FournisseurService, public _langService:LangService,private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.id=params['id'] //log the value of id
   });
   this._fournisseurService.get_Fournisseur(this.id).subscribe(res => {
    this._fournisseurService.initialiser(res)
     
    })
  }

  ModifyFournisseur(){
    this._fournisseurService.modifierFournisseur().subscribe(res => {
      this.submit=true
    })
  }

  onSubmit(){
    this.ModifyFournisseur();
    console.log('test')
 
  }
}
