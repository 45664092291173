 
<div
class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded"
[ngClass]="color === 'light' ? 'bg-white' : 'bg-red-900 text-white'"
>
<div class="rounded-t mb-0 px-4 py-3 border-0">
  <div class="flex flex-wrap items-center">
    <div class="relative w-full px-4 max-w-full flex-grow flex-1">
      <h3
        class="font-semibold text-lg"
        [ngClass]="color === 'light' ? 'text-blueGray-700' : 'text-white'"
      >
      {{ _langService.GetLanguage()=='FR' ? 'Historique de nettoyage' : 'Cleaning history' }}

          
      <!-- <button  [routerLink]="['/admin/Ajouter_nettoyage/' ]"  style=" position: absolute;right: 50px;  background-color: rgb(70, 175, 0); color: white;padding: 5px;border-radius: 6px;">Ajouter un nettoyage</button>
--> 
      </h3>
    </div>
  </div>
</div>
<br><br>
<div class="block w-full overflow-x-auto">
  <!-- Projects table -->
  <table class="items-center   bg-transparent border-collapse" style="width: 100%;">
    <thead>
      <tr>
        <th
          class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
          [ngClass]="
            color === 'light'
              ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
              : 'bg-red-800 text-red-300 border-red-700'
          "
          (click)="sort('date')"
        >
        Date
        </th>
 
    
 
            
      <th
      class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
      [ngClass]="
        color === 'light'
          ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
          : 'bg-red-800 text-red-300 border-red-700'
      "
      (click)="sort('nbr_Xframe')"
    >
    {{ _langService.GetLanguage()=='FR' ? 'Nombre de Xframes' : 'Number of Xframes' }}
    
    </th>
             
    <th
    class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
    [ngClass]="
      color === 'light'
        ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
        : 'bg-red-800 text-red-300 border-red-700'
    "
    (click)="sort('Xframe')"
  >
  {{ _langService.GetLanguage()=='FR' ? 'Xframes nettoyées' : 'Cleaned xframes' }}
  
  </th>  
  <th
class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
[ngClass]="
  color === 'light'
    ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
    : 'bg-red-800 text-red-300 border-red-700'
"
(click)="sort('aspect')"
>
{{ _langService.GetLanguage()=='FR' ? 'Aspect' : 'Appearance' }}

</th>      
  <th
  class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
  [ngClass]="
    color === 'light'
      ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
      : 'bg-red-800 text-red-300 border-red-700'
  "
  (click)="sort('operateur')"
>
{{ _langService.GetLanguage()=='FR' ? 'Opérateur' : 'Operator' }}

</th>

<th
class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
[ngClass]="
  color === 'light'
    ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
    : 'bg-red-800 text-red-300 border-red-700'
"
(click)="sort('commentaire')"
>
{{ _langService.GetLanguage()=='FR' ? 'Commentaire' : 'Comment' }}

</th>

      </tr>
    </thead>
    <tbody>
      <tr  *ngFor="let nettoyage of listnettoyage" >
        <th
          class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left flex items-left"
        >
      
          <span
            class="ml-3 font-bold"
            [ngClass]="color === 'light' ? 'text-blueGray-600' : 'text-white'"
          >
            {{nettoyage.date}}
          </span>
        </th>
  
  
 
    
            <td
            class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
          >
            {{nettoyage.nbr_Xframe}}
          </td>  

          <td
          class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
        >
          {{nettoyage.Xframe}}
        </td>
        <td
        class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
      >
        {{nettoyage.aspect}}
      </td>
        <td
        class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
      >
        {{nettoyage.operateur}}
      </td>
 
      <td
      class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
      style="white-space: pre-line"
    >
      {{nettoyage.commentaire}}
    </td>


      </tr>
 
    </tbody>
  </table>
</div>
</div>
