import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
 
@Injectable({
  providedIn: 'root',
})
export class LangService {
 
  constructor(private http: HttpClient , private router: Router) {}

  SetLanguage(lang: string) {
    localStorage.setItem('lang',lang)
  }

  GetLanguage() {
    if (!localStorage.getItem('lang'))
    this.SetLanguage("EN")
    
    return localStorage.getItem('lang')
  }
 
  
 
}