 
<div
class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0"
>
<div class="rounded-t bg-white mb-0 px-6 py-6">
  <div class="text-center flex justify-between">
    <h6 class="text-blueGray-700 text-xl font-bold">
      {{ _langService.GetLanguage()=='FR' ? 'Livraison' : 'Delivery' }}
      
      <button  [routerLink]="['/admin/Liste_Livraison/' ]"  style=" position: absolute;right: 50px;  background-color: rgb(70, 175, 0); color: white;padding: 5px;border-radius: 6px;">Historique des livraisons</button>


    </h6>
 
  </div>
</div>
<div class="flex-auto px-4 lg:px-10 py-10 pt-0">
    <form [formGroup]="_bonLivraisonService.formBonLivraison" autocomplete="off" (ngSubmit)="onSubmit()">
        <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
          {{ _langService.GetLanguage()=='FR' ? 'Informations générales' : 'General informations' }}

        </h6>
        <div class="flex flex-wrap">
   
  
          <div class="w-full lg:w-4/12 px-4">
            <div class="relative w-full mb-3">
              <label
                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
              {{ _langService.GetLanguage()=='FR' ? 'Client' : 'Customer' }}
              </label>
              <select
                 class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                 formControlName="client"
                [class.is-invalid]="_bonLivraisonService.formBonLivraison.controls['client'].errors?.required"
                (change)="clientChange($event.target.value)"
  
               >
               <option *ngFor="let client of clients"
               [value] ="client.id_client"  >{{client.nom}}</option>
             </select>
            </div>
          </div>
          <div class="w-full lg:w-4/12 px-4">
              <div class="relative w-full mb-3">
                <label
                  class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                  htmlFor="grid-password"
                >
                Date 
                </label>
         
                <input class="datepicker-here form-control border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"  name="dateDebutprojet"  formControlName="dateDebutprojet"  
                  type="date" id="start" name="trip-start"
                   formControlName="date"
                   [value]="date"
                   [(ngModel)]="date" 
                > 
              </div>
            </div>
 
        </div>
        <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
          {{ _langService.GetLanguage()=='FR' ? 'Adresse de livraison' : 'Delivery address' }}

          </h6>
        <div class="flex flex-wrap">

          
            <div class="w-full lg:w-4/12 px-4">
              <div class="relative w-full mb-3">
                <label
                  class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                  htmlFor="grid-password"
                >
                {{ _langService.GetLanguage()=='FR' ? 'Adresse' : 'Address' }}
                </label>
                <input
                type="text"
   
                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  placeholder="Adresse"
                  formControlName="adresse"
                  [class.is-invalid]="_bonLivraisonService.formBonLivraison.controls['adresse'].errors?.required"
     
                 />
                 
          
              </div>
            </div>
            <div class="w-full lg:w-4/12 px-4">
              <div class="relative w-full mb-3">
                <label
                  class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                  htmlFor="grid-password"
                >
                Zip
                </label>
                <input
                type="numbre"
                   class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  placeholder="Zip"
                  formControlName="zip"
                  [class.is-invalid]="_bonLivraisonService.formBonLivraison.controls['zip'].errors?.required"
    
                 />
                 
          
              </div>
            </div>
  
            <div class="w-full lg:w-4/12 px-4">
              <div class="relative w-full mb-3">
                <label
                  class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                  htmlFor="grid-password"
                >
                {{ _langService.GetLanguage()=='FR' ? 'Ville' : 'City' }}
                </label>
                <input
                type="text"
                   class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  placeholder="Ville"
                  formControlName="ville"
                  [class.is-invalid]="_bonLivraisonService.formBonLivraison.controls['ville'].errors?.required"
    
                 />
                 
          
              </div>
            </div>
        </div>
  
        <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
          {{ _langService.GetLanguage()=='FR' ? 'Information sur la TVA' : 'VAT information' }}
          </h6>
        <div class="flex flex-wrap">
            <div class="w-full lg:w-4/12 px-4">
                <div class="relative w-full mb-3">
                  <label
                    class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                  {{ _langService.GetLanguage()=='FR' ? ' TVA Intra' : 'Intra VAT' }}
                  </label>
                  <input
                  type="text"
                     class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    placeholder="TVA Intra"
                    formControlName="intra"
                    [class.is-invalid]="_bonLivraisonService.formBonLivraison.controls['intra'].errors?.required"
      
                   />
                   
            
                </div>
              </div>
  
              <div class="w-full lg:w-4/12 px-4">
                <div class="relative w-full mb-3">
                  <label
                    class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                  {{ _langService.GetLanguage()=='FR' ? 'Code TVA' : 'Code VAT' }}
                  </label>
                  <input
                  type="text"
                     class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    placeholder="Code TVA"
                    formControlName="codeTVA"
                    [class.is-invalid]="_bonLivraisonService.formBonLivraison.controls['codeTVA'].errors?.required"
      
                   />
                   
            
                </div>
              </div>
              <div class="w-full lg:w-4/12 px-4">
                <div class="relative w-full mb-3">
                  <label
                    class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                  {{ _langService.GetLanguage()=='FR' ? 'Taux TVA' : 'VAT rate' }}
                  </label>
                  <input
                  type="text"
                     class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    placeholder="Taux TVA"
                    formControlName="taux"
                    [class.is-invalid]="_bonLivraisonService.formBonLivraison.controls['taux'].errors?.required"
      
                   />
                   
            
                </div>
              </div>

   </div>
 
   <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
    {{ _langService.GetLanguage()=='FR' ? 'Information de facture' : 'Invoice information' }}

  </h6>
<div class="flex flex-wrap">
 
      <div class="w-full lg:w-4/12 px-4">
        <div class="relative w-full mb-3">
          <label
            class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
            htmlFor="grid-password"
          >
          {{ _langService.GetLanguage()=='FR' ? "Date d'échéance" : 'Deadline' }}

          </label>
   
          <input class="datepicker-here form-control border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"  name="dateDebutprojet"  formControlName="dateDebutprojet"  
            type="date" id="start" name="trip-start"
             formControlName="echeance"
             [value]="echeance"
             [(ngModel)]="echeance" 
          > 
        </div>
      </div>
 

</div>


            <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
              {{ _langService.GetLanguage()=='FR' ? 'Commentaire' : 'Comment' }}

              </h6>
            <div class="flex flex-wrap">
   
          <div class="w-full lg:w-9/12 px-9">
              <div class="relative w-full mb-3">
                <label
                  class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                  htmlFor="grid-password"
                >
                {{ _langService.GetLanguage()=='FR' ? 'Commentaire' : 'Comment' }}
                </label>
                <textarea 
                  type="text"
                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  placeholder="Commentaire"
                  formControlName="commentaire"
                  [class.is-invalid]="_bonLivraisonService.formBonLivraison['controls'].commentaire.errors?.required"
                ></textarea>
              </div>
            </div>
    
    
       </div>
     

     </form>
  <div style="background-color: rgb(220, 242, 255);">
    <table >
        <thead>
            <tr>
                <td class="tdBorderEmail"><label> {{ _langService.GetLanguage()=='FR' ? 'Article' : 'Article' }}</label></td>
                 <td class="tdBorderEmail"><label>                  
                  {{ _langService.GetLanguage()=='FR' ? 'Quantité(piéces)' : 'Quantity(pieces)' }}
                </label></td>
                 <td class="tdBorderEmail"><label>
                  {{ _langService.GetLanguage()=='FR' ? 'Poids(Tonnes)' : 'Weight(Tons)' }}
                 </label></td>
                 <td class="tdBorderEmail"><label>PU HT</label></td>
                 <td class="tdBorderEmail"><label></label></td>
          
              </tr>
        </thead>
        <tbody>
        <tr *ngFor="let dynamic of dynamicArray; let i = index;">
     
          <td>
            <select     
            class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
            name="{{dynamic.Code}}" [(ngModel)]="dynamic.Code"  type="text" 
    
          >
          <option   *ngFor="let graine of recoltes"
          [value] ="graine.unique_id" > {{graine.unique_id}} -- {{graine.variete}} ({{graine.nbr}})
         </option>
               </select>
          </td>
          <td>
          <input  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"    name="{{dynamic.Quantity}}" [(ngModel)]="dynamic.Quantity"  type="number" />
        </td>
          <td>
            <input class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"    name="{{dynamic.PU}}" [(ngModel)]="dynamic.Poids"  type="number" />
          </td>
          <td>
            <input  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"    name="{{dynamic.PU}}" [(ngModel)]="dynamic.PU"  type="number"/>
          </td>
          <td>
            <button class="button button5 fa fa-trash" (click)="deleteRow(i)"> </button>
          </td>
        </tr>
        <tr>
      
        </tr>
        </tbody>
        </table>
        <button style="margin-left: 20px;" class=" button button2 fa fa-plus" (click)="addRow()"> </button>
    
 </div> 
<div class="align-self-center mx-auto text-center" style="padding-top: 15px;">
    <button  *ngIf="!submit" 
    class="bg-red-600 text-white active:bg-blue-700 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
    type="submit" 
    (click)="onSubmit()" 
     >
     {{ _langService.GetLanguage()=='FR' ? 'Enregistrer la livraison' : 'Save delivery' }}
  </button></div>
  <div *ngIf="submit" class="alert alert-success" style="background-color: rgb(212, 255, 212);height: 100px;text-align: center;">Ajouter avec succès.. Vous pouvez maintenant:
    <br>
    <div class="align-self-center mx-auto text-center" style="padding-top: 15px;">
      <a href="https://seeds.bg-agri.com/babylone_API/bon_livraison_PDF/Bon{{id_BonLivraison}}.pdf" target="_blank">
        
        <button  
        class="bg-red-600 text-white active:bg-blue-700 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
        type="button" 
       
         >
         {{ _langService.GetLanguage()=='FR' ? 'Exporter Bon de livraison PDF' : 'Export PDF Delivery Note' }}

      </button>
    </a>
    <a href="https://seeds.bg-agri.com/babylone_API/FACTURE_PDF/FAC{{id_BonLivraison}}.pdf" target="_blank">
      <button  
      class="bg-red-600 text-white active:bg-blue-700 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
      type="button" 
       >
       {{ _langService.GetLanguage()=='FR' ? 'Exporter une facture PDF' : 'Export a PDF invoice' }}

    </button></a>
    <a href="https://seeds.bg-agri.com/babylone_API/ETIQUETTE_PDF/ETI{{id_BonLivraison}}.pdf" target="_blank">
      <button  
      class="bg-red-600 text-white active:bg-blue-700 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
      type="button" 
       >
       {{ _langService.GetLanguage()=='FR' ? 'Exporter Les étiquettes' : 'Export Labels' }}

    </button></a>
    </div>
</div>

 </div>
</div>

