import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { AuthService } from "src/app/services/auth.service";
import { LangService } from "src/app/services/lang.service";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
})
export class LoginComponent implements OnInit {
  constructor( public auth: AuthService , private router: Router, public _langService:LangService) {}
  Login
  password 
  invalide

  ngOnInit(): void {}
 login(f){
  this.auth.login(f.value).subscribe(res => {
    
   if(res){
     localStorage.setItem('privilege', 'admin' )
  if(res!=false)
  this.router.navigate(['/admin/dashboard']);
  } 
else{
  this.invalide=true

}
  })
  this.auth.loginUser(f.value).subscribe(res => {

    if(res){
      localStorage.setItem('privilege', 'user' )
      localStorage.setItem('log', res['email'] )
      if(res!=false)
      this.router.navigate(['/user/dashboard']);
      
    }
    else{
      this.invalide=true
    
    }
  })
  
 }

}
