import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { LangService } from 'src/app/services/lang.service';
import { PlantationService } from 'src/app/services/plantation.service';
import { FloatingService } from 'src/app/services/floating.service';
import { ActivatedRoute } from '@angular/router';


@Component({
  selector: 'app-edit-plantation',
  templateUrl: './edit-plantation.component.html',
  styleUrls: ['./edit-plantation.component.css']
})
export class EditPlantationComponent implements OnInit {
  submit: boolean;
  datePlantation   ;
  numbers: number[];
  graine: string;
  id: any;
  stock: any[];  
  Xframes: any[];  
  OldNbrGraine=0;
 
  floatings: any[];
 
  NBR=[]
  LIGne=["A","B","C","D","E","F"]
  dynamicArray = [];
  newDynamic;
  addRow() {
    this.dynamicArray.push({ XFrame_Numero: '' , XFrame_ligne: '' });
  }
  deleteRow(index) {
    this.dynamicArray.splice(index, 1);
  }
 


  constructor(private datePipe: DatePipe, public _langService:LangService, public _floatingService:FloatingService,public _plantationService:PlantationService,private route: ActivatedRoute) 
   { }
 

 ngOnInit(): void {
  this.route.params.subscribe(params => {
    this.id=params['id'] //log the value of id
 });
 for (let i=1;i<52;i++){
  this.NBR.push(i)
}
 this._plantationService.get_details(this.id).subscribe(res => {
   
  this._plantationService.initialiser(res)
  this.OldNbrGraine=Number(res["nbr_graines"]);

  this._plantationService.get_graine_by_Plantation().subscribe(res => {

    this.graine= res['variete'] 
   })
 
   /*
   this.Xframes=res["Xframe"].split(',') 
   for (let i=0;i<Number(res["nbr_Xframe"]);i++){
    this.dynamicArray.push({ XFrame_Numero: this.Xframes[i].substring(0,this.Xframes[i].length-1) , XFrame_ligne: this.Xframes[i].substring(this.Xframes[i].length-1,this.Xframes[i].length) })
   }

   
    */
  })
 }
 
 modifierPlantation(){
  this._floatingService.get_Floating_Test(this._plantationService.formPlantation.value['id_floating']).subscribe(res => {
 
      if((Number(res["nbr_disponible"])+this.OldNbrGraine)>=Number(this._plantationService.formPlantation.controls['nbr_graines'].value)+Number(this._plantationService.formPlantation.controls['nbr_perdus'].value))
        this._plantationService.modifierPlantation(this.datePlantation).subscribe(res => {
       this.submit=true  
       this._plantationService.modifierFloatingAvailable(Number(this._plantationService.formPlantation.controls['nbr_graines'].value)-this.OldNbrGraine).subscribe(res => {
       })
      })
      else {
        if(this._langService.GetLanguage()=="FR")
        alert('Le nombre de graine est supérieur à ce qui est disponible en stock!')
        else {
          alert('The number of seeds is higher than what is available in stock!')
  
        }
       }    
    })

    }

 onSubmit(){

  for (let i=0;i<this.dynamicArray.length;i++){
    if((this.dynamicArray[i].XFrame_Numero.length>0 )&&(this.dynamicArray[i].XFrame_ligne.length>0 )){
      this._plantationService.initialiserToXframe(this.dynamicArray[i].XFrame_Numero+this.dynamicArray[i].XFrame_ligne)
    
    }
  }

   this.modifierPlantation();
 }


 nbr_Xframe(){
  if (Number(this._plantationService.formPlantation.value['nbr_Xframe'])==this.dynamicArray.length+1)
  this.addRow()
  else if (Number(this._plantationService.formPlantation.value['nbr_Xframe'])==this.dynamicArray.length-1)
  this.dynamicArray.pop()
 else if (Number(this._plantationService.formPlantation.value['nbr_Xframe'])>this.dynamicArray.length)
  for (let i=1;i<Number(this._plantationService.formPlantation.value['nbr_Xframe']);i++){
    this.addRow()
  }
 else 
  for (let i=Number(this._plantationService.formPlantation.value['nbr_Xframe']);i>1;i--){
    this.dynamicArray.pop()
  }
}

}
