<div
  class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0"
>
  <div class="rounded-t bg-white mb-0 px-6 py-6">
    <div class="text-center flex justify-between">
      <h6 class="text-blueGray-700 text-xl font-bold">
        {{ _langService.GetLanguage()=='FR' ? 'Ajouter une opération de Transplantation' : 'Add Transplantation' }}

      </h6>
   
    </div>
  </div>
  <div class="flex-auto px-4 lg:px-10 py-10 pt-0">
    <form [formGroup]="_floatingService.formFloating" autocomplete="off" (ngSubmit)="onSubmit()">
      <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
        {{ _langService.GetLanguage()=='FR' ? 'Informations générales' : 'General informations' }}
      </h6>
      <div class="flex flex-wrap">
 

        <div class="w-full lg:w-4/12 px-4">
          <div class="relative w-full mb-3">
            <label
              class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
              htmlFor="grid-password"
            >
            {{ _langService.GetLanguage()=='FR' ? 'Variété' : 'Variety' }}

            
            </label>
            <select
               class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
               formControlName="unique_id"
              [class.is-invalid]="_floatingService.formFloating.controls['unique_id'].errors?.required"

             >
             <option   *ngFor="let graine of semis"
             [value] ="graine.unique_id" > {{graine.unique_id}} -- {{graine.variete}} ({{graine.nbr}}) -- {{graine.date}} 
            </option>
            </select>
          </div>
        </div>
        <div class="w-full lg:w-6/12 px-4">
            <div class="relative w-full mb-3">
              <label
                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
              {{ _langService.GetLanguage()=='FR' ? "Date d'opération" : 'Operation date' }}

              
              </label>
       
              <input class="datepicker-here form-control border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"  name="dateDebutprojet"  formControlName="dateDebutprojet"  
                type="date" id="start" name="trip-start"
                 formControlName="date"
                 [value]="dateFloating"
                 [(ngModel)]="dateFloating" 
                                 > 
                
       
            </div>
          </div>
 
        <div class="w-full lg:w-4/12 px-4">
          <div class="relative w-full mb-3">
            <label
              class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
              htmlFor="grid-password"
            >
            {{ _langService.GetLanguage()=='FR' ? "Nombre de plantes" : 'Number of plants' }}

            
            </label>
            <input
            type="number"
            min="1"
                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
              [placeholder]="_langService.GetLanguage()=='FR' ? 'Nombre de plantes'  : 'Number of plants'"
              formControlName="nbr_graines"
              [class.is-invalid]="_floatingService.formFloating.controls['nbr_graines'].errors?.required"

             />
          </div>
        </div>
        <div class="w-full lg:w-4/12 px-4">
          <div class="relative w-full mb-3">
            <label
              class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
              htmlFor="grid-password"
            >
            {{ _langService.GetLanguage()=='FR' ? 'Plantes perdus' : 'Lost plants' }}
            </label>
            <input
            type="number"
            min="0"
                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                [placeholder]="_langService.GetLanguage()=='FR' ? 'Plantes perdus' : 'Lost plants'"
                formControlName="nbr_perdus"
              [class.is-invalid]="_floatingService.formFloating.controls['nbr_perdus'].errors?.required"
             />
          </div>
        </div>
        <div class="w-full lg:w-3/12 px-3">
            <div class="relative w-full mb-3">
              <label
                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
              {{ _langService.GetLanguage()=='FR' ? " Nombre de plateaux" : 'Number of trays' }}

             
              </label>
              <input
              type="number"
              min="1"

                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                [placeholder]="_langService.GetLanguage()=='FR' ? 'Nombre de plateaux' : 'Number of trays'"
                formControlName="nbr_plaques"
                [class.is-invalid]="_floatingService.formFloating.controls['nbr_plaques'].errors?.required"
               />
               
        
            </div>
          </div>
          <div class="w-full lg:w-3/12 px-3">
            <div class="relative w-full mb-3">
              <label
                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
              {{ _langService.GetLanguage()=='FR' ? "Table transplantée" : 'Transplanted table' }}

              
              </label>
              <select
              type="text"
 
                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                 formControlName="table_transplantee"
                [class.is-invalid]="_floatingService.formFloating.controls['table_transplantee'].errors?.required"
   
               >
               <option value="Table 1" >Table 1</option>
               <option value="Table 2">Table 2</option>
               <option value="Table 3">Table 3</option>
               <option value="Table 4">Table 4</option>
               <option value="Table 5">Table 5</option>
               <option value="Table 6">Table 6</option>
               <option value="Table 7">Table 7</option>
               <option value="Table 8">Table 8</option>
               <option value="Table 9">Table 9</option>
               <option value="Table 10">Table 10</option>
               <option value="Table 11">Table 11</option>
               <option value="Table 12">Table 12</option>
               <option value="Table 13">Table 13</option>
               <option value="Table 14">Table 14</option>
               <option value="Table 15">Table 15</option>
               <option value="Table 16">Table 16</option>
               <option value="Table 17">Table 17</option>
               <option value="Table 18">Table 18</option>
               <option value="Table 19">Table 19</option>
               <option value="Table 20">Table 20</option>
               <option value="Table 21">Table 21</option>
               <option value="Table 22">Table 22</option>
               <option value="Table 23">Table 23</option>
               <option value="Table 24">Table 24</option>
               <option value="Table 25">Table 25</option>
               <option value="Table 26">Table 26</option>
               <option value="Table 27">Table 27</option>
               <option value="Table 28">Table 28</option>
               <option value="Table 29">Table 29</option>
               <option value="Table 30">Table 30</option>
               <option value="Table 31">Table 31</option>
               <option value="Table 32">Table 32</option>
               <option value="Table 33">Table 33</option>
               <option value="Table 34">Table 34</option>
               <option value="Table 35">Table 35</option>
               <option value="Table 36">Table 36</option>
               <option value="Table 37">Table 37</option>
               <option value="Table 38">Table 38</option>
               <option value="Table 39">Table 39</option>
               <option value="Table 40">Table 40</option>
               <option value="Table 41">Table 41</option>
               <option value="Table 42">Table 42</option>

               </select>
               
        
            </div>
          </div>
          <div class="w-full lg:w-4/12 px-4">
            <div class="relative w-full mb-3">
              <label
                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
              {{ _langService.GetLanguage()=='FR' ? "Opérateur" : 'Operator' }}

              
              </label>
              <input
              type="numbre"
                 class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                [placeholder]=" _langService.GetLanguage()=='FR' ? 'Opérateur' : 'Operator' "
                formControlName="operateur"
                [class.is-invalid]="_floatingService.formFloating.controls['operateur'].errors?.required"
  
               />
               
        
            </div>
          </div>
 
        <div class="w-full lg:w-6/12 px-4">
            <div class="relative w-full mb-3">
              <label
                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
              {{ _langService.GetLanguage()=='FR' ? 'Commentaire' : 'Comment' }}
 
              </label>
              <textarea 
                type="text"
                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                [placeholder]="_langService.GetLanguage()=='FR' ? 'Commentaire' : 'Comment' "
                formControlName="commentaire"
                [class.is-invalid]="_floatingService.formFloating['controls'].commentaire.errors?.required"
              ></textarea>
            </div>
          </div>
  
  
     </div>

      <div class="align-self-center mx-auto text-center" style="padding-top: 15px;">
      <button  *ngIf="!submit" 
      class="bg-red-600 text-white active:bg-red-700 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
      type="submit"  
      >
      {{ _langService.GetLanguage()=='FR' ? 'Ajouter' : 'Add' }}

      
    </button></div>
    <div *ngIf="submit" class="alert alert-success" style="background-color: rgb(98, 223, 98);height: 50px;text-align: center;">
      {{ _langService.GetLanguage()=='FR' ? 'Opération de Transplantation ajoutée avec succès' : 'Operation added successfully' }}

      </div>
</form>
  </div>
</div>
