import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FertilisantService } from 'src/app/services/fertilisant.service';
import { LangService } from 'src/app/services/lang.service';
import { SolutionService } from 'src/app/services/solution.service';

@Component({
  selector: 'app-add-solution',
  templateUrl: './add-solution.component.html',
  styleUrls: ['./add-solution.component.css']
})
export class AddSolutionComponent implements OnInit {


  dynamicArray = [];
  dynamicArray2 = [];
  dynamicArray3 = [];
  dynamicArray4 = [];
  dynamicArray5 = [];
  dynamicArray6 = [];
  submit: boolean;
  date: string;

  constructor(public _fertilisantService:FertilisantService , private datePipe: DatePipe, public _solutionService:SolutionService, public _langService:LangService) {}

  ngOnInit(): void {
        
this._fertilisantService.All_Fertilisant().subscribe(res => {
  this._fertilisantService.listFertilisant=res  as  any[];
  this.NBR=res  as  any[];
  })
  
this._solutionService.All_Solution().subscribe(res => {
  this._solutionService.listSolution=res  as  any[];
  })
    this._solutionService.initialiserVide()
    this.dynamicArray = [];
    this.dynamicArray2 = [];
    this.dynamicArray3 = [];
    this.dynamicArray4 = [];
    this.dynamicArray5 = [];
    this.dynamicArray6 = [];
  }

  AddSolution(){

    this._solutionService.insertSolution(this.date).subscribe(res => {
      this.submit=true
        
  for (let i=0;i<this.dynamicArray.length;i++){
    this._solutionService.insertSolution_Fertilisant(res,this.dynamicArray[i].fertilisant_object,1,this.dynamicArray[i].quantity).subscribe(res => {
    })
  }
         
  for (let i=0;i<this.dynamicArray2.length;i++){
    this._solutionService.insertSolution_Fertilisant(res,this.dynamicArray2[i].fertilisant_object,2,this.dynamicArray2[i].quantity).subscribe(res => {
    })
  }
         
  for (let i=0;i<this.dynamicArray3.length;i++){
    this._solutionService.insertSolution_Fertilisant(res,this.dynamicArray3[i].fertilisant_object,3,this.dynamicArray3[i].quantity).subscribe(res => {
    })
  }
         
  for (let i=0;i<this.dynamicArray4.length;i++){
    this._solutionService.insertSolution_Fertilisant(res,this.dynamicArray4[i].fertilisant_object,4,this.dynamicArray4[i].quantity).subscribe(res => {
    })
  }
         
  for (let i=0;i<this.dynamicArray5.length;i++){
    this._solutionService.insertSolution_Fertilisant(res,this.dynamicArray5[i].fertilisant_object,5,this.dynamicArray5[i].quantity).subscribe(res => {
    })
  }
         
  for (let i=0;i<this.dynamicArray6.length;i++){
    this._solutionService.insertSolution_Fertilisant(res,this.dynamicArray6[i].fertilisant_object,6,this.dynamicArray6[i].quantity).subscribe(res => {
    })
  }

  //////////  Eau

  this._solutionService.insertSolution_Eau(res,1,this._solutionService.formSolution_Fertisant.value['eau1']).subscribe(res => {
  })
  this._solutionService.insertSolution_Eau(res,2,this._solutionService.formSolution_Fertisant.value['eau2']).subscribe(res => {
  })
  this._solutionService.insertSolution_Eau(res,3,this._solutionService.formSolution_Fertisant.value['eau3']).subscribe(res => {
  })
  this._solutionService.insertSolution_Eau(res,4,this._solutionService.formSolution_Fertisant.value['eau4']).subscribe(res => {
  })
  this._solutionService.insertSolution_Eau(res,5,this._solutionService.formSolution_Fertisant.value['eau5']).subscribe(res => {
  })  
  this._solutionService.insertSolution_Eau(res,6,this._solutionService.formSolution_Fertisant.value['eau6']).subscribe(res => {
  })
    })
  }

  onSubmit(){
    if((this.date!=null)&&(this._solutionService.formSolution.value['operateur']!='')){
    if(this.testQuantity(this.dynamicArray)&&this.testQuantity(this.dynamicArray2)&&this.testQuantity(this.dynamicArray3)&&this.testQuantity(this.dynamicArray4)&&this.testQuantity(this.dynamicArray5)&&this.testQuantity(this.dynamicArray6))
    this.AddSolution();
  }
  else
  alert('Date et opérateur obligatoires')

   }
  ///////////////////////////Solution_Fertisant 1


  newDynamic;
  NBR
  LIGne=["A","B","C","D","E","F"]
  addRow() {
    this.dynamicArray.push({ fertilisant_object: '' , quantity: '' });
  }
 
 nbr_fertisants1(){
  if (Number(this._solutionService.formSolution_Fertisant.value['nbr_cuve1'])==this.dynamicArray.length+1)
  this.addRow()
  else if (Number(this._solutionService.formSolution_Fertisant.value['nbr_cuve1'])==this.dynamicArray.length-1)
  this.dynamicArray.pop()
 else if (Number(this._solutionService.formSolution_Fertisant.value['nbr_cuve1'])>this.dynamicArray.length)
  for (let i=1;i<Number(this._solutionService.formSolution_Fertisant.value['nbr_cuve1']);i++){
    this.addRow()
  }
 else 
  for (let i=Number(this._solutionService.formSolution_Fertisant.value['nbr_cuve1']);i>1;i--){
    this.dynamicArray.pop()
  }
}

mesure(id_fertilisant){
  for (let i=0;i<this.NBR.length;i++){
    if(this.NBR[i].id_fertilisant==id_fertilisant)
    return '('+this.NBR[i].mesure+')'
  }
}


  ///////////////////////////Solution_Fertisant 2


  NBR2
  LIGne2=["A","B","C","D","E","F"]
  addRow2() {
    this.dynamicArray2.push({ fertilisant_object: '' , quantity: '' });
  }
 

 nbr_fertisants2(){
  if (Number(this._solutionService.formSolution_Fertisant.value['nbr_cuve2'])==this.dynamicArray2.length+1)
  this.addRow2()
  else if (Number(this._solutionService.formSolution_Fertisant.value['nbr_cuve2'])==this.dynamicArray2.length-1)
  this.dynamicArray2.pop()
 else if (Number(this._solutionService.formSolution_Fertisant.value['nbr_cuve2'])>this.dynamicArray2.length)
  for (let i=1;i<Number(this._solutionService.formSolution_Fertisant.value['nbr_cuve2']);i++){
    this.addRow2()
  }
 else 
  for (let i=Number(this._solutionService.formSolution_Fertisant.value['nbr_cuve2']);i>1;i--){
    this.dynamicArray2.pop()
  }
}

  ///////////////////////////Solution_Fertisant 3


  addRow3() {
    this.dynamicArray3.push({ fertilisant_object: '' , quantity: '' });
  }
 

 nbr_fertisants3(){
  if (Number(this._solutionService.formSolution_Fertisant.value['nbr_cuve3'])==this.dynamicArray3.length+1)
  this.addRow3()
  else if (Number(this._solutionService.formSolution_Fertisant.value['nbr_cuve3'])==this.dynamicArray3.length-1)
  this.dynamicArray3.pop()
 else if (Number(this._solutionService.formSolution_Fertisant.value['nbr_cuve3'])>this.dynamicArray3.length)
  for (let i=1;i<Number(this._solutionService.formSolution_Fertisant.value['nbr_cuve3']);i++){
    this.addRow3()
  }
 else 
  for (let i=Number(this._solutionService.formSolution_Fertisant.value['nbr_cuve3']);i>1;i--){
    this.dynamicArray3.pop()
  }
}

  ///////////////////////////Solution_Fertisant 4


  
  addRow4() {
    this.dynamicArray4.push({ fertilisant_object: '' , quantity: '' });
  }
 

 nbr_fertisants4(){
  if (Number(this._solutionService.formSolution_Fertisant.value['nbr_cuve4'])==this.dynamicArray4.length+1)
  this.addRow4()
  else if (Number(this._solutionService.formSolution_Fertisant.value['nbr_cuve4'])==this.dynamicArray4.length-1)
  this.dynamicArray4.pop()
 else if (Number(this._solutionService.formSolution_Fertisant.value['nbr_cuve4'])>this.dynamicArray4.length)
  for (let i=1;i<Number(this._solutionService.formSolution_Fertisant.value['nbr_cuve4']);i++){
    this.addRow4()
  }
 else 
  for (let i=Number(this._solutionService.formSolution_Fertisant.value['nbr_cuve4']);i>1;i--){
    this.dynamicArray4.pop()
  }
}


  ///////////////////////////Solution_Fertisant 5


  addRow5() {
    this.dynamicArray5.push({ fertilisant_object: '' , quantity: '' });
  }
 

 nbr_fertisants5(){
  if (Number(this._solutionService.formSolution_Fertisant.value['nbr_cuve5'])==this.dynamicArray5.length+1)
  this.addRow5()
  else if (Number(this._solutionService.formSolution_Fertisant.value['nbr_cuve5'])==this.dynamicArray5.length-1)
  this.dynamicArray5.pop()
 else if (Number(this._solutionService.formSolution_Fertisant.value['nbr_cuve5'])>this.dynamicArray5.length)
  for (let i=1;i<Number(this._solutionService.formSolution_Fertisant.value['nbr_cuve5']);i++){
    this.addRow5()
  }
 else 
  for (let i=Number(this._solutionService.formSolution_Fertisant.value['nbr_cuve5']);i>1;i--){
    this.dynamicArray5.pop()
  }
}

testQuantity(dynamicArray){
  //////////

  
  if(dynamicArray.length>0)
  for (let i=0;i<dynamicArray.length;i++){
    for (let j=0;j<this.NBR.length;j++){
  if ((this.NBR[j].id_fertilisant==dynamicArray[i].fertilisant_object)&&(Number(dynamicArray[i].quantity)>Number(this.NBR[j].quantity))){
    {
      alert('La quantité de '+ this.NBR[j].label +' est supérieur à ce qui est disponible en stock!')

      
     return false
    } 
  }
    }

  }
return true
}




  ///////////////////////////Solution_Fertisant 6


  addRow6() {
    this.dynamicArray6.push({ fertilisant_object: '' , quantity: '' });
  }
 

 nbr_fertisants6(){
  if (Number(this._solutionService.formSolution_Fertisant.value['nbr_cuve6'])==this.dynamicArray6.length+1)
  this.addRow6()
  else if (Number(this._solutionService.formSolution_Fertisant.value['nbr_cuve6'])==this.dynamicArray6.length-1)
  this.dynamicArray5.pop()
 else if (Number(this._solutionService.formSolution_Fertisant.value['nbr_cuve6'])>this.dynamicArray6.length)
  for (let i=1;i<Number(this._solutionService.formSolution_Fertisant.value['nbr_cuve6']);i++){
    this.addRow6()
  }
 else 
  for (let i=Number(this._solutionService.formSolution_Fertisant.value['nbr_cuve6']);i>1;i--){
    this.dynamicArray6.pop()
  }
}
 
}
