 
<div
class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0"
>
<div class="rounded-t bg-white mb-0 px-6 py-6">
  <div class="text-center flex justify-between">
    <h6 class="text-blueGray-700 text-xl font-bold">
      <span> {{ _langService.GetLanguage()=='FR' ? 'Ajouter une Mesure' : 'Add a Measure' }}</span>
   
      
    
    </h6>
 
  </div>
</div>
<div class="flex-auto px-4 lg:px-10 py-10 pt-0">


 <form  [formGroup]="_mesureService.formMesure" autocomplete="off" (ngSubmit)="onSubmit()" >

 

  <div class="flex flex-wrap">
 
    <div class="w-full lg:w-3/12 px-3">
        <div class="relative w-full mb-3">
          <label
            class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
            htmlFor="grid-password"
          >
          
          Date
          </label>
   
          <input class="datepicker-here form-control border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"  name="dateDebutprojet"  formControlName="dateDebutprojet"  
            type="date" id="start" name="trip-start"
         
            [value]="date"
            [(ngModel)]="date" 
            formControlName="date"

            > 
        </div>
      </div>
      <div class="w-full lg:w-3/12 px-3">
        <div class="relative w-full mb-3">
          <label
            class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
            htmlFor="grid-password"
          >
          Type
          </label>
          <select
          type="text"

            class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
            placeholder="Mode"
            formControlName="mode"
 
           > 

           <option value="Entré" ><span> {{ _langService.GetLanguage()=='FR' ? 'Entrée' : 'entry' }}</span></option>
           <option value="Sortie"><span> {{ _langService.GetLanguage()=='FR' ? 'Sortiee' : 'exit' }}</span></option>

           </select>
           
    
        </div>
      </div>
      <div class="w-full lg:w-3/12 px-3">
        <div class="relative w-full mb-3">
          <label
            class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
            htmlFor="grid-password"
          >
          
          <span> {{ _langService.GetLanguage()=='FR' ? 'Serre' : 'Greenhouse' }}</span>
          </label>
          <select
          type="text"

            class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
            placeholder="Type"
            formControlName="type"
 
           >

           
           
           <option value="P1" ><span> {{ _langService.GetLanguage()=='FR' ? 'Serre de production P1' : 'P1 production greenhouse' }}</span></option>
           <option value="P2"><span> {{ _langService.GetLanguage()=='FR' ? 'Pépinière P2' : 'Nursery P2' }}</span></option>
 

           </select>
           
    
        </div>
      </div>

      <div class="w-full lg:w-3/12 px-3" *ngIf="_mesureService.formMesure.value['type']=='P1'">
        <div class="relative w-full mb-3">
          <label
            class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
            htmlFor="grid-password"
          >
          XFrame
          </label>
        <td  > 
 
        <span   style=" display: inline-block; width: 70px;">
          <select     
          class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
          name="{{XFrame_Numero}}" [(ngModel)]="XFrame_Numero"  type="text" 
          formControlName="XFrame_Numero"
         >
        <option   *ngFor="let nbr of NBR"
        [value] ="nbr" >   {{nbr}}
       </option>
             </select>
            </span>
      
            <span  style=" display: inline-block; width: 70px; ">
              <select     
              class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
              name="{{XFrame_ligne}}" [(ngModel)]="XFrame_ligne"  type="text" 
              formControlName="XFrame_ligne"
   
            >
            <option   *ngFor="let nbr of LIGne"
            [value] ="nbr" > {{nbr}}
           </option>
                 </select>
                </span>
                </td>

            </div>
        </div>


      <div class="w-full lg:w-3/12 px-3"  *ngIf="_mesureService.formMesure.value['type']=='P2'">
        <div class="relative w-full mb-3">
          <label
            class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
            htmlFor="grid-password"
          >
          Table
          </label>
          <select
          type="text"

            class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
            placeholder="Table transplantée"
            formControlName="table_transplantee"
 
           >
           <option value="Table 1" >Table 1</option>
           <option value="Table 2">Table 2</option>
           <option value="Table 3">Table 3</option>
           <option value="Table 4">Table 4</option>
           <option value="Table 5">Table 5</option>
           <option value="Table 6">Table 6</option>
           <option value="Table 7">Table 7</option>
           <option value="Table 8">Table 8</option>
           <option value="Table 9">Table 9</option>
           <option value="Table 10">Table 10</option>
           <option value="Table 11">Table 11</option>
           <option value="Table 12">Table 12</option>
           <option value="Table 13">Table 13</option>
           <option value="Table 14">Table 14</option>
           <option value="Table 15">Table 15</option>
           <option value="Table 16">Table 16</option>
           <option value="Table 17">Table 17</option>
           <option value="Table 18">Table 18</option>
           <option value="Table 19">Table 19</option>
           <option value="Table 20">Table 20</option>
           <option value="Table 21">Table 21</option>
           <option value="Table 22">Table 22</option>
           <option value="Table 23">Table 23</option>
           <option value="Table 24">Table 24</option>
           <option value="Table 25">Table 25</option>
           <option value="Table 26">Table 26</option>
           <option value="Table 27">Table 27</option>
           <option value="Table 28">Table 28</option>
           <option value="Table 29">Table 29</option>
           <option value="Table 30">Table 30</option>
           <option value="Table 31">Table 31</option>
           <option value="Table 32">Table 32</option>
           <option value="Table 33">Table 33</option>
           <option value="Table 34">Table 34</option>
           <option value="Table 35">Table 35</option>
           <option value="Table 36">Table 36</option>
           <option value="Table 37">Table 37</option>
           <option value="Table 38">Table 38</option>
           <option value="Table 39">Table 39</option>
           <option value="Table 40">Table 40</option>
           <option value="Table 41">Table 41</option>
           <option value="Table 42">Table 42</option>

           </select>
           
    
        </div>
      </div>

      <div class="w-full lg:w-4/12 px-4">
        <div class="relative w-full mb-3">
          <label
            class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
            htmlFor="grid-password"
          >
          PH
          </label>
          <input
          type="numbre"
             class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
            placeholder="PH"
            formControlName="ph"
            [class.is-invalid]="_mesureService.formMesure.controls['ph'].errors?.required"
           />
        </div>
      </div>
 
 
      <div class="w-full lg:w-4/12 px-4">
        <div class="relative w-full mb-3">
          <label
            class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
            htmlFor="grid-password"
          >
          EC
          </label>
          <input
          type="numbre"
             class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
            placeholder="EC"
            formControlName="ec"
            [class.is-invalid]="_mesureService.formMesure.controls['ec'].errors?.required"
           />
        </div>
      </div>
 
 
      <div class="w-full lg:w-4/12 px-4">
        <div class="relative w-full mb-3">
          <label
            class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
            htmlFor="grid-password"
          >
          Chlore
          </label>
          <input
          type="numbre"
             class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
            placeholder="Chlore"
            formControlName="od"
            [class.is-invalid]="_mesureService.formMesure.controls['od'].errors?.required"
           />
        </div>
      </div>
 
 
      <div class="w-full lg:w-4/12 px-4">
        <div class="relative w-full mb-3">
          <label
            class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
            htmlFor="grid-password"
          >
          <span> {{ _langService.GetLanguage()=='FR' ? 'Température intérieure' : 'Indoor temperature' }}</span>
          
          </label>
          <input
          type="numbre"
             class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
            [placeholder]="_langService.GetLanguage()=='FR' ? 'Température intérieure' : 'Indoor temperature' "
            formControlName="temp_inter"
            [class.is-invalid]="_mesureService.formMesure.controls['temp_inter'].errors?.required"
           />
        </div>
      </div>
 
      <div class="w-full lg:w-4/12 px-4">
        <div class="relative w-full mb-3">
          <label
            class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
            htmlFor="grid-password"
          >
          <span> {{ _langService.GetLanguage()=='FR' ? 'Température extérieure' : 'Outside temperature' }}</span>
          
          </label>
          <input
          type="numbre"
             class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
            [placeholder]="_langService.GetLanguage()=='FR' ? 'Température extérieure' : 'Outside temperature'"
            formControlName="temp_ext"
            [class.is-invalid]="_mesureService.formMesure.controls['temp_ext'].errors?.required"
           />
        </div>
      </div>
       
      <div class="w-full lg:w-4/12 px-4">
        <div class="relative w-full mb-3">
          <label
            class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
            htmlFor="grid-password"
          >
          <span> {{ _langService.GetLanguage()=='FR' ? 'Taux d’humidité' : 'Humidity level' }}</span>
          
          </label>
          <input
          type="numbre"
             class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
            [placeholder]="_langService.GetLanguage()=='FR' ? 'Taux d’humidité' : 'Humidity level'"
            formControlName="humidite"
            [class.is-invalid]="_mesureService.formMesure.controls['humidite'].errors?.required"
           />
        </div>
      </div>
  </div>

  <div class="align-self-center mx-auto text-center" style="padding-top: 15px;">
  <button  
  class="bg-red-600 text-white active:bg-red-700 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
  type="submit"  
  >
  <span> {{ _langService.GetLanguage()=='FR' ? 'Ajouter' : 'Add' }}</span>
  
</button></div>
<div *ngIf="submit" class="alert alert-success" style="background-color: rgb(98, 223, 98);height: 50px;text-align: center;">
  {{ _langService.GetLanguage()=='FR' ? 'Mesure ajoutée avec succès' : 'Measure added successfully' }}

  
  <div class="align-self-center mx-auto text-center" style="padding-top: 15px;">
 </div>
</div>
</form>
</div>
</div>
