import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FactureService } from 'src/app/services/facture.service';
import { LangService } from 'src/app/services/lang.service';
 @Component({
  selector: 'app-delete-facture',
  templateUrl: './delete-facture.component.html',
  styleUrls: ['./delete-facture.component.css']
})
export class DeleteFactureComponent implements OnInit {
  facture: any;
  id: any;

  constructor(public _factureService:FactureService, public _langService:LangService,private router: Router,private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.id=params['id'] //log the value of id
   });
    this.GetCommande()
  }


  GetCommande(){
     
    this._factureService.get_Details_facture(this.id).subscribe(res => {
      this.facture = res  
 
      })
  }

  deletefacture(){
    this._factureService.delete_facture(this.id).subscribe(res => {
      this._factureService.All_Facture().subscribe(res => {
         this.router.navigate(['/admin/factures'])
        
        })
       })
  }
}
