import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-chef',
  templateUrl: './chef.component.html',
})
export class ChefComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
