import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FertilisantService } from 'src/app/services/fertilisant.service';
import { LangService } from 'src/app/services/lang.service';

@Component({
  selector: 'app-edit-fertilisant',
  templateUrl: './edit-fertilisant.component.html',
  styleUrls: ['./edit-fertilisant.component.css']
})
export class EditFertilisantComponent implements OnInit {
  submit: boolean;
  id: any;

  constructor(private route: ActivatedRoute, public _langService:LangService ,public _fertilisantService:FertilisantService) {}

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.id=params['id'] 
   });
    this._fertilisantService.get_Fertilisant(this.id).subscribe(res => {
      this._fertilisantService.initialiser(res)
       
      })
  }

  AddFertilisant(){
    this._fertilisantService.modifierFertilisant().subscribe(res => {
      this.submit=true
    })
  }

  onSubmit(){
    this.AddFertilisant();
    console.log('test')
  }
  
}
