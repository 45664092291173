import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Utilisateur } from '../models/utilisateur/utilisateur.model';
import { environment } from 'src/environments/environment';
import { FormBuilder, Validators } from '@angular/forms';

@Injectable({
  providedIn: 'root' 
})
export class UtilisateurService {


  oldEmail="";
  formUtilisateur=this.fb.group({
 	  last : ["" , Validators.required],
	  first : ["" , Validators.required],
	  phone : ["" , Validators.required],

    commentaire : ["" , Validators.required],
	  password : ["" , Validators.required],
	  email : ["" , Validators.required],


  });

  initialiserVide(){
    this.formUtilisateur.setValue({
      last :  "" ,
      first : "" ,
      phone : "" ,
      commentaire : "" ,
      password : "" ,
      email : "" ,
    
    })
     }


  listUtilisateur:Utilisateur[]

  constructor(private http: HttpClient, private fb: FormBuilder) {

   }


  insertUtilisateur() {
      return this.http
    .post(
       'https://seeds.bg-agri.com/babylone_API/Utilisateurs' + "/save.php?last="+
      this.formUtilisateur.value['last']
      +"&first="+this.formUtilisateur.value['first']
      +"&phone="+this.formUtilisateur.value['phone']
      +"&email="+this.formUtilisateur.value['email']
      +"&password="+this.formUtilisateur.value['password']
      +"&commentaire="+this.formUtilisateur.value['commentaire']
 
      ,JSON.stringify(''))
 
  }
    Add_permission(email) {
    return this.http
  .post(
     'https://seeds.bg-agri.com/babylone_API/Utilisateurs' + "/Add_Permissions.php?email="+
    email
    ,JSON.stringify(''))

}
  All_utilisateur() {
 

     return this.http.get( 'https://seeds.bg-agri.com/babylone_API/Utilisateurs' + "/all.php" );
  }

  get_utilisateur(id:any) {
    return this.http.get( 'https://seeds.bg-agri.com/babylone_API/Utilisateurs' + "/get.php?email="+id);
  }
  get_permissions(id:any) {
    return this.http.get( 'https://seeds.bg-agri.com/babylone_API/Utilisateurs' + "/All_permissions_by_user.php?email="+id);
  }
  get_permissions_true(id:any) {
    return this.http.get( 'https://seeds.bg-agri.com/babylone_API/Utilisateurs' + "/All_permissions_true.php?email="+id);
  }

  initialiser(ev){
 this.formUtilisateur.setValue(ev);
 this.oldEmail=ev.email

  }
  modifier_permission(value,id,etat){
    return this.http
    .post(
       'https://seeds.bg-agri.com/babylone_API/Utilisateurs' + "/modify_permission.php?user="+id
      +"&value="+value 
      +"&etat="+etat

      ,JSON.stringify(''))

  }
  modifierUtilisateur( ) {
 
    
    return this.http
  .post(
     'https://seeds.bg-agri.com/babylone_API/Utilisateurs' + "/update.php?last="+
    this.formUtilisateur.value['last']
    +"&first="+this.formUtilisateur.value['first']
    +"&phone="+this.formUtilisateur.value['phone']
    +"&email="+this.formUtilisateur.value['email']
    +"&password="+this.formUtilisateur.value['password']
    +"&commentaire="+this.formUtilisateur.value['commentaire']
    +"&oldEmail="+this.oldEmail
   
    ,JSON.stringify(''))
  }

  delete_utilisateur(id:any) {
    return this.http.get( 'https://seeds.bg-agri.com/babylone_API/Utilisateurs' + "/delete.php?email="+id);
  }
}
