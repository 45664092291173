import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Projet } from 'src/app/models/Projet/Projet.model';
import { LangService } from 'src/app/services/lang.service';
import { ProjetService } from 'src/app/services/projet.service';

@Component({
  selector: 'app-delete-commande',
  templateUrl: './delete-commande.component.html',
  styleUrls: ['./delete-commande.component.css']
})
export class DeleteCommandeComponent implements OnInit {
  commande: any;
  id: any;

  constructor(private _commandeService:ProjetService, public _langService:LangService,private router: Router,private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.id=params['id'] //log the value of id
   });
    this.GetCommande()
  }


  GetCommande(){
     
    this._commandeService.get_Details_commande(this.id).subscribe(res => {
      this.commande = res  
 
      })
  }

  deletecommande(){
    this._commandeService.delete_commande(this.id).subscribe(res => {
      this._commandeService.All_Projet().subscribe(res => {
         this.router.navigate(['/admin/commandes'])
        
        })
       })
  }
}
