<div
  class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0"
>
  <div class="rounded-t bg-white mb-0 px-6 py-6">
    <div class="text-center flex justify-between">
      <h6 class="text-blueGray-700 text-xl font-bold">
        {{ _langService.GetLanguage()=='FR' ? 'Réception de graines' : 'Receiving seeds' }}

        
      
      </h6>
   
    </div>
  </div>
  <div class="flex-auto px-4 lg:px-10 py-10 pt-0">
    <form [formGroup]="_stockService.formStock" autocomplete="off" (ngSubmit)="onSubmit()">
      <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
        {{ _langService.GetLanguage()=='FR' ? 'Informations générales' : 'General informations' }}
      </h6>
      <div class="flex flex-wrap">
 
        <div class="w-full lg:w-6/12 px-4">
          <div class="relative w-full mb-3">
            <label
              class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
              htmlFor="grid-password"
            >
            {{ _langService.GetLanguage()=='FR' ? 'Graine' : 'Seed' }}

              
            </label>
            <input
              type="text"
              class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
              [placeholder]="_langService.GetLanguage()=='FR' ? 'Graine' : 'Seed'"
              [value]="graine"
              disabled
            />
          </div>
        </div>
        <div class="w-full lg:w-6/12 px-4">
          <div class="relative w-full mb-3">
            <label
              class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
              htmlFor="grid-password"
            >
            {{ _langService.GetLanguage()=='FR' ? 'Date de réception' : 'Date of receipt' }}

            
            </label>
     
            <input class="datepicker-here form-control border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"  name="dateDebutprojet"  formControlName="dateDebutprojet"  
              type="date" id="start" name="trip-start"
               formControlName="dateReception"
              [class.is-invalid]="_stockService.formStock.controls['dateReception'].errors?.required"


              > 
          </div>
        </div>

        <div class="w-full lg:w-6/12 px-4">
          <div class="relative w-full mb-3">
            <label
              class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
              htmlFor="grid-password"
            >
            {{ _langService.GetLanguage()=='FR' ? "Date d'expiration" : 'Expiration date' }}

            </label>
     
            <input class="datepicker-here form-control border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"  name="dateDebutprojet"  formControlName="dateDebutprojet"  
              type="date" id="expiration" name="trip-start"
               formControlName="dateExpiration"
              [class.is-invalid]="_stockService.formStock.controls['dateExpiration'].errors?.required"
              > 
          </div>
        </div>
        <div class="w-full lg:w-6/12 px-4">
          <div class="relative w-full mb-3">
            <label
              class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
              htmlFor="grid-password"
            >
            {{ _langService.GetLanguage()=='FR' ? 'Numéro de lot' : 'Lot Number' }}

            
            </label>
            <input
              type="text"
              class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
              [placeholder]="_langService.GetLanguage()=='FR' ? 'Numéro de lot' : 'Lot Number'"
              formControlName="loi"
              [class.is-invalid]="_stockService.formStock.controls['loi'].errors?.required"
            />
          </div>
        </div>
        <div class="w-full lg:w-6/12 px-4">
          <div class="relative w-full mb-3">
            <label
              class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
              htmlFor="grid-password"
            >
            {{ _langService.GetLanguage()=='FR' ? 'Nombre de graines reçues' : 'Number of seeds received' }}

            
            </label>
            <input
              type="number"
              class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
              [placeholder]="_langService.GetLanguage()=='FR' ? 'Nombre de graines reçues' : 'Number of seeds received'"
              formControlName="nbr"
              min="0"
              [class.is-invalid]="_stockService.formStock.controls['nbr'].errors?.required"
            
            />
          </div>
        </div>
        <div class="w-full lg:w-6/12 px-4">
          <div class="relative w-full mb-3">
            <label
              class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
              htmlFor="grid-password"
            >
            {{ _langService.GetLanguage()=='FR' ? 'Bon de livraison' : 'Delivery note' }}

            
            </label>
            <input
              type="text"
              class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
              [placeholder]="_langService.GetLanguage()=='FR' ? 'Bon de livraison' : 'Delivery note'"
              formControlName="bon_livraison"
               [class.is-invalid]="_stockService.formStock.controls['bon_livraison'].errors?.required"
            
            />
          </div>
        </div>
        <div class="w-full lg:w-6/12 px-4">
          <div class="relative w-full mb-3">
            <label
              class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
              htmlFor="grid-password"
            >
            {{ _langService.GetLanguage()=='FR' ? 'Numéro de facture' : 'Invoice number' }}

            
            </label>
            <input
              type="text"
              class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
              [placeholder]=" _langService.GetLanguage()=='FR' ? 'Numéro de facture' : 'Invoice number' "
              formControlName="num_facture"
              min="1"
               [class.is-invalid]="_stockService.formStock.controls['num_facture'].errors?.required"
            
            />
          </div>
        </div>

        <div class="w-full lg:w-6/12 px-4">
          <div class="relative w-full mb-3">
            <label
              class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
              htmlFor="grid-password"
            >
            {{ _langService.GetLanguage()=='FR' ? 'Commentaire' : 'Comment' }}

            
            </label>
            <textarea 
              type="text"
              class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
              [placeholder]="_langService.GetLanguage()=='FR' ? 'Commentaire' : 'Comment'"
              formControlName="commentaire"
              [class.is-invalid]="_stockService.formStock['controls'].commentaire.errors?.required"
            ></textarea>
          </div>
        </div>
      </div>
 
 
      <div class="align-self-center mx-auto text-center" style="padding-top: 15px;">
      <button   
      *ngIf="!submit"
      class="bg-red-600 text-white active:bg-red-700 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
      type="submit"  
      >
      {{ _langService.GetLanguage()=='FR' ? 'Ajouter au stock' : 'Add to stock' }}

      
    </button></div>
    <div *ngIf="submit" class="alert alert-success" style="background-color: rgb(98, 223, 98);height: 50px;text-align: center;">
      {{ _langService.GetLanguage()=='FR' ? 'Graines ajoutées au stock avec succès' : 'Seeds added to stock successfully' }}

      
      <div class="align-self-center mx-auto text-center" style="padding-top: 15px;">
        <button  
        class=" text-white  font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
        type="button"   [routerLink]="['/admin/Stock']" style="background-color:rgb(195, 189, 170) ; color: black;"
        >
        {{ _langService.GetLanguage()=='FR' ? 'Retourner' : 'Return' }}

        
      </button></div>
    </div>
</form>
  </div>
</div>
 