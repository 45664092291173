import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from 'src/environments/environment';
import { FormBuilder, Validators } from '@angular/forms';
  
@Injectable({
  providedIn: 'root' 
})
export class FournisseurService {
 
  formFournisseur=this.fb.group({
    id_fournisseur : ["" , Validators.required],
    nom : ["" , Validators.required],
    email : ["" , Validators.required],
    tel : ["" , Validators.required],
    contact : ["" , Validators.required],

  });

     
  initialiserVide(){
    this.formFournisseur.setValue({
      id_fournisseur :  ""  ,
      nom :  ""  ,
      email :  ""  ,
      tel :  ""  ,
      contact :  ""  ,

    })
     }


   listFournisseur:any[]
  constructor(private http: HttpClient, private fb: FormBuilder) {
    }


  insertFournisseur() {
      return this.http
    .post(
      "https://seeds.bg-agri.com/babylone_API/Fournisseurs" + "/save.php?nom="+
      this.formFournisseur.value['nom']
      +"&email="+this.formFournisseur.value['email']
      +"&tel="+this.formFournisseur.value['tel']
      +"&contact="+this.formFournisseur.value['contact']

      ,JSON.stringify(''))
  }

  All_Fournisseur() {
     return this.http.get("https://seeds.bg-agri.com/babylone_API/Fournisseurs" + "/all.php" );
  }

  get_Fournisseur(id:any) {
    return this.http.get("https://seeds.bg-agri.com/babylone_API/Fournisseurs" + "/get.php?id_fournisseur="+id);
  }

  initialiser(ev){
 this.formFournisseur.setValue(ev)
  }

  modifierFournisseur( ) {
    return this.http
  .post(
    "https://seeds.bg-agri.com/babylone_API/Fournisseurs" + "/update.php?id_fournisseur="+
    this.formFournisseur.value['id_fournisseur']
    +"&nom="+this.formFournisseur.value['nom']
    +"&email="+this.formFournisseur.value['email']
    +"&tel="+this.formFournisseur.value['tel']
    +"&contact="+this.formFournisseur.value['contact']
   
    ,JSON.stringify(''))
  }

  delete_Fournisseur(id:any) {
    return this.http.get("https://seeds.bg-agri.com/babylone_API/Fournisseurs" + "/delete.php?id_fournisseur="+id);
  }

   
}
