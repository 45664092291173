import { Component, Input, OnInit } from '@angular/core';
import { FournisseurService } from 'src/app/services/fournisseur.service';
import { LangService } from 'src/app/services/lang.service';

@Component({
  selector: 'app-gestion-fournisseur',
  templateUrl: './gestion-fournisseur.component.html',
  styleUrls: ['./gestion-fournisseur.component.css']
})
export class GestionFournisseurComponent implements OnInit {

  @Input()
  get color(): string {
    return this._color;
  }
  set color(color: string) {
    this._color = color !== "light" && color !== "dark" ? "light" : color;
  }
  private _color = "light";

  listfournisseur : any[]

  constructor(public _fournisseurService:FournisseurService, public _langService:LangService) {}

  ngOnInit(): void {
    this._fournisseurService.All_Fournisseur().subscribe(res => {
      this.listfournisseur = res as any[]
      console.log(res);
      
       
      }) 
  }
}
