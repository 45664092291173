import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { LangService } from 'src/app/services/lang.service';
import { MesureService } from 'src/app/services/mesure.service';

@Component({
  selector: 'app-add-mesure',
  templateUrl: './add-mesure.component.html',
  styleUrls: ['./add-mesure.component.css']
})
export class AddMesureComponent implements OnInit {
  submit: boolean;
  Lang: String;
  date: string;
  NBR=[]
  LIGne=["A","B","C","D","E","F"]
  XFrame_Numero=1
  XFrame_ligne="A"
  constructor(private datePipe: DatePipe,public _mesureService:MesureService, public _langService:LangService) {
   this.Lang=_langService.GetLanguage()
  }

  ngOnInit(): void {
    for (let i=1;i<52;i++){
      this.NBR.push(i)
    }
    this.date=this.datePipe.transform( new Date(), 'yyyy-MM-dd')

this._mesureService.All_Mesure().subscribe(res => {
  this._mesureService.listMesure=res  as  any[];
  })
    this._mesureService.initialiserVide()
  }

  AddMesure(){
    this._mesureService.AddMesure(this.date).subscribe(res => {
      this.submit=true
    })
  }

  onSubmit(){
    this.AddMesure();
    console.log('test')
  }
  
}
