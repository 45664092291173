import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from 'src/environments/environment';
import { FormBuilder, Validators } from '@angular/forms';
import { Stock } from '../models/stock/stock.model';
import { DatePipe } from '@angular/common';
 
@Injectable({
  providedIn: 'root' 
})
export class MesureService {
 
  formMesure=this.fb.group({
    id_mesure : ["" , Validators.required],
	  date : ["" , Validators.required],
	  type : ["" , Validators.required],
	  table_Xframe : [ 1 , Validators.required],
	  mode : [ 0 , Validators.required],
	  ph : ["" , Validators.required],
	  ec : ["" , Validators.required],
    od : ["" , Validators.required],
    temp_inter : ["" , Validators.required],
    temp_ext : ["" , Validators.required],
    humidite : ["" , Validators.required],
    table_transplantee : ["" , Validators.required],
    XFrame_Numero : ["" , Validators.required],
    XFrame_ligne : ["" , Validators.required],
    operateur : ["" , Validators.required],
   });

   initialiserVide(){
    this.formMesure.setValue({
      id_mesure : ""  ,
      date : ""  ,
      type : ""  ,
      table_Xframe :  "" ,
      mode : 'Entré' ,
      ph :  0  ,
      ec :  0  ,
      od :  0  ,
      temp_inter : 0 ,
      temp_ext : 0  ,
      humidite : 0  ,
      table_transplantee :'Table 1',
      XFrame_Numero :'1',
      XFrame_ligne :'A',
      operateur :''
    })
     }

  listMesure:any[]
  constructor(private datePipe: DatePipe,private http: HttpClient, private fb: FormBuilder) {

   }

   AddMesure(date) {
  let table_Xframe=''
  if(this.formMesure.value['type']=='P2')
  table_Xframe=this.formMesure.value['table_transplantee']
  else
  table_Xframe=this.formMesure.value['XFrame_Numero']+this.formMesure.value['XFrame_ligne']
     
      return this.http
    .post(
      "https://seeds.bg-agri.com/babylone_API/Mesure" + "/save.php?date="+
      this.datePipe.transform( date , 'dd/MM/yyyy')
      +"&type="+this.formMesure.value['type']
      +"&table_Xframe="+table_Xframe
      +"&mode="+this.formMesure.value['mode']
      +"&ph="+this.formMesure.value['ph']
      +"&ec="+this.formMesure.value['ec']
      +"&od="+this.formMesure.value['od']
      +"&temp_inter="+this.formMesure.value['temp_inter']
      +"&temp_ext="+this.formMesure.value['temp_ext']
      +"&humidite="+this.formMesure.value['humidite']
      +"&operateur="+localStorage.getItem('log')
      ,JSON.stringify(''))
  }

  initialiser(ev){
     
    this.formMesure.setValue(ev)
     
     }
 
  All_Mesure(){
 
     return this.http.get("https://seeds.bg-agri.com/babylone_API/Mesure" + "/all.php" );
  }
 

  get_Mesure(id){
    
    return this.http.get("https://seeds.bg-agri.com/babylone_API/Mesure" + "/get.php?type="+id);
   }

  modifierMesure(date) {
    return this.http
  .post(
    "https://seeds.bg-agri.com/babylone_API/Mesure" + "/update?date="+
    this.datePipe.transform( date , 'dd/MM/yyyy')
      +"&type="+this.formMesure.value['type']
      +"&table_Xframe="+this.formMesure.value['table_Xframe']
      +"&mode="+this.formMesure.value['mode']
      +"&ph="+this.formMesure.value['ph']
      +"&ec="+this.formMesure.value['ec']
      +"&od="+this.formMesure.value['od']
      +"&temp_inter="+this.formMesure.value['temp_inter']
      +"&temp_ext="+this.formMesure.value['temp_ext']
      +"&humidite="+this.formMesure.value['humidite']
      +"&id_Mesure="+this.formMesure.value['id_Mesure']
    
    ,JSON.stringify(''))
  }

  delete_Mesure(id:any) {
    return this.http.get("https://seeds.bg-agri.com/babylone_API/Mesure" + "/delete.php?id="+id);
  }
  All_Mesure_for_recolte(){
    return this.http.get("https://seeds.bg-agri.com/babylone_API/Mesure" + "/All_Mesure_for_recolte.php" );
  
  
  }
  
 
}
