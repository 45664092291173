import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from 'src/environments/environment';
import { FormBuilder, Validators } from '@angular/forms';
import { Stock } from '../models/stock/stock.model';
import { DatePipe } from '@angular/common';
 
@Injectable({
  providedIn: 'root' 
})
export class SemisService {
 
  formSemis=this.fb.group({
    id_semis : ["" , Validators.required],
	  date : ["" , Validators.required],
	  unique_id : ["" , Validators.required],
	  nbr_graines : [ 1 , Validators.required],
	  nbr_plaques : [ 1 , Validators.required],
	  operateur : ["" , Validators.required],
	  commentaire : ["" , Validators.required],
    nbr_disponible : [ 1 , Validators.required],
    nbr_perdus : [ 0 , Validators.required],
  });
  formMousse=this.fb.group({
    id_mousse  : ["" , Validators.required],
	  nbr_mousse : ["" , Validators.required],
 
  });

  initialiserVide(){
    this.formSemis.setValue({
    id_semis : ""  ,
	  date : ""  ,
	  unique_id : ""  ,
	  nbr_graines :   1  ,
	  nbr_plaques :  1  ,
	  nbr_disponible :  1  ,
	  nbr_perdus :  1  ,
	  operateur :  ""  ,
	  commentaire : ""  ,
    })
     }

      Nbr_Mousses_Disponible(){
      return this.http.get("https://seeds.bg-agri.com/babylone_API/Semis" + "/stock_mousse.php" );
     }
     nbr_facture_non_payer(){
      return this.http.get("https://seeds.bg-agri.com/babylone_API/Factures" + "/nbr_facture_non_payer.php" );
     }
     nbrP1(){
      return this.http.get("https://seeds.bg-agri.com/babylone_API/Plantation" + "/Get_nbr_P1_available.php" );
     }
     nbrP2(){
      return this.http.get("https://seeds.bg-agri.com/babylone_API/Floating" + "/Get_nbr_P2_available.php" );
     }
     get_mesure(mode){
      return this.http.get("https://seeds.bg-agri.com/babylone_API/Mesure" + "/get.php?mode="
      +mode
      );
     }

  listSemis:any[]
  constructor(private datePipe: DatePipe,private http: HttpClient, private fb: FormBuilder) {

   }

   AddSemis(id_semis,date) {
      return this.http
    .post(
      "https://seeds.bg-agri.com/babylone_API/Semis" + "/save.php?date="+
       this.datePipe.transform( date , 'dd/MM/yyyy')
      +"&unique_id="+this.formSemis.value['unique_id']
      +"&commentaire="+this.formSemis.value['commentaire']
      +"&nbr_graines="+this.formSemis.value['nbr_graines']
      +"&nbr_plaques="+this.formSemis.value['nbr_plaques']
      +"&operateur="+this.formSemis.value['operateur']
      +"&id_semis="+id_semis

      ,JSON.stringify(''))
  }

  initialiser(ev){
    this.formSemis.setValue(ev)
     }
     
  All_Semis(){
 
     return this.http.get("https://seeds.bg-agri.com/babylone_API/Semis" + "/all.php" );
  }
 

  get_Semis(id){
    return this.http.get("https://seeds.bg-agri.com/babylone_API/Semis" + "/get.php?unique_id="+id);
   }

   get_graine_by_semis(){
    return this.http.get("https://seeds.bg-agri.com/babylone_API/Semis" + "/get_graine_by_semis.php?unique_id="+this.formSemis.value['id_semis']);
   }


  modifierSemis() {
    return this.http
  .post(
    "https://seeds.bg-agri.com/babylone_API/Semis" + "/update.php?commentaire="
    +this.formSemis.value['commentaire']
   +"&nbr_disponible="+ (Number(this.formSemis.value['nbr_graines']) - Number(this.formSemis.value['nbr_perdus']))
   +"&nbr_perdus="+this.formSemis.value['nbr_perdus']
   +"&nbr_plaques="+this.formSemis.value['nbr_plaques']
   +"&operateur="+this.formSemis.value['operateur']
   +"&id_semis="+this.formSemis.value['id_semis']
    ,JSON.stringify(''))
  }

  delete_Semis(id:any) {
    return this.http.get("https://seeds.bg-agri.com/babylone_API/Semis" + "/delete.php?id="+id);
  }
  
  All_semis_for_floating(){
    return this.http.get("https://seeds.bg-agri.com/babylone_API/Semis" + "/All_semis_for_floating.php" );
  }

  insertMousse(){
    return this.http
    .post(
      "https://seeds.bg-agri.com/babylone_API/Semis" + "/ajouter_mousse.php?nbr_mousse="+
      +this.formMousse.value['nbr_mousse']
      ,JSON.stringify(''))
  }
 
}
