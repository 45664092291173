<a
  class="text-blueGray-500 block"
  href=" "
  (click)="toggleDropdown($event)"
  #btnDropdownRef
>
  <div class="items-center flex">
    <span
      class="w-12 h-12 text-sm text-white bg-blueGray-200 inline-flex items-center justify-center rounded-full"
    >
      <i
        alt="..."
        class="fas fa-bell " style="color: rgb(75,174,0) ; font-size:20px"
       ></i>
    </span>
  </div>
</a>
<div class="min-w-48 z-50" #popoverDropdownRef>
  <div
    class="bg-white text-base z-50 float-left py-2 list-none text-left rounded shadow-lg mt-1 min-w-48"
    [ngClass]="dropdownPopoverShow ? 'block' : 'hidden'"
  >
    <a
    
      class="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700"
    >
      Tache 1
    </a>
 
    <a
      
      class="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700"
    >
    Tache 1
    </a>
    <a
      
      class="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700"
    >
    Tache 1
    </a>
    <div class="h-0 my-2 border border-solid border-blueGray-100"></div>
    <a
      
      class="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700"
    >
    Tache 1
    </a> 
  </div>
</div>
