import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { LangService } from 'src/app/services/lang.service';
   import { SemisService } from 'src/app/services/semis.service';
import { StockService } from 'src/app/services/stock.service';
 
@Component({
  selector: 'app-semis',
  templateUrl: './semis.component.html',
  styleUrls: ['./semis.component.css']
})
export class SemisComponent implements OnInit {
  submit: boolean;
  stock: any[];  
  datesemis  ;
 mousses=0;
 erreur1: any;

 constructor(private datePipe: DatePipe, public _langService:LangService,  public _stockService:StockService,public _semisService:SemisService) { }

 ngOnInit(): void {
   this._semisService.initialiserVide()

   this.datesemis=this.datePipe.transform( new Date(), 'yyyy-MM-dd')

     this._stockService.All_Stock_for_semis().subscribe(res => {
       this.stock = res as any[]
       })

       this._semisService.Nbr_Mousses_Disponible() .subscribe(res => {
         this.mousses=Number(res)
         console.log( this.mousses);
     
         })
 }


 AddSemis(){
   
    if (this.mousses >= Number(this._semisService.formSemis.value['nbr_plaques'])){
   this._stockService.get_stock(this._semisService.formSemis.value['unique_id']).subscribe(res => {
    
     if(Number(res['nbr']) >= Number(this._semisService.formSemis.value['nbr_graines']) )
     this._stockService.verif_date(this._semisService.formSemis.value['unique_id']).subscribe(test => {
       let res= test as any[]
     if (res.length==0)
     this._stockService.get_graine_by_stock(this._semisService.formSemis.value['unique_id']).subscribe(res => {
       let id_semis=res['variete'].substring(0,3).toUpperCase() + res['loi'] + this.datePipe.transform(this.datesemis, 'yyMMdd') 
  
     this._semisService.AddSemis(id_semis,this.datesemis).subscribe(res => {
       this.submit=true  
     },error  => {

       this.erreur1=true  

 
         
       })
   })
   else{
    if(this._langService.GetLanguage()=="FR")
  alert('il y a des graines disponible en stock de même variété avec date de réception plus ancienne!')
  else{
    alert('there are seeds available in stock of the same variety with an older date of receipt!')

  }
}
   } )
   else {
    if(this._langService.GetLanguage()=="FR")

   alert('Le nombre de graine est supérieur à ce qui est disponible en stock!')
   else{
    alert('The number of seeds is higher than what is available in stock!')

  }
  }
  },error  => {

    alert('Veuillez remplir tous les champs!')

    
  })
}
else {
  if(this._langService.GetLanguage()=="FR")
  alert('Le nombre de plateaux est supérieur à ce qui est disponible en stock!')
  else{
    alert('The number of trays is greater than what is available in stock!')

  }
}
 }

 onSubmit(){
   this.AddSemis();
 }
}
