import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from 'src/environments/environment';
import { FormBuilder, Validators } from '@angular/forms';
import { Stock } from '../models/stock/stock.model';
import { DatePipe } from '@angular/common';
 
@Injectable({
  providedIn: 'root' 
})
export class FloatingService {
 
  formFloating=this.fb.group({
    id_floating : ["" , Validators.required],
	  date : ["" , Validators.required],
	  unique_id : ["" , Validators.required],
	  nbr_graines : [ 1 , Validators.required],
	  nbr_plaques : [ 1 , Validators.required],
	  operateur : ["" , Validators.required],
	  commentaire : ["" , Validators.required],
    table_transplantee : ["Table 1" , Validators.required],
    nbr_perdus  : [0 , Validators.required],
  });

  listFloating:any[]
  constructor(private datePipe: DatePipe,private http: HttpClient, private fb: FormBuilder) {

   }

   
   initialiserVide(){
    this.formFloating.setValue({
    id_floating : "" ,
	  date : "" ,
	  unique_id :"" ,
	  nbr_graines : 1 ,
	  nbr_plaques :   1  ,
	  operateur :"" ,
	  commentaire : "" ,
    table_transplantee :  "Table 1"  ,
    nbr_perdus :   0  ,
    

    })
     }

   AddFloating(date) {
 
    
      return this.http
    .post(
      "https://seeds.bg-agri.com/babylone_API/Floating" + "/save.php?date="+
       this.datePipe.transform( date , 'dd/MM/yyyy')

      +"&unique_id="+this.formFloating.value['unique_id']
      +"&commentaire="+this.formFloating.value['commentaire']
      +"&nbr_graines="+this.formFloating.value['nbr_graines']
      +"&nbr_plaques="+this.formFloating.value['nbr_plaques']
      +"&operateur="+this.formFloating.value['operateur']
      +"&nbr_perdus="+this.formFloating.value['nbr_perdus']
      +"&table_transplantee="+this.formFloating.value['table_transplantee']
      ,JSON.stringify(''))
  }

  initialiser(ev){
    let date;
   if(ev.date.indexOf("-")>-1)
   if(ev.date.indexOf("-")==2){
     date=  ev.date.substring(6) + '/'+ ev.date.substring(3,5)+'/'+ ev.date.substring(0,2)
    
   }
   else{
     date=ev.date.replaceAll("-","/")
   }
   else{
     date=  ev.date.substring(6) + '/'+ ev.date.substring(3,5)+'/'+ ev.date.substring(0,2)
   }
   ev.date=this.datePipe.transform( date, 'yyyy-MM-dd')  

   
    this.formFloating.setValue(ev)
     
     }
     
  All_Floating(){
 
     return this.http.get("https://seeds.bg-agri.com/babylone_API/Floating" + "/all.php" );
  }
 

  get_Floating(id){
    return this.http.get("https://seeds.bg-agri.com/babylone_API/Floating" + "/get.php?unique_id="+id);
   }

   
  get_details(id){
    return this.http.get("https://seeds.bg-agri.com/babylone_API/Floating" + "/get_details.php?unique_id="+id);
   }
   get_Floating_Test(id){

    
    return this.http.get("https://seeds.bg-agri.com/babylone_API/Floating" + "/get_Floating_Test.php?id_floating="+id);
   }

   get_graine_by_floating(){
    return this.http.get("https://seeds.bg-agri.com/babylone_API/Floating/get_graine.php?unique_id="+this.formFloating.value['id_floating']);
   }
   modifierSemisAvailable(nbr){
    return this.http
    .post(
      "https://seeds.bg-agri.com/babylone_API/Floating" + "/modifierSemisAvailable.php?nbr="
      +nbr
      +"&unique_id="+this.formFloating.value['unique_id']
     
      ,JSON.stringify(''))
   }
  modifierFloating(date) {
 

    return this.http
  .post(
    "https://seeds.bg-agri.com/babylone_API/Floating" + "/update?commentaire="
    + this.formFloating.value['commentaire']
    +"&nbr_graines="+this.formFloating.value['nbr_graines']
    +"&date="+ this.datePipe.transform( date , 'dd/MM/yyyy')
    +"&nbr_plaques="+this.formFloating.value['nbr_plaques']
    +"&operateur="+this.formFloating.value['operateur']
    +"&nbr_perdus="+this.formFloating.value['nbr_perdus']
    +"&table_transplantee="+this.formFloating.value['table_transplantee']
    +"&id_floating="+this.formFloating.value['id_floating']
    +"&unique_id="+this.formFloating.value['unique_id']

    
    ,JSON.stringify(''))
  }

  delete_Floating(id:any) {
    return this.http.get("https://seeds.bg-agri.com/babylone_API/Floating" + "/delete.php?id="+id);
  }
 
 //All_floating_for_plantation
 All_floating_for_plantation(){
  return this.http.get("https://seeds.bg-agri.com/babylone_API/Floating" + "/All_floating_for_plantation.php" );


}

 }
