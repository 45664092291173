import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { CompetenceService } from 'src/app/services/competence.service';
import { LangService } from 'src/app/services/lang.service';
import { ProjetService } from 'src/app/services/projet.service';
import { StockService } from 'src/app/services/stock.service';

@Component({
  selector: 'app-edit-stock',
  templateUrl: './edit-stock.component.html',
  styleUrls: ['./edit-stock.component.css']
})
export class EditStockComponent implements OnInit {
  submit: boolean;
   id: any;
  myGroup: FormGroup;
  graine:String
  selection: any[];
  ancien=0;
  dateReception = new Date();
  dateExpiration = new Date();

  nbr_commande: any;
  constructor(private datePipe: DatePipe, public _langService:LangService,public _graineService:CompetenceService,public _stockService:StockService,public _commandeService:ProjetService,private route: ActivatedRoute) {
    this.myGroup = new FormGroup({
      selection  :new FormControl()
   });
    
   }

  ngOnInit(): void {
    
    this.route.params.subscribe(params => {
      this.id=params['id'] //log the value of id
   });
   this._stockService.get_stock(this.id).subscribe(res => {
    this._stockService.initialiser(res)
     
    })     
   
    this._stockService.get_graine_by_stock(this.id).subscribe(res => {
      this.graine= res['variete']       
      })     
     
                
 
 
    

  }
  ModifierStock(){
   

     this._stockService.MofierStock(this.dateReception,this.dateExpiration).subscribe(res => {
      this.ListeEquipe()
       let test =res ;
     if (test != null) {
        this._stockService.All_Stock().subscribe(res => {
        this._stockService.listStock = res as any[]
        this.submit=true
 
 
        })
     }
   },
    err => {
       console.log(err)
           }
   ) 
  }
  onSubmit(){
    this.ModifierStock();
  }

  ListeEquipe(){
    this._stockService.All_Stock().subscribe(res => {
         
      })
 
  }

}
