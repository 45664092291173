import { Component, OnInit } from '@angular/core';
import { FournisseurService } from 'src/app/services/fournisseur.service';
import { LangService } from 'src/app/services/lang.service';

@Component({
  selector: 'app-addfournisseur',
  templateUrl: './addfournisseur.component.html',
  styleUrls: ['./addfournisseur.component.css']
})
export class AddfournisseurComponent implements OnInit {
  submit: boolean;

  constructor(public _fournisseurService:FournisseurService, public _langService:LangService) {}

  ngOnInit(): void {
    this._fournisseurService.initialiserVide()

  }

  AddFournisseur(){
    this._fournisseurService.insertFournisseur().subscribe(res => {
      this.submit=true
    
    })
  }

  onSubmit(){
    this.AddFournisseur();
    console.log('test')
  }
  
}
