import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-deletecompetence',
  templateUrl: './deletecompetence.component.html',
  styleUrls: ['./deletecompetence.component.css']
})
export class DeletecompetenceComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
