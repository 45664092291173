 
<div
class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0"
>
<div class="rounded-t bg-white mb-0 px-6 py-6">
  <div class="text-center flex justify-between">
    <h6 class="text-blueGray-700 text-xl font-bold">
      {{ _langService.GetLanguage()=='FR' ? 'Ajouter une facture' : 'Add an invoice' }}

    </h6>
 
  </div>
</div>
<div class="flex-auto px-4 lg:px-10 py-10 pt-0">


 <form  [formGroup]="_factureService.formFacture" autocomplete="off" (ngSubmit)="onSubmit()" >
  <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
    {{ _langService.GetLanguage()=='FR' ? 'Informations générales' : 'General informations' }}
  </h6>
  <div class="flex flex-wrap">

    
    <div class="w-full lg:w-6/12 px-4">
      <div class="relative w-full mb-3">
        <label
          class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
          htmlFor="grid-password"
        >
        {{ _langService.GetLanguage()=='FR' ? 'Numéro de facture' : 'Invoice number' }}
          
        </label>
        <input
          type="text"
          class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
          [placeholder]="_langService.GetLanguage()=='FR' ? 'Numéro de facture' : 'Invoice number'"
          formControlName="id_facture"
          [class.is-invalid]="_factureService.formFacture.controls['id_facture'].errors?.required"
        />
      </div>
    </div>


    <div class="w-full lg:w-6/12 px-4">
        <div class="relative w-full mb-3">
          <label
            class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
            htmlFor="grid-password"
          >
          {{ _langService.GetLanguage()=='FR' ? 'Fournisseur' : 'Supplier' }}
          
          </label>
          <select 
            type="text"
            class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
            formControlName="fournisseur"
            [class.is-invalid]="_factureService.formFacture.controls['fournisseur'].errors?.required"
          >
          <option *ngFor="let fournisseur of _fournisseurService.listFournisseur" [value]="fournisseur.id_fournisseur" >{{fournisseur.nom}}</option>
  
          </select>
        </div>
      </div>

    <div class="w-full lg:w-6/12 px-4">
        <div class="relative w-full mb-3">
          <label
            class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
            htmlFor="grid-password"
          >
          {{ _langService.GetLanguage()=='FR' ? 'Date de facture' : 'Invoice date' }}
          
          </label>
   
          <input class="datepicker-here form-control border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"  name="dateDebutprojet"  formControlName="dateDebutprojet"  
            type="date" id="start" name="trip-start"
         
           [value]="date_facture"
            [(ngModel)]="date_facture" 
            formControlName="date_facture"

            > 
        </div>
      </div>
    <div class="w-full lg:w-6/12 px-4">
        <div class="relative w-full mb-3">
          <label
            class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
            htmlFor="grid-password"
          >
          {{ _langService.GetLanguage()=='FR' ? 'échéance de paiement' : 'payment deadline' }}
          
          </label>
          <input
            type="date"
            class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
             formControlName="echeance_paymant"
            [value]="echeance_paymant"
            [(ngModel)]="echeance_paymant" 
           />
        </div>
      </div>
 
      <div class="w-full lg:w-6/12 px-4">
        <div class="relative w-full mb-3">
          <label
            class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
            htmlFor="grid-password"
          >
          {{ _langService.GetLanguage()=='FR' ? ' état de facture' : 'invoice status' }}
         
          </label>
          <select 
            type="text"
            class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
            formControlName="etat"
            [class.is-invalid]="_factureService.formFacture.controls['etat'].errors?.required"
          >
          <option   value="Réglée" >
            {{ _langService.GetLanguage()=='FR' ? 'Réglée' : 'solved' }}
            
          
          </option>
          <option   value="Non réglée" >
            {{ _langService.GetLanguage()=='FR' ? 'Non réglée' : 'Unresolved' }}
           
          </option>
  
          </select>
        </div>
      </div>
      <div class="w-full lg:w-6/12 px-4" >
         <label>
          {{ _langService.GetLanguage()=='FR' ? 'Fichier PDF' : 'PDF file' }}
          Fichier PDF
        
        </label>
    
            
            <div class="custom-file"  >


                <input class="col" accept="application/pdf" type="file" class="custom-file-input" multiple 
                (change)="onFileChanged1($event.target.files)"   
                formControlName="fichierPDF"
                [class.is-invalid]="_factureService.formFacture.controls['fichierPDF'].errors?.required"  
                >
            </div>
         <div class="progress" *ngIf='progress'>
         <div class="animated-progress progress-purple">
            <span data-progress="{{progressvalue}}"  [style.width]="progressvalue+'%'" aria-valuemin="0" aria-valuemax="100">
                {{progressvalue}}%
            </span>
          </div>
    </div>
    </div>
  </div>
 

  <div class="align-self-center mx-auto text-center" style="padding-top: 15px;">
  <button  
  class="bg-red-600 text-white active:bg-red-700 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
  type="submit"  
  >
  {{ _langService.GetLanguage()=='FR' ? 'Ajouter' : 'Add' }}
  
</button></div>
<div *ngIf="submit" class="alert alert-success" style="background-color: rgb(98, 223, 98);height: 50px;text-align: center;">
  {{ _langService.GetLanguage()=='FR' ? 'Facture ajoutée avec succès' : 'Invoice added successfully' }}

  
  <div class="align-self-center mx-auto text-center" style="padding-top: 15px;">
    <button  
    class=" text-white  font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
    type="button"   [routerLink]="['/admin/factures']" style="background-color:rgb(195, 189, 170) ; color: black;"
    >
    {{ _langService.GetLanguage()=='FR' ? 'Retourner' : 'Return' }}
    
  </button></div>
</div>
</form>
</div>
</div>
