import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ClientService } from 'src/app/services/client.service';
import { LangService } from 'src/app/services/lang.service';

@Component({
  selector: 'app-edit-client',
  templateUrl: './edit-client.component.html',
  styleUrls: ['./edit-client.component.css']
})
export class EditClientComponent implements OnInit {
  submit: boolean;
  id: any;

  constructor(public _clientService:ClientService, public _langService:LangService,private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.id=params['id']  
   });
   this._clientService.get_Client(this.id).subscribe(res => {
    this._clientService.initialiser(res)
     console.log(res);
     
    })
  }

  ModifyClient(){
    this._clientService.modifierClient().subscribe(res => {
      this.submit=true
    })
  }

  onSubmit(){
    this.ModifyClient();
    console.log('test')
 
  }
}
