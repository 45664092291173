import { Component, Input, OnInit } from '@angular/core';
import { Graine } from 'src/app/models/Graine/graine.model';
import { CompetenceService } from 'src/app/services/competence.service';
import { LangService } from 'src/app/services/lang.service';

@Component({
  selector: 'app-gestioncompetence',
  templateUrl: './gestioncompetence.component.html',
  styleUrls: ['./gestioncompetence.component.css']
})
export class GestioncompetenceComponent implements OnInit {
  listCompetence: Graine[];
  search
  @Input()
  get color(): string {
    return this._color;
  }
  set color(color: string) {
    this._color = color !== "light" && color !== "dark" ? "light" : color;
  }
  private _color = "light";

  constructor(public _competenceService:CompetenceService, public _langService:LangService) {}

  ngOnInit(): void {
   this.Listeusers();
  }

  Listeusers(){
    this._competenceService.All_Competence().subscribe(res => {
      this.listCompetence = res as Graine[]
      console.log(res);
      
      })
  }

///////////

isDesc: boolean = false;
column: string = 'CategoryName';
 
 
sort(property) {
  this.isDesc = !this.isDesc; //change the direction    
  this.column = property;
  let direction = this.isDesc ? 1 : -1;

  this.listCompetence.sort(function (a, b) {
    a[property]=a[property].replace(' ','');
    b[property]=b[property].replace(' ','');
    if (a[property].toUpperCase( ) < b[property].toUpperCase( )) {
      return -1 * direction;
    }
    else if (a[property].toUpperCase( ) > b[property].toUpperCase( )) {
      return 1 * direction;
    }
    else {
      return 0;
    }
  });
};

//////////////
  


}
