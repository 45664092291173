import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from 'src/environments/environment';
import { FormBuilder, Validators } from '@angular/forms';
  
@Injectable({
  providedIn: 'root' 
})
export class ClientService {
 
  formClient=this.fb.group({
    id_client : ["" , Validators.required],
    code_client : ["" , Validators.required],
    
    nom : ["" , Validators.required],
    email : ["" , Validators.required],
    tel : ["" , Validators.required],
    contact : ["" , Validators.required],
    Tva_Intra : ["" , Validators.required],
    adresse : ["" , Validators.required],
    zip : ["" , Validators.required],
    ville : ["" , Validators.required],
  });


   listClient:any[]
  constructor(private http: HttpClient, private fb: FormBuilder) {
    }


  insertClient() {
     let annee=  new Date().getFullYear()

      return this.http
    .post(
      "https://seeds.bg-agri.com/babylone_API/Clients" + "/save.php?nom="+
      this.formClient.value['nom']
      +"&email="+this.formClient.value['email']
      +"&tel="+this.formClient.value['tel']
      +"&contact="+this.formClient.value['contact']
      +"&adresse="+this.formClient.value['adresse']
      +"&zip="+this.formClient.value['zip']
      +"&ville="+this.formClient.value['ville']
      +"&Tva_Intra="+this.formClient.value['Tva_Intra']
      +"&annee="+annee

      ,JSON.stringify(''))
  }

  All_Client() {
     return this.http.get("https://seeds.bg-agri.com/babylone_API/Clients" + "/all.php" );
  }

  get_Client(id:any) {
    return this.http.get("https://seeds.bg-agri.com/babylone_API/Clients" + "/get.php?id_client="+id);
  }

  initialiser(ev){
 this.formClient.setValue(ev)
  }
  initialiserVide(){
    this.formClient.setValue({
      id_client :  ""  ,
      code_client :  ""  ,
      
      nom :  ""  ,
      email :  ""  ,
      tel :  ""  ,
      contact :  ""  ,
      Tva_Intra :  ""  ,
      adresse :  ""  ,
      zip :  ""  ,
      ville :  ""  ,

    })
     }
  modifierClient( ) {
    return this.http
  .post(
    "https://seeds.bg-agri.com/babylone_API/Clients" + "/update.php?id_client="+
    this.formClient.value['id_client']
    +"&nom="+this.formClient.value['nom']
    +"&email="+this.formClient.value['email']
    +"&tel="+this.formClient.value['tel']
    +"&contact="+this.formClient.value['contact']
    +"&adresse="+this.formClient.value['adresse']
    +"&zip="+this.formClient.value['zip']
    +"&ville="+this.formClient.value['ville']
    +"&Tva_Intra="+this.formClient.value['Tva_Intra']
    ,JSON.stringify(''))
  }

  delete_Client(id:any) {
    return this.http.get("https://seeds.bg-agri.com/babylone_API/Clients" + "/delete.php?id_client="+id);
  }

   
}
