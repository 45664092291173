import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from 'src/environments/environment';
import { FormBuilder, Validators } from '@angular/forms';
import { DatePipe } from '@angular/common';
 
@Injectable({
  providedIn: 'root' 
})
export class NettoyageService {
 
  formNettoyage=this.fb.group({
    id_nettoyage : ["" , Validators.required],
	  date : ["" , Validators.required],
	  aspect : [ "" , Validators.required],
	  nbr_Xframe : [ 1 , Validators.required],
	  operateur : ["" , Validators.required],
	  commentaire : ["" , Validators.required],
    Xframe : ["" , Validators.required],
  });


  initialiserVide(){
    this.formNettoyage.setValue({
      id_nettoyage : ""  ,
      date : ""  ,
      aspect : ""  ,
      nbr_Xframe :   1  ,
      operateur : ""  ,
      commentaire : ""  ,
      Xframe : ""  ,
    })
     }


  listNettoyage:any[]
  constructor(private datePipe: DatePipe,private http: HttpClient, private fb: FormBuilder) {

   }

   AddNettoyage(date) {

     
      return this.http
    .post(
      "https://seeds.bg-agri.com/babylone_API/Nettoyage" + "/save.php?date="+
      this.datePipe.transform( date , 'dd/MM/yyyy')
      +"&commentaire="+this.formNettoyage.value['commentaire']
      +"&aspect="+this.formNettoyage.value['aspect']
      +"&nbr_Xframe="+this.formNettoyage.value['nbr_Xframe']
      +"&operateur="+this.formNettoyage.value['operateur']
      +"&Xframe="+this.formNettoyage.value['Xframe']
      ,JSON.stringify(''))
  }

  initialiser(ev){
     
    this.formNettoyage.setValue(ev)
     
     }
     
  All_Nettoyage(){
 
     return this.http.get("https://seeds.bg-agri.com/babylone_API/Nettoyage" + "/all.php" );
  }
 

  get_Nettoyage(id){
    
    return this.http.get("https://seeds.bg-agri.com/babylone_API/Nettoyage" + "/get.php?unique_id="+id);
   }

  modifierNettoyage() {
    return this.http
  .post(
    "https://seeds.bg-agri.com/babylone_API/Nettoyage" + "/update?date="+
    this.formNettoyage.value['date']
    +"&commentaire="+this.formNettoyage.value['commentaire']
    +"&aspect="+this.formNettoyage.value['aspect']
    +"&nbr_Xframe="+this.formNettoyage.value['nbr_Xframe']
    +"&operateur="+this.formNettoyage.value['operateur']
    +"&Xframe="+this.formNettoyage.value['Xframe']
    +"&id_nettoyage="+this.formNettoyage.value['id_nettoyage']
    
    ,JSON.stringify(''))
  }

  delete_Nettoyage(id:any) {
    return this.http.get("https://seeds.bg-agri.com/babylone_API/Nettoyage" + "/delete.php?id="+id);
  }
 
  
 
}
