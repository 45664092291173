import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LangService } from 'src/app/services/lang.service';
import { StockService } from 'src/app/services/stock.service';
import { RecolteService } from 'src/app/services/recolte.service';


@Component({
  selector: 'app-edit-recolte',
  templateUrl: './edit-recolte.component.html',
  styleUrls: ['./edit-recolte.component.css']
})
export class EditRecolteComponent implements OnInit {
  submit: boolean;
  dateRecolte = new Date() ;
  numbers: number[];
  graine: string;
  id: any;
  stock: any[];  

  Xframes=[]
  XframTable=[]


 constructor( private datePipe: DatePipe, public _langService:LangService, public _stockService:StockService, public _recolteService:RecolteService,private route: ActivatedRoute)  
  { }

 ngOnInit(): void {
  this.route.params.subscribe(params => {
    this.id=params['id'] //log the value of id
 });
 
 this._recolteService.get_details(this.id).subscribe(res => {
  

  this.XframTable=res["Xframe"].split(',') 
  this._recolteService.initialiser(res)
 
  this.GraineChange();

  this._recolteService.get_graine_by_Recolte().subscribe(res => {

    this.graine= res['variete'] 
   })
  })

 }


   GraineChange(){
 
  this._recolteService.All_Xframes_By_graine().subscribe(res => {
       
    let t = res as String
    this.Xframes=t.split(',') 
 
   /*
    for(let i=0;i<this.Xframes.length;i++){
      if(this._recolteService.formRecolte.get("Xframes")['controls'].indexOf(this.Xframes[i])>-1)
      this.XframTable.push(this.Xframes[i])
     }
     console.log(this.XframTable)
     */

  })

}
 modifierRecolte(){
   

 
     this._recolteService.modifierRecolte(this.XframTable,this.dateRecolte).subscribe(res => {
       this.submit=true  
      })
    
 
    }

 onSubmit(){
   this.modifierRecolte();
 }

 fieldsChange(values:any):void {

  let verif=true
   if(values.currentTarget.checked){
     for(let i=0;i<this.XframTable.length;i++){
      if(this.XframTable[i]==values.currentTarget.checked)
      verif =false
    
      
     }
     if (verif)
     this.XframTable.push(values.target.defaultValue)
   }
   else 
     this.XframTable = this.XframTable.filter(obj =>  obj !== values.target.defaultValue);
    
 }

}
