import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

// layouts
import { AdminComponent } from "./layouts/admin/admin.component";
import { AuthComponent } from "./layouts/auth/auth.component";

// admin views
import { DashboardComponent } from "./views/admin/dashboard/dashboard.component";
import { AdduserComponent } from './views/adduser/adduser.component';
import { GestionuserComponent } from './views/gestionuser/gestionuser.component';

// auth views
import { LoginComponent } from "./views/auth/login/login.component";

// no layouts views
import { DetailuserComponent } from "./views/detailuser/detailuser.component";
import { EdituserComponent } from "./views/edituser/edituser.component";
import { DeleteuserComponent } from "./views/deleteuser/deleteuser.component";
import { LoginGuard } from "./login.guard";
import { LogoutGuard } from "./logout.guard";
import { AddcompetenceComponent } from "./views/addcompetence/addcompetence.component";
import { GestioncompetenceComponent } from "./views/gestioncompetence/gestioncompetence.component";
import { UpdatecompetenceComponent } from "./views/updatecompetence/updatecompetence.component";
 import { DetailcompetenceComponent } from "./views/detailcompetence/detailcompetence.component";
import { ChefComponent } from "./layouts/chef/chef.component";
import { DashbordChefComponent } from "./views/dashbord-chef/dashbord-chef.component";
import { AddprojetadminComponent } from "./views/addprojetadmin/addprojetadmin.component";
import { ListeprojetadminComponent } from "./views/listeprojetadmin/listeprojetadmin.component";
import { GanttComponent } from "./views/gantt/gantt.component";
 import { ListeprojetschefComponent } from "./views/listeprojetschef/listeprojetschef.component";
   import { EditCommandeComponent } from "./views/edit-commande/edit-commande.component";
import { ReceptionComponent } from "./views/reception/reception.component";
import { StockComponent } from "./views/stock/stock.component";
import { DeleteCommandeComponent } from "./views/delete-commande/delete-commande.component";
import { AddfournisseurComponent } from "./views/addfournisseur/addfournisseur.component";
import { GestionFournisseurComponent } from "./views/gestion-fournisseur/gestion-fournisseur.component";
import { EditFournisseurComponent } from "./views/edit-fournisseur/edit-fournisseur.component";
import { GestionTypeComponent } from "./views/gestion-type/gestion-type.component";
import { AddtypeComponent } from "./views/addtype/addtype.component";
import { EditTypeComponent } from "./views/edit-type/edit-type.component";
import { SemisComponent } from "./views/semis/semis.component";
import { EditStockComponent } from "./views/edit-stock/edit-stock.component";
import { FloatingComponent } from "./views/floating/floating.component";
import { PlantationComponent } from "./views/plantation/plantation.component";
import { AddfactureComponent } from "./views/addfacture/addfacture.component";
import { GestionfactureComponent } from "./views/gestionfacture/gestionfacture.component";
import { EditFactureComponent } from "./views/edit-facture/edit-facture.component";
import { DeleteFactureComponent } from "./views/delete-facture/delete-facture.component";
import { RecolteComponent } from "./views/recolte/recolte.component";
import { NettoyageComponent } from "./views/nettoyage/nettoyage.component";
import { BonLivraisonComponent } from "./views/bon-livraison/bon-livraison.component";
import { GestionClientComponent } from "./views/gestion-client/gestion-client.component";
import { AddclientComponent } from "./views/addclient/addclient.component";
import { EditClientComponent } from "./views/edit-client/edit-client.component";
import { AllSemisComponent } from "./views/all-semis/all-semis.component";
import { AllFloatingComponent } from "./views/all-floating/all-floating.component";
import { AllPlantationComponent } from "./views/all-plantation/all-plantation.component";
import { AllRecolteComponent } from "./views/all-recolte/all-recolte.component";
import { AllNettoyageComponent } from "./views/all-nettoyage/all-nettoyage.component";
import { AllLivraisonComponent } from "./views/all-livraison/all-livraison.component";
import { AdminGuard } from "./Admin.guard";
import { SemisAdminComponent } from "./views/semisAdmin/semis.component";
import { FloatingAdminComponent } from "./views/floatingAdmin/floating.component";
import { PlantationAdminComponent } from "./views/plantationAdmin/plantation.component";
import { RecolteAdminComponent } from "./views/recolteAdmin/recolte.component";
import { NettoyageAdminComponent } from "./views/nettoyageAdmin/nettoyage.component";
import { BonLivraisonAdminComponent } from "./views/bon-livraisonAdmin/bon-livraison.component";
import { EditSemisComponent } from "./views/edit-semis/edit-semis.component";
import { AddmousseComponent } from "./views/addmousse/addmousse.component";
import { ChecklistComponent } from "./views/checklist/checklist.component";
import { AddfertilisantComponent } from "./views/addfertilisant/addfertilisant.component";
import { FertilisantsComponent } from "./views/fertilisants/fertilisants.component";
import { AddStockfertilisantComponent } from "./views/add-stockfertilisant/add-stockfertilisant.component";
import { StockRecoltComponent } from "./views/stock-recolt/stock-recolt.component";
import { EditFertilisantComponent } from "./views/edit-fertilisant/edit-fertilisant.component";
import { AddSolutionComponent } from "./views/add-solution/add-solution.component";
import { AllSolutionComponent } from "./views/all-solution/all-solution.component";
import { DetailSolutionComponent } from "./views/detail-solution/detail-solution.component";
import { AddMesureComponent } from "./views/add-mesure/add-mesure.component";
import { HistoriqueMesureComponent } from "./views/historique-mesure/historique-mesure.component";
import { ChecklistHistoriqueComponent } from "./views/checklist-historique/checklist-historique.component";
import { ChecklistDetailComponent } from "./views/checklist-detail/checklist-detail.component";
import { ChecklistHistoriqueAdminComponent } from "./views/checklist-historique-admin/checklist-historique-admin.component";
import { ChecklistDetailAdminComponent } from "./views/checklist-detail-admin/checklist-detail-admin.component";
import { ChecklistUserComponent } from "./views/checklist-user/checklist-user.component";
import { EditFloatingComponent } from "./views/edit-floating/edit-floating.component";
import { EditRecolteComponent } from "./views/edit-recolte/edit-recolte.component";
import { EditPlantationComponent } from "./views/edit-plantation/edit-plantation.component";
 



const routes: Routes = [
  // admin views
  {
    path: "admin",
    component: AdminComponent,
    children: [
     { path: "dashboard", component: DashboardComponent , canActivate: [AdminGuard]},
     { path: "adduser", component: AdduserComponent  , canActivate: [AdminGuard]},
     { path: "gestionuser", component: GestionuserComponent  , canActivate: [AdminGuard]},
     { path: "detailuser/:id", component: DetailuserComponent  , canActivate: [AdminGuard] },
     { path: "edituser/:id", component: EdituserComponent  , canActivate: [AdminGuard]},
     { path: "deleteuser/:id", component: DeleteuserComponent , canActivate: [AdminGuard] },



    
      { path: "addGraine", component: AddcompetenceComponent  , canActivate: [AdminGuard] },
      { path: "gestionGraine", component: GestioncompetenceComponent   , canActivate: [AdminGuard]},
      { path: "detailGraine/:id", component: DetailcompetenceComponent   , canActivate: [AdminGuard]},
      { path: "updateGraine/:id", component: UpdatecompetenceComponent  , canActivate: [AdminGuard]},

      { path: "AddCommande", component: AddprojetadminComponent  , canActivate: [AdminGuard] },
      { path: "commandes", component: ListeprojetadminComponent  , canActivate: [AdminGuard]},
      { path: "editCommande/:id", component: EditCommandeComponent  , canActivate: [AdminGuard]  },
      { path: "reception/:id", component: ReceptionComponent  , canActivate: [AdminGuard] },
      { path: "deleteCommande/:id", component: DeleteCommandeComponent   , canActivate: [AdminGuard] },
      { path: "Stock", component: StockComponent  , canActivate: [AdminGuard]},
      { path: "editStock/:id", component: EditStockComponent  , canActivate: [AdminGuard]},

      ///Fournisseur
      { path: "fournisseurs", component: GestionFournisseurComponent , canActivate: [AdminGuard]},
      { path: "Ajouter_fournisseur", component: AddfournisseurComponent , canActivate: [AdminGuard]},
      { path: "editFournisseur/:id", component: EditFournisseurComponent , canActivate: [AdminGuard]},

      ///Fertilisant
      { path: "Fertilisants", component: FertilisantsComponent , canActivate: [AdminGuard]},
      { path: "Stock_Fertilisants", component: AddStockfertilisantComponent , canActivate: [AdminGuard]},
      { path: "Ajouter_fertilisant", component: AddfertilisantComponent , canActivate: [AdminGuard]},
      { path: "editFertilisant/:id", component: EditFertilisantComponent , canActivate: [AdminGuard]},

      ///Solution Nutritive
      { path: "Preparer_Solution", component: AddSolutionComponent , canActivate: [AdminGuard]},
      { path: "Historique_Solution", component: AllSolutionComponent , canActivate: [AdminGuard]},
      { path: "Detail_Solution/:id", component: DetailSolutionComponent , canActivate: [AdminGuard]},
 
      ///Client
      { path: "clients", component: GestionClientComponent , canActivate: [AdminGuard]},
      { path: "Ajouter_client", component: AddclientComponent , canActivate: [AdminGuard]},
      { path: "editclient/:id", component: EditClientComponent , canActivate: [AdminGuard]}, 
     
      ///Mesure
       { path: "Historique_Mesure", component: HistoriqueMesureComponent , canActivate: [AdminGuard]},

      ///Type
      { path: "types", component: GestionTypeComponent , canActivate: [AdminGuard]},
      { path: "Ajouter_type", component: AddtypeComponent , canActivate: [AdminGuard]},
      { path: "editType/:id", component: EditTypeComponent , canActivate: [AdminGuard]},

      ///mousse
       { path: "mousses", component: AddmousseComponent , canActivate: [AdminGuard]},
 
      //Facture
      { path: "AddFacture", component: AddfactureComponent , canActivate: [AdminGuard]},
      { path: "factures", component: GestionfactureComponent , canActivate: [AdminGuard]},
      { path: "editFacture/:id", component: EditFactureComponent , canActivate: [AdminGuard]},
      { path: "deleteFacture/:id", component: DeleteFactureComponent , canActivate: [AdminGuard]},


      //////Opération
      { path: "semis", component: SemisAdminComponent , canActivate: [AdminGuard]},
      { path: "editSemis/:id", component: EditSemisComponent , canActivate: [AdminGuard]},
      { path: "floating", component: FloatingAdminComponent , canActivate: [AdminGuard]},
      { path: "plantation", component: PlantationAdminComponent , canActivate: [AdminGuard]},
      { path: "recolte", component: RecolteAdminComponent , canActivate: [AdminGuard]},
      { path: "nettoyage", component: NettoyageAdminComponent , canActivate: [AdminGuard]},
      { path: "livraison", component: BonLivraisonAdminComponent , canActivate: [AdminGuard]},
      { path: "Liste_Semis", component: AllSemisComponent , canActivate: [AdminGuard]},
      { path: "Liste_Floating", component: AllFloatingComponent , canActivate: [AdminGuard]},
      { path: "Liste_Plantation", component: AllPlantationComponent , canActivate: [AdminGuard]},
      { path: "Liste_Recolte", component: AllRecolteComponent , canActivate: [AdminGuard]},
      { path: "Stock_Recolte", component: StockRecoltComponent , canActivate: [AdminGuard]},
      { path: "Liste_Nettoyage", component: AllNettoyageComponent , canActivate: [AdminGuard]},
      { path: "Liste_Livraison", component: AllLivraisonComponent , canActivate: [AdminGuard]},
      { path: "EditFloating/:id", component: EditFloatingComponent , canActivate: [AdminGuard]},
      { path: "EditRecolte/:id", component: EditRecolteComponent , canActivate: [AdminGuard]},
      { path: "EditPlantation/:id", component: EditPlantationComponent , canActivate: [AdminGuard]},

      

      //////Checkliste
      { path: "Check_lists", component: ChecklistHistoriqueAdminComponent , canActivate: [AdminGuard]},
      { path: "Checkliste_Detail/:id", component: ChecklistDetailAdminComponent , canActivate: [AdminGuard]},

      ///Mesure
      { path: "Ajouter_Mesure", component: AddMesureComponent , canActivate: [AdminGuard]},
      { path: "Historique_Mesure", component: HistoriqueMesureComponent , canActivate: [AdminGuard]},

///
 
{ path: "", redirectTo: "dashboard", pathMatch: "full"  },
{ path: "**", redirectTo: "dashboard", pathMatch: "full"  },      //
      //deleteCommande
       
     ],
  },
  
  {
    path: "user",
    component: ChefComponent,
    children: [
      { path: "semis", component: SemisComponent , canActivate: [LoginGuard]},
      { path: "floating", component: FloatingComponent , canActivate: [LoginGuard]},
      { path: "plantation", component: PlantationComponent , canActivate: [LoginGuard]},
      { path: "recolte", component: RecolteComponent , canActivate: [LoginGuard]},
      { path: "nettoyage", component: NettoyageComponent , canActivate: [LoginGuard]},
      { path: "livraison", component: BonLivraisonComponent , canActivate: [LoginGuard]},
      { path: "Liste_Semis", component: AllSemisComponent , canActivate: [LoginGuard]},
      { path: "Liste_Floating", component: AllFloatingComponent , canActivate: [LoginGuard]},
      { path: "Liste_Plantation", component: AllPlantationComponent , canActivate: [LoginGuard]},
      { path: "Liste_Recolte", component: AllRecolteComponent , canActivate: [LoginGuard]},
      { path: "Liste_Nettoyage", component: AllNettoyageComponent , canActivate: [LoginGuard]},
      { path: "Liste_Livraison", component: AllLivraisonComponent , canActivate: [LoginGuard]},
      { path: "checklist", component: ChecklistUserComponent , canActivate: [LoginGuard]},
      { path: "Check_lists", component: ChecklistHistoriqueComponent , canActivate: [LoginGuard]},
      { path: "Checkliste_Detail/:id", component: ChecklistDetailComponent , canActivate: [LoginGuard]},
         
      ///Mesure
      { path: "Ajouter_Mesure", component: AddMesureComponent , canActivate: [LoginGuard]},
      { path: "Historique_Mesure", component: HistoriqueMesureComponent , canActivate: [LoginGuard]},

      { path: "", redirectTo: "semis", pathMatch: "full"  },
      { path: "**", redirectTo: "semis", pathMatch: "full"  },
      
  
    ],
  },
 
 {
    path: "auth",
    component: AuthComponent,
    children: [
      { path: "login", component: LoginComponent  },
      { path: "", redirectTo: "login", pathMatch: "full" },
    ],
  },
 
  { path: "", component: LoginComponent    },
  { path: "**", component: LoginComponent   },
 ];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
