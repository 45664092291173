import { Component, Input, OnInit } from '@angular/core';
import { FertilisantService } from 'src/app/services/fertilisant.service';
import { LangService } from 'src/app/services/lang.service';

@Component({
  selector: 'app-fertilisants',
  templateUrl: './fertilisants.component.html',
  styleUrls: ['./fertilisants.component.css']
})
export class FertilisantsComponent implements OnInit {

  @Input()
  get color(): string {
    return this._color;
  }
  set color(color: string) {
    this._color = color !== "light" && color !== "dark" ? "light" : color;
  }
  private _color = "light";

  listfertilisant : any[]

  constructor(public _fertilisantService:FertilisantService, public _langService:LangService) {}

  ngOnInit(): void {
    this._fertilisantService.All_Fertilisant().subscribe(res => {
      this.listfertilisant = res as any[]
      console.log(res);
      
       
      }) 
  }
}
