import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CheckListeService } from 'src/app/services/checkListe.service';
import { LangService } from 'src/app/services/lang.service';

@Component({
  selector: 'app-checklist-detail-admin',
  templateUrl: './checklist-detail-admin.component.html',
  styleUrls: ['./checklist-detail-admin.component.css']
})
export class ChecklistDetailAdminComponent implements OnInit {
  id: any;
  check: any;
  P1_Global
  P1_1
  P1_2
  P1_3
  P2_Global
  P2_1
  P2_2
  P2_3
  Service_Global
  Service_1
  Service_2
  Service_3
  Service_4
  Service_5
  Service_6

   constructor(public _checkService:CheckListeService,private route: ActivatedRoute, public _langService:LangService) {}

  ngOnInit(): void {
  this.route.params.subscribe(params => {
       this.id=params['id'] //log the value of id
    });

    this.get_Check();
   }
   get_Check(){
    this._checkService.get_Check(this.id).subscribe(res => {
      this.check = res as any 
      if(this.check['Mode']=='P1'){

      this._checkService.get_item_Check(this.id,'Global').subscribe(res => {
      this.P1_Global= res
        })
        this._checkService.get_item_Check(this.id,'Etat des Xframes').subscribe(res => {
          this.P1_1= res
            })
            this._checkService.get_item_Check(this.id,'Etat des plantes').subscribe(res => {
              this.P1_2= res
                })
                this._checkService.get_item_Check(this.id,'Etat de la Serre').subscribe(res => {
                  this.P1_3= res
                    })
        }
        if(this.check['Mode']=='P2'){

          this._checkService.get_item_Check(this.id,'Global').subscribe(res => {
          this.P2_Global= res
            })
            this._checkService.get_item_Check(this.id,'Etat des mousses/semis').subscribe(res => {
              this.P2_1= res
               
                })
                this._checkService.get_item_Check(this.id,'Etat des tables floating').subscribe(res => {
                  this.P2_2= res
                    })
                    this._checkService.get_item_Check(this.id,'Etat de la Serre').subscribe(res => {
                      this.P2_3= res
                        })
        }
        if(this.check['Mode']=='Service'){

          this._checkService.get_item_Check(this.id,'Global').subscribe(res => {
          this.Service_Global= res
            })
            this._checkService.get_item_Check(this.id,'Cuves drainage').subscribe(res => {
              this.Service_1= res
                })
                this._checkService.get_item_Check(this.id,'Local Technique A').subscribe(res => {
                  this.Service_2= res
                    })
                    this._checkService.get_item_Check(this.id,'Local Technique B').subscribe(res => {
                      this.Service_3= res
                        })
                        this._checkService.get_item_Check(this.id,'Local compresseur').subscribe(res => {
                          this.Service_4= res
                            })
                            this._checkService.get_item_Check(this.id,'Serres P1').subscribe(res => {
                              this.Service_5= res
                                })
                                this._checkService.get_item_Check(this.id,'Serres P2').subscribe(res => {
                                  this.Service_6= res
                                    })
        }


      })
    }

}
