import { Component, OnInit } from '@angular/core';
import { Utilisateur } from 'src/app/models/utilisateur/utilisateur.model';
import { LangService } from 'src/app/services/lang.service';
import { UtilisateurService } from 'src/app/services/utilisateur.service';

@Component({
  selector: 'app-adduser',
  templateUrl: './adduser.component.html',
  styleUrls: ['./adduser.component.css']
})
export class AdduserComponent implements OnInit {
  submit=false;
  invalide=false;

  constructor(public _utilisateurService:UtilisateurService, public _langService:LangService) { }

  ngOnInit(): void {
    this._utilisateurService.initialiserVide()

  }


  
  AddUtilisateur(){
    this._utilisateurService.insertUtilisateur().subscribe(res => {

      let test =res as Utilisateur;
      this._utilisateurService.Add_permission(this._utilisateurService.formUtilisateur.value['email']).subscribe(res => {
      })
     if (test != null) {
        this._utilisateurService.All_utilisateur().subscribe(res => {
  
        this._utilisateurService.listUtilisateur = res as Utilisateur[]
        this.submit=true

        })
     }
   },
    err => {
       console.log(err)
           }
   )
  }

  onSubmit(){
    this.AddUtilisateur();
    if(this.submit==false)
    this.invalide=true
    else
    this.invalide=false
    console.log('test')
    
    

  }

}
