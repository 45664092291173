import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Graine } from 'src/app/models/Graine/graine.model';
import { Projet } from 'src/app/models/Projet/Projet.model';
import { Utilisateur } from 'src/app/models/utilisateur/utilisateur.model';
import { CompetenceService } from 'src/app/services/competence.service';
import { LangService } from 'src/app/services/lang.service';
import { ProjetService } from 'src/app/services/projet.service';
import { UtilisateurService } from 'src/app/services/utilisateur.service';

@Component({
  selector: 'app-edit-commande',
  templateUrl: './edit-commande.component.html',
  styleUrls: ['./edit-commande.component.css']
})
export class EditCommandeComponent implements OnInit {
  Vide=true
  dateCommande = new Date();
  dateLivraison = new Date();
  myDate2 = new Date();
  submit: boolean;
  id: any;
  @Input()
  get color(): string {
    return this._color;
  }
  set color(color: string) {
    this._color = color !== "light" && color !== "dark" ? "light" : color;
  }
  private _color = "dark";
  
  constructor(public _graineService:CompetenceService, public _langService:LangService,public _projetService:ProjetService,private route: ActivatedRoute) { }

  ngOnInit(): void {
//getNewProjet
//  All_Competence()
this._graineService.All_Competence().subscribe(res => {
  this._graineService.listCompetence=res  as  Graine[];
  })
  this.route.params.subscribe(params => {
    this.id=params['id'] //log the value of id
 });
  this._projetService.get_Projet(this.id).subscribe(res => {
    this._projetService.initialiser(res)
     
    })
 
  }

  AddProjet(){
 
    
    
    this._projetService.modifierProjet(this.dateCommande,this.dateLivraison).subscribe(res => {

      let test =res as  Projet;
     if (test != null) {
        this._projetService.All_Projet().subscribe(res => {
        this._projetService.listProjet = res as Projet[]
        this.submit=true
        })
     }
   },
    err => {
       console.log(err)
           }
   )
  }

  onSubmit(){
    this.AddProjet();
    console.log('test')
    
    

  }
}
