<div  
>
  <app-sidebar-chef></app-sidebar-chef>
  <div class="relative md:ml-64 bg-blueGray-100">
    <app-chef-navbar></app-chef-navbar>
  <app-header-chef></app-header-chef>
    <div class="px-4 md:px-10 mx-auto w-full -m-24">
      <router-outlet></router-outlet>
    <!--  <app-footer-admin></app-footer-admin>-->
    </div>
  </div>
</div>
