<a
  class="text-white block py-1 px-3"
 
  (click)="toggleDropdown($event)"
  #btnDropdownRef
>
  <i class="fas fa-ellipsis-v"></i>
</a>
<div class="min-w-48 z-50" #popoverDropdownRef>
  <div
    class="bg-white text-base float-left py-2 list-none text-left rounded shadow-lg mt-1 min-w-48"
    [ngClass]="dropdownPopoverShow ? 'block' : 'hidden'"
  >
    <a
      class="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700"

      [routerLink]= "['/chef/gestion_equipe',id_projet]"
    >
     Gestion d'equipe
    </a>
    <a
    class="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700"

    [routerLink]= "['/chef/gestion_module',id_projet]"
  >
  Gestion module
  </a>
    <a
    class="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700"

    [routerLink]= "['/chef/datailprojet',id_projet]"
  >
    Diagramme Gantt
  </a>
  </div>
</div>
