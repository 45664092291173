import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { LangService } from 'src/app/services/lang.service';
import { NettoyageService } from 'src/app/services/nettoyage.service';

@Component({
  selector: 'app-nettoyage',
  templateUrl: './nettoyage.component.html',
  styleUrls: ['./nettoyage.component.css']
})
export class NettoyageComponent implements OnInit {
  submit: boolean;
  Nettoyages: any[];
  dateNettoyage ;

 constructor( private datePipe: DatePipe, public _langService:LangService, public _nettoyageService:NettoyageService) { }
  ngOnInit(): void {
    this._nettoyageService.initialiserVide()

    this.dateNettoyage=this.datePipe.transform( new Date(), 'yyyy-MM-dd')

 
 }


 AddNettoyage(){
 
    
       this._nettoyageService.AddNettoyage(this.dateNettoyage).subscribe(res => {
       this.submit=true
 
      })
 

 }

 onSubmit(){
   this.AddNettoyage();
 }
}
