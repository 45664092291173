import { Component, OnInit } from '@angular/core';
import { LangService } from 'src/app/services/lang.service';

@Component({
  selector: 'app-chef-navbar',
  templateUrl: './chef-navbar.component.html',
 })
export class ChefNavbarComponent implements OnInit {

  constructor(public _langService:LangService) { }

  ngOnInit(): void {
  }

}
