import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
 
@Injectable({
  providedIn: 'root',
})
export class AuthService {
 
  constructor(private http: HttpClient , private router: Router) {}

  login(identifiants) {
    return this.http.post('https://seeds.bg-agri.com/babylone_API/Admins/login.php?email='+ identifiants.email
    + '&password='+identifiants.password
    ,JSON.stringify('')
    );  
  }
  loginUser(identifiants) {
    return this.http.post('https://seeds.bg-agri.com/babylone_API/Utilisateurs/login.php?email='+identifiants.email
    + '&password='+identifiants.password
    ,JSON.stringify('')
    );  
  }
  logout() {
    localStorage.removeItem('privilege');
    localStorage.removeItem('log');
    this.router.navigate(['/auth/login']);
  }

  estConnecte() {
    let token = localStorage.getItem('privilege');
    return !!token;
  }
}