import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from 'src/environments/environment';
import { FormBuilder, Validators } from '@angular/forms';
import { Graine } from '../models/Graine/graine.model';
import { UserCompetence } from '../models/Affectation/userCompetence.model';

@Injectable({
  providedIn: 'root' 
})
export class CompetenceService {
 
  formCompetence=this.fb.group({
    id_graine : ["" , Validators.required],
    variete : ["" , Validators.required],
	  fournisseur : ["" , Validators.required],
	  enrobee : ["" , Validators.required],
	  type : ["" , Validators.required],
	  saison  : ["" , Validators.required],
	  historique : ["" , Validators.required],
	  commentaire : ["" , Validators.required],
  });


   listCompetence:Graine[]
  constructor(private http: HttpClient, private fb: FormBuilder) {
    }


  insertCompetence() {
      return this.http
    .post(
      "https://seeds.bg-agri.com/babylone_API/Graines" + "/save.php?fournisseur="+
      this.formCompetence.value['fournisseur']
      +"&enrobee="+this.formCompetence.value['enrobee']
      +"&type="+this.formCompetence.value['type']
      +"&saison="+this.formCompetence.value['saison']
      +"&historique="+this.formCompetence.value['historique']
      +"&commentaire="+this.formCompetence.value['commentaire']
      +"&variete="+this.formCompetence.value['variete']
      ,JSON.stringify(''))
  }

  All_Competence() {
     return this.http.get('https://seeds.bg-agri.com/babylone_API/Graines' + "/all.php" );
  }

  get_Competence(id:any) {
    return this.http.get('https://seeds.bg-agri.com/babylone_API/Graines' + "/get.php?id_graine="+id);
  }
  
  get_details(id:any) {
    return this.http.get('https://seeds.bg-agri.com/babylone_API/Graines' + "/details.php?id_graine="+id);
  }


  initialiser(ev){
 this.formCompetence.setValue(ev)
  }
  initialiserVide(){
    this.formCompetence.setValue({
      id_graine :" ",
      variete :" ",
      fournisseur :" ",
      enrobee :" ",
      type :" ",
      saison  :" ",
      historique :" ",
      commentaire :" ",

    })
     }
  modifierCompetence( ) {
    return this.http
  .post(
    'https://seeds.bg-agri.com/babylone_API/Graines' + "/update.php?fournisseur="+
    this.formCompetence.value['fournisseur']
    +"&enrobee="+this.formCompetence.value['enrobee']
    +"&type="+this.formCompetence.value['type']
    +"&saison="+this.formCompetence.value['saison']
    +"&historique="+this.formCompetence.value['historique']
    +"&commentaire="+this.formCompetence.value['commentaire']
    +"&variete="+this.formCompetence.value['variete']
    +"&id_graine="+this.formCompetence.value['id_graine']
    ,JSON.stringify(''))
  }

  delete_Competence(id:any) {
    return this.http.get('https://seeds.bg-agri.com/babylone_API/Graines' + "/delete.php?id_graine="+id);
  }

   
}
