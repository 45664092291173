import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from 'src/environments/environment';
import { FormBuilder, Validators } from '@angular/forms';
  
@Injectable({
  providedIn: 'root' 
})
export class CheckListeService {
 
  formCheck=this.fb.group({
     id_checkListe : ["" , Validators.required],
    date : ["" , Validators.required],
    heure : ["" , Validators.required],
    Mode : ["" , Validators.required],
    etat : ["" , Validators.required],
    commentaire : ["" , Validators.required],
  });

 //`id_checkListe`, `date`, `heure`, `Mode`
  initialiserVide(){
    this.formCheck.setValue({
       id_checkListe : ""  ,
      date : ""  ,
      heure : ""  ,
      Mode : ""  ,
      etat  : ""  ,
      commentaire : ""  ,
    })
  }

   listCheck:any[]
  constructor(private http: HttpClient, private fb: FormBuilder) {
    }


  insertCheck(date,heure,Mode,operateur) {
      return this.http
    .post(
      "https://seeds.bg-agri.com/babylone_API/CheckList/save.php?date="
      +date
      +"&heure="+heure
      +"&Mode="+Mode
      +"&operateur="+operateur
      +"&commentaire="+this.formCheck.value['commentaire']
      
      ,JSON.stringify(''))
  }
  //this.P18H1[i].title,this.P18H1[i].checked,res,'Etat des Xframes'
  //id_checkListe
  
  insertCheck_Item(title,etat,id_checkListe,groupe) {
     
    return this.http
  .post(
    "https://seeds.bg-agri.com/babylone_API/CheckList" + "/save_item.php?id_checkListe="
    +id_checkListe
    +"&etat="+etat
    +"&title="+title 
    +"&groupe="+groupe 
    ,JSON.stringify(''))
}

  All_Check() {
     return this.http.get("https://seeds.bg-agri.com/babylone_API/CheckList" + "/all.php" );
  }

  get_Check(id:any) {
    return this.http.get("https://seeds.bg-agri.com/babylone_API/CheckList" + "/get.php?id_checkliste="+id);
  }

  get_item_Check(id:any,groupe) {
    return this.http.get("https://seeds.bg-agri.com/babylone_API/CheckList" + "/all_item_for_checklist.php?id_checkliste="+id
    +'&groupe='+groupe
    
    );
  }

  initialiser(ev){
 this.formCheck.setValue(ev)
  }

  modifierCheck(date,heure,Mode) {
    return this.http
  .post(
    "https://seeds.bg-agri.com/babylone_API/CheckList" + "/update.php?id_checkListe="+
    this.formCheck.value['id_checkListe']
     +"&date="+date
     +"&heure="+heure
     +"&Mode="+Mode
    +"&commentaire="+this.formCheck.value['commentaire']
   
    ,JSON.stringify(''))
  }

  delete_Check(id:any) {
    return this.http.get("https://seeds.bg-agri.com/babylone_API/CheckList" + "/delete.php?id_checkListe="+id);
  }

   
}
