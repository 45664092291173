import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { BonLivraisonService } from 'src/app/services/bonLivraison.service';
import { ClientService } from 'src/app/services/client.service';
import { LangService } from 'src/app/services/lang.service';
import { RecolteService } from 'src/app/services/recolte.service';

@Component({
  selector: 'app-bon-livraison',
  templateUrl: './bon-livraison.component.html',
  styleUrls: ['./bon-livraison.component.css']
})
export class BonLivraisonAdminComponent implements OnInit {
  recoltes: any[];
  date ;
  echeance ;
  clients : any[];
  submit: boolean;
  id_BonLivraison: String;
  num_facture: any;
  constructor(private datePipe: DatePipe, public _langService:LangService, public _clientService:ClientService, public _bonLivraisonService:BonLivraisonService,public _recolteService:RecolteService) { }

  ngOnInit(): void {
    this.date=this.datePipe.transform( new Date(), 'yyyy-MM-dd')
    this.echeance=this.datePipe.transform( new Date(), 'yyyy-MM-dd')
    this.addRow()
    this._recolteService.All_recolte_for_Livraison().subscribe(res => {
      this.recoltes = res as any[]
      })
      this._clientService.All_Client().subscribe(res => {
        this.clients = res as any[]
        })
  }
  dynamicArray = [];
  newDynamic;
  addRow() {
    this.dynamicArray.push({ Code: '' , Quantity: '' , Poids: '' , PU: '' });
    console.log('New row added successfully', 'New Row');
  }
  deleteRow(index) {
    this.dynamicArray.splice(index, 1);
  }
  getValues() {
    console.log(this.dynamicArray);
  }


  
 AddPlantation(){
 
   console.log(this.dynamicArray);
   
     this._bonLivraisonService.AddBonLivraison(this.date,this.echeance).subscribe(res => {
      console.log(res);

      this.id_BonLivraison=res as String 
      
      this.submit=true
      for(let i=0;i< this.dynamicArray.length;i++){
        this._bonLivraisonService.AddArticle(this.dynamicArray[i],res).subscribe(article => {
        })
      }
      this._bonLivraisonService.Add_PDF_BonLivraison().subscribe(article => {
      })
      this._bonLivraisonService.Add_PDF_Facture().subscribe(article => {
      })      
      this._bonLivraisonService.Add_PDF_Etiquette().subscribe(article => {
      })
    this._recolteService.All_recolte_for_Livraison().subscribe(res => {
     this.recoltes = res as any[]
     })
     })
     
     

}

onSubmit(){
  this.AddPlantation();
}
clientChange(id_client){
   
    this._clientService.get_Client(id_client).subscribe(res => {
    console.log(res);
    
    this._bonLivraisonService.initialiserClientInfo(res)
     console.log(res);
     
    })
}

}
