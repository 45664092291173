import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from 'src/environments/environment';
import { FormBuilder, Validators } from '@angular/forms';
import { DatePipe } from '@angular/common';
  
@Injectable({
  providedIn: 'root' 
})
export class SolutionService {
  nbr_cuve1=0
  formSolution=this.fb.group({
    id_solution : ["" , Validators.required],
    date : ["" , Validators.required],
    operateur : ["" , Validators.required],
 
  });
  formSolution_Fertisant=this.fb.group({
    id_solution_fertisant : ["" , Validators.required],
    id_solution : ["" , Validators.required],
    num_cuve : ["" , Validators.required],
    id_fertisant : ["" , Validators.required],
    quantity : ["" , Validators.required],
    nbr_cuve1 :  "" ,
    nbr_cuve2 :  "" ,
    nbr_cuve3 :  "" ,
    nbr_cuve4 :  "" ,
    nbr_cuve5 :  "" ,
    nbr_cuve6 :  "" ,
    eau1  :  "" ,
    eau2  :  "" ,
    eau3  :  "" ,
    eau4  :  "" ,
    eau5  :  "" ,
    eau6  :  "" ,
  });
     
  initialiserVide(){
    this.formSolution.setValue({
      id_solution :  ""  ,
      date :  ""  ,
      operateur :  ""  ,
 
    })

    this.formSolution_Fertisant.setValue({
      id_solution_fertisant :  "" ,
      id_solution : "" ,
      num_cuve :  "" ,
      id_fertisant :  "" ,
      quantity : "" ,
      nbr_cuve1 :  "" ,
      nbr_cuve2 :  "" ,
      nbr_cuve3 :  "" ,
      nbr_cuve4 :  "" ,
      nbr_cuve5 :  "" ,
      nbr_cuve6 :  "" ,
      eau1  :  "" ,
      eau2  :  "" ,
      eau3  :  "" ,
      eau4  :  "" ,
      eau5  :  "" ,
      eau6  :  "" ,
    })
     }


   listSolution:any[]
  constructor(private datePipe: DatePipe,private http: HttpClient, private fb: FormBuilder) {
    }


  insertSolution(date) {
      return this.http
    .post(
      "https://seeds.bg-agri.com/babylone_API/Solution" + "/save.php?date="+
      
      this.datePipe.transform( date , 'dd/MM/yyyy')
      +"&operateur="+this.formSolution.value['operateur']
      ,JSON.stringify(''))
  }

///////////////
  insertSolution_Fertilisant(id_solution,id_fertilisant,num_cuve,quantity) {
    return this.http
  .post(
    "https://seeds.bg-agri.com/babylone_API/Solution" + "/save_solution_fertilisant.php?id_solution="+
    +id_solution
    +"&id_fertilisant="+id_fertilisant
    +"&num_cuve="+num_cuve
    +"&quantity="+quantity
    ,JSON.stringify(''))
}
///////////////////Eau
insertSolution_Eau(id_solution,num_cuve,quantity) {
  return this.http
.post(
  "https://seeds.bg-agri.com/babylone_API/Solution" + "/save_solution_eau.php?id_solution="+
  +id_solution
   +"&num_cuve="+num_cuve
  +"&quantity="+quantity
  ,JSON.stringify(''))
}

  All_Solution() {
     return this.http.get("https://seeds.bg-agri.com/babylone_API/Solution" + "/all.php" );
  }

  get_Solution(id:any) {
    return this.http.get("https://seeds.bg-agri.com/babylone_API/Solution" + "/get.php?id_solution="+id);
  }

  get_Solution_Fertilisant(num_cuve,id_solution){
    return this.http.get("https://seeds.bg-agri.com/babylone_API/Solution" + "/get_Solution_Fertilisant.php?num_cuve="+num_cuve
    +"&id_solution="+id_solution

    );

  }
  get_Solution_Eau(num_cuve,id_solution){
    return this.http.get("https://seeds.bg-agri.com/babylone_API/Solution" + "/get_Solution_Eau.php?num_cuve="+num_cuve
    +"&id_solution="+id_solution

    );

  }
  initialiser(ev){
 this.formSolution.setValue(ev)
  }

  modifierSolution( ) {
    return this.http
  .post(
    "https://seeds.bg-agri.com/babylone_API/Solution" + "/update.php?id_solution="+
    this.formSolution.value['id_solution']
    +"&label="+this.formSolution.value['label']
    +"&quantity="+this.formSolution.value['quantity']
    +"&mesure="+this.formSolution.value['mesure']
   
    ,JSON.stringify(''))
  }
  add_to_stock( ) {
    return this.http
  .post(
    "https://seeds.bg-agri.com/babylone_API/Solution" + "/ajouter_au_stock.php?id_solution="+
    this.formSolution.value['id_solution']
    +"&quantity="+this.formSolution.value['quantity']
    
    ,JSON.stringify(''))
  }

  delete_Solution(id:any) {
    return this.http.get("https://seeds.bg-agri.com/babylone_API/Solution" + "/delete.php?id_solution="+id);
  }

   
}
