import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FloatingService } from 'src/app/services/floating.service';
import { LangService } from 'src/app/services/lang.service';
import { SemisService } from 'src/app/services/semis.service';
import { StockService } from 'src/app/services/stock.service';
 

@Component({
  selector: 'app-edit-floating',
  templateUrl: './edit-floating.component.html',
  styleUrls: ['./edit-floating.component.css']
})
export class EditFloatingComponent implements OnInit {
  submit: boolean;
  dateFloating ;
  numbers: number[];
  graine: string;
  id: any;
  stock: any[];  
  OldNbrGraine=0;


 constructor( private datePipe: DatePipe, public _langService:LangService, public _stockService:StockService, public _semisService:SemisService, public _floatingService:FloatingService,private route: ActivatedRoute)  
  { }

 ngOnInit(): void {
  this.route.params.subscribe(params => {
    this.id=params['id'] //log the value of id
 });
 
 this._floatingService.get_details(this.id).subscribe(res => {
  
  this._floatingService.initialiser(res)
  this.OldNbrGraine=Number(res["nbr_graines"]);
  this._floatingService.get_graine_by_floating().subscribe(res => {

    this.graine= res['variete'] 
   })
  })

 
 }


 modifierfloating(){
  this._semisService.get_Semis(this._floatingService.formFloating.value['unique_id']).subscribe(res => {
   if((Number(res["nbr_disponible"])+this.OldNbrGraine)>=Number(this._floatingService.formFloating.controls['nbr_graines'].value))
     this._floatingService.modifierFloating(this.dateFloating).subscribe(res => {
       this.submit=true  
        this._floatingService.modifierSemisAvailable(Number(this._floatingService.formFloating.controls['nbr_graines'].value)-this.OldNbrGraine).subscribe(res => {
 
       })
      })
      else {
        if(this._langService.GetLanguage()=="FR")
        alert('Le nombre de graine est supérieur à ce qui est disponible en stock!')
        else {
          alert('The number of seeds is higher than what is available in stock!')
  
        }
       }
    })
 
    }

 onSubmit(){
   this.modifierfloating();
 }
}
