import { Component, Input, OnInit } from '@angular/core';
import { FactureService } from 'src/app/services/facture.service';
import { LangService } from 'src/app/services/lang.service';

@Component({
  selector: 'app-gestionfacture',
  templateUrl: './gestionfacture.component.html',
  styleUrls: ['./gestionfacture.component.css']
})
export class GestionfactureComponent implements OnInit {
  @Input()
  get color(): string {
    return this._color;
  }
  set color(color: string) {
    this._color = color !== "light" && color !== "dark" ? "light" : color;
  }
  private _color = "light";

  listfacture : any[]

  constructor(public _factureService:FactureService, public _langService:LangService) {}

  ngOnInit(): void {
   this.Listefactures();
  }

  Listefactures(){
    this._factureService.All_Facture().subscribe(res => {
      this.listfacture = res as any[]
      console.log(res);
      
      })
  }
  
   ///////////

 isDesc: boolean = false;
 column: string = 'CategoryName';
  
  
 sort(property) {
   this.isDesc = !this.isDesc; //change the direction    
   this.column = property;
   let direction = this.isDesc ? 1 : -1;
   if(property!='echeance_paymant' && property!='date_facture'  )
   this.listfacture.sort(function (a, b) {
     if (a[property] < b[property]) {
       return -1 * direction;
     }
     else if (a[property] > b[property]) {
       return 1 * direction;
     }
     else {
       return 0;
     }
   });
   else if(property=='date_facture' || property=='echeance_paymant' )
    this.listfacture.sort(function (a, b) {
      let  date1=a[property]
      let  date2=b[property]
      let date1Number= (Number(date1.substring(0,2))*24) + (Number(date1.substring(3,5))*24*30) +  (Number(date1.substring(6,10))*24*30*365)
      let date2Number= (Number(date2.substring(0,2))*24) + (Number(date2.substring(3,5))*24*30) +  (Number(date2.substring(6,10))*24*30*365)
         
      if ( date1Number <  date2Number ){
      return -1 * direction;
    }
    else if ( date1Number >  date2Number ) {
      return 1 * direction;
    }
    else {
      return 0;
    }
  });
 
 };
 
 //////////////

}
