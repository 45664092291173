import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
 import { environment } from 'src/environments/environment';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { DatePipe } from '@angular/common';

@Injectable({
  providedIn: 'root' 
})
export class FactureService {
   formFacture=this.fb.group({
    id_facture : ["" , Validators.required],
	  fournisseur :  ["" , Validators.required],
	  date_facture : ["" , Validators.required],
    echeance_paymant :  ""  ,
	  fichierPDF : ["" , Validators.required],
	  etat : ["" , Validators.required],
   });
   listFacture:any[]
 
  constructor(private datePipe: DatePipe,private http: HttpClient, private fb: FormBuilder) {

   }

   initialiserVide(){
    this.formFacture.setValue({
      id_facture :  ""  ,
      fournisseur :  ""  ,
      date_facture :  ""  ,
      echeance_paymant :  ""  ,
      fichierPDF :  ""  ,
      etat :  ""  ,

    })
     }

  insertFacture( date_facture , echeance_paymant ,pdf) {
      return this.http
    .post(
      "https://seeds.bg-agri.com/babylone_API/Factures" + "/save.php?date_facture="+
    this.datePipe.transform( date_facture, 'dd/MM/yyyy')
      
      +"&echeance_paymant="+  this.datePipe.transform( echeance_paymant, 'dd/MM/yyyy') 
      +"&id_facture="+this.formFacture.value['id_facture']
      +"&fournisseur="+this.formFacture.value['fournisseur']
      +"&fichierPDF="+pdf
      +"&etat="+this.formFacture.value['etat']
 
      ,JSON.stringify(''))
 
  }

  All_Facture() {
 

     return this.http.get("https://seeds.bg-agri.com/babylone_API/Factures" + "/all.php" );
  }

 

  get_Facture(id:any) {
    return this.http.get("https://seeds.bg-agri.com/babylone_API/Factures" + "/get.php?id_facture="+id);
  }
   

  get_Details_facture(id:any) {
    return this.http.get("https://seeds.bg-agri.com/babylone_API/Factures" + "/details.php?id_facture="+id);
  }


  //get_Details_facture
  initialiser(ev){

    let date_facture=ev.date_facture.substring(6,4) +'/'+  ev.date_facture.substring(2,2) + '/'+ ev.date_facture.substring(0,2)
    let echeance_paymant=ev.echeance_paymant.substring(6,4) +'/'+  ev.echeance_paymant.substring(2,2) + '/'+ ev.echeance_paymant.substring(0,2)
    ev.date_facture=this.datePipe.transform( date_facture, 'yyyy-MM-dd') 
    ev.echeance_paymant=this.datePipe.transform( echeance_paymant, 'yyyy-MM-dd') 
    this.formFacture.setValue(ev)
  
  }

  modifierFacture( date_facture , echeance_paymant) {
     
    return this.http
  .post(
    "https://seeds.bg-agri.com/babylone_API/Factures" + "/update.php?date_facture="+
    this.datePipe.transform( date_facture, 'dd/MM/yyyy')
      
      +"&echeance_paymant="+  this.datePipe.transform( echeance_paymant, 'dd/MM/yyyy') 
      +"&id_facture="+this.formFacture.value['id_facture']
      +"&fournisseur="+this.formFacture.value['fournisseur']
      +"&fichierPDF="+this.formFacture.value['fichierPDF']
      +"&etat="+this.formFacture.value['etat']

    ,JSON.stringify(''))
  }

  delete_facture(id:any) {
    return this.http.get("https://seeds.bg-agri.com/babylone_API/Factures" + "/delete.php?id_facture="+id);
  }

 
 
 
 
}
