import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { LangService } from 'src/app/services/lang.service';
import { PlantationService } from 'src/app/services/plantation.service';
import { RecolteService } from 'src/app/services/recolte.service';
  

@Component({
  selector: 'app-recolte',
  templateUrl: './recolte.component.html',
  styleUrls: ['./recolte.component.css']
})
export class RecolteAdminComponent implements OnInit {
  submit: boolean;
  plantations: any[];
  dateRecolte  ;
  Xframes
  XframTable=[]
 constructor(private datePipe: DatePipe, public _langService:LangService, public _plantationService:PlantationService,public _recolteService:RecolteService) { }
  ngOnInit(): void {
    this._recolteService.initialiserVide()


    this.dateRecolte=this.datePipe.transform( new Date(), 'yyyy-MM-dd')

     this._plantationService.All_plantation_for_recolte().subscribe(res => {
       this.plantations = res as any[]
       
       
       })
 }

 GraineChange(){
 
      this._recolteService.All_Xframes_By_graine().subscribe(res => {
           
        let t = res as String
        this.Xframes=t.split(',') 

      })
 
 }
 AddPlantation(){
   console.log(this._recolteService.formRecolte.value['id_plantation']);
   
   this._plantationService.get_Plantation_Test(this._recolteService.formRecolte.value['id_plantation']).subscribe(res => {
   
     if(Number(res['nbr_disponible']) >= Number(this._recolteService.formRecolte.value['nbr_graines']) )
      this._recolteService.AddRecolte(this.dateRecolte).subscribe(res => {
       this.submit=true
       
     this._plantationService.All_plantation_for_recolte().subscribe(res => {
      this.plantations = res as any[]
      })
      })
      else 
      alert('Le nombre de graine est supérieur à ce qui est disponible en stock!')
     },error  => {
      })

 }

 onSubmit(){
  for(let i=0;i<this.XframTable.length;i++){
    this._recolteService.initialiserToXframe(this.XframTable[i]) 
   }
 
   this.AddPlantation();
 }

 
 fieldsChange(values:any):void {

   let verif=true
    if(values.currentTarget.checked){
      for(let i=0;i<this.XframTable.length;i++){
       if(this.XframTable[i]==values.currentTarget.checked)
       verif =false
       
      }
      if (verif)
      this.XframTable.push(values.target.defaultValue)
    }
    else 
      this.XframTable = this.XframTable.filter(obj =>  obj !== values.target.defaultValue);

  }
  
}
