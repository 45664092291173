<main class="profile-page" style="margin-top: 10%">
 <style>
 
 </style>
    <section class="relative py-16  ">
      <div class="container mx-auto px-4">
        <div
          class="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-xl rounded-lg -mt-64"
          style="min-height: 300px;">
          <div class="px-6">
            <div class="flex flex-wrap justify-center">
              <div class="w-full lg:w-3/12 px-4 lg:order-2 flex justify-center">
                <div class="relative">
              
                </div>
              </div>
              <div
                class="w-full lg:w-4/12 px-4 lg:order-3 lg:text-right lg:self-center"
              >
          
              </div>
            
            </div>
            <div class="text-center mt-12">
              <h5
                class="text-2xl font-semibold leading-normal mb-2 text-blueGray-700 mb-2"
              >
              Check-list  {{check.Mode}}  : du {{check.date}}  à  {{check.heure}} 
              </h5>
              <div
                class="text-2xl leading-normal mt-0 mb-2 text-blueGray-400 font-bold uppercase"
              >
              {{ _langService.GetLanguage()=='FR' ? 'Utilisateur: ' : 'User: ' }}
              
              {{check.operateur}}
              </div>

            </div>
                <!--------------------- P1   -------------->
                <div
                class="text-sm leading-normal mt-0 mb-2 text-blueGray-400 font-bold"  *ngIf="check.Mode=='P1'"  >
          
                
              <div class="card"  >
                <br>
                <div class="container">
                    <ul>
                         <li *ngFor="let item of P1_Global">
                            <span *ngIf="item.etat=='true'" style="color:rgb(62, 194, 62) ;">
                            <i class="fa fa-check" aria-hidden="true"></i>  {{item.title}}
                            </span>
                            <span *ngIf="item.etat=='false'" style="color:rgb(198, 33, 33) ;">
                                <i class="fa fa-times" aria-hidden="true"></i>  {{item.title}}
                                </span>
                          </li>
                        </ul>
     
    
                  
                  </div>
                  <br>

                  <div class="container">
                    <h3><b>
                      {{ _langService.GetLanguage()=='FR' ? 'État des Xframes' : 'Status of Xframes' }}
                      
                    </b></h3> 
                    <ul>
                        <li *ngFor="let item of P1_1">
                           <span *ngIf="item.etat=='true'" style="color:rgb(62, 194, 62) ;">
                           <i class="fa fa-check" aria-hidden="true"></i>  {{item.title}}
                           </span>
                           <span *ngIf="item.etat=='false'" style="color:rgb(198, 33, 33) ;">
                               <i class="fa fa-times" aria-hidden="true"></i>  {{item.title}}
                               </span>
                         </li>
                       </ul>
                  </div>
                  <br>

                  <div class="container">
                    <h3><b>
                      {{ _langService.GetLanguage()=='FR' ? 'État des plantes' : 'Plant condition' }}
                      
                    </b></h3> 
                    <ul>
                        <li *ngFor="let item of P1_2">
                           <span *ngIf="item.etat=='true'" style="color:rgb(62, 194, 62) ;">
                           <i class="fa fa-check" aria-hidden="true"></i>  {{item.title}}
                           </span>
                           <span *ngIf="item.etat=='false'" style="color:rgb(198, 33, 33) ;">
                               <i class="fa fa-times" aria-hidden="true"></i>  {{item.title}}
                               </span>
                         </li>
                       </ul>
                  </div>
                  <br>

                  <div class="container">
                    <h3><b>
                      {{ _langService.GetLanguage()=='FR' ? 'État de la Serre' : 'State of the Greenhouse' }}
                      </b></h3> 
                    <ul>
                        <li *ngFor="let item of P1_3">
                           <span *ngIf="item.etat=='true'" style="color:rgb(62, 194, 62) ;">
                           <i class="fa fa-check" aria-hidden="true"></i>  {{item.title}}
                           </span>
                           <span *ngIf="item.etat=='false'" style="color:rgb(198, 33, 33) ;">
                               <i class="fa fa-times" aria-hidden="true"></i>  {{item.title}}
                               </span>
                         </li>
                       </ul>
                  </div>


                </div>      
               
              </div>
                      <!--------------------- P2   -------------->
                      <div
                      class="text-sm leading-normal mt-0 mb-2 text-blueGray-400 font-bold"  *ngIf="check.Mode=='P2'"  >
                
                      
                    <div class="card"  >
                      <br>
                      <div class="container">
                          <ul>
                               <li *ngFor="let item of P2_Global">
                                  <span *ngIf="item.etat=='true'" style="color:rgb(62, 194, 62) ;">
                                  <i class="fa fa-check" aria-hidden="true"></i>  {{item.title}}
                                  </span>
                                  <span *ngIf="item.etat=='false'" style="color:rgb(198, 33, 33) ;">
                                      <i class="fa fa-times" aria-hidden="true"></i>  {{item.title}}
                                      </span>
                                </li>
                              </ul>
           
          
                        
                        </div>
                        <br>
      
                        <div class="container">
                          <h3><b>
                            {{ _langService.GetLanguage()=='FR' ? 'État des mousses/semis' : 'Condition of moss/seedlings' }}
                             </b></h3> 
                          <ul>
                              <li *ngFor="let item of P2_1">
                                 <span *ngIf="item.etat=='true'" style="color:rgb(62, 194, 62) ;">
                                 <i class="fa fa-check" aria-hidden="true"></i>  {{item.title}}
                                 </span>
                                 <span *ngIf="item.etat=='false'" style="color:rgb(198, 33, 33) ;">
                                     <i class="fa fa-times" aria-hidden="true"></i>  {{item.title}}
                                     </span>
                               </li>
                             </ul>
                        </div>
                        <br>
      
                        <div class="container">
                          <h3><b>
                            {{ _langService.GetLanguage()=='FR' ? 'État des tables Transplantations' : 'Status of Transplantations tables' }}
                            </b></h3> 
                          <ul>
                              <li *ngFor="let item of P2_2">
                                 <span *ngIf="item.etat=='true'" style="color:rgb(62, 194, 62) ;">
                                 <i class="fa fa-check" aria-hidden="true"></i>  {{item.title}}
                                 </span>
                                 <span *ngIf="item.etat=='false'" style="color:rgb(198, 33, 33) ;">
                                     <i class="fa fa-times" aria-hidden="true"></i>  {{item.title}}
                                     </span>
                               </li>
                             </ul>
                        </div>
                        <br>
      
                        <div class="container">
                          <h3><b>
                            {{ _langService.GetLanguage()=='FR' ? 'État de la Serre' : 'State of the Greenhouse' }}
                             </b></h3> 
                          <ul>
                              <li *ngFor="let item of P2_3">
                                 <span *ngIf="item.etat=='true'" style="color:rgb(62, 194, 62) ;">
                                 <i class="fa fa-check" aria-hidden="true"></i>  {{item.title}}
                                 </span>
                                 <span *ngIf="item.etat=='false'" style="color:rgb(198, 33, 33) ;">
                                     <i class="fa fa-times" aria-hidden="true"></i>  {{item.title}}
                                     </span>
                               </li>
                             </ul>
                        </div>
      
      
                      </div>      
                     
                    </div>
                       <!--------------------- Service   -------------->
                       <div
                       class="text-sm leading-normal mt-0 mb-2 text-blueGray-400 font-bold"  *ngIf="check.Mode=='Service'"  >
                 
                       
                     <div class="card"  >
                       <br>
                       <div class="container">
                           <ul>
                                <li *ngFor="let item of P2_Global">
                                   <span *ngIf="item.etat=='true'" style="color:rgb(62, 194, 62) ;">
                                   <i class="fa fa-check" aria-hidden="true"></i>  {{item.title}}
                                   </span>
                                   <span *ngIf="item.etat=='false'" style="color:rgb(198, 33, 33) ;">
                                       <i class="fa fa-times" aria-hidden="true"></i>  {{item.title}}
                                       </span>
                                 </li>
                               </ul>
            
           
                         
                         </div>
                         <br>
       
                         <div class="container">
                           <h3><b>
                            {{ _langService.GetLanguage()=='FR' ? 'Cuves drainage' : 'Drainage tanks' }}
                            </b></h3> 
                           <ul>
                               <li *ngFor="let item of Service_1">
                                  <span *ngIf="item.etat=='true'" style="color:rgb(62, 194, 62) ;">
                                  <i class="fa fa-check" aria-hidden="true"></i>  {{item.title}}
                                  </span>
                                  <span *ngIf="item.etat=='false'" style="color:rgb(198, 33, 33) ;">
                                      <i class="fa fa-times" aria-hidden="true"></i>  {{item.title}}
                                      </span>
                                </li>
                              </ul>
                         </div>
                         <br>
       
                         <div class="container">
                           <h3><b>
                            {{ _langService.GetLanguage()=='FR' ? 'Local Technique A' : 'Technical Room A' }}
                            </b></h3> 
                           <ul>
                               <li *ngFor="let item of Service_2">
                                  <span *ngIf="item.etat=='true'" style="color:rgb(62, 194, 62) ;">
                                  <i class="fa fa-check" aria-hidden="true"></i>  {{item.title}}
                                  </span>
                                  <span *ngIf="item.etat=='false'" style="color:rgb(198, 33, 33) ;">
                                      <i class="fa fa-times" aria-hidden="true"></i>  {{item.title}}
                                      </span>
                                </li>
                              </ul>
                         </div>
                         <br>
       
                         <div class="container">
                           <h3><b>
                            {{ _langService.GetLanguage()=='FR' ? 'Local Technique B' : 'Technical Room B' }}
                            </b></h3> 
                           <ul>
                               <li *ngFor="let item of Service_3">
                                  <span *ngIf="item.etat=='true'" style="color:rgb(62, 194, 62) ;">
                                  <i class="fa fa-check" aria-hidden="true"></i>  {{item.title}}
                                  </span>
                                  <span *ngIf="item.etat=='false'" style="color:rgb(198, 33, 33) ;">
                                      <i class="fa fa-times" aria-hidden="true"></i>  {{item.title}}
                                      </span>
                                </li>
                              </ul>
                         </div>
       
        
                         <div class="container">
                            <h3><b>
                              {{ _langService.GetLanguage()=='FR' ? 'Local compresseur' : 'Compressor room' }}
                              </b></h3> 
                            <ul>
                                <li *ngFor="let item of Service_4">
                                   <span *ngIf="item.etat=='true'" style="color:rgb(62, 194, 62) ;">
                                   <i class="fa fa-check" aria-hidden="true"></i>  {{item.title}}
                                   </span>
                                   <span *ngIf="item.etat=='false'" style="color:rgb(198, 33, 33) ;">
                                       <i class="fa fa-times" aria-hidden="true"></i>  {{item.title}}
                                       </span>
                                 </li>
                               </ul>
                          </div>
              
                          <div class="container">
                            <h3><b>
                              {{ _langService.GetLanguage()=='FR' ? 'Serres P1' : 'P1 Greenhouses' }}
                              </b></h3> 
                            <ul>
                                <li *ngFor="let item of Service_5">
                                   <span *ngIf="item.etat=='true'" style="color:rgb(62, 194, 62) ;">
                                   <i class="fa fa-check" aria-hidden="true"></i>  {{item.title}}
                                   </span>
                                   <span *ngIf="item.etat=='false'" style="color:rgb(198, 33, 33) ;">
                                       <i class="fa fa-times" aria-hidden="true"></i>  {{item.title}}
                                       </span>
                                 </li>
                               </ul>
                          </div>
              
                          <div class="container">
                            <h3><b>
                              {{ _langService.GetLanguage()=='FR' ? 'Serres P2' : 'P2 Greenhouses' }}
                              </b></h3> 
                            <ul>
                                <li *ngFor="let item of Service_6">
                                   <span *ngIf="item.etat=='true'" style="color:rgb(62, 194, 62) ;">
                                   <i class="fa fa-check" aria-hidden="true"></i>  {{item.title}}
                                   </span>
                                   <span *ngIf="item.etat=='false'" style="color:rgb(198, 33, 33) ;">
                                       <i class="fa fa-times" aria-hidden="true"></i>  {{item.title}}
                                       </span>
                                 </li>
                               </ul>
                          </div>
                
                       </div>      
                      
                     </div>                   
            
          </div>
        </div>
      </div>
    </section>
  </main>