 
<div
class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded"
[ngClass]="color === 'light' ? 'bg-white' : 'bg-red-900 text-white'"
>
<div class="rounded-t mb-0 px-4 py-3 border-0">
  <div class="flex flex-wrap items-center">
    <div class="relative w-full px-4 max-w-full flex-grow flex-1">
      <h3
        class="font-semibold text-lg"
        [ngClass]="color === 'light' ? 'text-blueGray-700' : 'text-white'"
      >
      {{ _langService.GetLanguage()=='FR' ? 'Historique de livraison' : 'Delivery history' }}
         
      <!-- <button  [routerLink]="['/admin/Ajouter_livraison/' ]"  style=" position: absolute;right: 50px;  background-color: rgb(70, 175, 0); color: white;padding: 5px;border-radius: 6px;">Ajouter un livraison</button>
--> 
      </h3>
    </div>
  </div>
</div>
<br><br>
<div class="block w-full overflow-x-auto">
  <!-- Projects table -->
  <table class="items-center   bg-transparent border-collapse" style="width: 100%;">
    <thead>
      <tr>
        <th
          class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
          [ngClass]="
            color === 'light'
              ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
              : 'bg-red-800 text-red-300 border-red-700'
          "
          (click)="sort('variete')"
        >
        {{ _langService.GetLanguage()=='FR' ? 'ID livraison' : 'Delivery ID' }}
        
        </th>
    
        <th
        class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
        [ngClass]="
          color === 'light'
            ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
            : 'bg-red-800 text-red-300 border-red-700'
        "
          (click)="sort('client')"
      >
      {{ _langService.GetLanguage()=='FR' ? 'Client' : 'Customer' }}
      
      </th>
              
        <th
        class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
        [ngClass]="
          color === 'light'
            ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
            : 'bg-red-800 text-red-300 border-red-700'
        "
          (click)="sort('date')"
      >
      {{ _langService.GetLanguage()=='FR' ? 'Date de livraison' : 'Delivery date' }}
      
      </th>
            
      <th
      class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
      [ngClass]="
        color === 'light'
          ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
          : 'bg-red-800 text-red-300 border-red-700'
      "
          (click)="sort('echeance')"
    >
    {{ _langService.GetLanguage()=='FR' ? 'Échéance paiement' : 'Payment deadline' }}
    
    </th>
                      
      <th
      class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
      [ngClass]="
        color === 'light'
          ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
          : 'bg-red-800 text-red-300 border-red-700'
      "
          (click)="sort('commentaire')"
    >
    {{ _langService.GetLanguage()=='FR' ? 'Commentaire' : 'Comment' }}
    
    </th>
             
    <th
    class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
    [ngClass]="
      color === 'light'
        ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
        : 'bg-red-800 text-red-300 border-red-700'
    "
         
  >
  {{ _langService.GetLanguage()=='FR' ? 'Bon de livraison' : 'Delivery note' }}

  </th>        
  <th
  class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
  [ngClass]="
    color === 'light'
      ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
      : 'bg-red-800 text-red-300 border-red-700'
  "
       
>
{{ _langService.GetLanguage()=='FR' ? 'Facture' : 'Invoice' }}

</th>  <th
class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
[ngClass]="
  color === 'light'
    ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
    : 'bg-red-800 text-red-300 border-red-700'
"
     
>
{{ _langService.GetLanguage()=='FR' ? 'Étiquette' : 'Label' }}

</th>
 

      </tr>
    </thead>
    <tbody>
      <tr  *ngFor="let livraison of listlivraison" >
      <th
        class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left flex items-left"
      >
      <span
        class="ml-3 font-bold"
        [ngClass]="color === 'light' ? 'text-blueGray-600' : 'text-white'"
      >
      {{livraison.id_bonLivraison}}
      </span>
    </th>
  
      <td
      class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
    >
      {{livraison.nom}}
    </td>
  
      <td
      class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
    >
      {{livraison.date}}
    </td>
    
        <td
        class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
      >
        {{livraison.echeance}}
      </td>  
    
        <td
        class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
        style="white-space: pre-line"
      >
        {{livraison.com}}
      </td>  

          <td
          class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
        >
        <a href="https://seeds.bg-agri.com/babylone_API/bon_livraison_PDF/Bon{{livraison.id_bonLivraison}}.pdf" target="_blank">
          <i class="fas fa-file-pdf" style="margin-right:20px ;color: rgb(64, 63, 86);"  ></i></a> 
        </td>

        <td
        class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
      >
      <a href="https://seeds.bg-agri.com/babylone_API/FACTURE_PDF/FAC{{livraison.id_bonLivraison}}.pdf" target="_blank">
    
        <i class="fas fa-file-pdf" style="margin-right:20px ;color: rgb(87, 75, 55);"  > </i></a> 
    </td>
     
        <td
        class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
      >
      <a href="https://seeds.bg-agri.com/babylone_API/ETIQUETTE_PDF/ETI{{livraison.id_bonLivraison}}.pdf" target="_blank">

        <i class="fas fa-file-pdf" style="margin-right:20px ;color: rgb(87, 75, 55);"  > </i></a> 
    </td>

      </tr>
 
    </tbody>
  </table>
</div>
</div>
