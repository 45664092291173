import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Graine } from 'src/app/models/Graine/graine.model';
import { CompetenceService } from 'src/app/services/competence.service';
import { FloatingService } from 'src/app/services/floating.service';
import { FournisseurService } from 'src/app/services/fournisseur.service';
import { LangService } from 'src/app/services/lang.service';
import { SemisService } from 'src/app/services/semis.service';
 import { TypeService } from 'src/app/services/type.service';


@Component({
  selector: 'app-floating',
  templateUrl: './floating.component.html',
  styleUrls: ['./floating.component.css']
})
export class FloatingAdminComponent implements OnInit {
  submit: boolean;
  semis: any[];
  dateFloating ;
  numbers: number[];
 constructor( private datePipe: DatePipe, public _langService:LangService, public _semisService:SemisService,public _floatingService:FloatingService) { }

 ngOnInit(): void {
  this._floatingService.initialiserVide()


     this._semisService.All_semis_for_floating().subscribe(res => {
       this.semis = res as any[]
       })
       this.dateFloating=this.datePipe.transform( new Date(), 'yyyy-MM-dd')

 }


 AddSemis(){
   
   this._semisService.get_Semis(this._floatingService.formFloating.value['unique_id']).subscribe(res => {
 
     if(Number(res['nbr_disponible']) >= Number(this._floatingService.formFloating.value['nbr_graines']) )
      this._floatingService.AddFloating(this.dateFloating).subscribe(res => {
       this.submit=true
       
     this._semisService.All_semis_for_floating().subscribe(res => {
      this.semis = res as any[]
      })
      })
      else {
        if(this._langService.GetLanguage()=="FR")
        alert('Le nombre de graine est supérieur à ce qui est disponible en stock!')
        else {
          alert('The number of seeds is higher than what is available in stock!')
  
        }
       }
      },error  => {

     console.log('erreur');
     
       
     })

 }

 onSubmit(){
   this.AddSemis();
 }
}
