import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LangService } from 'src/app/services/lang.service';
import { TypeService } from 'src/app/services/type.service';

@Component({
  selector: 'app-edit-type',
  templateUrl: './edit-type.component.html',
  styleUrls: ['./edit-type.component.css']
})
export class EditTypeComponent implements OnInit {
  submit: boolean;
  id: any;

  constructor(public _typeService:TypeService, public _langService:LangService,private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.id=params['id'] //log the value of id
   });
   this._typeService.get_Type(this.id).subscribe(res => {
    this._typeService.initialiser(res)
    })
  }

  ModifyType(){
    this._typeService.modifierType().subscribe(res => {
      this.submit=true
    })
  }

  onSubmit(){
    this.ModifyType();
    console.log('test')
 
  }
}
