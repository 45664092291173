import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import {HttpClientModule} from '@angular/common/http';

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";

// layouts
import { AdminComponent } from "./layouts/admin/admin.component";
import { AuthComponent } from "./layouts/auth/auth.component";

// admin views
import { DashboardComponent } from "./views/admin/dashboard/dashboard.component";
import { MapsComponent } from "./views/admin/maps/maps.component";
import { SettingsComponent } from "./views/admin/settings/settings.component";
import { TablesComponent } from "./views/admin/tables/tables.component";

// auth views
import { LoginComponent } from "./views/auth/login/login.component";
import { RegisterComponent } from "./views/auth/register/register.component";

 

// components for views and layouts

import { AdminNavbarComponent } from "./components/navbars/admin-navbar/admin-navbar.component";
import { AuthNavbarComponent } from "./components/navbars/auth-navbar/auth-navbar.component";
import { CardBarChartComponent } from "./components/cards/card-bar-chart/card-bar-chart.component";
import { CardLineChartComponent } from "./components/cards/card-line-chart/card-line-chart.component";
import { CardPageVisitsComponent } from "./components/cards/card-page-visits/card-page-visits.component";
import { CardProfileComponent } from "./components/cards/card-profile/card-profile.component";
import { CardSettingsComponent } from "./components/cards/card-settings/card-settings.component";
import { CardSocialTrafficComponent } from "./components/cards/card-social-traffic/card-social-traffic.component";
import { CardStatsComponent } from "./components/cards/card-stats/card-stats.component";
import { CardTableComponent } from "./components/cards/card-table/card-table.component";
import { FooterAdminComponent } from "./components/footers/footer-admin/footer-admin.component";
import { FooterComponent } from "./components/footers/footer/footer.component";
import { FooterSmallComponent } from "./components/footers/footer-small/footer-small.component";
import { HeaderStatsComponent } from "./components/headers/header-stats/header-stats.component";
import { IndexNavbarComponent } from "./components/navbars/index-navbar/index-navbar.component";
import { MapExampleComponent } from "./components/maps/map-example/map-example.component";
import { IndexDropdownComponent } from "./components/dropdowns/index-dropdown/index-dropdown.component";
import { TableDropdownComponent } from "./components/dropdowns/table-dropdown/table-dropdown.component";
import { PagesDropdownComponent } from "./components/dropdowns/pages-dropdown/pages-dropdown.component";
import { NotificationDropdownComponent } from "./components/dropdowns/notification-dropdown/notification-dropdown.component";
import { SidebarComponent } from "./components/sidebar/sidebar.component";
import { UserDropdownComponent } from "./components/dropdowns/user-dropdown/user-dropdown.component";
import { AdduserComponent } from './views/adduser/adduser.component';
import { GestionuserComponent } from './views/gestionuser/gestionuser.component';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
 import { DetailuserComponent } from './views/detailuser/detailuser.component';
import { EdituserComponent } from './views/edituser/edituser.component';
import { DeleteuserComponent } from './views/deleteuser/deleteuser.component';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { AddcompetenceComponent } from './views/addcompetence/addcompetence.component';
import { GestioncompetenceComponent } from './views/gestioncompetence/gestioncompetence.component';
import { UpdatecompetenceComponent } from './views/updatecompetence/updatecompetence.component';
import { DeletecompetenceComponent } from './views/deletecompetence/deletecompetence.component';
 import { DetailcompetenceComponent } from './views/detailcompetence/detailcompetence.component';
import { ChefComponent } from './layouts/chef/chef.component';
import { SidebarChefComponent } from './components/sidebar-chef/sidebar-chef.component';
import { ChefNavbarComponent } from './components/navbars/chef-navbar/chef-navbar.component';
 import { DashbordChefComponent } from './views/dashbord-chef/dashbord-chef.component';
import { UserDropdownNotificationComponent } from "./components/dropdowns/user-dropdown-Notification/user-dropdown.component";
import { HeaderChefComponent } from './components/headers/header-chef/header-chef.component';
import { UserDropdownChefComponent } from "./components/dropdowns/user-dropdown-chef/user-dropdown.component";
import { AddprojetadminComponent } from './views/addprojetadmin/addprojetadmin.component';
import { DatePipe } from "@angular/common";
import { ListeprojetadminComponent } from './views/listeprojetadmin/listeprojetadmin.component';
 import { GanttComponent } from './views/gantt/gantt.component';
import { ListeprojetschefComponent } from './views/listeprojetschef/listeprojetschef.component';
   import { EditCommandeComponent } from './views/edit-commande/edit-commande.component';
import { ReceptionComponent } from './views/reception/reception.component';
import { StockComponent } from './views/stock/stock.component';
import { DeleteCommandeComponent } from './views/delete-commande/delete-commande.component';
import { AddfournisseurComponent } from './views/addfournisseur/addfournisseur.component';
import { GestionFournisseurComponent } from './views/gestion-fournisseur/gestion-fournisseur.component';
import { EditFournisseurComponent } from './views/edit-fournisseur/edit-fournisseur.component';
import { AddtypeComponent } from './views/addtype/addtype.component';
import { GestionTypeComponent } from './views/gestion-type/gestion-type.component';
import { EditTypeComponent } from './views/edit-type/edit-type.component';
import { SemisComponent } from './views/semis/semis.component';
import { FloatingComponent } from './views/floating/floating.component';
import { EditStockComponent } from './views/edit-stock/edit-stock.component';
import { PlantationComponent } from './views/plantation/plantation.component';
import { AddfactureComponent } from './views/addfacture/addfacture.component';
import { GestionfactureComponent } from './views/gestionfacture/gestionfacture.component';
import { EditFactureComponent } from './views/edit-facture/edit-facture.component';
import { DeleteFactureComponent } from './views/delete-facture/delete-facture.component';
import { RecolteComponent } from './views/recolte/recolte.component';
import { NettoyageComponent } from './views/nettoyage/nettoyage.component';
import { BonLivraisonComponent } from './views/bon-livraison/bon-livraison.component';
import { AddclientComponent } from './views/addclient/addclient.component';
import { GestionClientComponent } from './views/gestion-client/gestion-client.component';
import { EditClientComponent } from './views/edit-client/edit-client.component';
import { AllSemisComponent } from './views/all-semis/all-semis.component';
import { AllFloatingComponent } from './views/all-floating/all-floating.component';
import { AllPlantationComponent } from './views/all-plantation/all-plantation.component';
import { AllRecolteComponent } from './views/all-recolte/all-recolte.component';
import { AllLivraisonComponent } from './views/all-livraison/all-livraison.component';
import { AllNettoyageComponent } from './views/all-nettoyage/all-nettoyage.component';
 
import { ChartsModule, ThemeService } from 'ng2-charts';
import { BonLivraisonAdminComponent } from "./views/bon-livraisonAdmin/bon-livraison.component";
import { NettoyageAdminComponent } from "./views/nettoyageAdmin/nettoyage.component";
import { RecolteAdminComponent } from "./views/recolteAdmin/recolte.component";
import { PlantationAdminComponent } from "./views/plantationAdmin/plantation.component";
import { FloatingAdminComponent } from "./views/floatingAdmin/floating.component";
import { SemisAdminComponent } from "./views/semisAdmin/semis.component";
import { EditSemisComponent } from './views/edit-semis/edit-semis.component';
import { AddmousseComponent } from './views/addmousse/addmousse.component';
import { ChecklistComponent } from './views/checklist/checklist.component';
import { CheckFloatingComponent } from './views/check-floating/check-floating.component';
import { FertilisantsComponent } from './views/fertilisants/fertilisants.component';
import { AddfertilisantComponent } from './views/addfertilisant/addfertilisant.component';
import { AddStockfertilisantComponent } from './views/add-stockfertilisant/add-stockfertilisant.component';
import { StockRecoltComponent } from './views/stock-recolt/stock-recolt.component';
import { EditFertilisantComponent } from './views/edit-fertilisant/edit-fertilisant.component';
import { AddSolutionComponent } from './views/add-solution/add-solution.component';
import { AllSolutionComponent } from './views/all-solution/all-solution.component';
import { DetailSolutionComponent } from './views/detail-solution/detail-solution.component';
import { AddMesureComponent } from './views/add-mesure/add-mesure.component';
import { HistoriqueMesureComponent } from './views/historique-mesure/historique-mesure.component';
import { ChecklistHistoriqueComponent } from './views/checklist-historique/checklist-historique.component';
import { ChecklistDetailComponent } from './views/checklist-detail/checklist-detail.component';
import { ChecklistDetailAdminComponent } from './views/checklist-detail-admin/checklist-detail-admin.component';
import { ChecklistHistoriqueAdminComponent } from './views/checklist-historique-admin/checklist-historique-admin.component';
import { ChecklistUserComponent } from './views/checklist-user/checklist-user.component';
import { EditFloatingComponent } from "./views/edit-floating/edit-floating.component";
import { EditRecolteComponent } from './views/edit-recolte/edit-recolte.component';
import { EditPlantationComponent } from './views/edit-plantation/edit-plantation.component';


@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    CardBarChartComponent,
    CardLineChartComponent,
    IndexDropdownComponent,
    PagesDropdownComponent,
    TableDropdownComponent,
    NotificationDropdownComponent,
    UserDropdownComponent,
    SidebarComponent,
    FooterComponent,
    FooterSmallComponent,
    FooterAdminComponent,
    CardPageVisitsComponent,
    CardProfileComponent,
    CardSettingsComponent,
    CardSocialTrafficComponent,
    CardStatsComponent,
    CardTableComponent,
    HeaderStatsComponent,
    MapExampleComponent,
    AuthNavbarComponent,
    AdminNavbarComponent,
    IndexNavbarComponent,
    AdminComponent,
    AuthComponent,
    MapsComponent,
    SettingsComponent,
    TablesComponent,
    LoginComponent,
    RegisterComponent,
    AdduserComponent,
    GestionuserComponent,
    DetailuserComponent,
    EdituserComponent,
    DeleteuserComponent,
    AddcompetenceComponent,
    GestioncompetenceComponent,
    UpdatecompetenceComponent,
    DeletecompetenceComponent,
     DetailcompetenceComponent,
    ChefComponent,
    SidebarChefComponent,
    ChefNavbarComponent,
     DashbordChefComponent,
     UserDropdownNotificationComponent,
     HeaderChefComponent,
     UserDropdownChefComponent,
     AddprojetadminComponent,
     ListeprojetadminComponent,
      GanttComponent,
     ListeprojetschefComponent,
       EditCommandeComponent,
     ReceptionComponent,
     StockComponent,
     DeleteCommandeComponent,
     AddfournisseurComponent,
     GestionFournisseurComponent,
     EditFournisseurComponent,
     AddtypeComponent,
     GestionTypeComponent,
     EditTypeComponent,
     SemisComponent,
     SemisAdminComponent,
     FloatingComponent,
     FloatingAdminComponent,
     EditStockComponent,
     PlantationComponent,
     PlantationAdminComponent,
     AddfactureComponent,
     GestionfactureComponent,
     EditFactureComponent,
     DeleteFactureComponent,
     RecolteComponent,
     RecolteAdminComponent,
     NettoyageComponent,
     NettoyageAdminComponent,
     BonLivraisonComponent,
     BonLivraisonAdminComponent,
     AddclientComponent,
     GestionClientComponent,
     EditClientComponent,
     AllSemisComponent,
     AllFloatingComponent,
     AllPlantationComponent,
     AllRecolteComponent,
     AllLivraisonComponent,
     AllNettoyageComponent,
     EditSemisComponent,
     AddmousseComponent,
     ChecklistComponent,
     CheckFloatingComponent,
     FertilisantsComponent,
     AddfertilisantComponent,
     AddStockfertilisantComponent,
     StockRecoltComponent,
     EditFertilisantComponent,
     AddSolutionComponent,
     AllSolutionComponent,
     DetailSolutionComponent,
     AddMesureComponent,
     HistoriqueMesureComponent,
     ChecklistHistoriqueComponent,
     ChecklistDetailComponent,
     ChecklistDetailAdminComponent,
     ChecklistHistoriqueAdminComponent,
     ChecklistUserComponent,
     EditFloatingComponent,
     EditRecolteComponent,
     EditPlantationComponent,
 
  ],
  imports: [
    BrowserModule,
    RouterModule.forRoot([], { relativeLinkResolution: 'legacy' }),
    AppRoutingModule,
     FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    Ng2SearchPipeModule,
    ChartsModule,

    ],
    providers: [DatePipe],
    bootstrap: [AppComponent],
})
export class AppModule {}
