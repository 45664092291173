 
<div
class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded"
[ngClass]="color === 'light' ? 'bg-white' : 'bg-red-900 text-white'"
>
<div class="rounded-t mb-0 px-4 py-3 border-0">
  <div class="flex flex-wrap items-center">
    <div class="relative w-full px-4 max-w-full flex-grow flex-1">
      <h3
        class="font-semibold text-lg"
        [ngClass]="color === 'light' ? 'text-blueGray-700' : 'text-white'"
      >
      {{ _langService.GetLanguage()=='FR' ? 'Liste des fournisseurs' : 'List of suppliers' }}

          
        <button  [routerLink]="['/admin/Ajouter_fournisseur/' ]"  style=" position: absolute;right: 50px;  background-color: rgb(70, 175, 0); color: white;padding: 5px;border-radius: 6px;">
          {{ _langService.GetLanguage()=='FR' ? 'Ajouter un fournisseur' : 'Add a supplier' }}

          </button>

      </h3>
    </div>
  </div>
</div>
<br><br>
<div class="block w-full overflow-x-auto">
  <!-- Projects table -->
  <table class="items-center   bg-transparent border-collapse" style="width: 80%;">
    <thead>
      <tr>
        <th
          class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
          [ngClass]="
            color === 'light'
              ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
              : 'bg-red-800 text-red-300 border-red-700'
          "
        >
        {{ _langService.GetLanguage()=='FR' ? 'Fournisseur' : 'Supplier' }}
 
        </th>
 
              
        <th
        class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
        [ngClass]="
          color === 'light'
            ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
            : 'bg-red-800 text-red-300 border-red-700'
        "
      >
      Contact
      </th>
            
      <th
      class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
      [ngClass]="
        color === 'light'
          ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
          : 'bg-red-800 text-red-300 border-red-700'
      "
    >
    Email
    </th>
          
    <th
    class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
    [ngClass]="
      color === 'light'
        ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
        : 'bg-red-800 text-red-300 border-red-700'
    "
  >
  {{ _langService.GetLanguage()=='FR' ? 'Téléphone' : 'Phone' }}

  
  </th>
      <th
      class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-center"
      [ngClass]="
        color === 'light'
          ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
          : 'bg-red-800 text-red-300 border-red-700' "
    >
  Actions
  </th>
      </tr>
    </thead>
    <tbody>
      <tr  *ngFor="let fournisseur of listfournisseur" >
        <th
          class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left flex items-left"
        >
      
          <span
            class="ml-3 font-bold"
            [ngClass]="color === 'light' ? 'text-blueGray-600' : 'text-white'"
          >
            {{fournisseur.nom}}
          </span>
        </th>
   
  
          <td
          class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
        >
          {{fournisseur.contact}}
        </td>
    
            <td
            class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
          >
            {{fournisseur.email}}
          </td>  

            <td
            class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
          >
            {{fournisseur.tel}}
          </td>


         <td
         class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-center"
       style="cursor:grab ;"
         >
        
        <i class="fas fa-edit "  style="margin-right:15px ; color: rgb(79, 170, 255);"  [routerLink]="['/admin/editFournisseur/',fournisseur.id_fournisseur]"></i>
     <!--   <i class="fas fa-trash "  style="margin-right:15px ; color: rgb(255 89 89);"  [routerLink]="['/admin/deleteFournisseur/',fournisseur.id_fournisseur]"></i>
       -->
    </td>
      </tr>
 
    </tbody>
  </table>
</div>
</div>
