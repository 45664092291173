import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from 'src/environments/environment';
import { FormBuilder, Validators } from '@angular/forms';
import { Stock } from '../models/stock/stock.model';
import { DatePipe } from '@angular/common';
 
@Injectable({
  providedIn: 'root' 
})
export class RecolteService {
 
  formRecolte=this.fb.group({
    id_recolte : ["" , Validators.required],
	  date : ["" , Validators.required],
	  unique_id : ["" , Validators.required],
	  nbr_graines : [ 1 , Validators.required],
	  nbr_Xframe : [ 1 , Validators.required],
	  operateur : ["" , Validators.required],
	  commentaire : ["" , Validators.required],
    Xframe : ["" , Validators.required],
    stockage : ["Oui" , Validators.required],
    lieu : ["" , Validators.required],
    tomperature : ["" , Validators.required],
    id_plantation : ["" , Validators.required],
  });

  initialiserVide(){
    this.formRecolte.setValue({
      id_recolte : ""  ,
      date : ""  ,
      unique_id : ""  ,
      nbr_graines : 1 ,
      nbr_Xframe : 1,
      operateur : ""  ,
      commentaire : ""  ,
      Xframe : ""  ,
      stockage :  "Oui" ,
      lieu :  ""  ,
      tomperature :  ""  ,
      id_plantation :  ""  ,
  
    })
     }

  listRecolte:any[]
  constructor(private datePipe: DatePipe,private http: HttpClient, private fb: FormBuilder) {
   }

   AddRecolte(date) {
 
     
      return this.http
    .post(
      "https://seeds.bg-agri.com/babylone_API/Recolte/save.php?date="+
      this.datePipe.transform( date , 'dd/MM/yyyy')
      +"&id_plantation="+this.formRecolte.value['id_plantation']
      +"&commentaire="+this.formRecolte.value['commentaire']
      +"&nbr_graines="+this.formRecolte.value['nbr_graines']
      +"&nbr_Xframe="+this.formRecolte.value['nbr_Xframe']
      +"&operateur="+this.formRecolte.value['operateur']
      +"&Xframe="+this.formRecolte.value['Xframe']
      +"&stockage="+this.formRecolte.value['stockage']
      +"&lieu="+this.formRecolte.value['lieu']
      +"&tomperature="+this.formRecolte.value['tomperature']
      ,JSON.stringify(''))
  }

  initialiser(ev){
    let date;
    if(ev.date.indexOf("-")>-1)
    if(ev.date.indexOf("-")==2){
      date=  ev.date.substring(6) + '/'+ ev.date.substring(3,5)+'/'+ ev.date.substring(0,2)
     
    }
    else{
      date=ev.date.replaceAll("-","/")
    }
    else{
      date=  ev.date.substring(6) + '/'+ ev.date.substring(3,5)+'/'+ ev.date.substring(0,2)
    }
    ev.date=this.datePipe.transform( date, 'yyyy-MM-dd')  
     console.log(ev.date)

    this.formRecolte.setValue(ev)
    
     }
   All_Recolte(){
 
    return this.http.get("https://seeds.bg-agri.com/babylone_API/Recolte" + "/all.php" );
 }

   
 get_details(id){
  return this.http.get("https://seeds.bg-agri.com/babylone_API/Recolte" + "/get_details.php?unique_id="+id);
 }

 Stock_All_Recolte(){
 
  return this.http.get("https://seeds.bg-agri.com/babylone_API/Recolte" + "/Stock_All_Recolte.php" );
}


  get_Recolte(id){
    return this.http.get("https://seeds.bg-agri.com/babylone_API/Recolte" + "/get.php?id="+id);
   }

   get_graine_by_Recolte(){
    return this.http.get("https://seeds.bg-agri.com/babylone_API/Recolte/get_graine.php?unique_id="+this.formRecolte.value['id_recolte']);
   }

  modifierRecolte(Xframes,date) {
    
    
    return this.http
  .post(
    "https://seeds.bg-agri.com/babylone_API/Recolte/update.php?commentaire="
    
    +this.formRecolte.value['commentaire']
    +"&nbr_graines="+this.formRecolte.value['nbr_graines']
    +"&nbr_Xframe="+this.formRecolte.value['nbr_Xframe']
    +"&operateur="+this.formRecolte.value['operateur']
    +"&Xframe="+Xframes
    +"&date="+this.datePipe.transform( date , 'dd/MM/yyyy')
    +"&stockage="+this.formRecolte.value['stockage']
    +"&lieu="+this.formRecolte.value['lieu']
    +"&tomperature="+this.formRecolte.value['tomperature']
    +"&id_recolte="+this.formRecolte.value['id_recolte']
    
    ,JSON.stringify(''))
  }

  delete_Recolte(id:any) {
    return this.http.get("https://seeds.bg-agri.com/babylone_API/Recolte" + "/delete.php?id="+id);
  }
  All_recolte_for_Livraison(){
    return this.http.get("https://seeds.bg-agri.com/babylone_API/Recolte" + "/All_recolte_for_livraison.php" );
  }

  All_Xframes_By_graine(){
    return this.http.get("https://seeds.bg-agri.com/babylone_API/Recolte" + "/All_Xframes_By_graine.php?id_plantation="
    +this.formRecolte.value['id_plantation']
     );
  }
 
  initialiserToXframe(x){
    if(this.formRecolte.value['Xframe']!="")
    this.formRecolte.value['Xframe']=this.formRecolte.value['Xframe']+','
    this.formRecolte.value['Xframe']=this.formRecolte.value['Xframe']+x
  }
  
}
