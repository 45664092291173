import { Component, Input, OnInit } from '@angular/core';
 import { LangService } from 'src/app/services/lang.service';
import { StockService } from 'src/app/services/stock.service';
 
@Component({
  selector: 'app-stock',
  templateUrl: './stock.component.html',
  styleUrls: ['./stock.component.css']
})
export class StockComponent implements OnInit {

  @Input()
  get color(): string {
    return this._color;
  }
  set color(color: string) {
    this._color = color !== "light" && color !== "dark" ? "light" : color;
  }
  private _color = "light";

  liststock : any[]

  constructor(public _stockService:StockService, public _langService:LangService) {}

  ngOnInit(): void {
 
    this._stockService.All_Stock().subscribe(res => {
      this.liststock = res as any[]
       
      }) 
  }

    ///////////

 isDesc: boolean = false;
 column: string = 'CategoryName';
  
  
 sort(property) {
   this.isDesc = !this.isDesc; //change the direction    
   this.column = property;
   let direction = this.isDesc ? 1 : -1;
   if(property!='dateReception' && property!='nbr')
   this.liststock.sort(function (a, b) {
    a[property]=a[property].replace(' ','');
    b[property]=b[property].replace(' ','');
     if (a[property] < b[property]) {
       return -1 * direction;
     }
     else if (a[property] > b[property]) {
       return 1 * direction;
     }
     else {
       return 0;
     }
   });
   else if(property=='dateReception')
    this.liststock.sort(function (a, b) {
      let  date1=a[property]
      let  date2=b[property]
      let date1Number= (Number(date1.substring(0,2))*24) + (Number(date1.substring(3,5))*24*30) +  (Number(date1.substring(6,10))*24*30*365)
      let date2Number= (Number(date2.substring(0,2))*24) + (Number(date2.substring(3,5))*24*30) +  (Number(date2.substring(6,10))*24*30*365)
        
      if ( date1Number <  date2Number ){
      return -1 * direction;
    }
    else if ( date1Number >  date2Number ) {
      return 1 * direction;
    }
    else {
      return 0;
    }
  });
   else 
   this.liststock.sort(function (a, b) {


    if (Number(a[property]) < Number(b[property])) {
      return -1 * direction;
    }
    else if (Number(a[property]) > Number(b[property])) {
      return 1 * direction;
    }
    else {
      return 0;
    }
  });
 };
 
 //////////////
 
}
