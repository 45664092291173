<main class="profile-page" style="margin-top: 10%">
 
    <section class="relative py-16  ">
      <div class="container mx-auto px-4">
        <div
          class="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-xl rounded-lg -mt-64"
       style="min-height: 300px;">
          <div class="px-6">
            <div class="flex flex-wrap justify-center">
              <div class="w-full lg:w-3/12 px-4 lg:order-2 flex justify-center">
                <div class="relative">
                 <!--  <img
                    alt="..."
                    src="assets/img/team-2-800x800.jpg"
                    class="shadow-xl rounded-full h-auto align-middle border-none absolute -m-16 -ml-20 lg:-ml-16 max-w-150-px"
                  />
                -->
                </div>
              </div>
              <div
                class="w-full lg:w-4/12 px-4 lg:order-3 lg:text-right lg:self-center"
              >
              <!--
                <div class="py-6 px-3 mt-32 sm:mt-0">
                  <button
                    class="bg-red-600 active:bg-red-700 uppercase text-white font-bold hover:shadow-md shadow text-xs px-4 py-2 rounded outline-none focus:outline-none sm:mr-2 mb-1 ease-linear transition-all duration-150"
                    type="button"
                  >
                    Connect
                  </button>
                </div>-->
              </div>
              <!-- 
              <div class="w-full lg:w-4/12 px-4 lg:order-1">
                <div class="flex justify-center py-4 lg:pt-4 pt-8">
                  <div class="mr-4 p-3 text-center">
                    <span
                      class="text-xl font-bold block uppercase tracking-wide text-blueGray-600"
                    >
                      {{_graineService.listAffectation.length}}
                    </span>
                    <span class="text-sm text-blueGray-400">Utilisateurs</span>
                  </div>
          
              
                </div>
              </div>
            -->
            </div>
            <div class="text-center mt-12">
              <h3
                class="text-4xl font-semibold leading-normal mb-2 text-blueGray-700 mb-2"
              >
              {{ _langService.GetLanguage()=='FR' ? 'Variété: ' : 'Variety: ' }}

               {{graine.variete}} 
              </h3>
              <div
                class="text-sm leading-normal mt-0 mb-2 text-blueGray-400 font-bold uppercase"
              >
              {{ _langService.GetLanguage()=='FR' ? 'Fournisseur: ' : 'Supplier: ' }}
               {{graine.fournisseur}}
              </div>
              <div
              class="text-sm leading-normal mt-0 mb-2 text-blueGray-400 font-bold uppercase"
            >
        
            Type: {{graine.type}}
            </div>
            <div
            class="text-sm leading-normal mt-0 mb-2 text-blueGray-400 font-bold uppercase"
          >
          {{ _langService.GetLanguage()=='FR' ? 'Saison: ' : 'Season: ' }}
           {{graine.saison}}
          </div>
          <div
          class="text-sm leading-normal mt-0 mb-2 text-blueGray-400 font-bold uppercase"
        >
        {{ _langService.GetLanguage()=='FR' ? 'Historique: ' : 'History: ' }}
         {{graine.historique}}
        </div>
        <div
        class="text-sm leading-normal mt-0 mb-2 text-blueGray-400 font-bold uppercase"
      >
      {{ _langService.GetLanguage()=='FR' ? 'Commentaire: ' : 'Comment: ' }}
       {{graine.commentaire}}
      </div> 
            </div>

      
          </div>
        </div>
      </div>
    </section>
  </main>