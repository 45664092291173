
<div
class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded"
[ngClass]="color === 'light' ? 'bg-white' : 'bg-red-900 text-white'"
>
<div class="rounded-t mb-0 px-4 py-3 border-0">
  <div class="flex flex-wrap items-center">
    <div class="relative w-full px-4 max-w-full flex-grow flex-1">
      <h3
        class="font-semibold text-lg"
        [ngClass]="color === 'light' ? 'text-blueGray-700' : 'text-white'"
      >
      {{ _langService.GetLanguage()=='FR' ? 'Liste des factures' : 'List of invoices' }}
        
      </h3>
    </div>
  </div>
</div>
<div class="block w-full overflow-x-auto">
  <!-- Projects table -->
  <table class="items-center w-full bg-transparent border-collapse">
    <thead>
      <tr>
        <th
          class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
          [ngClass]="
            color === 'light'
              ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
              : 'bg-red-800 text-red-300 border-red-700'
          "
          (click)="sort('id_facture')"
        >
        {{ _langService.GetLanguage()=='FR' ? 'Numéro de facture' : 'Invoice number' }}

        
        </th>
        <th
        class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
        [ngClass]="
          color === 'light'
            ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
            : 'bg-red-800 text-red-300 border-red-700'
        "
          (click)="sort('fournisseur')"
      >
      {{ _langService.GetLanguage()=='FR' ? 'Fournisseur' : 'Supplier' }}

        
      </th>
        <th
          class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
          [ngClass]="
            color === 'light'
              ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
              : 'bg-red-800 text-red-300 border-red-700'
          "
          (click)="sort('date_facture')"
        >
        {{ _langService.GetLanguage()=='FR' ? 'Date de facture' : 'Invoice date' }}

         
        </th>
        <th
          class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
          [ngClass]="
            color === 'light'
              ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
              : 'bg-red-800 text-red-300 border-red-700'
          "
          (click)="sort('echeance_paymant')"
        >
        {{ _langService.GetLanguage()=='FR' ? 'échéance de paiement' : 'payment deadline' }}

        
        </th>
        <th
        class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
        [ngClass]="
          color === 'light'
            ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
            : 'bg-red-800 text-red-300 border-red-700'
        "
          (click)="sort('etat')"
      >
      {{ _langService.GetLanguage()=='FR' ? 'état de facture' : 'invoice status' }}

      
      </th>
 
      <th
      class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-center"
      [ngClass]="
        color === 'light'
          ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
          : 'bg-red-800 text-red-300 border-red-700' "
    >
  Actions
  </th>
      </tr>
    </thead>
    <tbody>
      <tr  *ngFor="let facture of listfacture" >
        <th
          class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left flex items-left"
        >
      
          <span
            class="ml-3 font-bold"
            [ngClass]="color === 'light' ? 'text-blueGray-600' : 'text-white'"
          >
            {{facture.id_facture}}
          </span>
        </th>
        <td
          class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
        >
        {{facture.fournisseur}}
        </td>
        <td
          class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
        >
        {{facture.date_facture}}

        </td>
        <td
          class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
        >
        {{facture.echeance_paymant}}

        </td>

        <td
          class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
        >
       {{facture.etat}}
        </td>
 
        <td
         class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-center"
       style="cursor:grab ;"
         >
        <a href="https://seeds.bg-agri.com/babylone_API/Upload_PDF/{{facture.fichierPDF}}" target="_blank"><i class="fas fa-file-pdf" style="margin-right:20px ;color: rgb(76, 55, 87);"  ></i></a> 
        <i class="fas fas fa-edit "  style="margin-right:20px ; color: rgb(79, 170, 255);"  [routerLink]="['/admin/editFacture/',facture.id_facture]"></i>
        <i class="fas fa-trash "  style=" color: rgb(255 89 89);"  [routerLink]="['/admin/deleteFacture/',facture.id_facture]"></i>
    </td>
      </tr>
 
    </tbody>
  </table>
</div>
</div>
