import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Graine } from 'src/app/models/Graine/graine.model';
import { CompetenceService } from 'src/app/services/competence.service';
import { FournisseurService } from 'src/app/services/fournisseur.service';
import { LangService } from 'src/app/services/lang.service';
import { TypeService } from 'src/app/services/type.service';

@Component({
  selector: 'app-updatecompetence',
  templateUrl: './updatecompetence.component.html',
  styleUrls: ['./updatecompetence.component.css']
})
export class UpdatecompetenceComponent implements OnInit {
  id: any;
  submit: boolean;
  listfournisseur: any[];
  list_Type: any[];

    constructor(public _typeService:TypeService, public _langService:LangService,public _fournisseurService:FournisseurService,public _competenceService:CompetenceService,private route: ActivatedRoute) { }

  ngOnInit(): void {
    this._fournisseurService.All_Fournisseur().subscribe(res => {
      this.listfournisseur = res as any[]
      })
      this._typeService.All_Type().subscribe(res => {
        this.list_Type = res as any[]
        })
  
    this.route.params.subscribe(params => {
      this.id=params['id'] //log the value of id
   });
    this._competenceService.get_Competence(this.id).subscribe(res => {
      console.log(res);
      
      this._competenceService.initialiser(res)
      })
  }


  EditCompetence(){
    this._competenceService.modifierCompetence( ).subscribe(res => {

      let test =res as Graine;
     if (test != null) {
        this._competenceService.All_Competence().subscribe(res => {
        this._competenceService.listCompetence = res as Graine[]
        this.submit=true

        })
     }
   },
    err => {
       console.log(err)
           }
   )
  }

  onSubmit(){
    this.EditCompetence();
  } 

}
