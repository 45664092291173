
<div class="text-blueGray-500 block" style="cursor: pointer;">
  
<span  (click)="this.toggleLanguage()"
class="w-12 h-12 text-sm text-white bg-blueGray-200 inline-flex items-center justify-center rounded-full"
>

<h1 style="color: green; font-size:20px; font-weight: bold;">
  {{Language}}
</h1>
 
</span>

  <span (click)="this.auth.logout()"
  class="w-12 h-12 text-sm text-white bg-blueGray-200 inline-flex items-center justify-center rounded-full"
style="margin-left: 30px;"
  >
 
   <i
     class="fas fa-right-from-bracket " style="color: green; font-size:20px"

   ></i>
</span>

 
</div>
 