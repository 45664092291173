import { Component, OnInit } from '@angular/core';
import { LangService } from 'src/app/services/lang.service';
import { TypeService } from 'src/app/services/type.service';

@Component({
  selector: 'app-addtype',
  templateUrl: './addtype.component.html',
  styleUrls: ['./addtype.component.css']
})
export class AddtypeComponent implements OnInit {
  submit: boolean;

  constructor(public _typeService:TypeService, public _langService:LangService) {}

  ngOnInit(): void {
    this._typeService.initialiserVide()

  }

  AddType(){
    this._typeService.insertType().subscribe(res => {
      this.submit=true
    
    })
  }

  onSubmit(){
    this.AddType();
    console.log('test')
  }
  

}
