import { Component, AfterViewInit, ViewChild, ElementRef } from "@angular/core";
import { createPopper } from "@popperjs/core";
import { AuthService } from "src/app/services/auth.service";
import { LangService } from "src/app/services/lang.service";

@Component({
  selector: "app-user-dropdown-chef",
  templateUrl: "./user-dropdown.component.html",
})
export class UserDropdownChefComponent implements AfterViewInit {
  Language = "";

 
  ngAfterViewInit() {

  }

  constructor( public auth: AuthService, public _langService:LangService) {
    this.Language=_langService.GetLanguage();
  }


  toggleLanguage() {
    
    if (this.Language==="EN") {
      this.Language = "FR";
    } else {
      this.Language = "EN";
    }
    this._langService.SetLanguage(this.Language)
  }
 
 
  
}
