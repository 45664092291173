import { Component, OnInit } from "@angular/core";
import { LangService } from "src/app/services/lang.service";

@Component({
  selector: "app-sidebar",
  templateUrl: "./sidebar.component.html",
})
export class SidebarComponent implements OnInit {
  collapseShow = "hidden";
  constructor(public _langService:LangService) {}
  menu=''
  ngOnInit() {}
  toggleCollapseShow(classes) {
    this.collapseShow = classes;
  }
  choisirmenu(choix){
    if(this.menu==choix)
    this.menu=''
    else
  this.menu=choix

  }
}
