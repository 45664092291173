import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-header-chef',
  templateUrl: './header-chef.component.html',
 })
export class HeaderChefComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
