<div
  class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0"
>
  <div class="rounded-t bg-white mb-0 px-6 py-6">
    <div class="text-center flex justify-between">
      <h6 class="text-blueGray-700 text-xl font-bold">
        {{ _langService.GetLanguage()=='FR' ? 'Ajouter une graine' : 'Add a seed' }}
      </h6>
   
    </div>
  </div>
  <div class="flex-auto px-4 lg:px-10 py-10 pt-0">
    <form [formGroup]="_competenceService.formCompetence" autocomplete="off" (ngSubmit)="onSubmit()">
      <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
        {{ _langService.GetLanguage()=='FR' ? 'Informations générales' : 'General informations' }}
      </h6>
      <div class="flex flex-wrap">
 

        <div class="w-full lg:w-4/12 px-4">
          <div class="relative w-full mb-3">
            <label
              class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
              htmlFor="grid-password"
            >
            {{ _langService.GetLanguage()=='FR' ? 'Variété' : 'Variety' }}
            
            </label>
            <input
               class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
              [placeholder]="_langService.GetLanguage()=='FR' ? 'Variété' : 'Variety'"
              formControlName="variete"
              [class.is-invalid]="_competenceService.formCompetence.controls['variete'].errors?.required"

             />
          
          </div>
        </div>
        <div class="w-full lg:w-4/12 px-4">
          <div class="relative w-full mb-3">
            <label
              class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
              htmlFor="grid-password"
            >
            {{ _langService.GetLanguage()=='FR' ? 'Fournisseur' : 'Supplier' }}
            
            </label>
            <select
               class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
               
              formControlName="fournisseur"
              [class.is-invalid]="_competenceService.formCompetence.controls['fournisseur'].errors?.required"

             >
             <option   *ngFor="let fournisseur of listfournisseur"
             [value] ="fournisseur.id_fournisseur" >{{fournisseur.nom}}
            </option>
            
          </select>
          </div>
        </div>
 
        <div class="w-full lg:w-4/12 px-4">
          <div class="relative w-full mb-3">
            <label
              class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
              htmlFor="grid-password"
            >
            {{ _langService.GetLanguage()=='FR' ? 'Enrobée' : 'Coated' }}
            
            </label>
            <select
               class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
               
              formControlName="enrobee"
              [class.is-invalid]="_competenceService.formCompetence.controls['enrobee'].errors?.required"

             >
             <option  
             value ="Oui" >
             {{ _langService.GetLanguage()=='FR' ? 'Oui' : 'Yes' }}
             
            
            </option>
              <option  
             value ="Non" >{{ _langService.GetLanguage()=='FR' ? 'Non' : 'No' }}</option>
             </select>
      
          </div>
        </div>
      <div class="w-full lg:w-4/12 px-4">
        <div class="relative w-full mb-3">
          <label
            class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
            htmlFor="grid-password"
          >
          Type
          </label>
          <select
             class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
            placeholder="Type"
            formControlName="type"
            [class.is-invalid]="_competenceService.formCompetence.controls['type'].errors?.required"

           >
        
           <option   *ngFor="let type of list_Type"
           [value] ="type.id_type" >{{type.nom}}
          </option>

           </select>
        </div>
      </div>
      <div class="w-full lg:w-4/12 px-4">
        <div class="relative w-full mb-3">
          <label
            class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
            htmlFor="grid-password"
          >
          {{ _langService.GetLanguage()=='FR' ? 'Saison' : 'season' }}
          
          </label>
          <input
             class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
            [placeholder]="_langService.GetLanguage()=='FR' ? 'Saison' : 'season' "
            formControlName="saison"
            [class.is-invalid]="_competenceService.formCompetence.controls['saison'].errors?.required"

           />
           
        </div>
      </div>
      <div class="w-full lg:w-4/12 px-4">
        <div class="relative w-full mb-3">
          <label
            class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
            htmlFor="grid-password"
          >
          {{ _langService.GetLanguage()=='FR' ? 'Historique' : 'History' }}
          
          </label>
          <select
             class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
            
            formControlName="historique"
            [class.is-invalid]="_competenceService.formCompetence.controls['historique'].errors?.required"

           >
           <option  
           value ="Plein champs" >
           
           Plein champ
          
          </option>
            <option  
           value ="Abri" >Abri</option>
            <option  
           value ="Hydronique" >Hydronique</option>
      
           </select>
        </div>
      </div>
 
        <div class="w-full lg:w-6/12 px-4">
            <div class="relative w-full mb-3">
              <label
                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
              Commentaire
              </label>
              <textarea 
                type="text"
                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                placeholder="Commentaire"
                formControlName="commentaire"
                [class.is-invalid]="_competenceService.formCompetence['controls'].commentaire.errors?.required"
              ></textarea>
            </div>
          </div>
  
  
     </div>

      <div class="align-self-center mx-auto text-center" style="padding-top: 15px;">
      <button  *ngIf="!submit" 
      class="bg-red-600 text-white active:bg-red-700 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
      type="submit"  
      >
      Ajouter
    </button></div>
    <div *ngIf="submit" class="alert alert-success" style="background-color: rgb(98, 223, 98);height: 50px;text-align: center;">
      {{ _langService.GetLanguage()=='FR' ? 'Graine ajoutée avec succès' : 'Seed added successfully' }}

      
      <div class="align-self-center mx-auto text-center" style="padding-top: 15px;">
        <button  
        class=" text-white  font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
        type="button"   [routerLink]="['/admin/gestionGraine']" style="background-color:rgb(195, 189, 170) ; color: black;"
        >
        Retourner
      </button></div>
    </div>
</form>
  </div>
</div>
