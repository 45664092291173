import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { Graine } from 'src/app/models/Graine/graine.model';
import { Projet } from 'src/app/models/Projet/Projet.model';
import { Utilisateur } from 'src/app/models/utilisateur/utilisateur.model';
import { CompetenceService } from 'src/app/services/competence.service';
import { LangService } from 'src/app/services/lang.service';
import { ProjetService } from 'src/app/services/projet.service';
import { UtilisateurService } from 'src/app/services/utilisateur.service';

@Component({
  selector: 'app-addprojetadmin',
  templateUrl: './addprojetadmin.component.html',
  styleUrls: ['./addprojetadmin.component.css']
})
export class AddprojetadminComponent implements OnInit {
  Vide=true
  dateCommande  ;
  max=  new Date()  ;
  dateLivraison  ;
  myDate2 = new Date();
  submit: boolean;
  @Input()
  get color(): string {
    return this._color;
  }
  set color(color: string) {
    this._color = color !== "light" && color !== "dark" ? "light" : color;
  }
  private _color = "dark";
  
  constructor(private datePipe: DatePipe,public _graineService:CompetenceService,public _projetService:ProjetService, public _langService:LangService) { }

  ngOnInit(): void {
    this._projetService.initialiserVide()

//getNewProjet
//  All_Competence()
this.dateCommande=this.datePipe.transform( new Date(), 'yyyy-MM-dd')
this.dateLivraison=this.datePipe.transform( new Date(), 'yyyy-MM-dd')

this._graineService.All_Competence().subscribe(res => {
  this._graineService.listCompetence=res  as  Graine[];
  })
  
   }

  AddProjet(){
 
    
    
    this._projetService.insertProjet(this.dateCommande,this.dateLivraison).subscribe(res => {

      let test =res as  Projet;
     if (test != null) {
        this._projetService.All_Projet().subscribe(res => {
        this._projetService.listProjet = res as Projet[]
        this.submit=true
        })
     }
   },
    err => {
       console.log(err)
           }
   )
  }

  onSubmit(){
    this.AddProjet();
    console.log('test')
    
    

  }
}
