import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from 'src/environments/environment';
import { FormBuilder, Validators } from '@angular/forms';
  
@Injectable({
  providedIn: 'root' 
})
export class FertilisantService {
 
  formFertilisant=this.fb.group({
    id_fertilisant : ["" , Validators.required],
    label : ["" , Validators.required],
     quantity : ["" , Validators.required],
    mesure : ["" , Validators.required],

  });

     
  initialiserVide(){
    this.formFertilisant.setValue({
      id_fertilisant :  ""  ,
      label :  ""  ,
      quantity :  ""  ,
      mesure :  ""  ,

    })
     }


   listFertilisant:any[]
  constructor(private http: HttpClient, private fb: FormBuilder) {
    }


  insertFertilisant() {
      return this.http
    .post(
      "https://seeds.bg-agri.com/babylone_API/Fertilisant" + "/save.php?label="+
      this.formFertilisant.value['label']
      +"&quantity="+this.formFertilisant.value['quantity']
      +"&mesure="+this.formFertilisant.value['mesure']

      ,JSON.stringify(''))
  }

  All_Fertilisant() {
     return this.http.get("https://seeds.bg-agri.com/babylone_API/Fertilisant" + "/all.php" );
  }

  get_Fertilisant(id:any) {
    return this.http.get("https://seeds.bg-agri.com/babylone_API/Fertilisant" + "/get.php?id_fertilisant="+id);
  }

  initialiser(ev){
 this.formFertilisant.setValue(ev)
  }

  modifierFertilisant( ) {
    return this.http
  .post(
    "https://seeds.bg-agri.com/babylone_API/Fertilisant/update.php?id_fertilisant="+
    this.formFertilisant.value['id_fertilisant']
    +"&label="+this.formFertilisant.value['label']
    +"&quantity="+this.formFertilisant.value['quantity']
    +"&mesure="+this.formFertilisant.value['mesure']
   
    ,JSON.stringify(''))
  }
  add_to_stock( ) {
    return this.http
  .post(
    "https://seeds.bg-agri.com/babylone_API/Fertilisant" + "/ajouter_au_stock.php?id_fertilisant="+
    this.formFertilisant.value['id_fertilisant']
    +"&quantity="+this.formFertilisant.value['quantity']
    
    ,JSON.stringify(''))
  }

  delete_Fertilisant(id:any) {
    return this.http.get("https://seeds.bg-agri.com/babylone_API/Fertilisant" + "/delete.php?id_fertilisant="+id);
  }

   
}
