<main class="profile-page" style="margin-top: 20%">
 
    <section class="relative py-16  ">
      <div class="container mx-auto px-4">
        <div
          class="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-xl rounded-lg -mt-64"
        >
          <div class="px-6">
            <div class="flex flex-wrap justify-center">
              <div class="w-full lg:w-3/12 px-4 lg:order-2 flex justify-center">
                <div class="relative">
    
                </div>
              </div>
      
      
            </div>
            <div class="text-center mt-12">
              <h3
                class="text-4xl font-semibold leading-normal mb-2 text-blueGray-700 mb-2"
              >
              {{ _langService.GetLanguage()=='FR' ? ' Détails de facture N°' : 'Invoice details No.' }}
                {{facture.id_facture}}
              </h3>
              
              <div 
                class=" leading-normal  text-blueGray-400 font-bold  " 
              >
              <h1 class="text-2xl font-semibold leading-normal mb-2 text-blueGray-700 mb-2">  <i
                  class="fas fa-circle mr-2 text-blueGray-400"
                  
                ></i>
                {{ _langService.GetLanguage()=='FR' ? 'Fournisseur:' : 'Supplier:' }}

                {{facture.fournisseur}}</h1>
              </div>
        
            <div
            class="text-sm leading-normal mt-0 mb-2 text-blueGray-400 font-bold  "
          >
          <h1 class="text-2xl font-semibold leading-normal mb-2 text-blueGray-700 mb-2">   <i
              class="fas fa-calendar-check mr-2 text-lg text-blueGray-400"
              
            ></i>
            {{ _langService.GetLanguage()=='FR' ? 'Date de facture: ' : 'Invoice date: ' }}
          {{facture.date_facture}}
         </h1>
          </div>
          <div class=" text-sm leading-normal mt-0 mb-2 text-blueGray-400 font-bold  ">
            <div class="relative w-full mb-3">
            
              <a href="https://zgued.com/pdf_daly/{{facture['fichierPDF']}}" target="_blank"> <i class=" fas fa-file-pdf" style="font-size: 100px ;color: rgb(76, 55, 87);"  ></i>
           </a>
            </div>
          </div>
             
            </div>

            <div class="mt-10 py-10 border-t border-blueGray-200 text-center">
                
                <div class="align-self-center mx-auto text-center" style="padding-top: 15px;">
                    <button   (click)="deletefacture()"
                    class="bg-red-600 text-white active:bg-red-700 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                    type="submit"  
                    >
                    {{ _langService.GetLanguage()=='FR' ? 'Supprimer' : 'Delete' }}
                    
                  </button></div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>