import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from 'src/environments/environment';
import { FormBuilder, Validators } from '@angular/forms';
  
@Injectable({
  providedIn: 'root' 
})
export class TypeService {
 
  formType=this.fb.group({
    nom : ["" , Validators.required],
    id_type : ["" , Validators.required],
  });

 
  initialiserVide(){
    this.formType.setValue({
      nom : ""  ,
      id_type : ""  ,
    
    })
     }

   listType:any[]
  constructor(private http: HttpClient, private fb: FormBuilder) {
    }


  insertType() {
      return this.http
    .post(
      "https://seeds.bg-agri.com/babylone_API/Types" + "/save.php?nom="+
      this.formType.value['nom']
 
      ,JSON.stringify(''))
  }

  All_Type() {
     return this.http.get("https://seeds.bg-agri.com/babylone_API/Types" + "/all.php" );
  }

  get_Type(id:any) {
    return this.http.get("https://seeds.bg-agri.com/babylone_API/Types" + "/get.php?id_type="+id);
  }

  initialiser(ev){
 this.formType.setValue(ev)
  }

  modifierType( ) {
    return this.http
  .post(
    "https://seeds.bg-agri.com/babylone_API/Types" + "/update.php?id_type="+
    this.formType.value['id_type']
    +"&nom="+this.formType.value['nom']
   
    ,JSON.stringify(''))
  }

  delete_Type(id:any) {
    return this.http.get("https://seeds.bg-agri.com/babylone_API/Types" + "/delete.php?id_type="+id);
  }

   
}
