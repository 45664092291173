import { Component, OnInit } from '@angular/core';
import { FertilisantService } from 'src/app/services/fertilisant.service';
import { LangService } from 'src/app/services/lang.service';

@Component({
  selector: 'app-add-stockfertilisant',
  templateUrl: './add-stockfertilisant.component.html',
  styleUrls: ['./add-stockfertilisant.component.css']
})
export class AddStockfertilisantComponent implements OnInit {
  submit: boolean;

  constructor(public _fertilisantService:FertilisantService, public _langService:LangService) {}

  ngOnInit(): void {
    
this._fertilisantService.All_Fertilisant().subscribe(res => {
  this._fertilisantService.listFertilisant=res  as  any[];
  })
    this._fertilisantService.initialiserVide()
  }

  AddFertilisant(){
    this._fertilisantService.add_to_stock().subscribe(res => {
      this.submit=true
    })
  }

  onSubmit(){
    this.AddFertilisant();
    console.log('test')
  }
  
}
