import { Component, OnInit } from '@angular/core';
import { FertilisantService } from 'src/app/services/fertilisant.service';
import { LangService } from 'src/app/services/lang.service';

@Component({
  selector: 'app-addfertilisant',
  templateUrl: './addfertilisant.component.html',
  styleUrls: ['./addfertilisant.component.css']
})
export class AddfertilisantComponent implements OnInit {
  submit: boolean;

  constructor(public _fertilisantService:FertilisantService, public _langService:LangService) {}

  ngOnInit(): void {
    this._fertilisantService.initialiserVide()

  }

  AddFertilisant(){
    this._fertilisantService.insertFertilisant().subscribe(res => {
      this.submit=true
    
    })
  }

  onSubmit(){
    this.AddFertilisant();
    console.log('test')
  }
  
}
