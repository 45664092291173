import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UserCompetence } from 'src/app/models/Affectation/userCompetence.model';
import { Graine } from 'src/app/models/Graine/graine.model';
import { CompetenceService } from 'src/app/services/competence.service';
import { LangService } from 'src/app/services/lang.service';
import { UtilisateurService } from 'src/app/services/utilisateur.service';

@Component({
  selector: 'app-detailcompetence',
  templateUrl: './detailcompetence.component.html',
  styleUrls: ['./detailcompetence.component.css']
})
export class DetailcompetenceComponent implements OnInit {
  graine: Graine={
    variete : "",
     id_graine: 0,
 	  fournisseur :  "",
	  enrobee :  "",
	  type :  "",
	  saison :  "",
	  historique :  "",
	  commentaire :  "",
  }
  id: any;

  constructor(public _utilisateurService:UtilisateurService, public _langService:LangService,public _competenceService:CompetenceService,private route: ActivatedRoute) {}

  ngOnInit(): void {
  this.route.params.subscribe(params => {
       this.id=params['id'] //log the value of id
    });
    this.get_Competence();
   }
 
   get_Competence(){
     this._competenceService.get_details(this.id).subscribe(res => {
       this.graine = res as Graine 
 

       })
   }
}
