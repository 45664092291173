import { Component, Input, OnInit } from '@angular/core';
import { CheckListeService } from 'src/app/services/checkListe.service';
import { LangService } from 'src/app/services/lang.service';

@Component({
  selector: 'app-checklist-historique',
  templateUrl: './checklist-historique.component.html',
  styleUrls: ['./checklist-historique.component.css']
})
export class ChecklistHistoriqueComponent implements OnInit {

  @Input()
  get color(): string {
    return this._color;
  }
  set color(color: string) {
    this._color = color !== "light" && color !== "dark" ? "light" : color;
  }
  private _color = "light";

  listcheck : any[]

  constructor(public _checkService:CheckListeService, public _langService:LangService) {}

  ngOnInit(): void {
    this._checkService.All_Check().subscribe(res => {
      this.listcheck = res as any[]
      console.log(res);       
      }) 
  }
}