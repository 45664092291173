import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FloatingService } from 'src/app/services/floating.service';
import { LangService } from 'src/app/services/lang.service';
import { PlantationService } from 'src/app/services/plantation.service';

@Component({
  selector: 'app-plantation',
  templateUrl: './plantation.component.html',
  styleUrls: ['./plantation.component.css']
})
export class PlantationComponent implements OnInit {
  submit: boolean;
  floatings: any[];
  datePlantation  ;
  NBR=[]
  LIGne=["A","B","C","D","E","F"]
  dynamicArray = [];
  newDynamic;
  addRow() {
    this.dynamicArray.push({ XFrame_Numero: '' , XFrame_ligne: '' });
  }
  deleteRow(index) {
    this.dynamicArray.splice(index, 1);
  }
 

 constructor(private datePipe: DatePipe, public _langService:LangService, public _floatingService:FloatingService,public _plantationService:PlantationService) { }
  ngOnInit(): void {
    this._plantationService.initialiserVide()

    for (let i=1;i<52;i++){
      this.NBR.push(i)
    }
    this.addRow()
    this.nbr_Xframe() 

    this.datePlantation=this.datePipe.transform( new Date(), 'yyyy-MM-dd')

     this._floatingService.All_floating_for_plantation().subscribe(res => {
       this.floatings = res as any[]
       })
 }


 AddFloating(){
 
  this._floatingService.get_Floating_Test(this._plantationService.formPlantation.value['id_floating']).subscribe(res => {
   
    if((Number(res['nbr_disponible'])) >= Number(this._plantationService.formPlantation.value['nbr_graines'])+Number(this._plantationService.formPlantation.value['nbr_perdus']) )
     this._plantationService.AddPlantation(this.datePlantation).subscribe(res => {
      this.submit=true
      
    this._floatingService.All_floating_for_plantation().subscribe(res => {
     this.floatings = res as any[]
     })
     })
     else {
     if(this._langService.GetLanguage()=="FR")
     alert('Le nombre de graine est supérieur à ce qui est disponible en stock!')
     else {
       alert('The number of seeds is higher than what is available in stock!')

     }
    }
 },error  => {

    console.log('erreur');
    

    })


 }

 onSubmit(){
 
  for (let i=0;i<this.dynamicArray.length;i++){
    if((this.dynamicArray[i].XFrame_Numero.length>0 )&&(this.dynamicArray[i].XFrame_ligne.length>0 )){
      this._plantationService.initialiserToXframe(this.dynamicArray[i].XFrame_Numero+this.dynamicArray[i].XFrame_ligne)
      console.log(this.dynamicArray[i].XFrame_Numero);
      console.log(this.dynamicArray[i].XFrame_ligne);
      console.log('--------');
    }
  }

   this.AddFloating();
 }

 nbr_Xframe(){
  if (Number(this._plantationService.formPlantation.value['nbr_Xframe'])==this.dynamicArray.length+1)
  this.addRow()
  else if (Number(this._plantationService.formPlantation.value['nbr_Xframe'])==this.dynamicArray.length-1)
  this.dynamicArray.pop()
 else if (Number(this._plantationService.formPlantation.value['nbr_Xframe'])>this.dynamicArray.length)
  for (let i=1;i<Number(this._plantationService.formPlantation.value['nbr_Xframe']);i++){
    this.addRow()
  }
 else 
  for (let i=Number(this._plantationService.formPlantation.value['nbr_Xframe']);i>1;i--){
    this.dynamicArray.pop()
  }
}
}
