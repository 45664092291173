<div
  class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0"
>
  <div class="rounded-t bg-white mb-0 px-6 py-6">
    <div class="text-center flex justify-between">
      <h6 class="text-blueGray-700 text-xl font-bold">
        {{ _langService.GetLanguage()=='FR' ? 'Modifier utilisateur' : 'Edit user' }}

        </h6>
   
    </div>
  </div>
  <div class="flex-auto px-4 lg:px-10 py-10 pt-0">
    <form [formGroup]="_utilisateurService.formUtilisateur" autocomplete="off" (ngSubmit)="onSubmit()">
      <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
        {{ _langService.GetLanguage()=='FR' ? 'Informations générales' : 'General informations' }}
     
      
      </h6>
      <div class="flex flex-wrap">
 
        <div class="w-full lg:w-6/12 px-4">
          <div class="relative w-full mb-3">
            <label
              class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
              htmlFor="grid-password"
            >
            {{ _langService.GetLanguage()=='FR' ? 'Prénom' : 'First Name' }}

              
            </label>
            <input
              type="text"
              class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
              [placeholder]="_langService.GetLanguage()=='FR' ? 'Prénom' : 'First Name'"
              formControlName="first"
              [class.is-invalid]="_utilisateurService.formUtilisateur.controls['first'].errors?.required"
            
            />
          </div>
        </div>
        <div class="w-full lg:w-6/12 px-4">
          <div class="relative w-full mb-3">
            <label
              class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
              htmlFor="grid-password"
            >
            {{ _langService.GetLanguage()=='FR' ? 'Nom' : 'Last Name' }}

            </label>
            <input
              type="text"
              class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
              [placeholder]=" _langService.GetLanguage()=='FR' ? 'Nom' : 'Last Name' "
              formControlName="last"
              [class.is-invalid]="_utilisateurService.formUtilisateur.controls['last'].errors?.required"
            
            />
          </div>
        </div>
        <div class="w-full lg:w-6/12 px-4">
            <div class="relative w-full mb-3">
              <label
                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
                Email
              </label>
              <input
                type="text"
                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                placeholder="Email"
                formControlName="email"
                [class.is-invalid]="_utilisateurService.formUtilisateur.controls['email'].errors?.required"
             
              />
            </div>
          </div>
        <div class="w-full lg:w-6/12 px-4">
            <div class="relative w-full mb-3">
              <label
                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
              {{ _langService.GetLanguage()=='FR' ? 'Mot de passe' : 'Password' }}
                
              </label>
              <input
                type="password"
                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                [placeholder]="_langService.GetLanguage()=='FR' ? 'Mot de passe' : 'Password'"
                formControlName="password"
                [class.is-invalid]="_utilisateurService.formUtilisateur.controls['password'].errors?.required"
             
              />
            </div>
          </div>

      </div>
      <div class="flex flex-wrap">

      <div class="w-full lg:w-6/12 px-4">
        <div class="relative w-full mb-3">
          <label
            class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
            htmlFor="grid-password"
          >
          {{ _langService.GetLanguage()=='FR' ? 'Téléphone' : 'Phone' }}
 
          </label>
          <input
            type="phone"
            class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
            [placeholder]=" _langService.GetLanguage()=='FR' ? 'Téléphone' : 'Phone' " 
            formControlName="phone"
            [class.is-invalid]="_utilisateurService.formUtilisateur.controls['phone'].errors?.required"
          />
        </div>
      </div>
 
        <div class="w-full lg:w-6/12 px-4">
            <div class="relative w-full mb-3">
              <label
                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
              {{ _langService.GetLanguage()=='FR' ? 'Commentaire' : 'Comment' }}
 
              </label>
              <input 
                type="text"
                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                [placeholder]=" _langService.GetLanguage()=='FR' ? 'Commentaire' : 'Comment'"
                formControlName="commentaire"
                [class.is-invalid]="_utilisateurService.formUtilisateur['controls'].commentaire.errors?.required"
              />
            </div>
          </div>
          <div class="w-full lg:w-6/12 px-4">

          <b>
            {{ _langService.GetLanguage()=='FR' ? 'Permissions:' : 'Permissions:' }}

           </b><br><br>
          <div class="form-group"  *ngFor="let permission of permissions " >
            <input (change)="fieldsChange($event)" [value]="permission.value" type="checkbox" [checked]="permission.etat=='true'"/>
            <label  style="margin-left: 20px;" >{{permission.label}}</label>

          </div>
        </div>

      </div>
    
      <div class="align-self-center mx-auto text-center" style="padding-top: 15px;">
      <button  *ngIf="!submit" 
      class="bg-red-600 text-white active:bg-red-700 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
      type="submit"  
      >
      {{ _langService.GetLanguage()=='FR' ? 'Modifier' : 'Save' }}

      
    </button></div>
    <div *ngIf="submit" class="alert alert-success" style="background-color: rgb(98, 223, 98);height: 50px;text-align: center;">
      {{ _langService.GetLanguage()=='FR' ? "L'tilisateur a été modifié avec succès" : 'User has been successfully edited' }}

      
      <div class="align-self-center mx-auto text-center" style="padding-top: 15px;">
        <button  
        class=" text-white  font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
        type="button"   [routerLink]="['/admin/gestionuser']" style="background-color:rgb(195, 189, 170) ; color: black;"
        >
        {{ _langService.GetLanguage()=='FR' ? 'Retourner' : 'Return' }}

        
      </button></div>
    </div>
    </form>
  </div>
</div>
