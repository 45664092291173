import { Component, Input, OnInit } from '@angular/core';
import { ClientService } from 'src/app/services/client.service';
import { LangService } from 'src/app/services/lang.service';

@Component({
  selector: 'app-gestion-client',
  templateUrl: './gestion-client.component.html',
  styleUrls: ['./gestion-client.component.css']
})
export class GestionClientComponent implements OnInit {

  @Input()
  get color(): string {
    return this._color;
  }
  set color(color: string) {
    this._color = color !== "light" && color !== "dark" ? "light" : color;
  }
  private _color = "light";

  listClient : any[]

  constructor(public _clientService:ClientService, public _langService:LangService) {}

  ngOnInit(): void {
    this._clientService.All_Client().subscribe(res => {
      this.listClient = res as any[]
      console.log(res);
      
       
      }) 
  }
}
