import { Component, OnInit } from '@angular/core';
import { ClientService } from 'src/app/services/client.service';
import { LangService } from 'src/app/services/lang.service';

@Component({
  selector: 'app-addclient',
  templateUrl: './addclient.component.html',
  styleUrls: ['./addclient.component.css']
})
export class AddclientComponent implements OnInit {
  submit: boolean;

  constructor(public _clientService:ClientService, public _langService:LangService) {}

  ngOnInit(): void {
    this._clientService.initialiserVide()

  }

  AddClient(){
    this._clientService.insertClient().subscribe(res => {
      this.submit=true
    
    })
  }

  onSubmit(){
    this.AddClient();
    console.log('test')
  }
  
}
