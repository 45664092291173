<main class="profile-page" style="margin-top: 10%">
 
    <section class="relative py-16  ">
      <div class="container mx-auto px-4">
        <div
          class="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-xl rounded-lg -mt-64"
       style="min-height: 300px;">
          <div class="px-6">
            <div class="flex flex-wrap justify-center">
              <div class="w-full lg:w-3/12 px-4 lg:order-2 flex justify-center">
                <div class="relative">
                 <!--  <img
                    alt="..."
                    src="assets/img/team-2-800x800.jpg"
                    class="shadow-xl rounded-full h-auto align-middle border-none absolute -m-16 -ml-20 lg:-ml-16 max-w-150-px"
                  />
                -->
                </div>
              </div>
              <div
                class="w-full lg:w-4/12 px-4 lg:order-3 lg:text-right lg:self-center"
              >
              <!--
                <div class="py-6 px-3 mt-32 sm:mt-0">
                  <button
                    class="bg-red-600 active:bg-red-700 uppercase text-white font-bold hover:shadow-md shadow text-xs px-4 py-2 rounded outline-none focus:outline-none sm:mr-2 mb-1 ease-linear transition-all duration-150"
                    type="button"
                  >
                    Connect
                  </button>
                </div>-->
              </div>
              <div class="w-full lg:w-4/12 px-4 lg:order-1">
              <!---  <div class="flex justify-center py-4 lg:pt-4 pt-8">
                  <div class="mr-4 p-3 text-center">
                    <span
                      class="text-xl font-bold block uppercase tracking-wide text-blueGray-600"
                    >
                      {{_competenceService.listAffectation.length}}
                    </span>
                    <span class="text-sm text-blueGray-400">Compétences</span>
                  </div>
          
              
                </div>
              -->
              </div>
            </div>
            <div class="text-center mt-12">
              <h3
                class="text-4xl font-semibold leading-normal mb-2 text-blueGray-700 mb-2"
              >
                {{user.last}}  {{user.first}}
              </h3>
              <div
                class="text-sm leading-normal mt-0 mb-2 text-blueGray-400 font-bold uppercase"
              >
                <i
                  class="fas fa-envelope mr-2 text-lg text-blueGray-400"
                  
                ></i>
                {{user.email}}
              </div>
              <div class="mb-2 text-blueGray-600 mt-10">
                <i class="fas fa-phone mr-2 text-lg text-blueGray-400"></i>
                {{user.phone}}
              </div>
              <div class="mb-2 text-blueGray-600 mt-10">
                <i class="  fa-solid fa-comment mr-2 text-lg text-blueGray-400"></i>
                  {{user.commentaire}}
              </div>
            </div>
<!-- 
            <div *ngIf="_competenceService.listAffectation.length>0" class="mt-10 py-10 border-t border-blueGray-200 text-center" style="text-align:center ;" >
                <h6 style="color:red ; "> </h6>

                <table   class="items-center w-full bg-transparent border-collapse">
                    <thead>
                      <tr>
                        <th
                          class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold  "
                          [ngClass]="
                            color === 'light'
                              ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                              : 'bg-red-800 text-red-300 border-red-700'
                          "
                        >
                          Compétence
                        </th>
                        <th
                          class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold  "
                          [ngClass]="
                            color === 'light'
                              ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                              : 'bg-red-800 text-red-300 border-red-700'
                          "
                        
                        >
                          Expérience
                        </th>
  
                        <th
                        class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold  "
                        [ngClass]="
                          color === 'light'
                            ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                            : 'bg-red-800 text-red-300 border-red-700'
                        "
                      >
                        Description
                      </th>
                     
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let item of _competenceService.listAffectation">
                        <th
                          class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4   flex items-center"
                        >
                 
                          <span
                            
                          >
                            {{item.competence.titre}}
                          </span>
                        </th>
           
                 
                        <td
                          class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
                        >
                          <div class="flex items-center">
                            <span class="mr-2"></span>
                            <div class="relative w-full">
                              <div
                                class="overflow-hidden h-2 text-xs flex rounded bg-red-200"
                              >
                                <div
                                  [ngStyle]="{'width':  item.experience==1 ? '20%' : (item.experience==2 ? '40%' :  (item.experience==3 ? '60%' : (item.experience==4 ? '80%' : (item.experience==0 ? '0%' :'100%')) )) }"
                                  class="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-red-500"
                                ></div>
                              </div>
                            </div>
                          </div>
                        </td>
             
                         
                        <td
                          class="border-t-0 px-6 border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
                         
                          >
                        <span
                            
                        >
                        {{item.competence.description}}
                        </span>

                    </td>
                      </tr>
               
                   
                
                    </tbody>
                  </table>
            </div>
          -->
          </div>
        </div>
      </div>
    </section>
  </main>