import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Alert } from 'selenium-webdriver';
 import { CompetenceService } from 'src/app/services/competence.service';
import { LangService } from 'src/app/services/lang.service';
import { ProjetService } from 'src/app/services/projet.service';
import { StockService } from 'src/app/services/stock.service';

@Component({
  selector: 'app-reception',
  templateUrl: './reception.component.html',
  styleUrls: ['./reception.component.css']
})
export class ReceptionComponent implements OnInit {
  
  submit: boolean;
   id: any;
  myGroup: FormGroup;
  graine:String
  selection: any[];
  ancien=0;
  nbr_commande: any;
   constructor(private datePipe: DatePipe, public _langService:LangService,public _graineService:CompetenceService,public _stockService:StockService,public _commandeService:ProjetService,private route: ActivatedRoute) { 
    this.myGroup = new FormGroup({
      selection  :new FormControl()
   });
  }
 
  ngOnInit(): void {
 
    this.id = this.route.snapshot.paramMap.get("id");
    //  this.users = this.Artists
    this._commandeService.get_Projet(this.id).subscribe(res => {
       this._stockService.formStock.value['id_graine']=res['graine']
       this._stockService.initialiserDate( res['graine'])
       if(res['nbr_recue'])
       this.ancien=res['nbr_recue']
       this.nbr_commande=res['nbr']
       //this._stockService.formStock.value['nbr_recue']=0
        this._graineService.get_Competence(res['graine']).subscribe(res2 => {
         this.graine= res2['variete']
        })
 
      })

    
  }

  AddEquipe(){
   
    let id_stock=this.graine.substring(0,3).toUpperCase() + this._stockService.formStock.value['loi'] + this.datePipe.transform(this._stockService.formStock.value['dateReception'], 'ddMMyy') 
    console.log((this._stockService.formStock.value['nbr']) )

    if(Number(this.nbr_commande)>=(Number(this._stockService.formStock.value['nbr'])+Number(this.ancien))&&Number(this._stockService.formStock.value['nbr']>0))
    this._stockService.AddToStock(this.id,id_stock).subscribe(res => {
      this.ListeEquipe()
       let test =res ;
     if (test != null) {
        this._stockService.All_Stock().subscribe(res => {
        this._stockService.listStock = res as any[]
        this.submit=true
 
          this._commandeService.modifierEtat(this.id).subscribe(res => {
          })
 
        })
     }
   },
    err => {
       console.log(err)
           }
   )
   else {
    if(this._langService.GetLanguage()=="FR")
   alert('Le nombre de graines reçu est supérieur au nombre commandé')
   else {
    alert('The number of seeds received is greater than the number ordered')

  }
  }
}
  onSubmit(){
    this.AddEquipe();
  }

  ListeEquipe(){
    this._stockService.All_Stock().subscribe(res => {
         
      })
 
  }
  ///////////
  
}
