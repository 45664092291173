import { Component, Input, OnInit } from "@angular/core";
import { ChartDataSets, ChartOptions, ChartType } from "chart.js";
import { BaseChartDirective, Color, Label, SingleDataSet } from 'ng2-charts';
import { BonLivraisonService } from "src/app/services/bonLivraison.service";
import { LangService } from "src/app/services/lang.service";
import { ProjetService } from "src/app/services/projet.service";
import { SemisService } from "src/app/services/semis.service";
import { StockService } from "src/app/services/stock.service";


@Component({
  selector: "app-dashboard",
  templateUrl: "./dashboard.component.html",
})
export class DashboardComponent implements OnInit {
  stock: boolean;
  mousses=0;
  factures=0;
  nbrP2=0;
  nbrP1=0;
 
  p1_humidite='0';
  p1_temp_ext='0';
  p1_temp_inter='0';
  p1_od='0';
  p1_ec='0';
  p1_ph='0';
  p2_humidite='0';
  p2_temp_ext='0';
  p2_temp_inter='0';
  p2_od='0';
  p2_ec='0';
  p2_ph='0';
  @Input()
  get color(): string {
    return this._color;
  }
  set color(color: string) {
    this._color = color !== "light" && color !== "dark" ? "light" : color;
  }
  private _color = "light";

  ///////////
   
  public lineChartData: ChartDataSets[] = [
    {  
      
    
      data:[],

       fill: false,   
     //  borderCapStyle:'square',
    }, 
    {  
      
    
      data:[],
       fill: false,   
     //  borderCapStyle:'square',
    },  
    {  
      
    
      data:[],

       fill: false,   
     //  borderCapStyle:'square',
    }, {  
      
  
      data:[],

       fill: false,   
     //  borderCapStyle:'square',
    }, {  
      
  
      data:[],

       fill: false,   
     //  borderCapStyle:'square',
    }, 
  ];
  public lineChartLabels: Label[] = [  'Stock' ];
  public lineChartOptions:  ChartOptions  = {
    responsive: true,
    scales: {
      yAxes: [{
         ticks: {
          min: 0 ,
              /*max:  (Math.max.apply(this, this.lineChartData[0].data)).length   ,
            stepSize:  (Math.max.apply(this, this.lineChartData[0].data)).length/4
            */
         },
         //gridLines: {display:false}
      },
    
   
    ],   
      xAxes: [{
       
        gridLines: {display:false}
     }],  
         
    },
    animation: {
      duration: 0
    }
  }
   
  public lineChartColors: Color[] = [
    {
        borderColor: 'rgb(75, 192, 192)',

      backgroundColor: 'rgb(75, 192, 192)',
 
    },  {
      borderColor: '#3D2C8D',

    backgroundColor: '#3D2C8D',

  },
  {
    borderColor: '#FF0000',

  backgroundColor: '#FF0000',

},
{
  borderColor: '#ECD662',

backgroundColor: '#ECD662',

},
{
  borderColor: '#D57E7E',

backgroundColor: '#D57E7E',

},
  ];
  public lineChartLegend = false;
  public lineChartType = 'bar';
  public lineChartPlugins = [];

  typeUser: string;
  artist: any;
  chartReady: boolean;
//////////////////

//////////////////////////
public pieChartOptions: ChartOptions = {
  responsive: true,
  legend:{
    position:'bottom'  }
};
public pieChartLabels: Label[] = [['Bien réçue'], ['En cours'] ];
public pieChartData: SingleDataSet = [];
public pieChartType: ChartType = 'pie';
public pieChartLegend = true;
public pieChartPlugins = [];
public chartColors: Array<any> = [
  {
    backgroundColor: [
      '#185ADB',
      '#A72693',
      '#EAE7AF',
    ],
    hoverBackgroundColor: [
      '#514977',
      '#924886',
      '#C9C79C',
    ],

    
  }
];
///////////////////////////////

  constructor(public _semisService:SemisService, public _langService:LangService,public _stockService:StockService,public _commandeService:ProjetService) {}

  ngOnInit() {
    this._semisService.Nbr_Mousses_Disponible() .subscribe(res => {
      this.mousses=Number(res)
      console.log( this.mousses);
  
      })
      this._semisService.nbr_facture_non_payer() .subscribe(res => {
        this.factures=Number(res)
     
        })
        this._semisService.nbrP1() .subscribe(res => {
          this.nbrP1=Number(res)
       
          })
 
          this._semisService.nbrP2() .subscribe(res => {
            this.nbrP2=Number(res)
         
            })
     
        this._semisService.get_mesure('P1') .subscribe(res => {
          this.p1_ph=res['ph']
          this.p1_ec=res['ec']
          this.p1_od=res['od']
          this.p1_temp_inter=res['temp_inter']
          this.p1_temp_ext=res['temp_ext']
          this.p1_humidite=res['humidite']
            })
          this._semisService.get_mesure('P2') .subscribe(res => {
            this.p2_ph=res['ph']
            this.p2_ec=res['ec']
            this.p2_od=res['od']
            this.p2_temp_inter=res['temp_inter']
            this.p2_temp_ext=res['temp_ext']
            this.p2_humidite=res['humidite']
             })
    this._stockService.All_Stock_Chart().subscribe((response: any) => {
      for (let i = 0; i < response.length; i++) {
      this.lineChartData[i].data.push(Number(response[i].nbr))
      this.lineChartData[i].label=response[i].variete
    }
    this.stock=true
  });

  //////////
  this._commandeService.All_Commande_Chart().subscribe((response: any) => {
    console.log(response);
    
      if(response.length==2){
      this.pieChartData.push(response[0]['nbr'])
      this.pieChartData.push(response[1]['nbr'])
      }
      
      else if (response[0]['etat']=="En cours"){
        this.pieChartData.push(0)
        this.pieChartData.push(response[0]['nbr'])
      }
      else{
        this.pieChartData.push(response[0]['nbr'])
        this.pieChartData.push(0)
      }
  
 
  });
   
  }
}
