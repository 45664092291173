import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from 'src/environments/environment';
import { FormBuilder, Validators } from '@angular/forms';
import { Stock } from '../models/stock/stock.model';
import { DatePipe } from '@angular/common';
 
@Injectable({
  providedIn: 'root' 
})
export class PlantationService {
 
  formPlantation=this.fb.group({
    id_plantation : ["" , Validators.required],
	  date : ["" , Validators.required],
	  unique_id : ["" , Validators.required],
	  nbr_graines : [ 1 , Validators.required],
	  nbr_perdus : [ 1 , Validators.required],
	  nbr_Xframe : [ 0 , Validators.required],
	  operateur : ["" , Validators.required],
	  commentaire : ["" , Validators.required],
    Xframe : ["" , Validators.required],
    id_floating : ["" , Validators.required],
  });

  initialiserVide(){
    this.formPlantation.setValue({
      id_plantation : ""  ,
      date : ""  ,
      unique_id : ""  ,
      nbr_graines :   1  ,
      nbr_Xframe :  0  ,
      operateur : ""  ,
      commentaire : ""  ,
      Xframe : ""  ,
      id_floating  : ""  ,
      nbr_perdus   : ""  ,

    })
     }

  listPlantation:any[]
  constructor(private datePipe: DatePipe,private http: HttpClient, private fb: FormBuilder) {

   }

   AddPlantation(date) {

     
      return this.http
    .post(
      "https://seeds.bg-agri.com/babylone_API/Plantation" + "/save.php?date="+
      this.datePipe.transform( date , 'dd/MM/yyyy')
      +"&id_floating="+this.formPlantation.value['id_floating']
      +"&commentaire="+this.formPlantation.value['commentaire']
      +"&nbr_graines="+this.formPlantation.value['nbr_graines']
      +"&nbr_Xframe="+this.formPlantation.value['nbr_Xframe']
      +"&operateur="+this.formPlantation.value['operateur']
      +"&Xframe="+this.formPlantation.value['Xframe']
      +"&nbr_perdus="+this.formPlantation.value['nbr_perdus']
      ,JSON.stringify(''))
  }

  initialiser(ev){
    let date;
    if(ev.date.indexOf("-")>-1)
    if(ev.date.indexOf("-")==2){
      date=  ev.date.substring(6) + '/'+ ev.date.substring(3,5)+'/'+ ev.date.substring(0,2)
     
    }
    else{
      date=ev.date.replaceAll("-","/")
    }
    else{
      date=  ev.date.substring(6) + '/'+ ev.date.substring(3,5)+'/'+ ev.date.substring(0,2)
    }
    ev.date=this.datePipe.transform( date, 'yyyy-MM-dd') 

    

 
    this.formPlantation.setValue(ev)
     
     }
     initialiserToXframe(x){
      if(this.formPlantation.value['Xframe'].length==1)
      this.formPlantation.value['Xframe']=''
       if(this.formPlantation.value['Xframe'].length>1)
       this.formPlantation.value['Xframe']=this.formPlantation.value['Xframe']+','
       this.formPlantation.value['Xframe']=this.formPlantation.value['Xframe']+x
     }
  All_Plantation(){
 
     return this.http.get("https://seeds.bg-agri.com/babylone_API/Plantation" + "/all.php" );
  }
 
  
 get_details(id){
  return this.http.get("https://seeds.bg-agri.com/babylone_API/Plantation" + "/get_details.php?unique_id="+id);
 }


 get_graine_by_Plantation(){
  return this.http.get("https://seeds.bg-agri.com/babylone_API/Plantation/get_graine.php?unique_id="+this.formPlantation.value['id_plantation']);
 }

  get_Plantation(id){
    
    return this.http.get("https://seeds.bg-agri.com/babylone_API/Plantation" + "/get.php?unique_id="+id);
   }

   get_Plantation_Test(id){
    
    return this.http.get("https://seeds.bg-agri.com/babylone_API/Plantation" + "/get_Plantation_Test.php?id_plantation="+id);
   }

   modifierFloatingAvailable(nbr){
    return this.http
    .post(
      "https://seeds.bg-agri.com/babylone_API/Plantation" + "/modifierFloatingAvailable.php?nbr="
      +nbr
      +"&id_floating="+this.formPlantation.value['id_floating']
     
      ,JSON.stringify(''))
   }
  modifierPlantation(date) {
 
    return this.http
  .post(
    "https://seeds.bg-agri.com/babylone_API/Plantation/Modifier?commentaire="
    +this.formPlantation.value['commentaire']
    +"&nbr_graines="+this.formPlantation.value['nbr_graines']
    +"&date="+this.datePipe.transform( date , 'dd/MM/yyyy')
    +"&nbr_Xframe="+this.formPlantation.value['nbr_Xframe']
    +"&operateur="+this.formPlantation.value['operateur']
    +"&Xframe="+this.formPlantation.value['Xframe']
    +"&id_plantation="+this.formPlantation.value['id_plantation']
    +"&nbr_perdus="+this.formPlantation.value['nbr_perdus']

    
    ,JSON.stringify(''))
  }

  delete_Plantation(id:any) {
    return this.http.get("https://seeds.bg-agri.com/babylone_API/Plantation" + "/delete.php?id="+id);
  }
  All_plantation_for_recolte(){
    return this.http.get("https://seeds.bg-agri.com/babylone_API/Plantation" + "/All_plantation_for_recolte.php" );
  
  
  }
  
 
}
