import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { FactureService } from 'src/app/services/facture.service';
import { FournisseurService } from 'src/app/services/fournisseur.service';
import { LangService } from 'src/app/services/lang.service';


@Component({
  selector: 'app-edit-facture',
  templateUrl: './edit-facture.component.html',
  styleUrls: ['./edit-facture.component.css']
})
export class EditFactureComponent implements OnInit {

  Vide=true
  date_facture = new Date();
  max=  new Date()  ;
  echeance_paymant = new Date();
  myDate2 = new Date();
  submit: boolean;
  progress=false;
  progressvalue=0;
  pdf: string;
  id: any;
  @Input()
  get color(): string {
    return this._color;
  }
  set color(color: string) {
    this._color = color !== "light" && color !== "dark" ? "light" : color;
  }
  private _color = "dark";
  
  constructor(private route: ActivatedRoute, public _langService:LangService ,private datePipe: DatePipe,public _fournisseurService:FournisseurService,public _factureService:FactureService,private http: HttpClient) { }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.id=params['id'] 
   });
   this._factureService.get_Facture(this.id).subscribe(res => {
    this._factureService.initialiser(res)
     
    })
this._fournisseurService.All_Fournisseur().subscribe(res => {
  this._fournisseurService.listFournisseur=res  as  any[];
  })
  
   }

  AddFacture(){
 
    
     this._factureService.modifierFacture(this.date_facture,this.echeance_paymant).subscribe(res => {

      let test =res as  any;
     if (test != null) {
        this._factureService.All_Facture().subscribe(res => {
        this._factureService.listFacture = res as any[]
        this.submit=true
        })
     }
   },
    err => {
       console.log(err)
           }
   )
 
  }

  onSubmit(){
    this.AddFacture();
    console.log('test')

  }

}
