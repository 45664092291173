 
<div
class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0"
>
<div class="rounded-t bg-white mb-0 px-6 py-6">
  <div class="text-center flex justify-between">
    <h6 class="text-blueGray-700 text-xl font-bold">
      {{ _langService.GetLanguage()=='FR' ? 'Ajouter un Fertilisant au stock' : 'Add a Fertilizer to stock' }}
      </h6>
 
  </div>
</div>
<div class="flex-auto px-4 lg:px-10 py-10 pt-0">


 <form  [formGroup]="_fertilisantService.formFertilisant" autocomplete="off" (ngSubmit)="onSubmit()" >

 

  <div class="flex flex-wrap">

    <div class="w-full lg:w-6/12 px-4">
        <div class="relative w-full mb-3">
          <label
            class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
            htmlFor="grid-password"
          >
          {{ _langService.GetLanguage()=='FR' ? 'Fertilisant' : 'Fertilizer' }}
          
          </label>
        
          <select 
            type="text"
            class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
            placeholder="Fertilisant"
            formControlName="id_fertilisant"
            [class.is-invalid]="_fertilisantService.formFertilisant.controls['id_fertilisant'].errors?.required"
          >
          <option *ngFor="let Fertilisant of _fertilisantService.listFertilisant" [value]="Fertilisant.id_fertilisant" > {{Fertilisant.label}} </option>

          </select>
        </div>
      </div>
 

      <div class="w-full lg:w-6/12 px-4">
        <div class="relative w-full mb-3">
          <label
            class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
            htmlFor="grid-password"
          >
          {{ _langService.GetLanguage()=='FR' ? 'Quantité' : 'Quantity' }}
            
          </label>
          <input 
            type="number"
            class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
            [placeholder]="_langService.GetLanguage()=='FR' ? 'Quantité' : 'Quantity'"
            formControlName="quantity"
            [class.is-invalid]="_fertilisantService.formFertilisant['controls'].quantity.errors?.required"
          />
        </div>
      </div>

  </div>

  <div class="align-self-center mx-auto text-center" style="padding-top: 15px;">
  <button  
  class="bg-red-600 text-white active:bg-red-700 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
  type="submit"  
  >
  {{ _langService.GetLanguage()=='FR' ? 'Ajouter' : 'Add' }}
  
</button></div>
<div *ngIf="submit" class="alert alert-success" style="background-color: rgb(98, 223, 98);height: 50px;text-align: center;">
  {{ _langService.GetLanguage()=='FR' ? 'Fertilisant ajouté au stock avec succès' : 'Fertilizer added to stock successfully' }}

  
  <div class="align-self-center mx-auto text-center" style="padding-top: 15px;">
 </div>
</div>
</form>
</div>
</div>
