import { DatePipe } from '@angular/common';
import { HttpClient, HttpEventType } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { Graine } from 'src/app/models/Graine/graine.model';
 import { Utilisateur } from 'src/app/models/utilisateur/utilisateur.model';
import { CompetenceService } from 'src/app/services/competence.service';
import { FactureService } from 'src/app/services/facture.service';
import { FournisseurService } from 'src/app/services/fournisseur.service';
import { LangService } from 'src/app/services/lang.service';
 import { UtilisateurService } from 'src/app/services/utilisateur.service';

@Component({
  selector: 'app-addfacture',
  templateUrl: './addfacture.component.html',
  styleUrls: ['./addfacture.component.css']
})
export class AddfactureComponent implements OnInit {
  Vide=true
  date_facture  ;
  max  ;
  echeance_paymant ;
  myDate2  ;
  submit: boolean;
  progress=false;
  progressvalue=0;
  pdf: string;
  @Input()
  get color(): string {
    return this._color;
  }
  set color(color: string) {
    this._color = color !== "light" && color !== "dark" ? "light" : color;
  }
  private _color = "dark";
  
  constructor(private datePipe: DatePipe,public _fournisseurService:FournisseurService,public _factureService:FactureService,private http: HttpClient, public _langService:LangService) { }

  ngOnInit(): void {
    this._factureService.initialiserVide()

    this.echeance_paymant=this.datePipe.transform( new Date(), 'yyyy-MM-dd')
    this.date_facture=this.datePipe.transform( new Date(), 'yyyy-MM-dd')
    this.myDate2=this.datePipe.transform( new Date(), 'yyyy-MM-dd')
    this.max=this.datePipe.transform( new Date(), 'yyyy-MM-dd')

this._fournisseurService.All_Fournisseur().subscribe(res => {
  this._fournisseurService.listFournisseur=res  as  any[];
  })
  
   }

  AddFacture(){
 
    
    if(this.progressvalue==100)
    this._factureService.insertFacture(this.date_facture,this.echeance_paymant,this.pdf).subscribe(res => {

      let test =res as  any;
     if (test != null) {
        this._factureService.All_Facture().subscribe(res => {
        this._factureService.listFacture = res as any[]
        this.submit=true
        })
     }
   },
    err => {
       console.log(err)
           }
   )
   else {
     alert('Attendez le téléchargement de fichier PDF!')
   }
  }

  onSubmit(){
    this.AddFacture();
    console.log('test')

  }

  //
  selectedFile;
  public files: File = null;
  setImage(image: File) {
    const formData: FormData = new FormData();
    formData.append('avatar', image);
    this.progress=true
    this.pdf=this.files.name

     this.http.post('https://seeds.bg-agri.com/babylone_API/Upload_PDF/upload_pdf.php', formData,{
      reportProgress: true,
      observe: 'events'
   }).subscribe(resp => {
 if (resp.type === HttpEventType.Response) {
     console.log('Upload complete');
    
   
    
 }
 if (resp.type === HttpEventType.UploadProgress) {
     const percentDone = Math.round(100 * resp.loaded / resp.total);
     console.log('Progress ' + percentDone + '%');
     this.progressvalue=percentDone
 } 
});
  
  }
  onFileChanged1(files: FileList) {
  this.selectedFile = files[0]['name']
  this.files = files.item(0);
  this.setImage(this.files);
  }
}
