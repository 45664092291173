import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-dashbord-chef',
  templateUrl: './dashbord-chef.component.html',
  styleUrls: ['./dashbord-chef.component.css']
})
export class DashbordChefComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
