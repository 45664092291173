 
<div
class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded"
[ngClass]="color === 'light' ? 'bg-white' : 'bg-red-900 text-white'"
>
<div class="rounded-t mb-0 px-4 py-3 border-0">
  <div class="flex flex-wrap items-center">
    <div class="relative w-full px-4 max-w-full flex-grow flex-1">
      <h3
        class="font-semibold text-lg"
        [ngClass]="color === 'light' ? 'text-blueGray-700' : 'text-white'"
      >
      {{ _langService.GetLanguage()=='FR' ? 'Historique de semis ' : 'Sowing history' }}
       
      <!-- <button  [routerLink]="['/admin/Ajouter_semis/' ]"  style=" position: absolute;right: 50px;  background-color: rgb(70, 175, 0); color: white;padding: 5px;border-radius: 6px;">Ajouter un semis</button>
--> 
      </h3>
    </div>
  </div>
</div>
<br><br>
<div class="block w-full overflow-x-auto">
  <!-- Projects table -->
  <table class="items-center   bg-transparent border-collapse" style="width: 100%;">
    <thead>
      <tr style="cursor:grab ;">
        <th
          class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
          [ngClass]="
            color === 'light'
              ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
              : 'bg-red-800 text-red-300 border-red-700'
          "
          (click)="sort('id_semis')"
        >
        {{ _langService.GetLanguage()=='FR' ? 'Identifiant unique' : 'Unique ID' }}
        
        </th>
        <th
        class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
        [ngClass]="
          color === 'light'
            ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
            : 'bg-red-800 text-red-300 border-red-700'
        "
        (click)="sort('variete')"
      >
      {{ _langService.GetLanguage()=='FR' ? 'Variété' : 'Variety' }}
      
      </th>
        <th
        class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
        [ngClass]="
          color === 'light'
            ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
            : 'bg-red-800 text-red-300 border-red-700'
        "
        (click)="sort('date')"
      >
      Date 
      </th>
              
        <th
        class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
        [ngClass]="
          color === 'light'
            ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
            : 'bg-red-800 text-red-300 border-red-700'
        "
        (click)="sort('nbr_graines')"
      >
      {{ _langService.GetLanguage()=='FR' ? 'Nombre' : 'number' }}
      <br>
      {{ _langService.GetLanguage()=='FR' ? 'de graines' : 'of seeds' }}
         
      </th>
        
      <th
      class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
      [ngClass]="
        color === 'light'
          ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
          : 'bg-red-800 text-red-300 border-red-700'
      "
      (click)="sort('nbr_plaques')"
    >
    {{ _langService.GetLanguage()=='FR' ? 'Nombre' : 'number' }}
    <br>
    {{ _langService.GetLanguage()=='FR' ? 'de plaques' : 'of Foam plates' }}
 
    </th>
      <th
      class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
      [ngClass]="
        color === 'light'
          ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
          : 'bg-red-800 text-red-300 border-red-700'
      "
      (click)="sort('nbr_perdus')"
    >
    {{ _langService.GetLanguage()=='FR' ? 'Perdues' : 'Lost' }}

    
    </th>
    <th
    class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
    [ngClass]="
      color === 'light'
        ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
        : 'bg-red-800 text-red-300 border-red-700'
    "
    (click)="sort('floating')"
  >
  {{ _langService.GetLanguage()=='FR' ? 'En Transplantation' : 'Transplanted' }}

  
  </th> 
    <th
    class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
    [ngClass]="
      color === 'light'
        ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
        : 'bg-red-800 text-red-300 border-red-700'
    "
    (click)="sort('nbr_disponible')"
  >
  {{ _langService.GetLanguage()=='FR' ? 'Disponibles' : 'Availables' }}
  
  </th>      

          
    <th
    class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
    [ngClass]="
      color === 'light'
        ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
        : 'bg-red-800 text-red-300 border-red-700'
    "
    (click)="sort('operateur')"
  >
  {{ _langService.GetLanguage()=='FR' ? 'Opérateur' : 'Operator' }}
  
  </th>
 
<th
class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
[ngClass]="
  color === 'light'
    ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
    : 'bg-red-800 text-red-300 border-red-700'
"
(click)="sort('commentaire')"
>
{{ _langService.GetLanguage()=='FR' ? 'Commentaire' : 'Comment' }}

</th>        
  <th
  class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
  [ngClass]="
    color === 'light'
      ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
      : 'bg-red-800 text-red-300 border-red-700'
  "
>
Action

</th>
      </tr>
    </thead>
    <tbody>
      <tr  *ngFor="let semis of listSemis" >
        <th
          class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left flex items-left"
        >
      
          <span
            class="ml-3 font-bold"
            [ngClass]="color === 'light' ? 'text-blueGray-600' : 'text-white'"
          >
            {{semis.id_semis}}
          </span>
        </th>
   
  
        <td
        class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
      >
        {{semis.variete}}
      </td>
    
      <td
      class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
    >
      {{semis.date}}
    </td>

    <td
    class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
  >
    {{semis.nbr_graines}}
  </td>   
  
  <td
  class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
>
  {{semis.nbr_plaques}}
</td>   
  <td
  class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
>
  {{semis.nbr_perdus}}
</td>  
<td
class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
>
{{semis.nbr_floating}}
</td>    
<td
class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
>
{{semis.nbr_disponible}}
</td>
      

          <td
          class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
        >
          {{semis.operateur}}
        </td>
   
      <td
      class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
    >
      {{semis.com}}
    </td>
          <td
          class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-center"
        style="cursor:grab ;"
          >
         <i class="fas fa-edit "  style="margin-right:15px ; color: rgb(79, 170, 255);"  [routerLink]="['/admin/editSemis/',semis.id_semis]"></i>
        
        </td>

      </tr>
 
    </tbody>
  </table>
</div>
</div>
