import { Component, OnInit } from "@angular/core";
import { LangService } from "src/app/services/lang.service";

@Component({
  selector: "app-admin-navbar",
  templateUrl: "./admin-navbar.component.html",
})
export class AdminNavbarComponent implements OnInit {
  constructor(public _langService:LangService) {}

  ngOnInit(): void {}
}
