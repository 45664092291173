import { Component, Input, OnInit } from '@angular/core';
import { LangService } from 'src/app/services/lang.service';
import { PlantationService } from 'src/app/services/plantation.service';

@Component({
  selector: 'app-all-plantation',
  templateUrl: './all-plantation.component.html',
  styleUrls: ['./all-plantation.component.css']
})
export class AllPlantationComponent implements OnInit {

  @Input()
  get color(): string {
    return this._color;
  }
  set color(color: string) {
    this._color = color !== "light" && color !== "dark" ? "light" : color;
  }
  private _color = "light";

  listplantation : any[]

  constructor(public _plantationService:PlantationService, public _langService:LangService) {}

  ngOnInit(): void {
    this._plantationService.All_Plantation().subscribe(res => {
      this.listplantation = res as any[]
      console.log(res);
      
       
      }) 
  }
   ///////////

 isDesc: boolean = false;
 column: string = 'CategoryName';
  
  
 sort(property) {
   this.isDesc = !this.isDesc; //change the direction    
   this.column = property;
   let direction = this.isDesc ? 1 : -1;
   if(property!='date' && property!='nbr_graines'  && property!='nbr_Xframe'  && property!='nbr_disponible'  && property!='nbr_recolte' )
   this.listplantation.sort(function (a, b) {
     if (a[property] < b[property]) {
       return -1 * direction;
     }
     else if (a[property] > b[property]) {
       return 1 * direction;
     }
     else {
       return 0;
     }
   });
   else if(property=='date'|| property=='date_floating')
    this.listplantation.sort(function (a, b) {
      let  date1=a[property]
      let  date2=b[property]
      let date1Number= (Number(date1.substring(0,2))*24) + (Number(date1.substring(3,5))*24*30) +  (Number(date1.substring(6,10))*24*30*365)
      let date2Number= (Number(date2.substring(0,2))*24) + (Number(date2.substring(3,5))*24*30) +  (Number(date2.substring(6,10))*24*30*365)
        
      if ( date1Number <  date2Number ){
      return -1 * direction;
    }
    else if ( date1Number >  date2Number ) {
      return 1 * direction;
    }
    else {
      return 0;
    }
  });
   else 
   this.listplantation.sort(function (a, b) {
    if (Number(a[property]) < Number(b[property])) {
      return -1 * direction;
    }
    else if (Number(a[property]) > Number(b[property])) {
      return 1 * direction;
    }
    else {
      return 0;
    }
  });
 };
 
 //////////////
}
