<div>
  <main>

   <section class="relative w-full h-full py-40 min-h-screen">
     <div
       class="absolute top-0 w-full h-full bg-blueGray-800 bg-no-repeat bg-full"
       style="background-image: url('assets/img/register_bg_2.png');background-repeat:no-repeat;
       background-size:cover;
       background-position:center;"
     ></div>
 
     
     <img src="assets/img/logo.png" width="120px" style="position: absolute;
     left: 40px;
     top: 40px;
     z-index: 10;" />
    <!-- <h1 style="text-align: center ;color:dodgerblue;position: absolute;
      top: 20%;
     z-index: 10;
     font-size: 36px;
     font-weight: bold;
     ">Babylone Workflow</h1>
     -->
     <div class="container mx-auto px-4 h-full" style="margin-top:100px ;">
      <div class="flex content-center items-center justify-center h-full">
        <div class="w-full lg:w-4/12 px-4">
          <div
            class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-200 border-0"
          > 
            <br><br>
            <div class="flex-auto px-4 lg:px-10 py-10 pt-0">
              <div class="text-blueGray-400 text-center mb-3 font-bold">
                <small> SIGN IN </small>
              </div>
              <div *ngIf="invalide"  style="text-align:center; background:rgb(255, 96, 96); height:30px">
                {{ _langService.GetLanguage()=='FR' ? 'Login ou mot de passe incorrect' : 'Incorrect login or password' }}

              </div>

              <form   #f="ngForm"  (ngSubmit)="login(f)">
                <div class="relative w-full mb-3">
                  <label
                    class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Login
                  </label>
                  <input
                    type="text"
                    class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    placeholder="Login"
                 
                    name="email" ngModel required #email="ngModel"
                  />
                </div>
    
                <div class="relative w-full mb-3">
                  <label
                    class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Password
                  </label>
                  <input
                    type="password"
                    class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    placeholder="Password"
                     name="password" ngModel required #password="ngModel"

                  />
                </div>
                <div>
                  <label class="inline-flex items-center cursor-pointer">
                    <input
                      id="customCheckLogin"
                      type="checkbox"
                      class="form-checkbox border-0 text-blueGray-700 ml-1 w-5 h-5 ease-linear transition-all duration-150 rounded"
                    />
                    <span class="ml-2 text-sm font-semibold text-blueGray-600">
                      Remember me
                    </span>
                  </label>
                </div>
    
                <div class="text-center mt-6">
                  <button
                    class="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                    type="submit"
                  >
                    Sign In
                  </button>
                </div>
              </form>

            </div>
          </div>
          <!--
          <div class="flex flex-wrap mt-6 relative">
            <div class="w-1/2">
              <a href="javascript:void(0)" class="text-blueGray-200">
                <small>Forgot password?</small>
              </a>
            </div>
            <div class="w-1/2 text-right">
              <a [routerLink]="['/auth/register']" class="text-blueGray-200">
                <small>Create new account</small>
              </a>
            </div>
          </div>-->
        </div>
      </div>
    </div>
    
    </section>
 </main>
</div>


