 
<div
class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded"
[ngClass]="color === 'light' ? 'bg-white' : 'bg-red-900 text-white'"
>
<div class="rounded-t mb-0 px-4 py-3 border-0">
  <div class="flex flex-wrap items-center">
    <div class="relative w-full px-4 max-w-full flex-grow flex-1">
      <h3
        class="font-semibold text-lg"
        [ngClass]="color === 'light' ? 'text-blueGray-700' : 'text-white'"
      >
      {{ _langService.GetLanguage()=='FR' ? 'Historique check-liste' : 'Checklist history' }}
 
      </h3>
    </div>
  </div>
</div>
<br><br>
<div class="block w-full overflow-x-auto">
  <!-- Projects table -->
  <table class="items-center   bg-transparent border-collapse" style="width: 50%;">
    <thead>
      <tr>
        <th
          class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
          [ngClass]="
            color === 'light'
              ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
              : 'bg-red-800 text-red-300 border-red-700'
          "
        >
        {{ _langService.GetLanguage()=='FR' ? 'Check-liste' : 'Checklist' }}
        </th>
        <th
        class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
        [ngClass]="
          color === 'light'
            ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
            : 'bg-red-800 text-red-300 border-red-700'
        "
      >
      Date
      </th>
        <th
        class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
        [ngClass]="
          color === 'light'
            ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
            : 'bg-red-800 text-red-300 border-red-700'
        "
      >
      {{ _langService.GetLanguage()=='FR' ? 'Heure' : 'Hour' }}
      </th>
 
      
      <th
      class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-center"
      [ngClass]="
        color === 'light'
          ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
          : 'bg-red-800 text-red-300 border-red-700' "
    >
  Actions
  </th>
      </tr>
    </thead>
    <tbody>
      <tr  *ngFor="let check of listcheck" >
        <th
          class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-center flex items-left"
        >
      
          <span
            class="ml-3 font-bold"
            [ngClass]="color === 'light' ? 'text-blueGray-600' : 'text-white'"
          >
            {{check.Mode}}
          </span>
        </th>
       
        <td
        class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
      >
        {{check.date}}
      </td>
    
      <td
      class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
    >
      {{check.heure}}
    </td>
  
 
         <td
         class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-center"
       style="cursor:grab ;"
         >
        
        <i class="fas fa-eye "  style="margin-right:15px ; color: rgb(79, 170, 255);"  [routerLink]="['/user/Checkliste_Detail/',check.id_checkListe ]"></i>
 
    </td>
   </tr>
    </tbody>
  </table>
</div>
</div>
