import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LangService } from 'src/app/services/lang.service';
   import { SemisService } from 'src/app/services/semis.service';
import { StockService } from 'src/app/services/stock.service';
 
@Component({
  selector: 'app-edit-semis',
  templateUrl: './edit-semis.component.html',
  styleUrls: ['./edit-semis.component.css']
})
export class EditSemisComponent implements OnInit {
  submit: boolean;
  stock: any[];  
   
  id: any;
  graine: string;

 constructor(private datePipe: DatePipe, public _langService:LangService,  public _stockService:StockService,public _semisService:SemisService,private route: ActivatedRoute) { }

 ngOnInit(): void {
  this.route.params.subscribe(params => {
    this.id=params['id'] //log the value of id
 });
 
 this._stockService.All_Stock_for_semis().subscribe(res => {
  this.stock = res as any[]
  })
 this._semisService.get_Semis(this.id).subscribe(res => {
   console.log(res);
   
  this._semisService.initialiser(res)
  this._semisService.get_graine_by_semis().subscribe(res => {
    console.log(res);
    this.graine= res['id_semis']  + ' -- '+ res['variete'] 
   })
  })

 }


 modifierSemis(){
    
     
     if(Number(this._semisService.formSemis.value['nbr_graines']) >= Number(this._semisService.formSemis.value['nbr_perdus']) )
     this._stockService.verif_date(this._semisService.formSemis.value['unique_id']).subscribe(test => {
       let res= test as any[]
     if (res.length==0)
     this._stockService.get_graine_by_stock(this._semisService.formSemis.value['unique_id']).subscribe(res => {

     this._semisService.modifierSemis().subscribe(res => {
       this.submit=true  
      })
   })
    

      } )
      else
      alert('Le nombre de graine est supérieur à ce qui est disponible en stock!')
   
    }

 onSubmit(){
   this.modifierSemis();
 }
}
