import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LangService } from 'src/app/services/lang.service';
import { UtilisateurService } from 'src/app/services/utilisateur.service';

@Component({
  selector: 'app-sidebar-chef',
  templateUrl: './sidebar-chef.component.html',

})
export class SidebarChefComponent implements OnInit {
  collapseShow = "hidden";
  id: string;
  permissions: Object;
  constructor(public _utilisateurService:UtilisateurService,public _langService:LangService,private route: ActivatedRoute) {}

  ngOnInit() {

    this.id=localStorage.getItem('log');
    this._utilisateurService.get_permissions_true(this.id).subscribe(res => {
      this.permissions=res 
       
       
      })

  }
  toggleCollapseShow(classes) {
    this.collapseShow = classes;
  }

}
