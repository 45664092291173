import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from 'src/environments/environment';
import { FormBuilder, Validators } from '@angular/forms';
import { Stock } from '../models/stock/stock.model';
import { DatePipe } from '@angular/common';
 
@Injectable({
  providedIn: 'root' 
})
export class StockService {
 
  formStock=this.fb.group({
    id_stock : ["" , Validators.required],
	  nbr : [0 , Validators.required],
	  dateReception : ["" , Validators.required],
	  dateExpiration : ["" , Validators.required],
	  commentaire : ["" , Validators.required],
	  id_graine : ["" , Validators.required],
    loi : ["" , Validators.required],
    bon_livraison : ["" , Validators.required],
    num_facture : ["" , Validators.required],
  });

 
  initialiserVide(){
    this.formStock.setValue({
      id_stock : ""  ,
      nbr : 0  ,
      dateReception : ""  ,
      dateExpiration  : ""  ,
      commentaire : ""  ,
      id_graine : ""  ,
      loi : ""  ,
      bon_livraison : ""  ,
      num_facture : ""  ,
    })
     }

  listStock:Stock[]
  constructor(private datePipe: DatePipe,private http: HttpClient, private fb: FormBuilder) {

   }

   get_commande(id){
    return this.http.get("https://seeds.bg-agri.com/babylone_API/Commandes" + "/get_For_reception.php?id="+id);
   }
   verif_date(id){
    return this.http.get("https://seeds.bg-agri.com/babylone_API/Stocks" + "/verif_date.php?id_stock="+id);
   }

   AddToStock(id,id_stock) {
      return this.http
    .post(
      "https://seeds.bg-agri.com/babylone_API/Stocks" + "/save.php?nbr="+
      this.formStock.value['nbr']
      +"&dateReception="+ this.datePipe.transform( this.formStock.value['dateReception'], 'dd-MM-yyyy')
      +"&dateExpiration="+ this.datePipe.transform( this.formStock.value['dateExpiration'], 'dd-MM-yyyy')
      +"&commentaire="+this.formStock.value['commentaire']
      +"&id_graine="+this.formStock.value['id_graine']
      +"&id_stock="+id_stock
      +"&loi="+this.formStock.value['loi']
      +"&bon_livraison="+this.formStock.value['bon_livraison']
      +"&num_facture="+this.formStock.value['num_facture']
      +"&id_commande="+id
       ,JSON.stringify(''))
  }

  All_Stock(){
 
     return this.http.get("https://seeds.bg-agri.com/babylone_API/Stocks" + "/all.php" );
  }
  All_Stock_Chart(){
 
    return this.http.get("https://seeds.bg-agri.com/babylone_API/Stocks" + "/All_Stock_Chart.php" );
 }

  initialiser(ev){
    let dateReception;
    let dateExpiration;
   
      if(ev.dateReception.indexOf("-")==2){
        dateReception=  ev.dateReception.substring(6) + '/'+ ev.dateReception.substring(3,5)+'/'+ ev.dateReception.substring(0,2)
        
        console.log( dateReception)

      }
      else{
        dateReception=ev.dateReception.replaceAll("-","/")
      }

      if(ev.dateReception.indexOf("-")==2){
       dateExpiration=ev.dateExpiration.substring(6)+ '/'+  ev.dateExpiration.substring(3,5)  +'/'+ ev.dateExpiration.substring(0,2) 
      }
      else{
         dateExpiration=ev.dateExpiration.replaceAll("-","/")
      }

     ev.dateReception=this.datePipe.transform( dateReception, 'yyyy-MM-dd') 
     ev.dateExpiration=this.datePipe.transform( dateExpiration, 'yyyy-MM-dd') 

    this.formStock.setValue(ev)
  }

  initialiserDate(id_graine ){
 
    this.formStock.setValue({
      id_stock : ""  ,
      nbr : 0  ,
      dateReception : this.datePipe.transform( new Date(), 'yyyy-MM-dd')  ,
      dateExpiration : this.datePipe.transform( new Date(), 'yyyy-MM-dd')  ,
      commentaire : ""  ,
      id_graine : id_graine  ,
      loi : ""  ,
      bon_livraison : ""  ,
      num_facture : ""  ,
    })
  console.log(this.formStock.value);
  
      }
  //  
   initialiserValues( )
    {
   
    }

  get_stock(id:any) {
    return this.http.get("https://seeds.bg-agri.com/babylone_API/Stocks" + "/get.php?id_stock="+id);
  }

  get_graine_by_stock(id:any) {
    return this.http.get("https://seeds.bg-agri.com/babylone_API/Stocks" + "/get_graine_by_stock.php?id_stock="+id);
  }

  MofierStock(dateReception,dateExpiration) {
    return this.http
  .post(
    "https://seeds.bg-agri.com/babylone_API/Stocks" + "/update.php?nbr="+
    this.formStock.value['nbr']
    +"&dateReception="+dateReception
    +"&dateExpiration="+dateExpiration
    +"&commentaire="+this.formStock.value['commentaire']
    +"&id_graine="+this.formStock.value['id_graine']
    +"&id_stock="+this.formStock.value['id_stock']
    +"&bon_livraison="+this.formStock.value['bon_livraison']
    +"&num_facture="+this.formStock.value['num_facture']
    +"&loi="+this.formStock.value['loi']

    ,JSON.stringify(''))
  }

  delete_Equipe(id:any) {
    return this.http.get("https://seeds.bg-agri.com/babylone_API/Stocks" + "/delete?id="+id);
  }
 
  All_Stock_for_semis(){
    return this.http.get("https://seeds.bg-agri.com/babylone_API/Stocks" + "/all_for_semis.php" );

  }


}
