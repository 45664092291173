import { Component, Input, OnInit } from '@angular/core';
import { Projet } from 'src/app/models/Projet/Projet.model';
import { LangService } from 'src/app/services/lang.service';
import { ProjetService } from 'src/app/services/projet.service';

@Component({
  selector: 'app-listeprojetadmin',
  templateUrl: './listeprojetadmin.component.html',
  styleUrls: ['./listeprojetadmin.component.css']
})
export class ListeprojetadminComponent implements OnInit {
  @Input()
  get color(): string {
    return this._color;
  }
  set color(color: string) {
    this._color = color !== "light" && color !== "dark" ? "light" : color;
  }
  private _color = "light";

  listcommande : Projet[]

  constructor(public _projetService:ProjetService, public _langService:LangService) {}

  ngOnInit(): void {
   this.Listeusers();
  }

  Listeusers(){
    this._projetService.All_Projet().subscribe(res => {
      this.listcommande = res as Projet[]
      console.log(res);
      
      })
  }
///////////

 isDesc: boolean = false;
column: string = 'CategoryName';
 
 
sort(property) {
  this.isDesc = !this.isDesc; //change the direction    
  this.column = property;
  let direction = this.isDesc ? 1 : -1;

  this.listcommande.sort(function (a, b) {
    if (a[property] < b[property]) {
      return -1 * direction;
    }
    else if (a[property] > b[property]) {
      return 1 * direction;
    }
    else {
      return 0;
    }
  });
};

//////////////
}
