 
<div
class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded"
[ngClass]="color === 'light' ? 'bg-white' : 'bg-red-900 text-white'"
>
<div class="rounded-t mb-0 px-4 py-3 border-0">
  <div class="flex flex-wrap items-center">
    <div class="relative w-full px-4 max-w-full flex-grow flex-1">
      <h3
        class="font-semibold text-lg"
        [ngClass]="color === 'light' ? 'text-blueGray-700' : 'text-white'"
      >
      {{ _langService.GetLanguage()=='FR' ? 'Historique de mesures' : 'History of measurements' }}

      <!-- <button  [routerLink]="['/admin/Ajouter_mesure/' ]"  style=" position: absolute;right: 50px;  background-color: rgb(70, 175, 0); color: white;padding: 5px;border-radius: 6px;">Ajouter un mesure</button>
--> 
      </h3>
    </div>
  </div>
</div>
<br><br>
<div class="block w-full overflow-x-auto">
  <!-- Projects table -->
  <table class="items-center   bg-transparent border-collapse" style="width: 100%;">
    <thead>
      <tr>
        <th
          class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
          [ngClass]="
            color === 'light'
              ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
              : 'bg-red-800 text-red-300 border-red-700'
          "
          (click)="sort('date')"
        >
        Date
        </th>
        <th
        class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
        [ngClass]="
          color === 'light'
            ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
            : 'bg-red-800 text-red-300 border-red-700'
        "
        (click)="sort('type')"
      >
      {{ _langService.GetLanguage()=='FR' ? 'Serre' : 'Greenhouse' }}

      
      </th>
        <th
        class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
        [ngClass]="
          color === 'light'
            ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
            : 'bg-red-800 text-red-300 border-red-700'
        "
        (click)="sort('table_Xframe')"
      >
      Table {{ _langService.GetLanguage()=='FR' ? 'ou' : 'or' }} Xframe
      </th>
              
        <th
        class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
        [ngClass]="
          color === 'light'
            ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
            : 'bg-red-800 text-red-300 border-red-700'"
          (click)="sort('mode')"
      >
      Type
      </th>
            
      <th
      class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
      [ngClass]="
        color === 'light'
          ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
          : 'bg-red-800 text-red-300 border-red-700'
      "
          (click)="sort('ph')"
    >
     PH
    </th>
             
    <th
    class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
    [ngClass]="
      color === 'light'
        ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
        : 'bg-red-800 text-red-300 border-red-700'
    "
          (click)="sort('ec')"
  >
  EC
  </th>        
  <th
  class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
  [ngClass]="
    color === 'light'
      ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
      : 'bg-red-800 text-red-300 border-red-700'
  "
        (click)="sort('od')"
>
Chlore
</th>
    
<th
class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
[ngClass]="
  color === 'light'
    ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
    : 'bg-red-800 text-red-300 border-red-700'
"
      (click)="sort('od')"
>
{{ _langService.GetLanguage()=='FR' ? 'température' : 'indoor' }}
<br> 
{{ _langService.GetLanguage()=='FR' ? 'intérieure' : 'temperature' }}
 
</th>
    
<th
class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
[ngClass]="
  color === 'light'
    ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
    : 'bg-red-800 text-red-300 border-red-700'
"
      (click)="sort('od')"
>
{{ _langService.GetLanguage()=='FR' ? 'température' : 'outside' }}
<br> 
{{ _langService.GetLanguage()=='FR' ? 'intérieure' : 'temperature' }}
 
</th>
    
<th
class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
[ngClass]="
  color === 'light'
    ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
    : 'bg-red-800 text-red-300 border-red-700'
"
      (click)="sort('od')"
>
{{ _langService.GetLanguage()=='FR' ? 'Humidité' : 'Humidity' }}


</th>
<th
class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
[ngClass]="
  color === 'light'
    ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
    : 'bg-red-800 text-red-300 border-red-700'
"
      (click)="sort('od')"
>
{{ _langService.GetLanguage()=='FR' ? 'Opérateur' : 'Operator' }}

</th>

      </tr>
    </thead>
    <tbody>
      <tr  *ngFor="let mesure of listMesure" >
        <th
          class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left flex items-left"
        >
      
          <span
            class="ml-3 font-bold"
            [ngClass]="color === 'light' ? 'text-blueGray-600' : 'text-white'"
          >
            {{mesure.date}}
          </span>
        </th>
        <td
        class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
      >
      <span *ngIf="mesure.type=='P1'">Serre de production P1</span>   
      <span *ngIf="mesure.type=='P2'">Pépinière P2</span>
      </td>
  
        <td
        class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
      >
        {{mesure.table_Xframe}}
      </td>
  
      <td
      class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
    >
      {{mesure.mode}}
    </td>
    
            <td
            class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
          >
            {{mesure.ph}}
          </td>  

          <td
          class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
        >
          {{mesure.ec}}
        </td>

        <td
        class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
      >
        {{mesure.od}}
      </td>

      <td
      class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
    >
      {{mesure.temp_inter}}
    </td>

    <td
    class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
  >
    {{mesure.temp_ext}}
  </td>

  <td
  class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
>
  {{mesure.humidite}}
</td>

<td
class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
>
{{mesure.operateur}}
</td>

 
      </tr>
 
    </tbody>
  </table>
</div>
</div>
