import { Component, OnInit } from '@angular/core';
import { LangService } from 'src/app/services/lang.service';
import { SemisService } from 'src/app/services/semis.service';

@Component({
  selector: 'app-addmousse',
  templateUrl: './addmousse.component.html',
  styleUrls: ['./addmousse.component.css']
})
export class AddmousseComponent implements OnInit {
  submit: boolean;
  mousses=0
  constructor(public _semisService:SemisService, public _langService:LangService) {}

  ngOnInit(): void {
  this._semisService.Nbr_Mousses_Disponible() .subscribe(res => {
    this.mousses=Number(res)
    console.log( this.mousses);

    })
   
  }

  AddMousse(){
    this._semisService.insertMousse().subscribe(res => {
      this.submit=true
      this._semisService.Nbr_Mousses_Disponible() .subscribe(res => {
        this.mousses=Number(res)
        console.log( this.mousses);
    
        })
    })
  }

  onSubmit(){
    this.AddMousse();
   }
  

}
